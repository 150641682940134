
import axiosInstance from '../services/AxiosInstance'

export class IVRiversMatchingService {
    static  getAllRiversMatching() {
        return axiosInstance.get(`/IVRiversMatching`)
                    .then((resp)=>resp.data);
    }

    static  getPagedRiversMatching(currentPage, pageSize) {
        return axiosInstance.get(`/IVRiversMatching/GetPageIVRiversMatching?PageNumber=${currentPage}&PageSize=${pageSize}`)
                    .then((resp)=>resp.data);
    }

    static  getRiversMatching(RuleCode, FieldName) {
        return axiosInstance.get(`IVRiversMatching/GetIVRiversMatching?RuleCode=${RuleCode}&fieldName=${FieldName}`)
                    .then((resp)=>resp.data);
    }

    static  getRiversMatchingRule(RuleCode) {
        return axiosInstance.get(`IVRiversMatching/GetIVRiversMatchingRule?RuleCode=${RuleCode}`)
                    .then((resp)=>resp.data);
    }

    static addRiversMatching(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.post(`/IVRiversMatching`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editRiversMatching(payload) {
        return axiosInstance.patch("IVRiversMatching/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteRiversMatching(payload) {
        return axiosInstance.post("IVRiversMatching/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteRiversMatchingSpecial(payload) {
        return axiosInstance.post("IVRiversMatching/DeleteSpecial", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default IVRiversMatchingService