import { React, useEffect, useState } from 'react'
import { Row, Col, Container, Table, Button } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import UserService from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Accordion from 'react-bootstrap/Accordion';
import Badge from 'react-bootstrap/Badge';
//import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'
import MetaData from '../../services/Data/MetaData'

import KPI from './KPI';

const Home = () => {
  let users = [{id:1, username: "name1", roleId: "admin"},{id:2, username: "name2", roleId: "admin"}]
  let [state, setState] = useState(users);

  const auth = useAuth();
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);
  const [ServiceAlerts, setServiceAlerts] = useState([]);

  useEffect(()=>{
    MetaData.getServiceAlerts().then((data)=>{
      setServiceAlerts(...ServiceAlerts,data);
      console.log('Alerts=>',data);
    })
  },[])

  useEffect(() => {
    let userLogin = null;
      try
      {
        userLogin = JSON.parse(localStorage.getItem('credentials')).userName;
            AuthService.getForcedLogoutUser(userLogin).then((login)=>{
                if ((login.loginStatus === 'ForcedLogout') || (login.loginStatus === 'Logout')) {                
                    localStorage.removeItem('credentials')
                    navigate("/Login");
                }});
            UserService.getUser(userLogin).then((user)=>{
              if (user.status === 'InActive') {                
                  localStorage.removeItem('credentials')
                  navigate("/Login");
              }});

      }
      catch{}
      
      
      if (userLogin === null || userLogin === undefined) {
        setLogin(false);
        navigate('/Login');
      }
      else
      {
        setLogin(true);
      }
    },[]);
  // useEffect(()=>{
  //   UserService.getAllUsers().then((resp) =>{
  //     setState({...state, users: resp.data });
  //     console.log('RESP = ', resp);
  //   }).catch((e) => {
  //     console.log('Error', e.message);
  //   },[]);
  // })

  const generate = () => {
    UserService.getAllUsers().then((resp) =>{
      console.log('Response = ', resp);

      setState({...state, users: resp.data });
      users = [...resp];
      console.log('State modified = ', users);

    }).catch((e) => {
      console.log('Error', e.message);
    },[]);
  }
  const item = "sales.jpg";
  return (    
    <>
            {
              JSON.parse(localStorage.getItem('credentials')) && 
              <>              
                  <KPI />
                  <span><Button hidden onClick={ generate }>Generate Report</Button></span>

                <Accordion>
                  <Accordion.Item eventKey="0" key="0">
                    <Accordion.Header><h6>Service Alert History</h6></Accordion.Header>
                    <Accordion.Body>
                          <Container>
                          <Row>
                            <Col>
                                    <Table striped bordered hover variant="dark">
                                    <thead>
                                          <tr key= { Math.random() }>
                                            <th>Type</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Equipments</th>
                                            <th className='alertMessage'>Message</th>
                                          </tr>
                                    </thead>
                                    <tbody>
                                          {
                                            ServiceAlerts.map((alert)=>{
                                              //console.log('User = ',user);
                                          return ( 
                                                    <>
                                                      <tr key={alert.id}>
                                                        <td className='alertType'> {alert.alertType}</td>
                                                        <td className='alertDate'> {alert.alertDate.toString().substring(0,10)}</td>
                                                        <td className='alertTime'> {alert.alertTime}</td>
                                                        <td className='alertTime'> {alert.equipments}</td>
                                                        <td className='alertMessage'> {alert.alertMessage}</td>
                                                        </tr>
                                                    </>
                                                    ) 
                                          })}
                                    </tbody>
                                </Table>                              
                            </Col>
                          </Row>
                        </Container>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1">
                    <Accordion.Header><h6>Price Trend Report</h6></Accordion.Header>
                    <Accordion.Body>
                    <Container>
                          <Row>
                            <Col>
                                    <Table striped bordered hover variant="dark">
                                    <thead>
                                          <tr key= { Math.random() }>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                          </tr>
                                    </thead>
                                    <tbody>
                                          {
                                            state.users?.map((user)=>{
                                          return ( 
                                                    <>
                                                      <tr  key= { Math.random()} >
                                                        <td> {user.id}</td>
                                                        <td> {user.name}</td>
                                                        <td> {user.email}</td>
                                                        </tr>
                                                    </>
                                                    ) 
                                          })}
                                    </tbody>
                                </Table>                              
                            </Col>
                          </Row>
                        </Container>
                    </Accordion.Body>
                  </Accordion.Item>



                  <Accordion.Item eventKey="2">
                    <Accordion.Header><h6>Price Forecast Report</h6></Accordion.Header>
                    <Accordion.Body>
                    <Container>
                          <Row>
                            <Col>
                                    <Table striped bordered hover variant="dark">
                                    <thead>
                                          <tr key= { Math.random() }>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                          </tr>
                                    </thead>
                                    <tbody>
                                          {
                                            state.users?.map((user)=>{
                                          return ( 
                                                    <>
                                                      <tr  key= { Math.random()} >
                                                        <td> {user.id}</td>
                                                        <td> {user.name}</td>
                                                        <td> {user.email}</td>
                                                        </tr>
                                                    </>
                                                    ) 
                                          })}
                                    </tbody>
                                </Table>                              
                            </Col>
                          </Row>
                        </Container>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>
          </>
      }
    </>
  )
}

export default Home