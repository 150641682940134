import { machineTypeUri, apiMachineTypeUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class MachineTypeService {
    static  getAllMachineTypes() {
        return axiosInstance.get(`/MachineType`)
                    .then((resp)=>resp.data);
    }

    static  getPagedMachineType(currentPage, pageSize) {
        return axiosInstance.get(`/MachineType/GetPageMachineType?PageNumber=${currentPage}&PageSize=${pageSize}`)
                            .then((resp)=>resp.data);
    }

    static  getMachineType(machineTypeId) {
        return axiosInstance.get(`/MachineType/GetMachineType?type=${machineTypeId}`)
                    .then((resp)=>resp.data);
    }

    static  getMachineTypeIndustry(IndustryName) {
        return axiosInstance.get(`/MachineType/GetMachineTypeIndustry?IndustryName=${IndustryName}`)
                    .then((resp)=>resp.data);
    }
    

    static addMachineType(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.post(`/MachineType`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editMachineType(payload) {
        return axiosInstance.patch("/MachineType/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteMachineType(payload) {
        return axiosInstance.post("/MachineType/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default MachineTypeService