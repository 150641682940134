import { makeUri, apiMakeUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'


const axios = require('axios');

export class MakeService {
    static  getAllMakes() {
        return axiosInstance.get(`/Make`)
                    .then((resp)=>resp.data);
    }

    static  getPagedMake(currentPage, pageSize) {
        return axiosInstance.get(`/Make/GetPageMake?PageNumber=${currentPage}&PageSize=${pageSize}`)
                            .then((resp)=>resp.data);
    }

    static  getMake(makeId) {
        return axiosInstance.get(`/Make/GetMake?MakeName=${makeId}`)
                    .then((resp)=>resp.data);
    }

    static addMake(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.post(`/Make`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editMake(payload) {
        return axiosInstance.patch("/Make/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteMake(payload) {
        return axiosInstance.post("/Make/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default MakeService