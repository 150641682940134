import React, {  useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Input from 'react-bootstrap/Form';
import {Row, Container, Col, Button } from 'react-bootstrap/'
import './style.css'

import { bool, string } from 'yup';
import './style.css'
import { OutboundEMSService } from '../../services/OutboundEMSService'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

import SettingsService from '../../services/SettingsService'
import { number } from 'yup/lib/locale';

import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'
import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'


let payLoad = {
    Id: number,
    Property1: string,
    Property2: string,
    Property3: string,
    Property4: string,
    Property5: bool,
    Property6: bool,
    Property7: bool,
    Property8: bool,
    Property9: Date,
    Property10: Date,
    Property11: number,
    Property12: number,
    Property13: number
}

const Settings = () => {
    const [Property3, setProperty3] = useState();
    const [Property4, setProperty4] = useState();

    const [Property5, setProperty5] = useState();
    const [Property6, setProperty6] = useState();
    const [Property7, setProperty7] = useState();
    const [Property8, setProperty8] = useState();

    const [isLogin, setLogin] = useState(false);
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(()=>{
        SettingsService.getSettings().then((data)=>{
            document.getElementById('Property1').value = data.property1;
            document.getElementById('Property2').value = data.property2;
            document.getElementById('Property3').value = data.property3;
            document.getElementById('Property4').value = data.property4;
            var date = new Date(data.property9);
            var year = date.toLocaleString("default", { year: "numeric" });
            var month = date.toLocaleString("default", { month: "2-digit" });
            var day = date.toLocaleString("default", { day: "2-digit" });
            // Generate yyyy-mm-dd date string
            var formattedDate = year + "-" + month + "-" + day;
            document.getElementById('Property9').value = formattedDate; 

            console.log('Data ==== > ',data);
            setProperty3(data.property3);
            setProperty4(data.property4);
            setProperty5(data.property5);
            setProperty6(data.property6);
            setProperty7(data.property7);
            setProperty8(data.property8);
            console.log('Property3 = ',Property3);
            console.log('Property4 = ',Property4);
            console.log('Property5 = ',Property5);
            console.log('Property6 = ',Property6);
            console.log('Property7 = ',Property7);
            console.log('Property8 = ',Property8);
         });
    },[]);

    useEffect(() => {
        let userLogin = null;
          try
          {
            userLogin = JSON.parse(localStorage.getItem('credentials')).userName;
            AuthService.getForcedLogoutUser(userLogin).then((login)=>{                
                if ((login.loginStatus === 'ForcedLogout') || (login.loginStatus === 'Logout')) {                
                    localStorage.removeItem('credentials')
                    navigate("/Login");
                }});
            UserService.getUser(userLogin).then((user)=>{
                if (user.status === 'InActive') {                
                    localStorage.removeItem('credentials')
                    navigate("/Login");
                }});

      
          }
          catch{}
          
          
          if (userLogin === null || userLogin === undefined) {
            setLogin(false);
            navigate('/Login');
          }
          else
          {
            setLogin(true);
          }
        },[]);
        
    const setValues = (() => {        
        payLoad = {
            Id: 3,
            Property1: document.getElementById('Property1').value,
            Property2: document.getElementById('Property2').value,
            Property3: document.getElementById('Property3').value,
            Property4: document.getElementById('Property4').value,
            Property5: Property5,
            Property6: Property6,
            Property7: Property7,
            Property8: Property8,
            Property9: document.getElementById('Property9').value,
            Property10: new Date(),
            Property11: 0,
            Property12: 0,
            Property13: 0,
         };


         console.log('Data Set = ',Property5,Property6,Property7,Property8);

             SettingsService.setSettings(payLoad)
                .then((setting)=> {
                    setting.data ? toast.success('Settings modified successfully',{ position: 'bottom-center'}):toast.info('Settings does not exists',{ position: 'bottom-center'});
                }).catch((e) => {
                    toast.error('Settings modify error',{ position: 'bottom-center'})
                });
        });

    const submitForm = (()=>{
        setValues();
    })
    const pullSettings = (()=>{
        SettingsService.getSettings().then((data)=>{
            setProperty3(data.property3);
            setProperty4(data.property4);
            setProperty5(data.property5);
            setProperty6(data.property6);
            setProperty7(data.property7);
            setProperty8(data.property8);
    
            console.log('Data Read = ',data.property5,data.property6,data.property7,data.property8);            
            console.log('Data Set = ',Property5,Property6,Property7,Property8);

            document.getElementById('Property1').value = data.property1;
            document.getElementById('Property2').value = data.property2;

                var date = new Date(data.property9);
                var year = date.toLocaleString("default", { year: "numeric" });
                var month = date.toLocaleString("default", { month: "2-digit" });
                var day = date.toLocaleString("default", { day: "2-digit" });
                // Generate yyyy-mm-dd date string
                var formattedDate = year + "-" + month + "-" + day;
                document.getElementById('Property9').value = formattedDate; 

                console.log('useEffect Data = ',data);
                console.log('Property 5 =',data.property5,document.getElementById('Property5').value);
                console.log('Property 6 =', data.property6,document.getElementById('Property6').value);
                setProperty5(data.property5);
                console.log('data.property5 = ', data.property5);
                console.log('data.property6 = ', data.property6);
                console.log('Property5 = ', Property5);
                console.log('Property6 = ', Property6);

                document.getElementById('Property3').value = data.property3;
                document.getElementById('Property4').value = data.property4;
                document.getElementById('Property5').value = data.property5;
                document.getElementById('Property6').value = data.property6;    
                document.getElementById('Property7').value = data.property7;
                document.getElementById('Property8').value = data.property8
            
         });
    })

    const switch5Clicked = ((e)=> {
         e.preventDefault();
         setProperty5(e.target.checked);
         console.log(e.target.checked);
    })

    const switch6Clicked = ((e)=>{
        e.preventDefault();
        setProperty6(e.target.checked);
        console.log(e.target.checked);
    })

    const switch7Clicked = ((e)=>{
        e.preventDefault();
        setProperty7(e.target.checked);
        console.log(e.target.checked);
    })


    const switch8Clicked = ((e)=>{
        e.preventDefault();
        setProperty8(e.target.checked);
        console.log(e.target.checked);
    })
    
    const refreshFrontEnd = (()=>{


        OutboundEMSService.RefreshGetCall().then(data=>{
            console.log('Data fetched => ',data);
            const UID = 'dpl_2a1PUNfFMTAdn24coRggaqCDuvuA';
            OutboundEMSService.RefreshPostCall(UID).then(dat=>{
                console.log('The post return => ',dat);
                toast.success('Frontend refreshed successfully',{ position: 'bottom-center'})
            }).catch(error=> {
                toast.error('Frontend refreshed failed',{ position: 'bottom-center'})
            });
    
        }).catch(error=> {
            toast.error('Frontend refreshed failed',{ position: 'bottom-center'})
        });  

    })

    const refreshEMSLiveFeed = (()=>{
        const payload = {
            sku: '0',
            features: '0',
            updateSwitch: '1'
        }

        OutboundEMSService.LiveFeedManually(payload).then(data=>{
            toast.success('EMS Livefeed Update successfully',{ position: 'bottom-center'})
        }).catch(error=>{
            toast.error('EMS Livefeed Update failed',{ position: 'bottom-center'})
        });
    })
    
    const themeData = [
        { value: 'Default Theme', name: 'Default Theme' },
        { value: 'Dark Theme', name: 'Dark Theme' },
        { value: 'Light Theme', name: 'Light Theme' }            
    ];

    return (
    <>

        {
            JSON.parse(localStorage.getItem('credentials')) && 
            JSON.parse(localStorage.getItem('roles')).role === 'admin' &&

                <>
                    <div className="settings">
                    <span>
                    
                      <Container>
                            <Row>

                            <Col xs sm md lg ="6">
                                <Form>
                                        <Form.Check 
                                                type="switch"
                                                id = "Property5"
                                                label="Users can edit profile"
                                                onChange={(e)=>  switch5Clicked(e) }
                                                defaultChecked={ Property5 }
                                        />
                                    
                                        <Form.Check 
                                            type="switch"
                                            id="Property6"
                                            label="Users can view list of users"
                                            onChange={(e)=>  switch6Clicked(e) }    
                                            defaultChecked={ Property6 }
                                        />
                                            

                                        <Form.Switch 
                                            type="switch"
                                            id="Property7"
                                            label="Users can change password"
                                            onChange={(e)=>  switch7Clicked(e) }
                                            defaultChecked={ Property7 }

                                        />
                        
                                        <Form.Switch 
                                            type="switch"
                                            id="Property8"
                                            label="Users can view profile"
                                            onChange={(e)=> switch8Clicked(e) }                                               
                                            defaultChecked ={ Property8 }
                                            />                                               
                                </Form>
                                <hr/>
                                <Button variant="primary" onClick={ refreshFrontEnd }  type="button">Refresh Frontend</Button>
                                <br/><br/>
                                
                                <Button variant="primary" onClick={ refreshEMSLiveFeed }  type="button">Refresh EMS Livefeed</Button>        
                            </Col>

                            <Col xs sm md lg ="6">
                            <Form>
                                <Form.Group className="mb-3" controlId="Property9">
                                    <Form.Label>Alert Date</Form.Label>
                                    <Form.Control type="date" placeholder="Email Date" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="Property1">
                                    <Form.Control type="email" placeholder="Alert email" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="Property2">
                                    <Form.Control type="text" placeholder="Alert Phone" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="Property3">
                                    <Form.Label>Service Interval</Form.Label>
                                    <Form.Control type="text" placeholder="0" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="Property4">
                                <Form.Label>Console Theme</Form.Label>
                                    <select className="select" name="theme" id="Property4">
                                        <option key="Default Theme" value="Default Theme">Default Theme</option>
                                        <option key="Dark Theme" value="Dark Theme">Dark Theme</option>
                                        <option key="Light Theme" value="Light Theme">Light Theme</option>
                                    </select>
                                </Form.Group>


                                </Form>                            
                            </Col>
                        </Row>

                        <Row>
                            <Col xs sm md lg ="12">
                            <hr/>
                                <Button variant="primary" onClick={ submitForm }  type="button">Save Settings</Button>
                                {/* <Button variant="primary" onClick={ pullSettings }  type="button">Pull Settings</Button> */}
                            <hr/>                    
                            </Col>
                    
                            <Col xs sm md lg ="12">
                            
                            </Col>
                        </Row>

                        </Container>
                </span>
                <span>
                </span>
            </div>
        </>
        }
    </>
  )
}

export default Settings