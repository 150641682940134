import React, { useState, useEffect } from 'react'
import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'
import { AuthService } from '../../services/AuthService'
import { number, string } from 'yup/lib/locale'
import UserService from '../../services/UserService'
import Cookies from 'universal-cookie';


const Logout = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [isLogin, setLogin] = useState(false);

    useEffect(() => {
        let userLogin = null;
          try
          {
            userLogin = JSON.parse(localStorage.getItem('credentials')).userName;
            AuthService.getForcedLogoutUser(userLogin).then((login)=>{
                if ((login.loginStatus === 'ForcedLogout') || (login.loginStatus === 'Logout')) {                
                    localStorage.removeItem('credentials')
                    localStorage.removeItem('roles')
                    navigate("/Login");
                }});
            UserService.getUser(userLogin).then((user)=>{
              if (user.status === 'InActive') {                
                  localStorage.removeItem('credentials')
                  localStorage.removeItem('roles')
                  navigate("/Login");
              }});

          }
          catch{}
          
          
          if (userLogin === null || userLogin === undefined) {
            setLogin(false);
            navigate('/Login');
          }
          else
          {
            setLogin(true);      
          }
        },[]);

        
    let loginValues = {
        Id: number,
        username: string,
        loginDate: Date,
        logoutDate: Date,
        loginStatus: string
    }

    const SignOut = (()=>{
               // Logout Logic here 
               var userInStorage = JSON.parse(localStorage.getItem('credentials')).userName; 

               console.log('userInStorage', userInStorage);
               console.log('Local Storage = ',localStorage.getItem('credentials'));

               AuthService.getUser(userInStorage).then((user)=>{
                console.log('Logout User', user)
                loginValues = {
                    Id: user.id,
                    username: userInStorage,
                    LoginDate: user.loginDate,
                    LogoutDate: new Date(),
                    loginStatus: 'Logout'
                }
//                console.log('User in storage = ', user, user === null, user !== null);
                if (user !== null) {
                    AuthService.signOut(loginValues).then((data)=>{
                      console.log('Logout Data = ', data);
                        auth.logout(null); 
                        localStorage.removeItem('credentials');
                        localStorage.removeItem('roles');

                        const cookies = new Cookies();
                        cookies.remove('Username');
                        cookies.remove('Password');
                        console.log('Username = ',cookies.get('Username')); // Pacman
                        console.log('Password = ',cookies.get('Password')); // Pacman
  
                        navigate('/Login');
                    });                              
                }   
            });    
    })


  return (
    <>  
        
        {
            JSON.parse(localStorage.getItem('credentials')) && SignOut()             
        }
                
    </>
  )
}

export default Logout