import React, {  useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { string } from 'yup';
import '../forms/forms.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

import MachineTypeService from '../../services/MachineTypeService'
import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Modal from '../../components/Modal/Modal'
import MetaData from '../../services/Data/MetaData'


import { number } from 'yup/lib/locale';

import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'

// *******************************************************************
import ReactPaginate from 'react-paginate'
import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { machineTypeUri, pageSize, BaseURL, PortNumber } from '../../constants'
// ********************************************************************

let formValues = {
    id: number,
    type: string,
    desc: string
}
const MachineType = () => {
    const auth = useAuth();  
    const navigate = useNavigate();
    const [isLogin, setLogin] = useState(false);
    const [RecordAdded, setRecordAdded] = useState(0);

    // **************************************************************************************************
    const [modalOpen, setModalOpen] = useState(false);
    const [modalAction, setAction] = useState(false);
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState([]);
    const [IndustrySet, setIndustrySet] = useState([]);

    useEffect(() => {
      const getComments = async () => {
        //   const res = await fetch(machineTypeUri);
        //   const data = await res.json();
          const data = await MachineTypeService.getAllMachineTypes();

          const total = data.length;
          const TotalPages = Math.ceil(total/pageSize);
          setPageCount(TotalPages);
          setItems(data);            
          const dataSet2 = await MetaData.getAllIndustry();
            setIndustrySet(dataSet2);

          const PageOneFromServer = await fetchComments(1);
          setItems(PageOneFromServer);
      };
  
      getComments();
  
    },[RecordAdded]);     
    
    const fetchComments = async(currentPage) => {
            // const res = await fetch(`${BaseURL}:${PortNumber}/MachineType/GetPageMachineType?PageNumber=${currentPage}&PageSize=${pageSize}`);
            // const data = await res.json();
            const data = await MachineTypeService.getPagedMachineType(currentPage, pageSize);

            return data;
        };
          
  
        const handlePageClick = async (data) => {
            let currentPage = data.selected + 1;
            const commentsFromServer = await fetchComments(currentPage);
            setItems(commentsFromServer);
          }
          
    // **************************************************************************************************
    const { 
            register, watch, handleSubmit, 
            // setValue, resetValue,unregister, 
            // resetField, setError, clearErrors, 
            // setFocus, getValues, trigger, control,
            formState: { 
                errors, 
               // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
            } } 
        = useForm({ defaultValues: { id: 0, type1: '', desc: '' } });

    useEffect(() => {
        const subscription = watch((data)=>{
            console.log(data);
        })
        return ()=> {
            subscription.unsubscribe();
        }
    }, [watch]) 

    useEffect(() => {
        let userLogin = null;
          try
          {
            userLogin = JSON.parse(localStorage.getItem('credentials')).userName;
            AuthService.getForcedLogoutUser(userLogin).then((login)=>{                
                if ((login.loginStatus === 'ForcedLogout') || (login.loginStatus === 'Logout')) {                
                    localStorage.removeItem('credentials')
                    navigate("/Login");
                }});
            UserService.getUser(userLogin).then((user)=>{
                if (user.status === 'InActive') {                
                    localStorage.removeItem('credentials')
                    navigate("/Login");
                }});      
          }
          catch{}
          
          
          if (userLogin === null || userLogin === undefined) {
            setLogin(false);
            navigate('/Login');
          }
          else
          {
            setLogin(true);
          }
        },[]);

    const onSubmit = (data, event) => {
        event.preventDefault();
        formValues = {
            id: 0,
            type: data.type1.trim(),
            desc: data.desc.trim()
        };
        MachineTypeService.addMachineType(formValues).then((machineType)=>{
            console.log('Form Values = ', formValues);
            console.log('Machine Type = ',machineType);
            toast.success('Machine Type Added Successfully',{ position: 'bottom-center'});
            setRecordAdded((previousState)=> previousState + 1);
            clearValueForm();
        }).catch((e) => {
            console.log('Add Machine Type Error', e.message);
            toast.error('Adding Machine Type Error ',{ position: 'bottom-center'})
        });
    }
    const updateMachineType = () =>{
        setValueForm();
         MachineTypeService.editMachineType(formValues).then((machineType)=>{
            machineType.data ? toast.success('Machine Type Edited Successfully',{ position: 'bottom-center'}):toast.info('Profile does not exists',{ position: 'bottom-center'});
            setRecordAdded((previousState)=> previousState + 1);
            clearValueForm();
        })
        .catch((e) => {
            console.log('Edit Machine Type Error', e.message);
            toast.error('Edit Machine Type Error ',{ position: 'bottom-center'})
        });
    }
    const deleteMachineType = () =>{
        // setValueForm();
        // ProfileService.deleteProfile(formValues).then((profile)=> {
        //     profile.data ? toast.success('Profile Delete Successfully .... ',{ position: 'bottom-center'}):toast.info('Profile does not exists',{ position: 'bottom-center'});
        //     clearValueForm();
        // }).catch((e) => {
        //     console.log('Delete Profile Error', e.message);
        //     toast.error('Delete Profile Error ',{ position: 'bottom-center'})
        // });
    }

    const setValueForm = (() => { 
        formValues = {
            id: document.getElementById('id').value,
            type: document.getElementById('type1').value,
            desc: document.getElementById('desc').value
         };
    });
 
    const clearValueForm = (() => {
        formValues = {
            id: '',
            type: '',
            desc: ''
         };

         document.getElementById('id').value= '';
         document.getElementById('type1').value= '';
         document.getElementById('desc').value= '';
    });

    const GetMachineType = (selectedMachineType) =>{
        MachineTypeService.getMachineType(selectedMachineType.trim()).then((resp) =>{
              const selectedMachineType =  resp;
              document.getElementById('id').value= resp.id;
              document.getElementById('type1').value= resp.type;
              document.getElementById('desc').value= resp.desc;
              console.log(selectedMachineType);
          }).catch((e) => {
              console.log('Error', e.message);
          });    
  }

    return (
    <>
        {
            localStorage.getItem('credentials') && 
          <>



      <Container>
        <Row>
            <Col md = "4">
                   <div className="heading">
                        <h5 className="mainheading">Machine Types</h5>
                        <hr/>
                    </div>              

        
                    <form onSubmit={handleSubmit(onSubmit)}>

                    <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>

                    <input className="shortInput" type="text" {...register("type1", { required: {value: true, message: "This is required field"}})}  id="type1" placeholder="Machine Type"></input>
                    <h2>{errors.type1 && <p>{errors.type1.message}</p>}</h2>

                    {/* <input  className="input" type="text" {...register("desc")}  id="desc" placeholder="Description"></input> */}
                    <select 
                        className="select shortInput" 
                        {...register("desc")} 
                        id="desc"
                        onChange={(e)=> {
                        }}  
                        >
                        <option  key={Math.random()}   value="Select">Select Industry</option> 
                    {
                        IndustrySet.map((d)=>(
                        <option  key={Math.random()}  value={d.industryName}>{d.industryName}</option>
                        ))
                    }
                    </select>
                    <h2>{errors.desc && <p>{errors.desc.message}</p>}</h2>

                    <hr/>
                    <div className="container">
                        <div className="row">
                            <div className="col md-3 sm-3 xs-2">
                                <button className="submit" type="submit">Save</button>
                            </div>
                        </div>
                    </div>
                </form>  
                <hr/>
            </Col>

            <Col md = "2">
            <div className="heading">
            <h5 className="operation">Operations</h5>
            <hr/>
            </div>              

            <div className="container">
                <div className="row">
                    <div className="col md-3 sm-3 xs-2">
                        <button onClick={ updateMachineType } className="button" type="button">Update</button>
                    </div>

                    {/* <div className="col md-3 sm-3 xs-2">
                        <button onClick={ deleteProfile } className="button" type="button">Delete</button>
                    </div> */}

                    <div className="col md-3 sm-3 xs-2">
                        <button className="button"  onClick={() => { setValueForm(); setModalOpen(true); } }> Delete </button>
                    </div>

                    {    
                        modalOpen && 
                        <Modal 
                            setOpenModal={ setModalOpen } 
                            setAction = { setAction }
                            formValues = { formValues }
                            formName = "Machine Type"
                            title = "Are you sure to delete ?" 
                            message = "Machine Type will be deleted !" 
                            setRecordAdded = { setRecordAdded }
                            />
                    }            
                </div>
            </div>
            </Col>

          <Col md="6">
          <ReactPaginate 
            previousLabel = {'<'} 
            nextLabel = {'>'}
            breakLabel = {'...'}
            pageCount = { Math.ceil(pageCount) }
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}

            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}

            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}

            breakLinkClassName={'page-link'}
            activeClassName={'active'}
        />
          <Table striped bordered hover variant="dark">
          <thead>
                <tr key= { Math.random() }>
                  <th>Machine Type</th>
                  <th>Industry</th>
                  <th className="theTh">Edit</th>
                </tr>
          </thead>
          <tbody>

          { 
            items.map((machineType) => {
            return <>
                  <tr key= { Math.random() }>
                      <td> { machineType.type }</td>
                      <td> { machineType.desc }</td>
                      <td className="theTh"><button className="selectButton" onClick={(()=> GetMachineType(machineType.type))}> Select</button></td>
                  </tr>
               </>
            })                  
          }
          </tbody>
          </Table>
        </Col>
        </Row>
      </Container>      

            </>        
        }
    </>
  )
}

export default MachineType