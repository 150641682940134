import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import './NavBar.css'
import { useAuth } from '../utilities/auth'
import { useNavigate } from 'react-router-dom'


const NavBar = () => {
  const auth = useAuth();
  const [isLogin, setLogin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let userLogin = null;
      try
      {
        userLogin = JSON.parse(localStorage.getItem('credentials'));
      }
      catch{}
      
      
      if (userLogin === null || userLogin === undefined) {
        setLogin(false);
        navigate('/Login');
      }
      else
      {
        setLogin(true);
      }
    },[]);


  return (
      <>
      <ul>
        <li><Link to="/Home">Home</Link></li>
        <li><Link to="/Profile">Profile</Link></li>
        <li><Link to="/Products">Rules</Link></li>
        <li><Link to="/Promotions">Promotions</Link></li>
        <li><Link to="/Locations">Locations</Link></li>
        <li><Link to="/Users">Users</Link></li>
        <li><Link to="/Configurations">Configurations</Link></li>                
        {
          !JSON.parse(localStorage.getItem('credentials')) && <li><Link to="/Login">Login</Link></li>
        }
        {
          JSON.parse(localStorage.getItem('credentials')) && <li><Link to="/Logout">Logout</Link></li>
        }
        
      </ul>
      <hr />
    </>
    )
}
export default NavBar;