import { React, useEffect, useState } from 'react'
import { Row, Col, Container, Table, Button } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import UserService from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Accordion from 'react-bootstrap/Accordion';
import Badge from 'react-bootstrap/Badge';
//import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'


const KPI = () => {
  return (
    <>
                          <div className="KPI">
                    <span>
                        <Card style={{ width: '15rem', height:'8rem' }}>
                          <Card.Body>
                            <Card.Title>EMS Units</Card.Title>
                            <Card.Text>          
                              <h1>512</h1>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                    </span>

                    <span>
                        <Card style={{ width: '15rem', height:'8rem' }}>
                          <Card.Body>
                            <Card.Title>Enriched Units</Card.Title>
                            <Card.Text>          
                              <h1>50</h1>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                    </span>

                    <span>
                        <Card style={{ width: '15rem', height:'8rem' }}>
                          <Card.Body>
                            <Card.Title>Ghost Units</Card.Title>
                            <Card.Text>          
                              <h1>25</h1>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                    </span>

                    <span>
                        <Card style={{ width: '15rem', height:'8rem' }}>
                          <Card.Body>
                            <Card.Title>Orphan Units</Card.Title>
                            <Card.Text>          
                              <h1>160</h1>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                    </span>

                    <span>
                        <Card style={{ width: '16rem', height:'8rem' }}>
                          <Card.Body>
                            <Card.Title>Publish Alerts</Card.Title>
                            <Card.Text>          
                                <h1>10</h1>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                    </span>
                  </div>
    </>
  )
}

export default KPI