import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Router } from 'react-router-dom';
import App from './App';
import Routes1 from './components/Routes';
import { CookiesProvider } from "react-cookie";
//import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <CookiesProvider>
      <Routes1 />
    </CookiesProvider>
</BrowserRouter>
);