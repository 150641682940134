import { productAttachmentUri, apiProductAttachementUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class OutboundEMSService {

    static  getAllEMS() {
        return axiosInstance.get(`/OutboundEMS`)
                    .then((resp)=>resp.data);
    }
    static  getAllEMSCOPY() {
        return axiosInstance.get(`/OutboundEMSLiveFeed`)
                    .then((resp)=>resp.data);
    }

    static  getPagedEMS(currentPage, pageSize) {
        return axiosInstance.get(`/OutboundEMS/GetPageEMS?PageNumber=${currentPage}&PageSize=${pageSize}`)
                    .then((resp)=>resp.data);
    }

    static  getEMS(equipmentId) {
        console.log('Equipment ID = ', equipmentId);
        console.log('URI = ',`OutboundEMS/GetEMS?EquipmentId=${equipmentId}`);        
        return axiosInstance.get(`OutboundEMS/GetEMS?EquipmentId=${equipmentId}`)
                    .then((resp)=>resp.data);
    }
    static async createEMS(payload) {
        console.log('Payload = ',payload);
        return await axiosInstance.post(`/OutboundEMS`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static async bulkEMS(payload) {
        console.log('Bulk Ka Payload = ',payload);
        return await axiosInstance.post(`/OutboundEMS/RangeRule`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static  editEMS(payload) {
        return axiosInstance.post('/OutboundEMS/Update', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static  LiveFeedManually(payload) {
        return axiosInstance.post('/OutboundEMS/ManualLiveFeed', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
    
    static  deleteEMS(payload) {
        return axiosInstance.post('/OutboundEMS/Delete', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
//    https://localhost:5001/api/OutboundEMS/DeleteAll
// https://localhost:5001/OutboundEMS/DeleteAll

    static  deleteAllEMS() {
        return axiosInstance.post('/OutboundEMS/DeleteAll', {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }


    static  RefreshGetCall() {
        return axios.get('https://api.vercel.com/v6/deployments', {} , {
            headers: { 
                Authorization: `Bearer q7C34V04xAzXkdauvFPIYt2S`
            }
          });
    }

    static  RefreshPostCall(UID) {
        const payload = {
            deploymentId: UID,
            name: '4rivers-next-app'
        };

        return axios.post('https://api.vercel.com//v13/deployments', JSON.stringify(payload), {
            headers: { 
                Authorization:'q7C34V04xAzXkdauvFPIYt2S',
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
   }
}

export default OutboundEMSService