import React from 'react'
import NavBar from './NavBar'
import { Routes, Route } from 'react-router-dom'
import Profile from './forms/Profile'
import Users from './forms/Users'
import Home from '../components/forms/Home'
import Login from './forms/Login'
import { AuthProvider } from '../utilities/auth'
import Logout from './forms/Logout'
import ProductsEdit from './forms/ProductsEdit'
import Promotions from './forms/Promotions'
import Locations from './forms/Locations'
import Products from './forms/Products'
import Configurations from './Settings/Configurations'
import Form from './forms/Machines/Form'
import BaseMachine from '../components/forms/MachinesHooked/BaseMachine'
import LinearCall from './forms/LinearForm/LinearCall'
import ProductBaseMachineUpdate from './forms/ProductBaseMachineUpdate'
import OutboundEMSAPIEdit from './Settings/OutboundEMSAPIEdit'
import OutboundEMSAPIView from './Settings/OutboundEMSAPIView'

import OutboundDeereAPIEdit from './Settings/OutboundDeereAPIEdit'
import OutboundDeereAPIView from './Settings/OutboundDeereAPIView'
//import Newproducts from './forms/Newproducts'

const Routes1 = () => {
  return (
    <AuthProvider>
          <NavBar/>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Home" element={<Home />} />
              <Route path="/Profile" element={<Profile />} />

              <Route path="/Products" element={<Products />} />
              <Route path="/ProductsEdit" element={<ProductsEdit />} />
              <Route path="/Promotions" element={<Promotions/>} />
              <Route path="/Locations" element={<Locations />} />
              <Route path="/Users" element={<Users />} />
              <Route path="/Configurations" element={<Configurations />} />
              {/* <Route path="/Machines" element={<BaseMachine />} /> */}
              <Route path="/Linear" element={<LinearCall />} />
              <Route path="/Login" element={<Login />} />
              <Route path="/Logout" element={<Logout />} />

              <Route path="/MachineUpdate" element={<ProductBaseMachineUpdate />} />
              <Route path="/EMSEdit" element={<OutboundEMSAPIEdit />} />
              <Route path="/EMSView" element={<OutboundEMSAPIView />} />
              
              <Route path="/DeereEdit" element={<OutboundDeereAPIEdit />} />
              <Route path="/DeereView" element={<OutboundDeereAPIView />} />
              
          </Routes>
      </AuthProvider>
  )
}

export default Routes1