import React, {  useEffect, useState } from 'react'
import Card from '../forms/ui/Card'
import { Row, Col, Container, Table } from 'react-bootstrap'
import classes from './forms.css'


import { useForm } from 'react-hook-form'
import { string } from 'yup';
import './forms.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import PromotionService from '../../services/PromotionService'
import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Modal from '../../components/Modal/Modal'
import { boolean, number } from 'yup/lib/locale';
import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'
// *******************************************************************
import ReactPaginate from 'react-paginate'
//import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { industryUri, pageSize } from '../../constants'
import MetaData from '../../services/Data/MetaData'
// ********************************************************************

let formValues = {
    id: number,
    promotionId: string,
    specialOfferTitle: string,
    alert: string,
    published: string,
    industry: string,
    machineType: string  
}

const PromotionGrid = () => {
  const auth = useAuth();  
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);
  const [RecordAdded, setRecordAdded] = useState(0);

  // **************************************************************************************************
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setAction] = useState(false);
  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [MachineTypeSet, setMachineTypeSet] = useState([]);
  const [IndustrySet, setIndustrySet] = useState([]);
  const [MachineType, setMachineType] = useState();
  const [Industry, setIndustry] = useState();

  const [check1, setCheck1] = useState();
  const [check2, setCheck2] = useState();
  const { 
    register, watch, handleSubmit, 
    // setValue, resetValue,unregister, 
    // resetField, setError, clearErrors, 
    // setFocus, getValues, trigger, control,
    formState: { 
        errors, 
       // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
    } } 
      = useForm({ defaultValues: {  id: 0, promotionId: '', specialOfferTitle: '', alert: '', published: ''} });

      useEffect(() => {
        const getComments = async () => {
            const data = await PromotionService.getAllPromotions();  
            const total = data.length;
            const TotalPages = Math.ceil(total/pageSize);
            setPageCount(TotalPages);
            setItems(data);
            const PageOneFromServer = await fetchComments(1);
            setItems(PageOneFromServer);
            // Loading ComboBox Data
            const dataSet1 = await MetaData.getAllMachineType();
            setMachineTypeSet(dataSet1);

            const dataSet2 = await MetaData.getAllIndustry();
            setIndustrySet(dataSet2);
        };
    
        getComments();
    
      },[RecordAdded]);     
      
      const fetchComments = async(currentPage) => {
              const data = await PromotionService.getPagedPromotions(currentPage, pageSize);  
              return data;
          };
            
    
          const handlePageClick = async (data) => {
              let currentPage = data.selected + 1;
              const commentsFromServer = await fetchComments(currentPage);
              setItems(commentsFromServer);
            }
            
      // **************************************************************************************************
  
      const booleanify = (value: string): boolean => {
        const truthy: string[] = [
            'true',
            'True',
            '1'
        ]
      
        return truthy.includes(value)
      }
    const onSubmit = (data, event) => {
      event.preventDefault();
      formValues = {
        id: data.id,
        promotionId: data.promotionId,
        specialOfferTitle: data.specialOfferTitle,
        alert: data.alert.toString(),
        published: data.published.toString(),
        industry: document.getElementById('industry').value,
        machineType: document.getElementById('machineType').value        
      };

      console.log('Form Data = ',formValues);

      PromotionService.getPromotions(data.promotionId.trim().replace("&","And").replace("+","And")).then((data)=>{
        if (data.length !== 0) {
               console.log('Data Exist =>> ',data)
               toast.info('Record Exists ! ',{ position: 'bottom-center'});        
               return;
        }
        else
        {
            PromotionService.addPromotions(formValues).then((pro)=>{
              console.log('Form Values = ', formValues);
              console.log('Promotion = ',pro);
              toast.success('Promotion Added Successfully',{ position: 'bottom-center'});
              setRecordAdded((previousState)=> previousState + 1);
              clearValueForm();
            }).catch((e) => {
              console.log('Add Promotion Error', e.message);
              toast.error('Adding Promotion Error ',{ position: 'bottom-center'})
          });
        }
       });
  }

  const updatePromotion = () =>{
    setValueForm();
    console.log('Form Values = ', formValues);
    PromotionService.editPromotions(formValues).then((attach)=>{
      attach.data ? toast.success('Promotion Edited Successfully',{ position: 'bottom-center'}):toast.info('Promotion does not exists',{ position: 'bottom-center'});
        setRecordAdded((previousState)=> previousState + 1);
        clearValueForm();
    })
    .catch((e) => {
        console.log('Edit Promotion Error', e.message);
        toast.error('Edit Promotion Error ',{ position: 'bottom-center'})
    });
}

  const GetPromotion = (selectedPro) =>{
    PromotionService.getPromotions(selectedPro).then((resp) =>{
          const selectedLoc =  resp;
          console.log(resp.promotionId + '-' + selectedPro );
          document.getElementById('id').value= resp.id;
          document.getElementById('promotionId').value= resp.promotionId;
          document.getElementById('specialOfferTitle').value= resp.specialOfferTitle;
          setCheck2(booleanify(resp.alert));  
          setCheck1(booleanify(resp.published));  
          // document.getElementById('industry').value= resp.industry;
          // document.getElementById('machineType').value= resp.machineType;
          console.log('Trim Industry  ',resp.industry.trim());
          console.log('Industry  ',resp.industry);
          
          setIndustry(resp.industry.trim());
          setMachineType(resp.machineType.trim());

      }).catch((e) => {
          console.log('Error', e.message);
      });    
}


const setValueForm = (() => { 
  formValues = {
      id: document.getElementById('id').value,
      promotionId: document.getElementById('promotionId').value,
      specialOfferTitle: document.getElementById('specialOfferTitle').value,
      alert: check2.toString(),
      published: check1.toString(),
      industry: document.getElementById('industry').value,
      machineType: document.getElementById('machineType').value,

   };
});

const clearValueForm = (() => {
  formValues = {
      id: '',
      promotionId: '',
      specialOfferTitle: '',
      alert: '',
      published: '',
      industry: '',
      machineType: '',
   };

   document.getElementById('id').value= '';
   document.getElementById('promotionId').value= '';
   document.getElementById('specialOfferTitle').value= '';
   setCheck2(false);
   setCheck1(false);
   //document.getElementById('published').value= '';
   document.getElementById('industry').value= '';
   document.getElementById('machineType').value= '';
});
const onChangeIndustryBox = async (e)=>{
  e.preventDefault();
  setIndustry(e.target.value);
}
const DEF_DELAY = 1000;
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms || DEF_DELAY));
}

const onChangeMachineTypeBox = async (e)=>{
  e.preventDefault();
  setMachineType(e.target.value);
}

  return (
    <>
      <Container>
      <Row>
      <Col md="12">
          <ReactPaginate 
            previousLabel = {'<'} 
            nextLabel = {'>'}
            breakLabel = {'...'}
            pageCount = { Math.ceil(pageCount) }
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}

            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}

            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}

            breakLinkClassName={'page-link'}
            activeClassName={'active'}
        />
          <Table striped bordered hover variant="dark">
          <thead>
                <tr key= { Math.random() }>
                  <th>Promotion Id</th>
                  <th>Offer Title</th>
                  <th>Alert</th>
                  <th>Published</th>
                  <th>Industry</th>
                  <th>Machine Type</th>
                  <th>Edit</th>
                </tr>
          </thead>
          <tbody>

          { 
            items.map((pro) => {
            return <>
                  <tr key= { Math.random() }>
                      <td> { pro.promotionId }</td>
                      <td> { pro.specialOfferTitle }</td>
                      <td> { pro.alert }</td>
                      <td> { pro.published }</td>
                      <td> { pro.industry }</td>
                      <td> { pro.machineType }</td>
                      <td><button className="selectButton" onClick={(()=> GetPromotion(pro.promotionId))}> Select</button></td>
                  </tr>
               </>
            })                  
          }
          </tbody>
          </Table>
        </Col>                

      </Row>
          <Row>
                <Col md="10">
                        <div className="heading">
                            <h5 className="mainheading">Promotion Summary</h5>
                            <hr/>
                        </div>              

        
                          <form onSubmit={handleSubmit(onSubmit)}>

                          <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>

                          <input className="input" type="text" 
                                  {...register("promotionId", { required: {value: true, message: "This is required field"} })}  
                                  id="promotionId" 
                                  placeholder="Promotion Id"></input>
                          <h2>{errors.promotionId && <p>{errors.promotionId.message}</p>}</h2>


                          <input className="input" 
                                  type="text" 
                                  {...register("specialOfferTitle")}  
                                  id="specialOfferTitle" placeholder="Special Offer Title"></input>
                          <h2>{errors.specialOfferTitle && <p>{errors.specialOfferTitle.message}</p>}</h2>


                          <span>
                          <label htmlFor="alert">Alert</label>
                          <input className="checkbox" 
                                  type="checkbox" 
                                  onClick={(e)=> setCheck2(e.target.checked)} 
                                  checked={check2}  
                                  {...register("alert")}  
                                  id="alert" 
                                  placeholder="Alert"></input>
                          <h2>{errors.alert && <p>{errors.alert.message}</p>}</h2>
                          </span>

                          <span>
                          <label htmlFor="published">Published</label>
                          
                          <input className="checkbox" 
                                  type="checkbox" 
                                  onClick={(e)=> setCheck1(e.target.checked)} 
                                  checked={check1}  
                                  {...register("published")}  
                                  id="published" 
                                  placeholder="Published">                                    
                          </input>
                          <h2>{errors.published && <p>{errors.published.message}</p>}</h2>
                          </span>


                          <select 
                                  className="select" 
                                    id="industry"
                                    value= {Industry}
                                    onChange={(e)=> {
                                    onChangeIndustryBox(e);
                            			}
                                  }  
                                  >
                                  <option  key={Math.random()}   value="Select">Select Industry</option> 
                              {
                                  IndustrySet.map((d)=>(
                          			    <option  key={Math.random()}  value={d.industryName}>{d.industryName}</option>
                                  ))
                              }
                              </select>
                              <h2>{errors.industry && <p>{errors.industry.message}</p>}</h2>

                                <select 
                                  className="select" 
                                    id="machineType"
                                    value={MachineType}
                                    onChange={(e)=> {
                                    onChangeMachineTypeBox(e);
                            			}}  
                                  >
                                  <option  key={Math.random()}   value="Select">Select Machine Type</option> 
                              {
                                  MachineTypeSet.map((d)=>(
                          			    <option  key={Math.random()}  value={d.type}>{d.type}</option>
                                  ))
                              }
                              </select>
                              <h2>{errors.machineType && <p>{errors.machineType.message}</p>}</h2>


                          <hr/>
                          <div className="container">
                              <div className="row">
                                  <div className="col md-3 sm-3 xs-2">
                                      <button className="submit" type="submit">Save</button>
                                  </div>
                              </div>
                          </div>
                      </form>  
                </Col>
  <Col md = "2">
            <div className="heading">
            <h5 className="operation">Operations</h5>
            <hr/>
            </div>              

            <div className="container">
                <div className="row">
                    <div className="col md-3 sm-3 xs-2">
                        <button onClick={ updatePromotion } className="button" type="button">Update</button>
                    </div>

                    {/* <div className="col md-3 sm-3 xs-2">
                        <button onClick={ deleteProfile } className="button" type="button">Delete</button>
                    </div> */}

                    <div className="col md-3 sm-3 xs-2">
                        <button className="button"  onClick={() => { setValueForm(); setModalOpen(true); } }> Delete </button>
                    </div>

                    {    
                        modalOpen && 
                        <Modal 
                            setOpenModal={ setModalOpen } 
                            setAction = { setAction }
                            formValues = { formValues }
                            formName = "Promotion"
                            title = "Are you sure to delete ?" 
                            message = "Promotion will be deleted !" 
                            setRecordAdded = { setRecordAdded }
                            />
                    }            
                </div>
            </div>
            </Col>
          </Row>
      </Container>
    </>
  )
}

export default PromotionGrid