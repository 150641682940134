import axiosInstance from '../AxiosInstance'
import liveInstance from '../LiveInstance'
import { UserService } from '../../services/UserService'

export class MetaData {

    static  getAllPromotionsId() {
        return axiosInstance.get(`/Promotion`)
                    .then((resp)=>resp.data);
      }
      static getServiceAlerts(username){
        return axiosInstance.get(`/ServiceAlert`)
                    .then((resp)=>resp.data);
    }
    static async getLiveFeed(){      
      return await liveInstance.get()
                    .then((res)=>res.data.matches);        
    }
  
    static getAllLocationCode(){
      return axiosInstance.get(`/Location`)
      .then((resp)=>resp.data);
    }

    static getAllLocationCodeOnly(){
      return axiosInstance.get(`/Location/Only`)
      .then((resp)=>resp.data);
    }

    static getAllMachineType(){
      return axiosInstance.get(`/MachineType`)
      .then((resp)=>resp.data);
    }
    static getInboundEMSData(){
      return axiosInstance.get(`/OutboundEMSLiveFeed`)
      .then((resp)=>resp.data);
    }

    static getAllIndustry(){
      return axiosInstance.get(`/Industry`)
      .then((resp)=>resp.data);
    }

    static getAllIndustryOnly(){
      return axiosInstance.get(`/Industry/Only`)
      .then((resp)=>resp.data);
    }

    static getAllMachineSubType(){
      return axiosInstance.get(`/MachineSubType`)
      .then((resp)=>resp.data);
    }

    static getAllMake(){
      return axiosInstance.get(`/Make`)
      .then((resp)=>resp.data);
    }

    static getAllRules(){
      return axiosInstance.get(`/ProductBaseMachine`)
      .then((resp)=>resp.data);
    }

    static getAllCategories(){
      return axiosInstance.get(`/DeereCategory`)
      .then((resp)=>resp.data);
    }

    static  getIndustryCategoryies(Industry) {
      return axiosInstance.get(`/DeereCategory/GetCategory?Industry=${Industry}`)
                  .then((resp)=>resp.data);
  }

    static getAllMasterAttachment(){
      return axiosInstance.get(`/ProductAttachmentMaster`)
      .then((resp)=>resp.data);
    }

    static getActiveRules(){
      return axiosInstance.get(`/Active`)
      .then((resp)=>resp.data);
    }

    static getAllModal(){
      return axiosInstance.get(`/Modal`)
      .then((resp)=>resp.data);
    }
n
    static getAllProduct(){
      return axiosInstance.get(`/Product`)
      .then((resp)=>resp.data);
    }

    static getAdditionalFields(){
      return axiosInstance.get('/DynamicFields')
                          .then((resp)=> resp.data);
    }
    
    static getAccessToken(payload){
      UserService.checkUser(payload).then((tokenReceived) => {
        console.log('>>>>>> Token Received = >>>>>>> ',tokenReceived);
    })
  }
}

export default MetaData