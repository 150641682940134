import React, {  useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { string } from 'yup';
import './forms.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import AuthService from '../../services/AuthService'
import UserService from '../../services/UserService'

import { number } from 'yup/lib/locale';

import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'

// *******************************************************************
import ReactPaginate from 'react-paginate'
import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { authUri, pageSize, PortNumber, BaseURL } from '../../constants'
// ********************************************************************

let loginValues = {
  id: number,
  username: string,
  loginDate: Date,
  logoutDate: Date,
  loginStatus: string
}


const CurrentLogins = () => {
    const auth = useAuth();  
    const navigate = useNavigate();
    const [isLogin, setLogin] = useState(false);

    // **************************************************************************************************

    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState([]);
  
    useEffect(() => {
      const getComments = async () => {
          const res = await fetch(authUri);
          const data = await res.json();
          const total = data.length;
          const TotalPages = Math.ceil(total/pageSize);
          setPageCount(TotalPages);
          setItems(data);

          const PageOneFromServer = await fetchComments(1);
          setItems(PageOneFromServer);
      };
  
      getComments();
  
    },[]);     
    
    const fetchComments = async(currentPage) => {
            const res = await fetch(`${BaseURL}:${PortNumber}/Auth/ShowLogins?PageNumber=${currentPage}&PageSize=${pageSize}`);
            const data = await res.json();
            return data;
        };
          
  
        const handlePageClick = async (data) => {
            let currentPage = data.selected + 1;
            const commentsFromServer = await fetchComments(currentPage);
            setItems(commentsFromServer);
          }
          
    // **************************************************************************************************
    const { 
            register, watch, handleSubmit, 
            // setValue, resetValue,unregister, 
            // resetField, setError, clearErrors, 
            // setFocus, getValues, trigger, control,
            formState: { 
                errors, 
               // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
            } } 
        = useForm({ defaultValues: { Id: 0, ProfileId: '', FirstName: '', LastName: '', Contact: '', Email: '' } });

    useEffect(() => {
        const subscription = watch((data)=>{
            console.log(data);
        })
        return ()=> {
            subscription.unsubscribe();
        }
    }, [watch]) 

    useEffect(() => {
        let userLogin = null;
          try
          {
            userLogin = JSON.parse(localStorage.getItem('credentials'));
            AuthService.getForcedLogoutUser(userLogin).then((login)=>{
                if ((login.loginStatus === 'ForcedLogout') || (login.loginStatus === 'Logout')) {                
                    localStorage.removeItem('credentials')
                    navigate("/Login");
                }});
            UserService.getUser(userLogin).then((user)=>{
              if (user.status === 'InActive') {                
                  localStorage.removeItem('credentials')
                  navigate("/Login");
              }});

          }
          catch{}
          
          
          if (userLogin === null || userLogin === undefined) {
            setLogin(false);
            navigate('/Login');
          }
          else
          {
            setLogin(true);
          }
        },[]);

 
 
    const ForceLogout = (selectedUser) => {
      AuthService.getUser(selectedUser).then((resp) =>{
        console.log(resp.loginStatus);
        loginValues = {
          id: resp.id,
          username: selectedUser,
          LoginDate: resp.loginDate, 
          logoutDate: new Date(),
          loginStatus: 'ForcedLogout'
        }
            AuthService.signOutForce(loginValues).then((resp) => {      
                toast.success('Logout Forcefully Successfully',{ position: 'bottom-center'});
            }).catch((e) => {
                  console.log('Error', e.message);
                  toast.error('Logout Forcefully Failed',{ position: 'bottom-center'})
            });    

        }).catch((e)=>{

      });
  }

    return (
    <>
        {
            localStorage.getItem('credentials') && 
          <>



      <Container>
        <Row>
          <Col>
          <hr/>
          <ReactPaginate 
            previousLabel = {'<'} 
            nextLabel = {'>'}
            breakLabel = {'...'}
            pageCount = { Math.ceil(pageCount) }
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}

            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}

            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}

            breakLinkClassName={'page-link'}
            activeClassName={'active'}
        />
          <hr/>
          <Table striped bordered hover variant="dark">
          <thead>
                <tr key= { Math.random() }>
                  <th>Username</th>
                  <th>Login Date</th>
                  <th>Logout Date</th>
                  <th>Status</th>
                  <th>Edit</th>
                </tr>
          </thead>
          <tbody>

          { 
            items.map((login) => {
            return <>
                  <tr key= { Math.random() }>
                      <td> { login.username }</td>
                      <td> { login.loginDate }</td>
                      <td> { login.logoutDate }</td>
                      <td> { login.loginStatus }</td>
                      <td><button className="selectButton" onClick={(()=> ForceLogout(login.username))}> Logout</button></td>
                  </tr>
               </>
            })                  
          }
          </tbody>
          </Table>
        </Col>
        </Row>
      </Container>      
            </>        
        }
    </>
  )
}

export default CurrentLogins