import axiosInstance from '../services/AxiosInstance'

export class ProductAttachmentMediaService {

    static  getAllProductMedia() {
        return axiosInstance.get(`/ProductAttachmentMedia`)
                    .then((resp)=>resp.data);
    }

    static  getPagedProductMedia(currentPage, pageSize) {
        return axiosInstance.get(`/ProductAttachmentMedia/GetPageMedia?PageNumber=${currentPage}&PageSize=${pageSize}`)
                    .then((resp)=>resp.data);
    }

    static  getProductMedia(ItemId) {
        console.log('Item Id = ', ItemId);
        console.log('URI = ',`ProductAttachmentMedia/GetMedia?ItemId=${ItemId}`);        
        return axiosInstance.get(`ProductAttachmentMedia/GetMedia?ItemId=${ItemId}`)
                    .then((resp)=>resp.data);
    }

    static  createProductMedia(payload) {
        return axiosInstance.post(`/ProductAttachmentMedia`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static  editProductMedia(payload) {
        return axiosInstance.patch('ProductAttachmentMedia/Update', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
    
    // static  removeProductMediaId(MediaId) {
    //     console.log('URI = ',`ProductAttachmentMedia/Delete?mediaId=${MediaId}`);        
    //     return axiosInstance.get(`ProductAttachmentMedia/Delete?mediaId=${MediaId}`)
    //                 .then((resp)=>resp.data);
    // }


    static  deleteProductMedia(payload) {
        console.log('Delete Load = ',payload);
        return axiosInstance.post('ProductAttachmentMedia/Delete', JSON.stringify(payload), {            
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default ProductAttachmentMediaService