import React, {  useEffect, useState,useLayoutEffect } from 'react'
import Card from '../forms/ui/Card'
import { Row, Col, Container, Table } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { string } from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import ProductBaseMachineService from '../../services/ProductBaseMachineService'
import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Modal from '../../components/Modal/Modal'
import { number } from 'yup/lib/locale';
import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'


// *******************************************************************
import ReactPaginate from 'react-paginate'
import 'bootstrap/dist/css/bootstrap.min.css'
import { profilesUri, pageSize } from '../../constants'
import MetaData from '../../services/Data/MetaData'

// ********************************************************************

import { EMSAPI } from '../../constants'
import { EMSApiService } from '../../services/EMSApiService'
import { OutboundEMSService }  from '../../services/OutboundEMSService'
import  Records  from '../../emsingle.json'

//import  Records  from '../../emsingle.json'
//import { OutboundEMSService } from '../../services/OutboundEMSService'
import './style.css'
import { IVRiversMatchingService } from '../../services/IVRiversMatchingService'

let emsRecordValues = {
    id: number,
    equipmentItemId: string,
    serialNumber: string,
    tagNumber: string,
    groupName: string,
    categoryName: string,
    makeName: string,
    modelName: string,
    advertisedPrice: string,
    meterReadingHours: string,
    modelYear: string,
    store: string,
    storeShipState: string,
    phone: string,
    url: string,
    isNew: string,
    description: string,
    storeNumber: string,
    isActive: string,
    isHotList: string,
    attachmentList: string,
    makeOrder: string,
    ba: string,
    extraField1: string,
    extraField2: string,
    status: string
    
}
const ProcessEMSAPI = () => {
    const auth = useAuth();  
    const navigate = useNavigate();
    const [Condkey, setCondKey]= useState("d.modelYear")
    const [EmsEdit, setEmsEdit] = useState(0);
    const [EmsView, setEmsView] = useState(0);
    const [isLogin, setLogin] = useState(false);
    const [RecordAdded, setRecordAdded] = useState(0);
    const [formStep, setFormStep] = useState(0);
    const [machineEdit, setMachineEdit] = useState(0);
    const [check1, setCheck1] = useState();
    const [RuleSet, setRuleSet] = useState([]);
    let BulkSet = [];
    let filterToSearch = {}

     // **************************************************************************************************
    const [modalOpen, setModalOpen] = useState(false);
    const [modalAction, setAction] = useState(false);
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState([]);
    const [Published, setPublished]=useState('Not Published');
    const [PublishedEMS, setPublsihedEMS] = useState(0);  
    const [FilteredRecordState,setFilteredRecordState] = useState();
    const [fields, setFields] = useState([]);
    const [uri, setUri] = useState('');
    const keys = [];
    const [foodState, setFoodState] = useState();
    const [readyEMS, setReadyEMS] = useState(0);
    const [SelectedRecord, setSelectedRecord] = useState();
    const [SelectedLogic,setSelectedLogic] = useState("AND");
    const [PublishedRecord, setPublishedRecord] = useState();
    const [RuleName, setRuleName] = useState();
    const [Condition1, setCondition1] = useState();
    const [Condition2, setCondition2] = useState();
    const [Condition3, setCondition3] = useState();
    const [Condition4, setCondition4] = useState(false);
    const [Condition5, setCondition5] = useState(false);
    const [SearchCriteria, setSearchCriteria] = useState();
    const [ViewNew, setViewNew] = useState(0);
    const [LiveRecords, SetLiveRecords] = useState([]);

    useLayoutEffect(()=>{
          MetaData.getInboundEMSData().then((data)=>{
            SetLiveRecords(...LiveRecords, [data]);
            console.log('LiveFeed=>',data);
            data.map((d)=>{
                console.log(d);
            })
            //Records.matches = data;
        });
    },[])
    // **************************************************************************************************
        useEffect(() => {
          const getComments = async () => {
              const data = await EMSApiService.getAll();
              const total = data.length;
              const TotalPages = Math.ceil(total/pageSize);
              setPageCount(TotalPages);
              setItems(data);              
              console.log('Data Retrieved = ',data);
             const PageOneFromServer = await fetchComments(1);
              setItems(PageOneFromServer);
          };      
          getComments();          

          MetaData.getAllRules().then(data=> {

            setRuleSet(data.filter(d => 
                { return(
                        (d.status === "Enable") ) }));
            });   

        }, [RecordAdded]);
        
        const fetchComments = async(currentPage) => {
            
                const data = await EMSApiService.getPagedEMS(currentPage, pageSize);
                return data;
            };
              
      
            const handlePageClick = async (data) => {
                let currentPage = data.selected + 1;
                const commentsFromServer = await fetchComments(currentPage);
                setItems(commentsFromServer);
              }
              
        // **************************************************************************************************
useEffect(()=>{
    //setReadyEMS(0);
    OutboundEMSService.getAllEMS().then((data)=>{
        console.log('Total Records = ', data.length);
        setPublsihedEMS(data.length);
    })    

    // Calling EMS Api by passing username:password OR Encrypted Basic Token
    OutboundEMSService.getAllEMSCOPY().then((data)=>{
        console.log('Inbound Data => ', data.length);
        setReadyEMS(data.length);
    })    
},[readyEMS])    
    
const { 
      register, watch, handleSubmit, 
      // setValue, resetValue,unregister, 
      // resetField, setError, clearErrors, 
      // setFocus, getValues, trigger, control,
      formState: { 
          errors, isValid
         // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating,  submitCount 
      } } 
      = useForm({ 
        mode: "all", 
        // defaultValues: { id: 0, productCode: '', modelName: '', packageDesc: '', inventoryMatching: '', riversEMSMatching: '', regularPrice: 0, riversPrice: 0, validDate: '1900-01-01', advertiseDesc: '', disclaimer: '', media: '', promotionsAvailable: '' } 
      });
  
      useEffect(() => {
        const subscription = watch((data)=>{
            console.log(data);
        })
        return ()=> {
            subscription.unsubscribe();
        }
    }, [watch]) 


    const  PublishEMS = async () => {
        console.log('Record Phone = >> ',emsRecordValues);
                if (emsRecordValues){
                    OutboundEMSService.createEMS(emsRecordValues).then(()=>{      
                        setRecordAdded(emsRecordValues);
                        console.log('Record Created ===> ',emsRecordValues);
                        setPublished('Published');
                        toast.success('Published Successfully',{ position: 'bottom-center'});                                                  
                    }).catch((e) => {
                        console.log('Adding EMS Record Error', e.message, emsRecordValues.equipmentId);
                    })    
                }
                
                const thedata = await OutboundEMSService.getAllEMS();
                setPublishedRecord(thedata);
                OutboundEMSService.getAllEMS().then((data)=>{
                    setPublsihedEMS(data.length);
                })            
    }

    const nullIf = (value)=>{        
        if (value === null) {
            return '-';
        }
        else
        {
            var thevalue = value !== undefined? value.toString():"-"
            return thevalue;
        }
    }   

    const CreateNewOutbound = (e) => {
//        e.preventDefault();
        let formValues ={
            id: 0,
            equipmentItemId: '111' + Math.floor((Math.random() * 1000000) + 1),
            serialNumber: '-',
            tagNumber: '-',
            groupName: '-',
            categoryName: '-',
            makeName: '-',
            modelName: '-',
            advertisedPrice: '0.00',
            meterReadingHours: '-',
            modelYear: '-',
            store: '-',
            storeShipState: '-',
            phone: '-',
            url: '-',
            isNew: 'true',
            description: '-',
            storeNumber: '-',
            isActive: 'false',
            isHotList: 'false',
            attachmentList: '-',
            makeOrder: '-',
            ba: '-',
            extraField1: nullIf(RuleName),
            extraField2: 'Ghost Machine',
            status: 'Published'
        }


        if (formValues){
            OutboundEMSService.createEMS(formValues).then(()=>{      
                setRecordAdded(formValues);
                console.log('Record Created ===> ',formValues);
                setPublished('Published');
                toast.success('Published Successfully',{ position: 'bottom-center'});                                                  
            }).catch((e) => {
                console.log('Adding EMS Record Error', e.message, formValues.equipmentId);
            })    
        }
        
        const timer = setTimeout(async () => {
            const thedata = await OutboundEMSService.getAllEMS();
            setPublishedRecord(thedata);
            OutboundEMSService.getAllEMS().then((data)=>{
                setPublsihedEMS(data.length);
            })            
            }, 1000);
          return () => clearTimeout(timer);

        }
    const getAllUrl = (pictures)=>{
        console.log('PICTURES=>',pictures);
        let url = '';
        pictures.map((data)=>{
            url = (data.url + "," + url).trim();
        })
        url= url.substring(0,url.length-1);
        return url
    }
    const  PublishLogicalEMS = () => {
        let formValues ={
            id: number,
            equipmentItemId: string,
            serialNumber: string,
            tagNumber: string,
            groupName: string,
            categoryName: string,
            makeName: string,
            modelName: string,
            advertisedPrice: string,
            meterReadingHours: string,
            modelYear: string,
            store: string,
            storeShipState: string,
            phone: string,
            url: string,
            isNew: string,
            description: string,
            storeNumber: string,
            isActive: string,
            isHotList: string,
            attachmentList: string,
            makeOrder: string,
            ba: string,
            extraField1: string,
            extraField2: string,
            status: string
        }

        if (check1){
            //RecordsSingle.matches.map((rec)=> {
                LiveRecords.map((rec)=> {
                    console.log('THEREC=>',rec);
                formValues = {
                    id: 0,
                    equipmentItemId: nullIf(rec.equipmentItemId),
                    serialNumber: nullIf(rec.serialNumber),
                    tagNumber: nullIf(rec.tagNumber),
                    groupName: nullIf(rec.groupName),
                    categoryName: nullIf(rec.categoryName),
                    makeName: nullIf(rec.makeName),
                    modelName: nullIf(rec.modelName),
                    advertisedPrice: nullIf(rec.advertisedPrice),
                    meterReadingHours: nullIf(rec.meterReadingHours),
                    modelYear: nullIf(rec.modelYear),
                    store: nullIf(rec.store),
                    storeShipState:'-' ,//nullIf(rec.storeShipState),
                    phone: nullIf(rec.primaryPhone),
                    url: ((rec.url === undefined) || (rec.url === null))? '-': nullIf(rec.url),//getAllUrl(rec.pictures)
                    isNew: nullIf(rec.isNew),
                    description: nullIf(rec.description),
                    storeNumber: nullIf(rec.storeNumber),
                    isActive: nullIf(rec.isActive),
                    isHotList: nullIf(rec.isHotList),
                    attachmentList: nullIf(rec.attachmentList),
                    makeOrder: nullIf(rec.makeOrder),
                    ba: nullIf(rec.ba),
                    extraField1: 'ALL',
                    extraField2: 'extra field 2',
                    status: 'Published'
                }        
                console.log('Record formation => ',formValues);

                if (formValues){
                    BulkSet.push(formValues);        
                }
            });

            console.log('BulkSet = >> ',BulkSet);
            OutboundEMSService.bulkEMS(BulkSet).then(()=>{      
                setRecordAdded(BulkSet);
                console.log('Record Created ===> ',BulkSet);
                setPublished('Published');
                toast.success('Published Successfully',{ position: 'bottom-center'});                                                  
            }).catch((e) => {
                console.log('Adding EMS Record Error', e.message, formValues.equipmentId);
            })    


            const timer = setTimeout(async () => {
            const thedata = await OutboundEMSService.getAllEMS();
                setPublishedRecord(thedata);
                OutboundEMSService.getAllEMS().then((data)=>{
                    setPublsihedEMS(data.length);
                })            
                }, 1000);
          return () => clearTimeout(timer);
        }
        else{
            console.log('FilteredRecordState=>',FilteredRecordState);
            FilteredRecordState.map((rec)=> {
                console.log('THEREC=>',rec);
                formValues = {
                    id: 0,
                    equipmentItemId: nullIf(rec.equipmentItemId),
                    serialNumber: nullIf(rec.serialNumber),
                    tagNumber: nullIf(rec.tagNumber),
                    groupName: nullIf(rec.groupName),
                    categoryName: nullIf(rec.categoryName),
                    makeName: nullIf(rec.makeName),
                    modelName: nullIf(rec.modelName),
                    advertisedPrice: nullIf(rec.advertisedPrice),
                    meterReadingHours: nullIf(rec.meterReadingHours),
                    modelYear: nullIf(rec.modelYear),
                    store: nullIf(rec.store),
                    storeShipState: '-',//nullIf(rec.storeShipState),
                    phone: nullIf(rec.primaryPhone),
                    url: ((rec.url === undefined) || (rec.url === null))? '-': nullIf(rec.url),//getAllUrl(rec.pictures)
                    isNew: nullIf(rec.isNew),
                    description: nullIf(rec.description),
                    storeNumber: nullIf(rec.storeNumber),
                    isActive: nullIf(rec.isActive),
                    isHotList: nullIf(rec.isHotList),
                    attachmentList: nullIf(rec.attachmentList),
                    makeOrder: nullIf(rec.makeOrder),
                    ba: nullIf(rec.ba),
                    extraField1: nullIf(RuleName),
                    extraField2: 'extra field 2',
                    status: 'Published'
                }        
                console.log('Record formation => ',formValues);

                if (formValues){
                    BulkSet.push(formValues);        
                }
            });

            console.log('BulkSet = >> ',BulkSet);
            OutboundEMSService.bulkEMS(BulkSet).then(()=>{      
                setRecordAdded(BulkSet);
                console.log('Record Created ===> ',BulkSet);
                setPublished('Published');
                toast.success('Published Successfully',{ position: 'bottom-center'});                                                  
            }).catch((e) => {
                console.log('Adding EMS Record Error', e.message, formValues.equipmentId);
            })    


            const timer = setTimeout(async () => {
            const thedata = await OutboundEMSService.getAllEMS();
                setPublishedRecord(thedata);
                OutboundEMSService.getAllEMS().then((data)=>{
                    setPublsihedEMS(data.length);
                })            
                }, 1000);
          return () => clearTimeout(timer);
        }
        

}



    const  PublishSingleEMS =  (equipmentId) => {
        let formValues ={
            id: number,
            equipmentItemId: string,
            serialNumber: string,
            tagNumber: string,
            groupName: string,
            categoryName: string,
            makeName: string,
            modelName: string,
            advertisedPrice: string,
            meterReadingHours: string,
            modelYear: string,
            store: string,
            storeShipState: string,
            phone: string,
            url: string,
            isNew: string,
            description: string,
            storeNumber: string,
            isActive: string,
            isHotList: string,
            attachmentList: string,
            makeOrder: string,
            ba: string,
            extraField1: string,
            extraField2: string,
            status: string
        
        }
        console.log('Equipment ID = ', equipmentId);
    
        LiveRecords[0].map((rec)=> {
            console.log('LIVERE=>',rec);
            if (rec.equipmentItemId === equipmentId) {
                formValues = {
                    id: 0,
                    equipmentItemId: nullIf(rec.equipmentItemId),
                    serialNumber: nullIf(rec.serialNumber),
                    tagNumber: nullIf(rec.tagNumber),
                    groupName: nullIf(rec.groupName),
                    categoryName: nullIf(rec.categoryName),
                    makeName: nullIf(rec.makeName),
                    modelName: nullIf(rec.modelName),
                    advertisedPrice: nullIf(rec.advertisedPrice),
                    meterReadingHours: nullIf(rec.meterReadingHours),
                    modelYear: nullIf(rec.modelYear),
                    store: nullIf(rec.store),
                    storeShipState: '-',//nullIf(rec.storeShipState),
                    phone: nullIf(rec.primaryPhone),
                    url: ((rec.url === undefined) || (rec.url === null))? '-': nullIf(rec.url),//getAllUrl(rec.pictures)
                    isNew: nullIf(rec.isNew),
                    description: nullIf(rec.description),
                    storeNumber: nullIf(rec.storeNumber),
                    isActive: nullIf(rec.isActive),
                    isHotList: nullIf(rec.isHotList),
                    attachmentList: nullIf(rec.attachmentList),
                    makeOrder: nullIf(rec.makeOrder),
                    ba: nullIf(rec.ba),
                    extraField1: nullIf(RuleName),
                    extraField2: 'extra field 2',
                    status: 'Published'
                }        
            console.log('Recorded formation => ',formValues);
            }});

            if (formValues){
            OutboundEMSService.createEMS(formValues).then(()=>{      
                setRecordAdded(formValues);
                console.log('Record Created ===> ',formValues);
                setPublished('Published');
                toast.success('Published Successfully',{ position: 'bottom-center'});                                                  
            }).catch((e) => {
                console.log('Adding EMS Record Error', e.message, formValues.equipmentId);
            })    
        }
        
        const timer = setTimeout(async () => {
            const thedata = await OutboundEMSService.getAllEMS();
            setPublishedRecord(thedata);
            OutboundEMSService.getAllEMS().then((data)=>{
                setPublsihedEMS(data.length);
            })            
            }, 1000);
          return () => clearTimeout(timer);

}

const ClearOutboundEMS = async () => {
    await OutboundEMSService.deleteAllEMS();

    const thedata = await OutboundEMSService.getAllEMS();
    setPublishedRecord(thedata);

    OutboundEMSService.getAllEMS().then((data)=>{
        setPublsihedEMS(data.length);
    })
    toast.success('Cleared Successfully',{ position: 'bottom-center'});                                                  

}
    const  DisplayOutboundEMS = async () => {
        const thedata = await OutboundEMSService.getAllEMS();
        setPublishedRecord(thedata);
        OutboundEMSService.getAllEMS().then((data)=>{
            setPublsihedEMS(data.length);
        })
    
}

// ***********************************************************
// Direct API Call using Axios
// Axios Call Directly
// ***********************************************************
const callEMSApi= async () => { 
    const webApiUrl = 'https://inspect.jamesriverequipment.com/EMSMarketingConsoleAPI/api/EquipmentItems/GetMachineDetails?id=478864';
   // const webApiUrl = 'https://inspect.jamesriverequipment.com/EMSMarketingConsoleAPI/api/EquipmentItems/GetCards'
    var username = 'MarketingConsoleUser';
    var password = '[99b5Pc~|F_ABW7p.@b[+N77B43amZJV';

    // btoa to convert username:password to encrypted using base64 encryption
    var encodedToken = btoa(username + ':' + password);
    // console.log(encodedToken);

    const headers = { 'Authorization': 'Basic '+ encodedToken };            

    return await axios.get(webApiUrl, {
            headers: headers 
        //  auth: { username, password }
      });
}

// ***********************************************************
// ***********************************************************
        
const onSubmit = (data, event) => {
    event.preventDefault();
    console.log('Data => ', data);
}

const onChangeLogic = (e)=>{
    setSelectedLogic(e.target.value.toString());
}

const onChangeRule = (e) => {
    e.preventDefault();
    console.log('Target Rule = ',e.target.value);
    setRuleName(e.target.value);
    // if (e.target.value !== "Open") {

    // // const duedata = RuleSet.filter((data)=>{ 
    // //         return(data.productCode === e.target.value);
    // //     })
    // //     console.log(duedata[0].modelName);
    // //     console.log(duedata[0].industry);
    // //     console.log(duedata[0].make);
    // //     console.log(duedata[0].machineType);
    // //     console.log('Total Records = ',Records.matches.length);

    // //     if (SelectedLogic === "AND"){
    // //         const selectedRecordState = Records.matches
    // //         .filter(d =>                 
    // //             {
    // //                  return(
    // //                            (d.modelName === duedata[0].modelName) 
    // //                         && (d.makeName === duedata[0].make)                                            
    // //                 )                    
    // //                     });

    // //         setItems(selectedRecordState);    
    // //         setSelectedRecord(selectedRecordState);                
    // //         setFilteredRecordState(selectedRecordState);   
    // //     }
    // //     if (SelectedLogic === "OR"){
    // //         const selectedRecordState = Records.matches
    // //         .filter(d => 
    // //             { return(
    // //                            (d.modelName === duedata[0].modelName) 
    // //                         || (d.make === duedata[0].make)           
    // //                         // || (d.groupName === duedata[0].industry)
    // //                         // || (d.categoryName === duedata[0].machineType)                                      
                                                                           
    // //                 )                    
    // //                     });
    // //         console.log('duedata = ',duedata);            
    // //         setItems(selectedRecordState);    
    // //         setSelectedRecord(selectedRecordState); 
    // //         setFilteredRecordState(selectedRecordState);     
    // //     }
    //     //alert(data.groupName, ' , ',data.modelName,' , ', data.modelYear);
    // }
}

const onChangeComboBox = (e) => {
	const selectedId = e.target.value;
    const selectedRecordState = LiveRecords.filter(d => { return (d.equipmentItemId.toString() === selectedId) });
    setItems(selectedRecordState);    
    setSelectedRecord(selectedRecordState);

    OutboundEMSService.getEMS(e.target.value).then((data)=>{        
        if (data.field25)
        {
            setPublished(data.field25);
        }
        else
        {
            setPublished('Not Published');
        }
    }).catch((err)=>{
        console.log(err.message);
    })
    // if (check1){
    //     setSelectedRecord(Records.matches)
    // }

    console.log('Selected = ',selectedRecordState);
  //  console.log('selectedRecordState = ',SelectedRecord);
    selectedRecordState.map((rec) => {
      emsRecordValues = {
            id: 0,
            equipmentItemId: rec.equipmentItemId.toString(),
            serialNumber: rec.serialNumber.toString(),
            tagNumber: rec.tagNumber.toString(),
            groupName: rec.groupName.toString(),
            categoryName: rec.categoryName.toString(),
            makeName: rec.makeName.toString(),
            modelName: rec.modelName.toString(),
            advertisedPrice: rec.advertisedPrice.toString(),
            meterReadingHours: rec.meterReadingHours.toString(),
            modelYear: rec.modelYear.toString(),
            store: rec.store.toString(),
            storeShipState: '-',//rec.storeShipState.toString(),
            phone: rec.primaryPhone.toString(),
            url: ((rec.url === undefined) || (rec.url === null))? '-': nullIf(rec.url),//getAllUrl(rec.pictures)
            isNew: rec.isNew.toString(),
            description: rec.description.toString(),
            storeNumber: rec.storeNumber.toString(),
            isActive: rec.isActive.toString(),
            isHotList: rec.isHotList.toString(),
            attachmentList: rec.attachmentList.toString(),
            makeOrder: rec.makeOrder.toString(),
            ba: rec.ba.toString(),
            extraField1: RuleName,
            extraField2: 'extra field 2',
            status: 'Published'
        }

        console.log(emsRecordValues);
    })


};

const onCheckBoxChange = (e) => {
    setCheck1(e.target.checked);
    if (e.target.checked){
        const selectedRecordState = LiveRecords[0]
     //   console.log('All Records === >>>',Records.matches)            
        
        // Records.matches.map((data)=>{
        //     console.log('All Records === >>>',data.pictures[0].url)            
        // })            

        setItems(selectedRecordState);    
        setSelectedRecord(selectedRecordState);      
    } 
    else
    {    
        setSelectedRecord()
    }
}

const GetEMSEdit = (equipmentId) =>{
    let formValues ={
        equipmentItemId: string,
        serialNumber: string,
        tagNumber: string,
        groupName: string,
        categoryName: string,
	    makeName: string,
        modelName: string,
        advertisedPrice: string,
        meterReadingHours: string,
        modelYear: string,
        store: string,
        storeShipState: string,
        phone: string,
        isNew: string,
        description: string,
        storeNumber: string,
        isActive: string,
        isHotList: string,
        attachmentList: string,
        makeOrder: string,
        ba: string
    }
    console.log('Equipment ID = ', equipmentId);
    OutboundEMSService.getEMS(equipmentId).then((data)=>{
        formValues ={
            equipmentItemId: data.equipmentItemId,
            serialNumber: data.serialNumber,
            tagNumber: data.tagNumber,
            groupName: data.groupName,
            categoryName: data.categoryName,
            makeName: data.makeName,
            modelName: data.modelName,
            advertisedPrice: data.advertisedPrice,
            meterReadingHours: data.meterReadingHours,
            modelYear: data.modelYear,
            store: data.store,
            storeShipState: '-',//data.storeShipState,
            phone: data.phone,
            isNew: data.isNew,
            url:data.url,
            description: data.description,
            storeNumber: data.storeNumber,
            isActive: data.isActive,
            isHotList: data.isHotList,
            attachmentList: data.attachmentList,
            makeOrder: data.makeOrder,
            ba: data.ba,
            rule: data.extraField1
        }
        setEmsEdit(1);
        if (EmsEdit===1){
            console.log('Form >>> Attacheed',formValues)
            navigate('/EMSEdit', {state:{data:formValues}});
        }    
    })
}

const ApplySearch = () =>{    

        if (SelectedLogic === "AND"){
            const selectedRecordState = LiveRecords
            .filter(d => 
                { return(
                                (d.groupName === Condition1)                     
                                && (d.categoryName === Condition2)   
                                && (d.modelName === Condition3)
                                && (d.isActive === Condition4)
                                && (d.isHotList === Condition5)
                    )                    
                        });

            setItems(selectedRecordState);    
            setSelectedRecord(selectedRecordState);                
            setFilteredRecordState(selectedRecordState);   
        }
        if (SelectedLogic === "OR"){
            const selectedRecordState = LiveRecords
            .filter(d => 
                { return(
                        (d.groupName === Condition1)                     
                    || (d.categoryName === Condition2)   
                    || (d.modelName === Condition3)
                    || (d.isActive === Condition4)
                    || (d.isHotList === Condition5)

                                                                           
                    )                    
                        });
            setItems(selectedRecordState);    
            setSelectedRecord(selectedRecordState); 
            setFilteredRecordState(selectedRecordState);     
        }
}

function canSearch(searchString) {
    return searchString;
}

// *************************************************************
// Filtering methds using Objects To Search
// *************************************************************
function filterRecordsOne(records, filter) {
    var result = [];
    for (var prop in filter) {
        if (filter.hasOwnProperty(prop)) {    
            //at the first iteration prop will be address
            for (var i = 0; i < 1; i++) {
                console.log('Checking = ',records[i][prop], filter[prop]);
                if (records[i][prop] === filter[prop]) {
                    result.push(records[i]);
                }
            }
        }
    }
    return result;
}

// *************************************************************
function filterRecords(records, filter) {
    var result = [];
    records.filter((item)=> {
        for (var key in filter) {
          if (item[key] === undefined || item[key] !== filter[key]){
            return null;
          }
            else {
                console.log('Pushing')
                result.push(item);
            }    
        }
        console.log("RES = ", result);
        return result;
    })
}


  const ApplyNewSearch = (e) =>{    
    //e.preventDefault();

    console.log('Target Rule = ',e.target.value);
    setRuleName(e.target.value);
 
    var searchString = ''; 
    var newSearchString = [];
    if (e.target.value === undefined || e.target.value === "" || e.target.value === null){
        return null;
    }
    else{
    IVRiversMatchingService.getRiversMatchingRule(e.target.value).then((data)=>{       
        data.map(da=> {
            let theFieldValue = ''
            theFieldValue = da.fieldValue
            console.log('theFieldValue', theFieldValue);

            if (da.fieldName === "isNew"){
                filterToSearch.isNew = booleanify(theFieldValue)             
            }
            if (da.fieldName === "isActive"){
                filterToSearch.isActive = booleanify(theFieldValue)             
            }
            if (da.fieldName === "isHotList"){
                filterToSearch.isHotList = booleanify(theFieldValue)             
            }
            if (da.fieldName === "equipmentItemId"){
                filterToSearch.equipmentItemId = parseInt(theFieldValue)             
            }
            if (da.fieldName === "advertisedPrice"){
                filterToSearch.advertisedPrice = parseFloat(theFieldValue)            
            }
            if (da.fieldName === "meterReadingHours"){
                filterToSearch.meterReadingHours = theFieldValue             
            }
            if (da.fieldName === "modelYear"){
                filterToSearch.modelYear = theFieldValue             
            }
            if (da.fieldName === "serialNumber"){
                filterToSearch.serialNumber = theFieldValue             
            }

            if (da.fieldName === "tagNumber"){
                filterToSearch.tagNumber = theFieldValue             
            }

            if (da.fieldName === "groupName"){
                filterToSearch.groupName = theFieldValue             
            }
            
            if (da.fieldName === "categoryName"){
                filterToSearch.categoryName = theFieldValue             
            }

            if (da.fieldName === "makeName"){
                filterToSearch.makeName = theFieldValue             
            }

            if (da.fieldName === "modelName"){
                filterToSearch.modelName =  theFieldValue          
            }

            if (da.fieldName === "store"){
                filterToSearch.store = theFieldValue          
            }

            if (da.fieldName === "storeShipState"){
                filterToSearch.storeShipState = theFieldValue             
            }

            if (da.fieldName === "primaryPhone"){
                filterToSearch.primaryPhone = theFieldValue             
            }

            if (da.fieldName === "description"){
                filterToSearch.description = theFieldValue             
            }

            if (da.fieldName === "storeNumber"){
                filterToSearch.storeNumber = theFieldValue             
            }

            if (da.fieldName === "attachmentList"){
                filterToSearch.attachmentList=  theFieldValue             
            }

            if (da.fieldName === "makeOrder"){
                filterToSearch.makeOrder =  theFieldValue             
            }

            if (da.fieldName === "ba"){
                filterToSearch.ba = theFieldValue             
            }
            console.log('Filter To Search = ',filterToSearch);
        });
        let selectedRecordState;

        if (Object.keys(filterToSearch).length > 0) {
            console.log('DATABUFFER=>',LiveRecords);
             selectedRecordState = LiveRecords[0].filter(rec => {
                return Object.keys(filterToSearch).every(filter => {
                    return filterToSearch[filter] === rec[filter]
                });
            })
        }  else {
            filterToSearch = {
                equipmentItemId: 0
            }
            selectedRecordState = LiveRecords.filter(rec => {
                return Object.keys(filterToSearch).every(filter => {
                    return filterToSearch[filter] === rec[filter]
                });
            })

        }

    //         const selectedRecordState = Records.matches
    //     .filter(d => 
    //         {                 
    //             return (

    //     //  ******************************************************************
    //     //  String Based Search                    
    //     //  ******************************************************************
    //     //  (d.storeNumber === "22") && (d.isNew === false) && (d.advertisedPrice === 318200.0000) && (d.modelName === "1023E")
    //     //  `${searchString}`    ------> Still Now Working   
    //     //  canSearch(searchString)   ------> Still Now Working
    //     //  searchString             ------> Still Now Working

    //     //  ******************************************************************
    //     //  Array Based Search  //  Array = [key, value]                   
    //     //  ******************************************************************
    //     d.modelName === "1023E"             //------> Working
    //     // d.modelName === filterToSearch.modelName 
    //     //   ( newSearchString[0][0] 
    //     //         && newSearchString[1][0]            
    //     //         && newSearchString[2][0]            
    //     //         && newSearchString[3][0]            
    //     //     )  // ------> Still Not Working

            
    //      )                    
    //  });
        console.log('Result Set = ',selectedRecordState.length);    
        setViewNew(selectedRecordState.length)        
        setItems(selectedRecordState);    
        setSelectedRecord(selectedRecordState);                
        setFilteredRecordState(selectedRecordState);   
    })
}

}
const booleanify = (value: string): boolean => {
    const truthy: string[] = [
        'true',
        'True',
        '1'
    ]
  
    return truthy.includes(value)
  }

  const GetEMSView = (equipmentId) =>{
    let formValues ={
        equipmentItemId: string,
        serialNumber: string,
        tagNumber: string,
        groupName: string,
        categoryName: string,
        makeName: string,
        modelName: string,
        advertisedPrice: string,
        meterReadingHours: string,
        modelYear: string,
        store: string,
        storeShipState: string,
        phone: string,
        isNew: string,
        description: string,
        storeNumber: string,
        isActive: string,
        isHotList: string,
        attachmentList: string,
        makeOrder: string,
        ba: string
    }
    console.log('Equipment ID = ', equipmentId);

    LiveRecords.map((record)=> {
        if (record.equipmentItemId === equipmentId) {
        formValues = {
            equipmentItemId: record.equipmentItemId,
            serialNumber: record.serialNumber,
            tagNumber: record.tagNumber,
            groupName: record.groupName,
            categoryName: record.categoryName,
            makeName: record.makeName,
            modelName: record.modelName,
            advertisedPrice: record.advertisedPrice,
            meterReadingHours: record.meterReadingHours,
            modelYear: record.modelYear,
            store: record.store,
            storeShipState: '-',//record.storeShipState,
            phone: record.primaryPhone,
            isNew: record.isNew,
            url: getAllUrl(record.pictures),//record.pictures[0].url,
            description: record.description,
            storeNumber: record.storeNumber,
            isActive: record.isActive,
            isHotList: record.isHotList,
            attachmentList: record.attachmentList,
            makeOrder: record.makeOrder,
            ba: record.ba
        }
            console.log('Rec => ',formValues);
            setEmsView(1);
            if (EmsView===1){
                console.log('Form<<',formValues)
                navigate('/EMSView', {state:{data:formValues}});
            }    
        }
    });

}


return (
    <>
        <hr/>
        <Container>
        <Row>
                <Col md="3">        
                        <div className="heading">
                            <table className="table table-primary">
                                <tr><td><h5>Inbound :</h5></td><td> <h5>{readyEMS}</h5> </td></tr>
                                <tr><td><h5>Published :</h5></td><td> <h5>{PublishedEMS}</h5></td></tr>
                            </table>
                        </div>      
                        <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <section>
                            <div className="container m-5">

                            <input  className="checkbox" 
                                         type="checkbox" 
                                         onClick={(e)=> onCheckBoxChange(e)}                                             
                                         checked = {check1}  
                                         {...register("requestOnly", { required: {value: true, message: "This is required field"} })}  
                                         id="AllEquipments" 
                                         placeholder="All Equipments">
                            </input>

                            <select  className="custom-select" {...register("Rules")}  id="Rules" value = { RuleName } onChange={(e)=> { ApplyNewSearch(e); }}>
                            {
                                RuleSet.map((record)=>
                                ( 
                                    <option  key={Math.random()}  value={record.productCode}>{record.productCode}</option>
                                ))
                            }
                            </select>   

                            <h2>{errors.EMSKEYS && <p>{errors.EMSKEYS.message}</p>}</h2>

                            <td><button className="theAll" onClick={(()=> PublishLogicalEMS())}>Publish All</button></td>

                            {
                                (ViewNew === 0) &&
                                <td><button className="theNew" onClick={((e)=> CreateNewOutbound(e))}>New</button></td>
                            }
                            </div>
                            </section>
                      </form>  

                        </div>
                </Col>

                <Col md="9">
                <h6>Inbound EMS API</h6>
                <hr/>          
                <ReactPaginate 
                        previousLabel = {'<'} 
                        nextLabel = {'>'}
                        breakLabel = {'...'}
                        pageCount = { Math.ceil(pageCount) }
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}

                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}

                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}

                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}

                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                    <Table striped bordered hover variant="dark">
                    <thead>
                            <tr key= { Math.random() }>
                                <th>Equipment</th>
                                <th>Tag</th>

                                <th>Group</th>
                                <th>Category</th>
                                <th>Make</th>

                                <th>Model</th>
                                <th>Year</th>
                                {/* <th className="buttons">View</th> */}
                                <th className="buttons">Publish</th>

                                {/* <th>State</th>
                                <th>Phone</th>
                                <th>isNew</th>
                                <th>Desc</th>
                                <th>StoreNo</th>
                                <th>IsActive</th>
                                <th>IsHotList</th>
                                <th>List</th>
                                <th>MakeOrder</th>
                                <th>BA</th> */}

                            </tr>
                    </thead>
                    <tbody>

                    { 
                        SelectedRecord !== undefined && SelectedRecord.map((pro) => {
                        return <>
                            <tr key= { Math.random() }>
                                <td> { pro.equipmentItemId }</td>
                                <td> { pro.tagNumber }</td>
                                <td> { pro.groupName }</td>
                                <td> { pro.categoryName }</td>
                                <td> { pro.makeName }</td>
                                <td> { pro.modelName }</td>
                                <td> { pro.modelYear }</td>
                                {/* <td><button className="viewnewwButton buttons" onClick={(()=> GetEMSView(pro.equipmentItemId))}>View</button></td> */}
                                <td><button className="viewnewwButton buttons" onClick={(()=> PublishSingleEMS(pro.equipmentItemId))}>Publish</button></td>
                                {/* <td> { pro.storeShipState }</td>
                                <td> { pro.primaryPhone }</td>
                                <td> { pro.isNew }</td>
                                <td> { pro.description }</td>
                                <td> { pro.storeNumber }</td>
                                <td> { pro.isActive }</td>
                                <td> { pro.isHotList }</td>
                                <td> { pro.attachmentList }</td>
                                <td> { pro.makeOrder }</td>
                                <td> { pro.ba }</td> */}
                                {/* <td><button className="selectButton" onClick={(()=> GetPromotion(pro.promotionId))}> Select</button></td> */}
                            </tr>
                        </>
                        })                  
                    }
                    </tbody>
                    </Table>
                    {/* <img width="400px" height="400px" src={uri} alt="" /> */}
                </Col>
          </Row>
          <hr/>          
                    <table>
                    <tr>
                        <td>
                            <button className="theButtons" onClick={()=> DisplayOutboundEMS()}>View Outbound</button>                            
                        </td>
                        <td>
                            <button className="theButtons" onClick={()=> ClearOutboundEMS()}>Clear Outbound</button>                                                        
                        </td>
                    </tr>
                    </table>
          <hr/>          
          <Row>
            <Col md="12">
            <Table striped bordered hover variant="dark">
                    <thead>
                            <tr key= { Math.random() }>
                                <th>Status</th>
                                <th>Rule</th>
                                <th>Equipment</th>
                                <th>Tag</th>
                                <th>Group</th>
                                <th>Category</th>
                                <th>Make</th>
                                <th>Model</th>
                                <th>Year</th>
                                <th className="editbutton">Edit</th>
                            </tr>
                    </thead>
                    <tbody>

                    { 
                        PublishedRecord !== undefined && PublishedRecord.map((pro) => {
                        return <>
                            <tr key= { Math.random() }>
                                <td>{pro.status}</td>
                                <td> { pro.extraField1 }</td>
                                <td> { pro.equipmentItemId }</td>
                                <td> { pro.tagNumber }</td>
                                <td> { pro.groupName }</td>
                                <td> { pro.categoryName }</td>
                                <td> { pro.makeName }</td>
                                <td> { pro.modelName }</td>
                                <td> { pro.modelYear }</td>
                                <td><button className="editButton editbutton" onClick={(()=> GetEMSEdit(pro.equipmentItemId))}>Edit</button></td>
                                {/* <td><button className="selectButton" onClick={(()=> GetPromotion(pro.promotionId))}> Select</button></td> */}
                            </tr>
                        </>
                        })                  
                    }
                    </tbody>
                    </Table>

            </Col>
          </Row>
      </Container>
    </>
  )
}

export default ProcessEMSAPI