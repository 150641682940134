import React, {  useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { string } from 'yup';
import './forms.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import ProfileService from '../../services/ProfileService'
import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Modal from '../../components/Modal/Modal'

import { number } from 'yup/lib/locale';

import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'

// *******************************************************************
import ReactPaginate from 'react-paginate'
import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { profilesUri, pageSize, PortNumber,BaseURL } from '../../constants'
// ********************************************************************

let formValues = {
    id: number,
    profileId: string,
    firstName: string,
    lastName: string,
    contact: string,
    email: string
}
const Profile = () => {
    const auth = useAuth();  
    const navigate = useNavigate();
    const [isLogin, setLogin] = useState(false);
    const [RecordAdded, setRecordAdded] = useState(0);

    // **************************************************************************************************
    const [modalOpen, setModalOpen] = useState(false);
    const [modalAction, setAction] = useState(false);
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState([]);
  
    useEffect(() => {
      const getComments = async () => {
        //   const res = await fetch(profilesUri);
        //   const data = await res.json();
          const data = await ProfileService.getAllProfles();
          const total = data.length;
          const TotalPages = Math.ceil(total/pageSize);
          setPageCount(TotalPages);
          setItems(data);

          const PageOneFromServer = await fetchComments(1);
          setItems(PageOneFromServer);
      };
  
      getComments();
  
    }, [RecordAdded]);
    
    const fetchComments = async(currentPage) => {
            // const res = await fetch(`${BaseURL}:${PortNumber}/Profile/GetPageProfile?PageNumber=${currentPage}&PageSize=${pageSize}`);
            // const data = await res.json();
            const data = await ProfileService.getPagedProfles(currentPage, pageSize);
            return data;
        };
          
  
        const handlePageClick = async (data) => {
            let currentPage = data.selected + 1;
            const commentsFromServer = await fetchComments(currentPage);
            setItems(commentsFromServer);
          }
          
    // **************************************************************************************************
    const { 
            register, watch, handleSubmit, 
            // setValue, resetValue,unregister, 
            // resetField, setError, clearErrors, 
            // setFocus, getValues, trigger, control,
            formState: { 
                errors, 
               // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
            } } 
        = useForm({ defaultValues: { Id: 0, ProfileId: '', FirstName: '', LastName: '', Contact: '', Email: '' } });

    useEffect(() => {
        const subscription = watch((data)=>{
            console.log(data);
        })
        return ()=> {
            subscription.unsubscribe();
        }
    }, [watch]) 

    useEffect(() => {
        let userLogin = null;
          try
          {
            userLogin = JSON.parse(localStorage.getItem('credentials')).userName;
            AuthService.getForcedLogoutUser(userLogin).then((login)=>{                
                if ((login.loginStatus === 'ForcedLogout') || (login.loginStatus === 'Logout')) {                
                    localStorage.removeItem('credentials')
                    localStorage.removeItem('role')
                    navigate("/Login");
                }});
            UserService.getUser(userLogin).then((user)=>{
                if (user.status === 'InActive') {                
                    localStorage.removeItem('credentials')
                    localStorage.removeItem('role')
                    navigate("/Login");
                }});

      
          }
          catch{}
          
          
          if (userLogin === null || userLogin === undefined) {
            setLogin(false);
            navigate('/Login');
          }
          else
          {
            setLogin(true);
          }
        },[]);

    const onSubmit = (data, event) => {
        event.preventDefault();
        formValues = {
            id: 0,
            profileId: data.profileId,
            firstName: data.firstName,
            lastName: data.lastName,
            contact: data.contact,
            email: data.email
        };
        ProfileService.addProfile(formValues).then((profile)=>{
            console.log('Form Values = ', formValues);
            console.log('Profile = ',profile);
            toast.success('Profile Added Successfully',{ position: 'bottom-center'});
            setRecordAdded((previousState)=> previousState + 1);
            clearValueForm();
        }).catch((e) => {
            console.log('Add Profile Error', e.message);
            toast.error('Adding Profile Error ',{ position: 'bottom-center'})
        });
    }
    const updateProfile = () =>{
        setValueForm();
         ProfileService.editProfile(formValues).then((profile)=>{
            profile.data ? toast.success('Profile Edited Successfully',{ position: 'bottom-center'}):toast.info('Profile does not exists',{ position: 'bottom-center'});
            setRecordAdded((previousState)=> previousState + 1);
            clearValueForm();
        })
        .catch((e) => {
            console.log('Edit Profile Error', e.message);
            toast.error('Edit PRofile Error ',{ position: 'bottom-center'})
        });
    }
    const deleteProfile = () =>{
        // setValueForm();
        // ProfileService.deleteProfile(formValues).then((profile)=> {
        //     profile.data ? toast.success('Profile Delete Successfully .... ',{ position: 'bottom-center'}):toast.info('Profile does not exists',{ position: 'bottom-center'});
        //     clearValueForm();
        // }).catch((e) => {
        //     console.log('Delete Profile Error', e.message);
        //     toast.error('Delete Profile Error ',{ position: 'bottom-center'})
        // });
    }

    const setValueForm = (() => { 
        formValues = {
            id: document.getElementById('id').value,
            profileId: document.getElementById('profileId').value,
            firstName: document.getElementById('firstName').value,
            lastName: document.getElementById('lastName').value,
            contact: document.getElementById('contact').value,
            email: document.getElementById('email').value
         };
    });
 
    const clearValueForm = (() => {
        formValues = {
            id: '',
            profileId: '',
            firstName: '',
            lastName: '',
            contact: '',
            email: ''
         };

         document.getElementById('id').value= '';
         document.getElementById('profileId').value= '';
         document.getElementById('firstName').value= '';
         document.getElementById('lastName').value= '';
         document.getElementById('contact').value= '';
         document.getElementById('email').value= '';
    });

    const GetProfile = (selectedProfile) =>{
        ProfileService.getAllProfles().then((data)=>{
            console.log('axios Instance Data = ', data);
        })
        ProfileService.getProfile(selectedProfile).then((resp) =>{
              const selectedProfile =  resp;
              document.getElementById('id').value= resp.id;
              document.getElementById('profileId').value= resp.profileId;
              document.getElementById('firstName').value= resp.firstName;
              document.getElementById('lastName').value= resp.lastName;
              document.getElementById('contact').value= resp.contact;
              document.getElementById('email').value= resp.email;
              console.log(selectedProfile);
          }).catch((e) => {
              console.log('Error', e.message);
          });    
  }

    return (
    <>
        {
            localStorage.getItem('credentials') && 
          <>



      <Container>
        <Row>
            <Col md = "5">
                   <div className="heading">
                        <h5 className="mainheading">User Profile</h5>
                        <hr/>
                    </div>              

        
                    <form onSubmit={handleSubmit(onSubmit)}>

                    <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>

                    <input className="input" type="text" {...register("profileId", { required: {value: true, message: "This is required field"} })}  id="profileId" placeholder="Profile ID"></input>
                    <h2>{errors.profileId && <p>{errors.profileId.message}</p>}</h2>

                    <input className="input" type="text" {...register("firstName", { required: {value: true, message: "This is required field"} })}  id="firstName" placeholder="First Name"></input>
                    <h2>{errors.firstName && <p>{errors.firstName.message}</p>}</h2>

                    <input className="input" type="text"   {...register("lastName", { required: {value: true, message:"This is required field"}})} id="lastName" placeholder="Last name"></input>
                    <h2>{errors.lastName && <p>{errors.lastName.message}</p>}</h2>

                    <input className="input" type="text"  {...register("contact", { required: {value: true, message:"This is requiredfield"}})} id="contact" placeholder="Contact"></input>
                    <h2>{errors.contact && <p>{errors.contact.message}</p>}</h2>

                    <input className="input" type="text" {...register("email", { required: {value: true, message:"This is required field"} })} id="email" placeholder="Email Address"></input>
                    <h2>{errors.email && <p>{errors.email.message}</p>}</h2>
                    <hr/>
                    <div className="container">
                        <div className="row">
                            <div className="col md-3 sm-3 xs-2">
                                <button className="submit" type="submit">Save</button>
                            </div>
                        </div>
                    </div>
                </form>  
                <hr/>
                <ToastContainer />
            </Col>

            <Col md = "2">
            <div className="heading">
            <h5 className="operation">Operations</h5>
            <hr/>
            </div>              

            <div className="container">
                <div className="row">
                    <div className="col md-3 sm-3 xs-2">
                        <button onClick={ updateProfile } className="button" type="button">Update</button>
                    </div>

                    {/* <div className="col md-3 sm-3 xs-2">
                        <button onClick={ deleteProfile } className="button" type="button">Delete</button>
                    </div> */}

                    <div className="col md-3 sm-3 xs-2">
                        <button className="button"  onClick={() => { setValueForm(); setModalOpen(true); } }> Delete </button>
                    </div>

                    {    
                        modalOpen && 
                        <Modal 
                            setOpenModal={ setModalOpen } 
                            setAction = { setAction }
                            formValues = { formValues }
                            formName = "Profile"
                            title = "Are you sure to delete ?" 
                            message = "Profile will be deleted !" 
                            setRecordAdded = { setRecordAdded }
                            />
                    }            
                </div>
            </div>
            </Col>

          <Col md="5">
          <ReactPaginate 
            previousLabel = {'<'} 
            nextLabel = {'>'}
            breakLabel = {'...'}
            pageCount = { Math.ceil(pageCount) }
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}

            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}

            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}

            breakLinkClassName={'page-link'}
            activeClassName={'active'}
        />
          <Table striped bordered hover variant="dark">
          <thead>
                <tr key= { Math.random() }>
                  <th>Profile ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Contact</th>
                  <th>Edit</th>
                </tr>
          </thead>
          <tbody>

          { 
            items.map((user) => {
            return <>
                  <tr key= { Math.random() }>
                      <td> { user.profileId }</td>
                      <td> { user.firstName }</td>
                      <td> { user.lastName }</td>
                      <td> { user.contact }</td>
                      <td><button className="selectButton" onClick={(()=> GetProfile(user.profileId))}> Select</button></td>
                  </tr>
               </>
            })                  
          }
          </tbody>
          </Table>
        </Col>
        </Row>
      </Container>      

            </>        
        }
    </>
  )
}

export default Profile