import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import "./Modal.css";
import ProfileService from '../../services/ProfileService'
import UserService from '../../services/UserService'
import RoleService from '../../services/RoleService'
import ProductService from "../../services/ProductService";
import MakeService from "../../services/MakeService";
import ModalService from "../../services/ModalService";
import IndustryService from "../../services/IndustryService";
import StoreService from "../../services/StoreService";
import MachineTypeService from "../../services/MachineTypeService";
import MachineSubTypeService from "../../services/MachineSubTypeService";
import LocationService from "../../services/LocationService";
import LocationBuilderService from "../../services/LocationBuilderService";
import PromotionService from "../../services/PromotionService";
import PromotionBuilderService from "../../services/PromotionBuilderService";
import ProductAttachmentService from "../../services/ProductAttachmentService";
import ProductCheckoutService from "../../services/ProductCheckoutService";
import ProductAvailabilityService from "../../services/ProductAvailabilityService";
import ProductDeliveryService from "../../services/ProductDeliveryService"
import DynamicFieldsService from "../../services/DynamicFieldsService";
import ProductBaseMachineService from "../../services/ProductBaseMachineService";
import AdditionalFieldsService  from "../../services/AdditionalFieldsService";
import IVRiversMatchingService from "../../services/IVRiversMatchingService";
import IVDeereMatchingService from "../../services/IVDeereMatchingService";

function Modal({ setOpenModal, title, message, setAction, formValues, formName, setRecordAdded}) {

  const continueAction = (e)=>{
    e.preventDefault();
    setAction(true);
    setOpenModal(false);    
    
    if (formName === "Profile") {
        setRecordAdded((previousState)=> previousState - 1);
        ProfileService.deleteProfile(formValues).then((profile)=> {
          profile.data ? toast.success('Profile Delete Successfully .... ',{ position: 'bottom-center'}):toast.info('Profile does not exists',{ position: 'bottom-center'});
        }).catch((e) => {
          console.log('Delete Profile Error', e.message);
          toast.error('Delete Profile Error ',{ position: 'bottom-center'})
        });
    }          
    
    if (formName === "User") {
      setRecordAdded((previousState)=> previousState - 1);
      UserService.deleteUser(formValues).then((user)=> {
      user.data ? toast.success('User Deleted Successfully',{ position: 'bottom-center'}):toast.info('User not exists',{ position: 'bottom-center'});
      }).catch((e) => {
        toast.error('Delete User Error ',{ position: 'bottom-center'})
      });                
    }         

    if (formName === "Role"){
      console.log('Form Name = ',formName);
      console.log('Form Values = ', formValues);
      setRecordAdded((previousState)=> previousState - 1);
      RoleService.deleteRole(formValues).then((role)=> {
        toast.success('Role Deleted Successfully',{ position: 'bottom-center'})
        }).catch((e) => {
          toast.error('Delete Role Error ',{ position: 'bottom-center'})
        });
    }

    if (formName === "Product"){
      console.log('Form Name = ',formName);
      console.log('Form Values = ', formValues);
      setRecordAdded((previousState)=> previousState - 1);
      ProductService.deleteProduct(formValues).then((product)=> {
        toast.success('Product Deleted Successfully',{ position: 'bottom-center'})
        }).catch((e) => {
          toast.error('Delete Product Error ',{ position: 'bottom-center'})
        });
    }            

    if (formName === "Product Base Machine"){
      console.log('Form Name = ',formName);
      console.log('Form Values = ', formValues);
      setRecordAdded((previousState)=> previousState - 1);

      console.log('************** Form Values to be deleted *********** = ', formValues);

      ProductAttachmentService.deleteProductAttachmentSpecial(formValues).then(data=>{
        console.log('Product Attachment is deleted ', data);
      }).catch(err=>{
        console.log('Product Attachment delete error', err);
      })
      ProductCheckoutService.deleteProductCheckoutSpecial(formValues).then(data=>{        
        console.log('Product Checkout is deleted ', data);
      }).catch(err=>{
        console.log('Product Checkout delete error', err);
      })
      ProductAvailabilityService.deleteProductAvailabilitySpecial(formValues).then(data=>{        
        console.log('Product Availability is deleted ', data);
      }).catch(err=>{
        console.log('Product Availability delete error', err);
      })
      ProductDeliveryService.deleteProductDeliverySpecial(formValues).then(data=>{        
        console.log('Product Delivery is deleted ', data);
      }).catch(err=>{
        console.log('Product Delivery delete error', err);
      })

      const form1Special = {
        id: 0,
        ruleName: formValues.productCode,
        fieldName:'-',
        fieldValue:'-'
      }
      console.log('Special Form 1 = ', form1Special);

      AdditionalFieldsService.deleteAdditionalFieldsSpecial(form1Special).then(data=>{
         console.log('Additional Fields are deleted ', data);
      }).catch(err=>{
        console.log('Additional Fields delete error', err);
      })
      const form2Special = {
        id: 0,
        ruleCode: formValues.productCode,
        fieldName:'-',
        fieldValue:'-'
      }
      console.log('Special Form 2 = ', form2Special);

      IVRiversMatchingService.deleteRiversMatchingSpecial(form2Special).then(data=>{
        console.log('Rivers Matching rules are deleted ', data);
     }).catch(err=>{
       console.log('Rivers Matching rules delete error', err);
     })

     IVDeereMatchingService.deleteDeereMatchingSpecial(form2Special).then(data=>{
        console.log('Deere Matching rules are deleted ', data);
     }).catch(err=>{
         console.log('Deere Matching rules delete error', err);
     })

      ProductBaseMachineService.deleteProductBaseMachine(formValues).then((product)=> {
        toast.success('Product Base Machine Deleted Successfully',{ position: 'bottom-center'})
        }).catch((e) => {
          toast.error('Delete Product Base Machine Error ',{ position: 'bottom-center'})
        });
    }            

    if (formName === "Make"){
      console.log('Form Name = ',formName);
      console.log('Form Values = ', formValues);
      setRecordAdded((previousState)=> previousState - 1);
      MakeService.deleteMake(formValues).then((product)=> {
        toast.success('Make Deleted Successfully',{ position: 'bottom-center'})
        }).catch((e) => {
          toast.error('Make Product Error ',{ position: 'bottom-center'})
        });
    }            

    if (formName === "Modal"){
      console.log('Form Name = ',formName);
      console.log('Form Values = ', formValues);
      setRecordAdded((previousState)=> previousState - 1);
      ModalService.deleteModal(formValues).then((product)=> {
        toast.success('Modal Deleted Successfully',{ position: 'bottom-center'})
        }).catch((e) => {
          toast.error('Delete Modal Error ',{ position: 'bottom-center'})
        });
    }            

    if (formName === "Industry"){
      console.log('Form Name = ',formName);
      console.log('Form Values = ', formValues);
      setRecordAdded((previousState)=> previousState - 1);
      IndustryService.deleteIndustry(formValues).then((product)=> {
        toast.success('Industry Deleted Successfully',{ position: 'bottom-center'})
        }).catch((e) => {
          toast.error('Delete Industry Error ',{ position: 'bottom-center'})
        });
    }            

    if (formName === "Store"){
      console.log('Form Name = ',formName);
      console.log('Form Values = ', formValues);
      setRecordAdded((previousState)=> previousState - 1);
      StoreService.deleteStore(formValues).then((product)=> {
        toast.success('Store Deleted Successfully',{ position: 'bottom-center'})
        }).catch((e) => {
          toast.error('Delete Store Error ',{ position: 'bottom-center'})
        });
    }            

    if (formName === "Machine Type"){
      console.log('Form Name = ',formName);
      console.log('Form Values = ', formValues);
      setRecordAdded((previousState)=> previousState - 1);
      MachineTypeService.deleteMachineType(formValues).then((product)=> {
          toast.success('Machine Type Deleted Successfully',{ position: 'bottom-center'})
        }).catch((e) => {
          toast.error('Delete Machine Type Error ',{ position: 'bottom-center'})
        });
    }            

    if (formName === "Machine Sub Type"){
      console.log('Form Name = ',formName);
      console.log('Form Values = ', formValues);
      setRecordAdded((previousState)=> previousState - 1);
      MachineSubTypeService.deleteMachineSubType(formValues).then((product)=> {
        toast.success('Machine Sub Type Deleted Successfully',{ position: 'bottom-center'})
        }).catch((e) => {
          toast.error('Delete Machine Sub Type Error ',{ position: 'bottom-center'})
        });
    }            



    if (formName === "Product Attachment"){
      console.log('Form Name = ',formName);
      console.log('Form Values = ', formValues);
      setRecordAdded((previousState)=> previousState - 1);
      ProductAttachmentService.deleteProductAttachment(formValues).then((product)=> {
        toast.success('Product Attachment Deleted Successfully',{ position: 'bottom-center'})
        }).catch((e) => {
          toast.error('Delete Product Attachment Error ',{ position: 'bottom-center'})
        });
    }            


    if (formName === "Product Checkout"){
      console.log('Form Name = ',formName);
      console.log('Form Values = ', formValues);
      setRecordAdded((previousState)=> previousState - 1);
      ProductCheckoutService.deleteProductCheckout(formValues).then((product)=> {
        toast.success('Product Checkout Deleted Successfully',{ position: 'bottom-center'})
        }).catch((e) => {
          toast.error('Delete Product Checkout Error ',{ position: 'bottom-center'})
        });
    }            


    if (formName === "Product Availability"){
      console.log('Form Name = ',formName);
      console.log('Form Values = ', formValues);
      setRecordAdded((previousState)=> previousState - 1);
      ProductAvailabilityService.deleteProductAvailability(formValues).then((product)=> {
        toast.success('Product Availability Deleted Successfully',{ position: 'bottom-center'})
        }).catch((e) => {
          toast.error('Delete Product Availability Error ',{ position: 'bottom-center'})
        });
    }            

    if (formName === "Location"){
      console.log('Form Name = ',formName);
      console.log('Form Values = ', formValues);
      setRecordAdded((previousState)=> previousState - 1);
      LocationService.deleteLocation(formValues).then((product)=> {
        toast.success('Location Deleted Successfully',{ position: 'bottom-center'})
        }).catch((e) => {
          toast.error('Delete Location Error ',{ position: 'bottom-center'})
        });
    }            

    if (formName === "Location Builder"){
      console.log('Form Name = ',formName);
      console.log('Form Values = ', formValues);
      setRecordAdded((previousState)=> previousState - 1);
      LocationBuilderService.deleteLocationBuilder(formValues).then((product)=> {
        toast.success('Location Builder Deleted Successfully',{ position: 'bottom-center'})
        }).catch((e) => {
          toast.error('Delete Location Builder Error ',{ position: 'bottom-center'})
        });
    }            

    if (formName === "Promotion"){
      console.log('Form Name = ',formName);
      console.log('Form Values = ', formValues);
      setRecordAdded((previousState)=> previousState - 1);
      PromotionService.deletePromotions(formValues).then((product)=> {
        toast.success('Promotion Deleted Successfully',{ position: 'bottom-center'})
        }).catch((e) => {
          toast.error('Delete Promotion Error ',{ position: 'bottom-center'})
        });
    }            

    if (formName === "Promotion Builder"){
      console.log('Form Name = ',formName);
      console.log('Form Values = ', formValues);
      setRecordAdded((previousState)=> previousState - 1);
      PromotionBuilderService.deletePromotions(formValues).then((product)=> {
        toast.success('Promotion Builder Deleted Successfully',{ position: 'bottom-center'})
        }).catch((e) => {
          toast.error('Delete Promotion Builder Error ',{ position: 'bottom-center'})
        });
    }            

    if (formName === "Dynamic Fields"){
      console.log('Form Name = ',formName);
      console.log('Form Values = ', formValues);
      setRecordAdded((previousState)=> previousState - 1);
      DynamicFieldsService.deleteField(formValues).then((product)=> {
        toast.success('Field Deleted Successfully',{ position: 'bottom-center'})
        }).catch((e) => {
          toast.error('Delete Field Error ',{ position: 'bottom-center'})
        });
    }            

  }

  return (

    <div className="modalBackground">
    <ToastContainer />
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div className="title">
          <h4>{ title }</h4>
        </div>
        <div className="body">
          <h5>{ message }</h5>
        </div>
        <div className="footer">
        </div>

        <table>
          <tr>
            <td className="tdclass">
            </td>
            <td className="tdclass">
            </td>
            <td className="tdclass">
            </td>
            <td className="tdclass">
                <button className="actionButtons" onClick={(e) => continueAction(e)}>Continue</button>          
            </td>
            <td className="tdclass">
                <button className="actionButtons" onClick={() => { setOpenModal(false);}}>Close</button>
            </td>

          </tr>
        </table>
      </div>
    </div>
  );
}

export default Modal;