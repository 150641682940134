import { locationBuilderUri, apiLocationBuilderUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class LocationBuilderService {
    static  getAllLocationsBuilder() {
        return axiosInstance.get(`/LocationBuilder`)
                    .then((resp)=>resp.data);
    }

    static  getPagedLocationsBuilder(currentPage, pageSize) {
        return axiosInstance.get(`/LocationBuilder/GetPageLocationBuilder?PageNumber=${currentPage}&PageSize=${pageSize}`)
                            .then((resp)=>resp.data);
    }

    static  getLocationBuilder(locationCode) {
        return axiosInstance.get(`LocationBuilder/GetLocationBuilder?LocationCode=${locationCode}`)
                    .then((resp)=>resp.data);
    }

    static addLocationBuilder(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.post(`/LocationBuilder`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editLocationBuilder(payload) {
        return axiosInstance.patch("LocationBuilder/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteLocationBuilder(payload) {
        return axiosInstance.post("LocationBuilder/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default LocationBuilderService