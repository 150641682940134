import React, {useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import User from './User';
import AuthService from '../../services/AuthService'
import UserService from '../../services/UserService'
import { ToastContainer } from 'react-toastify';


import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'
import Roles from './Roles';
import CurrentLogins from './CurrentLogins';
import ProductsGrid from './ProductsGrid';
import ProductBaseMachine from './ProductBaseMachine';
import ProductAvailability from './ProductAvailability';
import ProductCheckout from './ProductCheckout';
import ProductAttachment from './ProductAttachment';
import ProductSummary from './ProductSummary';
//import Groups from './Groups';

const Products = () => {
  const auth = useAuth();  
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);

  useEffect(() => {
    let userLogin = null;
      try
      {
        userLogin = JSON.parse(localStorage.getItem('credentials')).userName;
        AuthService.getForcedLogoutUser(userLogin).then((login)=>{                
            if ((login.loginStatus === 'ForcedLogout') || (login.loginStatus === 'Logout')) {                
                localStorage.removeItem('credentials')
                navigate("/Login");
            }});
        UserService.getUser(userLogin).then((user)=>{
            if (user.status === 'InActive') {                
                localStorage.removeItem('credentials')
                navigate("/Login");
            }});

  
      }
      catch{}
      
      
      if (userLogin === null || userLogin === undefined) {
        setLogin(false);
        navigate('/Login');
      }
      else
      {
        setLogin(true);
      }
    },[]);
  return (
    <>

        {
          JSON.parse(localStorage.getItem('credentials')) && 
          JSON.parse(localStorage.getItem('roles')).role === 'admin' &&

          <>
            <Tabs defaultActiveKey="first">
              <Tab eventKey="first" title="Products">
              <div style={{ display: 'block', width: '100%', padding: 0, background: 'white' }}>
                  <ProductSummary />
              </div>          
              </Tab>

              <Tab eventKey="second" title="Base Machine">
              <div style={{ display: 'block', width: '100%', padding: 0, background: 'white' }}>
                  <ProductBaseMachine />
              </div>          
              </Tab>

              <Tab eventKey="third" title="Locations & Availability">
              <div style={{ display: 'block', width: '100%', padding: 0, background: 'white' }}>
                  <ProductAvailability />
              </div>          
              </Tab>

              <Tab eventKey="fourth" title="Attachment">
              <div style={{ display: 'block', width: '100%', padding: 0, background: 'white' }}>
                  <ProductAttachment />
              </div>          
              </Tab>

              <Tab eventKey="fifth" title="Checkout">
              <div style={{ display: 'block', width: '100%', padding: 0, background: 'white' }}>
                  <ProductCheckout />
              </div>          
              </Tab>

            </Tabs>
            <ToastContainer/>

          </>
        }
    </>
  )
}

export default Products