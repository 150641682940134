import { productAttachmentUri, apiProductAttachementUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class ProductAttachmentService {

    static  getAllProductsAttachment() {
        return axiosInstance.get(`/ProductAttachment`)
                    .then((resp)=>resp.data);
    }

    static  getPagedProductAttachment(currentPage, pageSize) {
        return axiosInstance.get(`/ProductAttachment/GetPageProductAttachment?PageNumber=${currentPage}&PageSize=${pageSize}`)
                    .then((resp)=>resp.data);
    }

    static  getProductAttachment(productId) {
        console.log('productID = ', productId);
        console.log('URI = ',`ProductAttachment/GetProductAttachmentId?ProductId=${productId}`);        
        return axiosInstance.get(`ProductAttachment/GetProductAttachmentId?ProductId=${productId}`)
                    .then((resp)=>resp.data);
    }

    static  getAttachmentName(Name) {
        return axiosInstance.get(`ProductAttachment/AttachmentName?Name=${Name}`)
                    .then((resp)=>resp.data);
    }

    static  createProductAttachment(payload) {
        return axiosInstance.post(`/ProductAttachment`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static  editProductAttachment(payload) {
        return axiosInstance.patch('ProductAttachment/Update', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
    
    static  deleteProductAttachment(payload) {
        return axiosInstance.post('ProductAttachment/Delete', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static  deleteProductAttachmentSpecial(payload) {
        return axiosInstance.post('ProductAttachment/DeleteSpecial', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default ProductAttachmentService