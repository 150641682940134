import { promotionUri, apiPromotionUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class PromotionService {
    static  getAllPromotions() {
        return axiosInstance.get(`/Promotion`)
                    .then((resp)=>resp.data);
    }

    static  getPagedPromotions(currentPage, pageSize) {
        return axiosInstance.get(`/Promotion/GetPagePromotion?PageNumber=${currentPage}&PageSize=${pageSize}`)
                            .then((resp)=>resp.data);
    }

    static  getPromotions(promotionId) {
        return axiosInstance.get(`Promotion/GetPromotion?PromotionId=${promotionId}`)
                    .then((resp)=>resp.data);
    }

    static addPromotions(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.post(`/Promotion`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editPromotions(payload) {
        return axiosInstance.patch("Promotion/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deletePromotions(payload) {
        return axiosInstance.post("Promotion/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default PromotionService