import { modalUri, apiModalUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class ModalService {
    static  getAllModals() {
        return axiosInstance.get(`/Modal`)
                    .then((resp)=>resp.data);
    }

    static  getPagedModal(currentPage, pageSize) {
        return axiosInstance.get(`/Modal/GetPageModal?PageNumber=${currentPage}&PageSize=${pageSize}`)
                            .then((resp)=>resp.data);
    }

    static  getModal(modalId) {
        return axiosInstance.get(`/Modal/GetModal?ModalName=${modalId}`)
                    .then((resp)=>resp.data);
    }

    static addModal(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.post(`/Modal`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editModal(payload) {
        return axiosInstance.patch("/Modal/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteModal(payload) {
        return axiosInstance.post("/Modal/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default ModalService