
import axiosInstance from '../services/AxiosInstance'

export class IVDeereMatchingService {
    static  getAllDeereMatching() {
        return axiosInstance.get(`/IVDeereMatching`)
                    .then((resp)=>resp.data);
    }

    static  getPagedDeereMatching(currentPage, pageSize) {
        return axiosInstance.get(`/IVDeereMatching/GetPageIVDeereMatching?PageNumber=${currentPage}&PageSize=${pageSize}`)
                    .then((resp)=>resp.data);
    }

    static  getDeereMatching(RuleCode, FieldName) {
        return axiosInstance.get(`IVDeereMatching/GetIVDeereMatching?RuleCode=${RuleCode}&fieldName=${FieldName}`)
                    .then((resp)=>resp.data);
    }

    static  getDeereMatchingRule(RuleCode) {
        return axiosInstance.get(`IVDeereMatching/GetIVDeereMatchingRule?RuleCode=${RuleCode}`)
                    .then((resp)=>resp.data);
    }

    static addDeereMatching(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.post(`/IVDeereMatching`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editDeereMatching(payload) {
        return axiosInstance.patch("IVDeereMatching/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteDeereMatching(payload) {
        return axiosInstance.post("IVDeereMatching/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteDeereMatchingSpecial(payload) {
        return axiosInstance.post("IVDeereMatching/DeleteSpecial", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default IVDeereMatchingService