
import axiosInstance from './AxiosInstance'
import deereInstance from './DeereInstance'
//import { RequestAuthorizationCode } from "react-oauth2-auth-code-flow";
//import ClientOAuth2 from "client-oauth2";
const axios = require('axios');
 
export class DeereCollectionService {
    static  getAllDeere() {
        return axiosInstance.get(`/DeereCollection`)
                    .then((resp)=>resp.data);
    }

    static  getPagedDeere(currentPage, pageSize) {
        return axiosInstance.get(`/DeereCollection/GetPageDeereCollection?PageNumber=${currentPage}&PageSize=${pageSize}`)
                            .then((resp)=>resp.data);
    }

    static  getDeere(SKU) {
        return axiosInstance.get(`/DeereCollection/GetDeereCollection?SKU=${SKU}`)
                    .then((resp)=>resp.data);
    }

    static addDeere(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.post(`/DeereCollection`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editDeere(payload) {
        return axiosInstance.patch("/DeereCollection/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteDeere(payload) {
        return axiosInstance.post("/DeereCollection/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
 
    // ***************************************************************** 
    // Deere Endoints 
    // *****************************************************************

    static  generateCode() {
        return deereInstance.get('/')
                    .then((resp)=>resp.data);
    }
    static  getProducts(payload) {
        return deereInstance.post(`ddf/api/productjson`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          }).then((resp) => resp.data);
    }
    
    static  getProductList(payload) {
        return deereInstance.post(`ddf/api/productlist`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          }).then((resp) => resp.data);
    }

    static  getProductPrice(payload) {
        return deereInstance.post(`ddf/api/productprice`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          }).then((resp) => resp.data);
    }

    static  getProductDisclaimer(payload) {
        return deereInstance.post(`ddf/api/productdisclaimer`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          }).then((resp) => resp.data);
    }

    static  getProductLinkList(payload) {
        return deereInstance.post(`ddf/api/linklist`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          }).then((resp) => resp.data);
    }

    static  getProductSummary(payload) {
        return deereInstance.post(`ddf/api/productsummary`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          }).then((resp) => resp.data);
    }


    static  getTaxonomy(payload) {
        return deereInstance.post(`ddf/api/taxonomy`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          }).then((resp) => resp.data);
    }


    static  getCarousel(payload) {
        return deereInstance.post(`ddf/api/carouseljson`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          }).then((resp) => resp.data);
    }

    static  getMedia(payload) {
        return deereInstance.post(`ddf/api/media`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          }).then((resp) => resp.data);
    }

    static  getMetadata(payload) {
        return deereInstance.post(`ddf/api/metadata`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          }).then((resp) => resp.data);
    }


    static  getNews(payload) {
        return deereInstance.post(`ddf/api/news`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }

        }).then((resp) => resp.data);
            
        }
    static  getModelList(payload) {
        return deereInstance.post(`ddf/api/modellist`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          }).then((resp) => resp.data);
    }

    static  getComponents(payload) {
        return deereInstance.post(`ddf/api/component`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          }).then((resp) => resp.data);
    }

// *********************************************************************
// Fetch Method Requests using Fetch And Axios
// *********************************************************************
    static async getAccessTokenV0(){
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        }        
        
        return await fetch("https://sso.johndeere.com/oauth2/ausb10y6h81iolhhJ1t7/v1/token",                             
        { 
            method: 'POST',
            header: headers,
            data: JSON.stringify({
                'client_id': '0oaoz5eqhdFqvidtW1t7',
                'client_secret': 'SvpJDdVfxAenLP18wzyohOvgMjyWQMI-rqBDk15w',
                'grant_type': 'client_credentials',
                "scope": ""    
            })
        })
            .then(response => {
                console.log('Response Token = ',response);
                return response.json()
            })
            .catch((res) => {
                console.log('Token Access Response = ',res);
            });    
    }

    static async getAccessTokenV1(){
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            }        
            
            return await fetch("https://sso.johndeere.com/oauth2/ausb10y6h81iolhhJ1t7/v1/token",                             
            { 
                method: 'POST',
                header: headers,
                data: JSON.stringify({
                    mode:'urlencoded',
                    urlencoded: [
                            {key: "grant_type", value: "client_credentials", disabled: false},
                            {key: "client_id", value: "0oaoz5eqhdFqvidtW1t7", disabled: false},
                            {key: "client_secret", value: "SvpJDdVfxAenLP18wzyohOvgMjyWQMI-rqBDk15w", disabled: false},
                            {key: "scope", value: "", disabled:false}
                        ]
                                // 'client_id': '0oaoz5eqhdFqvidtW1t7',
                                // 'client_secret': 'SvpJDdVfxAenLP18wzyohOvgMjyWQMI-rqBDk15w',
                                // 'grant_type': 'client_credentials',
                                // "scope": ""    
                })
            })
                .then(response => {
                    console.log('Response Token = ',response);
                    return response.json()
                })
                .catch((res) => {
                    console.log('Token Access Response = ',res);
                });    
        }

    static getAccessTokenV2(){
        return axios.request({
            url: "/oauth2/ausb10y6h81iolhhJ1t7/v1/token",
            method: "POST",
            body: JSON.stringify({
                'client_id': '0oaoz5eqhdFqvidtW1t7',
                'client_secret': 'SvpJDdVfxAenLP18wzyohOvgMjyWQMI-rqBDk15w',
                'grant_type': 'client_credentials',
                "scope": ""    
            }),
            headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
            },
            baseURL: "https://sso.johndeere.com",
            // auth: {
            //   ClientId: "0oaoz5eqhdFqvidtW1t7", 
            //   ClientSecret: "SvpJDdVfxAenLP18wzyohOvgMjyWQMI-rqBDk15w"
            // },
          }).then(respose => {
            console.log(respose);  
          }); 
    }
    
    static getAccessTokenV3(){
        return axios.request({
            url: "https://sso.johndeere.com/oauth2/ausb10y6h81iolhhJ1t7/v1/token",
            method: "POST",
            body: JSON.stringify({
                mode:'urlencoded',
                urlencoded: [
                      {key: "grant_type", value: "client_credentials", disabled: false},
                      {key: "client_id", value: "0oaoz5eqhdFqvidtW1t7", disabled: false},
                      {key: "client_secret", value: "SvpJDdVfxAenLP18wzyohOvgMjyWQMI-rqBDk15w", disabled: false},
                      {key: "scope", value: "", disabled:false}
                  ],
             }),
            header: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
            },
//            baseURL: "https://sso.johndeere.com",
          }).then(respose => {
            console.log(respose);  
          }); 
    }

    static getQuickTaxonomy(payload,accessToken){
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            dataType: "json",
            'Authorization': `Bearer ${accessToken}`,
        }
    
        return axios("https://servicesext.deere.com/ddf/api/taxonomy", JSON.stringify(payload),
                { 
                    method: 'POST',
                    header: headers, 
//                    body: JSON.stringify(payload)
                })
                    //.then(this._checkStatus)
                    .then(response => {
                    console.log('Taxonomy Response = ',response);
                    return response.json()
            })
            .catch((res) => {
                console.log('Taxonomy Error Response = ',res);
            });    
    }

    // static getSimpleToken(){
    //     return fetch("https://sso.johndeere.com/oauth2/ausb10y6h81iolhhJ1t7/v1/token")
    //     .then(res=>res.json())
    // }

    
// static Oauth2AccessToken(){
//     var authClient= new ClientOAuth2({
//         clientId: '0oaoz5eqhdFqvidtW1t7',
//         clientSecret: 'SvpJDdVfxAenLP18wzyohOvgMjyWQMI-rqBDk15w',
//         accessTokenUri: 'https://sso.johndeere.com/oauth2/ausb10y6h81iolhhJ1t7/v1/token',
//         //authorizationUri: 'https://github.com/login/oauth/authorize',
//         //redirectUri: 'http://example.com/auth/github/callback',
//         scopes: [] //'notifications', 'gist'
//       })
//       const token1 = authClient.token;
//       const token = authClient.createToken('access token', 'optional refresh token', 'optional token type', { data: 'raw user data' })      
//       token.expiresIn(10000000000)
//       token.expiresIn(new Date('2023-12-12'))
//       return token1
// }

// static performSignIn() {

    //     let headers = new Headers();
    //     headers.append('Content-Type', 'application/json');
    //     headers.append('Accept', 'application/json');      
    //     headers.append('Access-Control-Allow-Origin', 'https://localhost:3000');
    //     headers.append('Access-Control-Allow-Credentials', 'true');      
    //     headers.append('GET', 'POST', 'OPTIONS');      
    //     headers.append('Authorization', 'Bearer ' + base64.encode(username + ":" + password));
      
    //     fetch(sign_in, {
    //         //mode: 'no-cors',
    //         credentials: 'include',
    //         method: 'POST',
    //         headers: headers
    //       })
    //       .then(response => response.json())
    //       .then(json => console.log(json))
    //       .catch(error => console.log('Authorization failed : ' + error.message));
    //   }

    // This is normally used in Postman Pre-Script to create token and place it in variable
    // static getToken(){
    //     const postRequest = {
    //         url: "https://sso.johndeere.com/oauth2/ausb10y6h81iolhhJ1t7/v1/token",
    //         method: 'POST',
    //         header: {
    //           'Accept': 'application/json',
    //           'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         body: {
    //             mode: 'urlencoded',
    //             urlencoded: [
    //               {key: "grant_type", value: "client_credentials", disabled: false},
    //               {key: "client_id", value: "0oaoz5eqhdFqvidtW1t7", disabled: false},
    //               {key: "client_secret", value: "SvpJDdVfxAenLP18wzyohOvgMjyWQMI-rqBDk15w", disabled: false},
    //               {key: "scope", value: "", disabled:false}
    //             ]
    //         }
    //       };
    //       console.log('Request = ', postRequest);
    //           pm.sendRequest(postRequest, function (err, response) {
    //             var responseData = response.json();
    //             // uncomment for debug purpose
    //             console.log("access_token is ---> "+responseData["access_token"]);
    //             pm.environment.set("access_token",responseData["access_token"]);
    //         });

    //       //  return fetch(postRequest).then((resp) => resp.json());
    //     }
        // Postman Pre-Script
        // ****************************************************************************

    /// https://en.ryte.com/wiki/Status_Code_400
}

export default DeereCollectionService