import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { PortNumber, BaseURL } from '../constants';
import MetaData from '../services/Data/MetaData'

//const axiosInstance = axios.create({baseURL: `https://localhost:${PortNumber}/`,});
// testuser:UserPassword 
// const accessToken = "dGVzdHVzZXJAcml2ZXJzLmNvbTpVc2VyUGFzc3dvcmQ="


// rajeshlohana@gmail.com:UserPassword 
 const accessToken = 'cmFqZXNobG9oYW5hQGdtYWlsLmNvbTpVc2VyUGFzc3dvcmQ=';
//  const accessToken1 = await MetaData.getAccessToken('testuser@rivers.com','UserPassword');
//  console.log('Access Token 1 ==>>>>> ', accessToken1);

const axiosInstance = axios.create({
	baseURL: `${BaseURL}:${PortNumber}`,
	//baseURL: `https://riversapi.azurewebsites.net`,
	headers: {
		Authorization: `Basic ${accessToken}`
 	},
//	credentials: true
})

// *****************************************************
// Not Recommended Approach
// *****************************************************
// axios.interceptors.request.use(config=>{
// 	config.headers.authorization = `Bearer ${accessToken}`
// 	return config;
// }, error=>{
// 	return Promise.reject(error);
// })


export default  axiosInstance