import { industryUri, apiIndustryUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'


const axios = require('axios');

export class IndustryService {
    static  getAllIndustries() {
        return axiosInstance.get(`/Industry`)
                    .then((resp)=>resp.data);
    }

    static  getPagedIndustry(currentPage, pageSize) {
        return axiosInstance.get(`/Industry/GetPageIndustry?PageNumber=${currentPage}&PageSize=${pageSize}`)
                    .then((resp)=>resp.data);
    }

    static  getIndustry(industryId) {
        return axiosInstance.get(`Industry/GetIndustry?IndustryName=${industryId}`)
                    .then((resp)=>resp.data);
    }

    static addIndustry(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.post(`/Industry`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editIndustry(payload) {
        return axiosInstance.patch("Industry/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteIndustry(payload) {
        return axiosInstance.post("Industry/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default IndustryService