import { industryUri, apiIndustryUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'


const axios = require('axios');

export class DynamicFieldsService {
    static  getAllFields() {
        return axiosInstance.get(`\DynamicFields`)
                    .then((resp)=>resp.data);
    }

    static  getPagedFields(currentPage, pageSize) {
        return axiosInstance.get(`/DynamicFields/GetPageDynamicFields?PageNumber=${currentPage}&PageSize=${pageSize}`)
                    .then((resp)=>resp.data);
    }

    static  getField(fieldName) {
        return axiosInstance.get(`DynamicFields/GetDynamicField?FieldName=${fieldName}`)
                    .then((resp)=>resp.data);
    }

    static addField(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.post(`/DynamicFields`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editField(payload) {
        return axiosInstance.patch("DynamicFields/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteField(payload) {
        return axiosInstance.post("DynamicFields/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default DynamicFieldsService