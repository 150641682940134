import React, {  useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { string } from 'yup';
import '../forms/forms.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import DynamicFieldsService from '../../services/DynamicFieldsService'
import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Modal from '../../components/Modal/Modal'

import { number } from 'yup/lib/locale';

import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'

// *******************************************************************
import ReactPaginate from 'react-paginate'
import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { industryUri, pageSize } from '../../constants'
// ********************************************************************

let formValues = {
    id: number,
    fieldName: string,
    fieldType: string,
    defaultValue: string
}
const ExtraFields = () => {
    const auth = useAuth();  
    const navigate = useNavigate();
    const [isLogin, setLogin] = useState(false);
    const [RecordAdded, setRecordAdded] = useState(0);
    const [fieldState, setFieldState]= useState('Select');
    // **************************************************************************************************
    const [modalOpen, setModalOpen] = useState(false);
    const [modalAction, setAction] = useState(false);
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState([]);
  
    useEffect(() => {
      const getComments = async () => {
          const data = await DynamicFieldsService.getAllFields();
          const total = data.length;
          const TotalPages = Math.ceil(total/pageSize);
          setPageCount(TotalPages);
          setItems(data);

          const PageOneFromServer = await fetchComments(1);
          setItems(PageOneFromServer);
      };
  
      getComments();
  
    },[RecordAdded]);     
    
    const fetchComments = async(currentPage) => {
            const data = await DynamicFieldsService.getPagedFields(currentPage, pageSize);
            return data;
        };
          
  
        const handlePageClick = async (data) => {
            let currentPage = data.selected + 1;
            const commentsFromServer = await fetchComments(currentPage);
            setItems(commentsFromServer);
          }
          
    // **************************************************************************************************
    const { 
            register, watch, handleSubmit, 
            // setValue, resetValue,unregister, 
            // resetField, setError, clearErrors, 
            // setFocus, getValues, trigger, control,
            formState: { 
                errors, 
               // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
            } } 
        = useForm({ defaultValues: { id: 0, industryName: '', description: '' } });

    useEffect(() => {
        const subscription = watch((data)=>{
            console.log(data);
        })
        return ()=> {
            subscription.unsubscribe();
        }
    }, [watch]) 

    useEffect(() => {
        let userLogin = null;
          try
          {
            userLogin = JSON.parse(localStorage.getItem('credentials')).userName;

            AuthService.getForcedLogoutUser(userLogin).then((login)=>{                
                if ((login.loginStatus === 'ForcedLogout') || (login.loginStatus === 'Logout')) {                
                    localStorage.removeItem('credentials')
                    navigate("/Login");
                }});

            UserService.getUser(userLogin).then((user)=>{
                if (user.status === 'InActive') {                
                    localStorage.removeItem('credentials')
                    navigate("/Login");
                }});      
          }
          catch{}
          
          
          if (userLogin === null || userLogin === undefined) {
            setLogin(false);
            navigate('/Login');
          }
          else
          {
            setLogin(true);
          }
        },[]);

    const onSubmit = (data, event) => {
        event.preventDefault();
        formValues = {
            id: 0,
            fieldName: data.fieldName.trim(),
            fieldType: data.fieldType.trim(),
            defaultValue: data.defaultValue.trim()
        };

        DynamicFieldsService.addField(formValues).then((field)=>{
            console.log('Form Values = ', formValues);
            console.log('Field = ',field);
            toast.success('Field Added Successfully',{ position: 'bottom-center'});
            setRecordAdded((previousState)=> previousState + 1);
            clearValueForm();
        }).catch((e) => {
            console.log('Add Field Error', e.message);
            toast.error('Adding Field Error ',{ position: 'bottom-center'})
        });
    }
    const updateField = () =>{
        setValueForm();
         DynamicFieldsService.editField(formValues).then((field)=>{
            field.data ? toast.success('Field Edited Successfully',{ position: 'bottom-center'}):toast.info('Field does not exists',{ position: 'bottom-center'});
            setRecordAdded((previousState)=> previousState + 1);
            clearValueForm();
        })
        .catch((e) => {
            console.log('Edit Field Error', e.message);
            toast.error('Edit Field Error ',{ position: 'bottom-center'})
        });
    }
    const deleteField = () =>{
        setValueForm();
        DynamicFieldsService.deleteField(formValues).then((field)=> {
            field.data ? toast.success('Field Delete Successfully .... ',{ position: 'bottom-center'}):toast.info('Field does not exists',{ position: 'bottom-center'});
            clearValueForm();
        }).catch((e) => {
            console.log('Delete Field Error', e.message);
            toast.error('Delete Field Error ',{ position: 'bottom-center'})
        });
    }

    const setValueForm = (() => { 
        formValues = {
            id: document.getElementById('id').value,
            fieldName: document.getElementById('fieldName').value,
            fieldType: document.getElementById('fieldType').value,
            defaultValue: document.getElementById('defaultValue').value
         };
    });
 
    const clearValueForm = (() => {
        formValues = {
            id: '',
            fieldName: '',
            fieldType: '',
            defaultValue: ''
         };

         document.getElementById('id').value= '';
         document.getElementById('fieldName').value= '';
         document.getElementById('fieldType').value= '';
         document.getElementById('defaultValue').value= '';
    });

    const GetField = (selectedField) =>{
        DynamicFieldsService.getField(selectedField.trim()).then((resp) =>{
              const selectedField =  resp;
              document.getElementById('id').value= resp.id;
              document.getElementById('fieldName').value= resp.fieldName;
              document.getElementById('fieldType').value= resp.fieldType;
              document.getElementById('defaultValue').value= resp.defaultValue;
              console.log(selectedField);
          }).catch((e) => {
              console.log('Error', e.message);
          });    
  }

  const onChangeComboBox = async (e)=>{
	setFieldState(e.target.value);
  }
    return (
    <>
        {
            localStorage.getItem('credentials') && 
          <>



      <Container>
        <Row>
            <Col md = "4">
                   <div className="heading">
                        <h5 className="mainheading">Additional Fields</h5>
                        <hr/>
                    </div>              

        
                    <form onSubmit={handleSubmit(onSubmit)}>

                    <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>

                    <input className="input" type="text" {...register("fieldName", { required: {value: true, message: "This is required field"}})}  id="fieldName" placeholder="Field Name"></input>
                    <h2>{errors.fieldName && <p>{errors.fieldName.message}</p>}</h2>


                    <select 
                        className="select" 
                        {...register("fieldType", { required: {value: true, message: "This is required field"} })} 
                        id="fieldType"
                        onChange={(e)=> {
		    		    onChangeComboBox(e);
        			}}  
                    >
                                <option  key={Math.random()}   value="Select">Select Field</option>
                                <option  key={Math.random()}  value="String">String</option>
                                <option  key={Math.random()}  value="Number">Number</option>
                                <option  key={Math.random()}  value="Boolean">Boolean</option>
                                <option  key={Math.random()}  value="Date">Date</option>
                              </select>
                    <h2>{errors.fieldType && <p>{errors.fieldType.message}</p>}</h2>

                    <input className="input" type="text" {...register("defaultValue", { required: {value: true, message: "This is required field"}})}  id="defaultValue" placeholder="Default Value"></input>
                    <h2>{errors.defaultValue && <p>{errors.defaultValue.message}</p>}</h2>

                    <hr/>
                    <div className="container">
                        <div className="row">
                            <div className="col md-3 sm-3 xs-2">
                                <button className="submit" type="submit">Save</button>
                            </div>
                        </div>
                    </div>
                </form>  
                <hr/>
            </Col>

            <Col md = "2">
            <div className="heading">
            <h5 className="operation">Operations</h5>
            <hr/>
            </div>              

            <div className="container">
                <div className="row">
                    <div className="col md-3 sm-3 xs-2">
                        <button onClick={ updateField } className="button" type="button">Update</button>
                    </div>

                    {/* <div className="col md-3 sm-3 xs-2">
                        <button onClick={ deleteProfile } className="button" type="button">Delete</button>
                    </div> */}

                    <div className="col md-3 sm-3 xs-2">
                        <button className="button"  onClick={() => { setValueForm(); setModalOpen(true); } }> Delete </button>
                    </div>

                    {    
                        modalOpen && 
                        <Modal 
                            setOpenModal={ setModalOpen } 
                            setAction = { setAction }
                            formValues = { formValues }
                            formName = "Dynamic Fields"
                            title = "Are you sure to delete ?" 
                            message = "Field will be deleted !" 
                            setRecordAdded = { setRecordAdded }
                            />
                    }            
                </div>
            </div>
            </Col>

          <Col md="6">
          <ReactPaginate 
            previousLabel = {'<'} 
            nextLabel = {'>'}
            breakLabel = {'...'}
            pageCount = { Math.ceil(pageCount) }
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}

            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}

            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}

            breakLinkClassName={'page-link'}
            activeClassName={'active'}
        />
          <Table striped bordered hover variant="dark">
          <thead>
                <tr key= { Math.random() }>
                  <th>Field Name</th>
                  <th>Field Type</th>
                  <th>Field Value</th>
                  <th>Edit</th>
                </tr>
          </thead>
          <tbody>

          { 
            items.map((field) => {
            return <>
                  <tr key= { Math.random() }>
                      <td> { field.fieldName }</td>
                      <td> { field.fieldType }</td>
                      <td> { field.defaultValue }</td>
                      <td><button className="selectButton" onClick={(()=> GetField(field.fieldName))}> Select</button></td>
                  </tr>
               </>
            })                  
          }
          </tbody>
          </Table>
        </Col>
        </Row>
      </Container>      

            </>        
        }
    </>
  )
}

export default ExtraFields