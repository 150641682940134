import { storeUri, apiStoreUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class StoreService {
    static  getAllStores() {
        return axiosInstance.get(`/Store`)
                    .then((resp)=>resp.data);
    }

    static  getPagedStore(currentPage, pageSize) {
        return axiosInstance.get(`/Store/GetPageStore?PageNumber=${currentPage}&PageSize=${pageSize}`)
                            .then((resp)=>resp.data);
    }

    static  getStore(storeId) {
        return axiosInstance.get(`/Store/GetStore?StoreName=${storeId}`)
                    .then((resp)=>resp.data);
    }

    static addStore(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.post(`/Store`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editStore(payload) {
        return axiosInstance.patch("/Store/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteStore(payload) {
        return axiosInstance.post("/Store/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default StoreService