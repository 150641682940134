import { makeUri, apiMakeUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'


const axios = require('axios');

export class DeereAPIBufferService {
    static  getAllAPI() {
        return axiosInstance.get(`/DeereAPIBuffer`)
                    .then((resp)=>resp.data);
    }

    static  getPagedAPI(currentPage, pageSize, Industry) {        
        return axiosInstance.get(`/DeereAPIBuffer/GetPageAPI?PageNumber=${currentPage}&PageSize=${pageSize}&Industry=${Industry}`)
                            .then((resp)=>resp.data);
    }

    static  getAPICategory(Category) {
        return axiosInstance.get(`/DeereAPIBuffer/GetDeereCategory?Category=${Category}`)
                    .then((resp)=>resp.data);
    }

    static  getAPIIndustry(Industry) {
        return axiosInstance.get(`/DeereAPIBuffer/GetDeereIndustry?Industry=${Industry}`)
                    .then((resp)=>resp.data);
    }

    static  getAPIProduct(Product) {
        return axiosInstance.get(`/DeereAPIBuffer/GetDeereProduct?Product=${Product}`)
                    .then((resp)=>resp.data);
    }

    static addAPI(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.post(`/DeereAPIBuffer`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static PublishAPI(BufferId) {
        console.log('Buffer Update Payload = ', BufferId)
        return axiosInstance.post(`/DeereAPIBuffer/Update/Publish?Id=${BufferId}`,{}, {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static UnPublishAPI(BufferId) {
        console.log('Buffer Update Payload = ', BufferId)
        return axiosInstance.post(`/DeereAPIBuffer/Update/UnPublish?Id=${BufferId}`,{}, {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteAPI() {
        return axiosInstance.post("/DeereAPIBuffer/Delete",{}, {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static async bulkDeere(payload) {
        console.log('Bulk Ka Payload =>>>> ',payload);
        return await axiosInstance.post(`/DeereAPIBuffer/RangeRover`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default DeereAPIBufferService