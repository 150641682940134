import { React, useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import RoleService from '../../services/RoleService'
import { rolesUri, pageSize, PortNumber, BaseURL } from '../../constants'
import { ToastContainer, toast } from 'react-toastify';
import Modal from '../../components/Modal/Modal'
import { useForm } from 'react-hook-form'
import { string } from 'yup';
import './forms.css'

// import { Form, Button } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'
import UserService from '../../services/UserService'
import { number } from 'yup/lib/locale';

// ************** Pagination Logic *********************************
import 'bootstrap/dist/css/bootstrap.min.css';
// *******************************************************************

let formValues = {
  id: number,
  roleId: string,
  roleDescription: string
}

const Roles = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setAction] = useState(false);
  const [RecordAdded, setRecordAdded] = useState(0);

  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState([]);

  let [roles, setRoles] = useState([]);
  let rolesArray = [];
  useEffect(() => {
    const getComments = async () => {
        const res = await fetch(rolesUri);
        const data = await res.json();

        const total = data.length;
        const PageSize = Math.ceil(total/pageSize);

        setPageCount(PageSize);
        setItems(data);

        const PageOneFromServer = await fetchComments(1);
        setItems(PageOneFromServer);  

    };

    getComments();

  },[RecordAdded]);     
  
  const fetchComments = async(currentPage) => {

     // console.log('Current Page = ', currentPage, 'Page Size = ', pageSize);

    // Page Data returned should be paginated data to display
    const res = await fetch(`${BaseURL}:${PortNumber}/Roles/GetPageRole?PageNumber=${currentPage}&PageSize=${pageSize}`);
          const data = await res.json();
          console.log('Page Data = ',data);
          return data;
      };
        

      const handlePageClick = async (data) => {
          let currentPage = data.selected + 1;
          const commentsFromServer = await fetchComments(currentPage);
          setItems(commentsFromServer);
        }
        
    
  useEffect(()=>{
    const getUsers = async () => {
    RoleService.getAllRoles().then((role)=>{
      console.log('Users Returned =',role);
      // setUsers(user);
      setRoles({...roles, u:role });      
      rolesArray = [...role];    
      console.log('usersArray Returned =',rolesArray);
      rolesArray.map((role) => {
        console.log('Data', role.roleId);
      }); 

      // console.log('usersArray Returned =',usersArray);
         
    }).catch((e) => {
      console.log('Error', e.message);
    });
  };
    getUsers();
  },[RecordAdded]);

  const { 
    register, watch, handleSubmit,
    // setValue, resetValue,unregister, 
    // resetField, setError, clearErrors, 
    // setFocus, getValues, trigger, control,
    formState: { 
        errors, 
       // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
    } } 
      = useForm({ defaultValues: { id: 0, roleId: '', roleDescription: '' } });

      const onSubmit = (data, event) => {
        event.preventDefault();
        formValues = {
            id:0,
            roleId: data.roleId,
            roleDescription: data.roleDescription
        };        
        
        RoleService.addRole(formValues).then((role)=>{
            console.log('Role Added =',role);    

            toast.success('Role Added Successfully',{ position: 'bottom-center'});
            clearValueForm();
        }).catch((e) => {
            console.log('Add Role Error', e.message);
            toast.error('Adding Role Error ',{ position: 'bottom-center'})
          });
    }

    const updateRole = () =>{
        setValueForm();
         RoleService.editRole(formValues).then((role)=>{
            role.data ? toast.success('Role Edited Successfully',{ position: 'bottom-center'}):toast.info('Role does not exists',{ position: 'bottom-center'});
            clearValueForm();
        })
        .catch((e) => {
            console.log('Edit Role Error', e.message);
            toast.error('Edit Role Error ',{ position: 'bottom-center'})
        });
        }
 
    const deleteRole = () => {
        // setValueForm();
        // RoleService.deleteRole(formValues).then((role)=> {
        //     role.data ? toast.success('Role Deleted Successfully',{ position: 'bottom-center'}):toast.info('Role does not exists',{ position: 'bottom-center'});
        //     clearValueForm();
        // }).catch((e) => {
        //     console.log('Delete Role Error', e.message);
        //     toast.error('Delete Role Error ',{ position: 'bottom-center'})
        // });
    }

    const setValueForm = (() => {
      formValues = {
          id: document.getElementById('id').value,
          roleId: document.getElementById('roleId').value,
          roleDescription: document.getElementById('roleDescription').value
       };
       console.log('Form Values = ',formValues);
  });

  const clearValueForm = (() => {
      formValues = {
          id: '',
          roleId: '',
          roleDescription: ''
       };
       //setValue(formValues);
       console.log('Cleared', formValues);
       document.getElementById('id').value= '';
       document.getElementById('roleId').value= '';
       document.getElementById('roleDescription').value= '';
  });


  const GetRole = (selectedRole) =>{
        RoleService.getRole(selectedRole).then((resp) =>{
              const selectedName =  resp;
              document.getElementById('id').value= resp.id;
              document.getElementById('roleId').value= resp.roleId;
              document.getElementById('roleDescription').value= resp.roleDescription;
              console.log('Selected Role = ',resp);              
          }).catch((e) => {
              console.log('Error', e.message);
          });    
  }



  
  return (
    <>

      <Container>
        <Row>
        <Col md="5">
                        <div className="heading">
                          <hr/>
                          <h5>Roles Registration</h5>
                          <hr/>
                        </div>
                    
                        <form onSubmit={handleSubmit(onSubmit)}>

                        <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="Id"></input>

                        <input className="input" type="text" {...register("roleId", { required: {value: true, message: "This is required one field"} })}  id="roleId" placeholder="Role ID"></input>
                        <h2>{errors.ProfileId && <p>{errors.ProfileId.message}</p>}</h2>

                        <input className="input" type="text" {...register("roleDescription", { required: {value: true, message: "This is required one field"}})}  id="roleDescription" placeholder="Role Description"></input>
                        <h2>{errors.Username && <p>{errors.Username.message}</p>}</h2>
                        
                        <hr/>
                        <div className="container">
                            <div className="row">
                                <div className="col md-3 sm-3 xs-2">
                                    <button className="submit" type="submit">Save</button>
                                </div>
                            </div>
                        </div>
                    </form>  
                    <hr/>    

        </Col>
        <Col md="2">
          <div className="heading">
              <hr/>
                <h5 className = "operation">Operations</h5>
              <hr/>
          </div>
          <div className="container">
              <div className="row">
              <div className="col md-3 sm-3 xs-2">
                    <button onClick = { updateRole } className="button" type="button">Update</button>
              </div>
              {/* <div className="col md-3 sm-3 xs-2">
                    <button onClick={ deleteRole } className="button" type="button">Delete..</button>
              </div> */}

              <div className="col md-3 sm-3 xs-2">
                  <button className="button"  onClick={() => { setValueForm(); setModalOpen(true); } }> Delete </button>
              </div>

                    {    
                        modalOpen && 
                        <Modal 
                            setOpenModal={ setModalOpen } 
                            setAction = { setAction }
                            formValues = { formValues }
                            formName = "Role"
                            title = "Are you sure to delete ?" 
                            message = "Role will be deleted !"
                            setRecordAdded = { setRecordAdded }                               
                            />
                        }            
              </div>
          </div>
        </Col>

        <Col md="5">
        <hr/>
          <ReactPaginate 
            previousLabel = {'<'} 
            nextLabel = {'>'}
            breakLabel = {'...'}
            pageCount = { Math.ceil(pageCount) }
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}

            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}

            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}

            breakLinkClassName={'page-link'}
            activeClassName={'active'}
        />

          <Table striped bordered hover variant="dark">
          <thead>
                <tr key= { Math.random() }>
                  <th>Role ID</th>
                  <th>Role Description</th>
                  <th>Edit</th>
                </tr>
          </thead>
          <tbody>

          { 
            items.map((role) => {
            return <>
                  <tr key= { Math.random() }>
                      <td> { role.roleId }</td>
                      <td> { role.roleDescription }</td>
                      <td><button className="selectButton" onClick={(()=> GetRole(role.roleId))}> Select</button></td>
                  </tr>
               </>
            })                  
          }
          </tbody>
          </Table>
        </Col>
        </Row>
      </Container>      
    </>
  )
}
export default Roles
