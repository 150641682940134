import React, {  useEffect, useState } from 'react'
import Card from '../forms/ui/Card'
import { Row, Col, Container, Table } from 'react-bootstrap'
import classes from './forms.css'


import { useForm } from 'react-hook-form'
import { string } from 'yup';
import './forms.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import LocationBuilderService from '../../services/LocationBuilderService'
import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Modal from '../../components/Modal/Modal'
import { boolean, number } from 'yup/lib/locale';
import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'
import Multiselect from 'multiselect-react-dropdown'

// *******************************************************************
import ReactPaginate from 'react-paginate'
//import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { industryUri, pageSize, LocationCodeURI } from '../../constants'
import MetaData from '../../services/Data/MetaData'
// ********************************************************************


let formValues = {
  id: number,
  locationCode: string,
  address: string,
  city: string, 
  state: string,
  zip: string,
  googleLink: string,
  phoneNumber: string,
  storeNumber: string,
  storeHours: string,
  zipCodes: string,
  industries: string,
  zipCodeRadius: string
}

const LocationBuilder = () => {
  const auth = useAuth();  
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);
  const [RecordAdded, setRecordAdded] = useState(0);

  // **************************************************************************************************
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setAction] = useState(false);
  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [LocationCodeSet, setLocationCodeSet] = useState([]);
  const [IndustrySet, setIndustrySet] = useState([]);
  const [LocationCode, setLocationCode] = useState();
  const [IndustrySetOnly, setIndustrySetOnly] = useState([]);



  // const [StoreHours, setStoreHours] = useState();
  // const [StoreTime, setStoreTime] = useState();

  const { 
    register, watch, handleSubmit, 
    // setValue, resetValue,unregister, 
    // resetField, setError, clearErrors, 
    // setFocus, getValues, trigger, control,
    formState: { 
        errors, 
       // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
    } } 
      = useForm({ defaultValues: {  id: 0, locationCode: '', address: '', city: '',  state: '', zip: '', googleLink: '', phoneNumber: '', storeNumber: '', storeHours: '', zipCodes: '', industries: '' } });

      useEffect(() => {
        const getComments = async () => {
            const data = await LocationBuilderService.getAllLocationsBuilder();  
            const total = data.length;
            const TotalPages = Math.ceil(total/pageSize);
            setPageCount(TotalPages);
            setItems(data);  
            const PageOneFromServer = await fetchComments(1);
            setItems(PageOneFromServer);
            const dataSet = await MetaData.getAllLocationCode();
            setLocationCodeSet(dataSet);

            const dataSet2 = await MetaData.getAllIndustry();
           // setIndustrySet(dataSet2);

            const dataSet3 = await MetaData.getAllIndustryOnly();
            let dataset = []
            dataSet3.map(data=>{
              dataset = [...dataset, {"industryName": data}]
            })
            setIndustrySetOnly(dataset);
        };
    
        getComments();
    
      },[RecordAdded]);     
      
      const fetchComments = async(currentPage) => {
              const data = await LocationBuilderService.getPagedLocationsBuilder(currentPage, pageSize);  
              return data;
          };
            
    
          const handlePageClick = async (data) => {
              let currentPage = data.selected + 1;
              const commentsFromServer = await fetchComments(currentPage);
              setItems(commentsFromServer);
            }
            
            const handleChange = e => {
              console.log(e);
//              setSelectedOptions(e);
            }
            

            // **************************************************************************************************

      const onStoreHoursChange = async (e)=>{
        e.preventDefault();
        if (e.target.value === "Monday To Friday")
        {
          document.getElementById("zipCodes").value = "7.00 - 17:00"
        }
        if (e.target.value === "Saturday")
        {
          document.getElementById("zipCodes").value = "7:00 - 12:00"
        }
        //setPromotionIDSet(e.target.value);
      }
      const onChangeIndustryBox = async (e)=>{
        //setPromotionIDSet(e.target.value);
      }

      let result="";
      const onSubmit = (data, event) => {
      event.preventDefault();
      if (LocationCode !== undefined)  {
        LocationCode.map(data=>{
          result = result + data.industryName.concat(",")          
        })
      }
      result = result.substring(0, result.length-1);

      if (document.getElementById('storeHours').value === "" || document.getElementById('storeHours').value === undefined)
      {
          alert('Please select Store Hours ! ');
          return;
      }   
  

      formValues = {
        id: data.id,
        locationCode: data.locationCode1,
        address: data.address,
        city: data.city, 
        state: data.state,
        zip: data.zip,
        googleLink: data.googleLink,
        phoneNumber: data.phoneNumber,
        storeNumber: data.storeNumber,
        storeHours: data.storeHours,
        zipCodes: data.zipCodes,
        industries: result,
        zipCodeRadius: document.getElementById('zipcoderadius').value
      };


      LocationBuilderService.getLocationBuilder(data.locationCode1.trim().replace("&","And").replace("+","And")).then((data)=>{
        if (data.length !== 0) {
               console.log('Data Exist =>> ',data)
               toast.info('Record Exists ! ',{ position: 'bottom-center'});        
               return;
        }
        else
        {
          LocationBuilderService.addLocationBuilder(formValues).then((loc)=>{
            console.log('Form Values = ', formValues);
            console.log('Location = ',loc);
            toast.success('Location Builder Added Successfully',{ position: 'bottom-center'});
            setRecordAdded((previousState)=> previousState + 1);
            clearValueForm();
          }).catch((e) => {
            console.log('Add Location Builder Error', e.message);
            toast.error('Adding Location Builder Error ',{ position: 'bottom-center'})
          });     
        }
          });
  }

  const updateLocation = () =>{
    setValueForm();
    if 
    (document.getElementById('address').value === "" || document.getElementById('address').value === undefined)
    {
        alert('Please Select Record First ! ');
        return;
    }   
    if (document.getElementById('city').value === "" || document.getElementById('city').value === undefined)
    {
      alert('Please Select Record First ! ');
      return;
    }   

    if (document.getElementById('state').value === "" || document.getElementById('state').value === undefined)
    {
      alert('Please Select Record First ! ');
      return;
    }   
    if (document.getElementById('zip').value === "" || document.getElementById('zip').value === undefined)
    {
      alert('Please Select Record First ! ');
        return;
    }   
    if (document.getElementById('googleLink').value === "" || document.getElementById('googleLink').value === undefined)
    {
      alert('Please Select Record First ! ');
        return;
    }   

    if (document.getElementById('phoneNumber').value === "" || document.getElementById('phoneNumber').value === undefined)
    {
      alert('Please Select Record First ! ');
        return;
    }   

    if (document.getElementById('storeNumber').value === "" || document.getElementById('storeNumber').value === undefined)
    {
      alert('Please Select Record First ! ');
        return;
    }   
    if (document.getElementById('storeHours').value === "" || document.getElementById('storeHours').value === undefined)
    {
      alert('Please Select Record First ! ');
        return;
    }   
    if (document.getElementById('zipCodes').value === "" || document.getElementById('zipCodes').value === undefined)
    {
      alert('Please Select Record First ! ');
        return;
    }   
    
      LocationBuilderService.editLocationBuilder(formValues).then((attach)=>{
      attach.data ? toast.success('Location Builder Edited Successfully',{ position: 'bottom-center'}):toast.info('Location does not exists',{ position: 'bottom-center'});
        setRecordAdded((previousState)=> previousState + 1);
        clearValueForm();
    })
    .catch((e) => {
        console.log('Edit Location Builder Error', e.message);
        toast.error('Edit Location Builder Error ',{ position: 'bottom-center'})
    });
}

  const GetLocation = (selectedLoc) =>{
    let selected = []
    LocationBuilderService.getLocationBuilder(selectedLoc).then((resp) =>{
          const selectedLoc =  resp;
          console.log(resp)
          document.getElementById('id').value= resp.id;
          document.getElementById('locationCode1').value= resp.locationCode;
          document.getElementById('address').value= resp.address;
          document.getElementById('city').value= resp.city;
          document.getElementById('state').value= resp.state;
          document.getElementById('zip').value= resp.zip;
          document.getElementById('googleLink').value= resp.googleLink;
          document.getElementById('phoneNumber').value= resp.phoneNumber;
          document.getElementById('storeNumber').value= resp.storeNumber;
          document.getElementById('storeHours').value= resp.storeHours;
          document.getElementById('zipCodes').value= resp.zipCodes;
          document.getElementById('setIndustry').value = resp.industries;
          document.getElementById('zipcoderadius').value = resp.zipCodeRadius;
          
          //document.getElementById('industries').value= resp.industries;
        //   resp.industries.split(/\s*,\s*/).forEach(function(myString) {
        //     console.log(myString)
        //     selected = [...selected, {"industryName": myString}]
        // });
        //   setIndustrySet(selected)
      }).catch((e) => {
          console.log('Error', e.message);
      });    
}

const setValueForm = (() => { 
  let result = "";
  if (LocationCode !== undefined)  {
    LocationCode.map(data=>{
      result = result + data.industryName.concat(",")          
    })
  }
  result = result.substring(0, result.length-1);

  formValues = {
      id: document.getElementById('id').value,
      locationCode: document.getElementById('locationCode1').value,
      address: document.getElementById('address').value,
      city: document.getElementById('city').value,
      state: document.getElementById('state').value,
      zip: document.getElementById('zip').value,
      googleLink: document.getElementById('googleLink').value,
      phoneNumber: document.getElementById('phoneNumber').value,
      storeNumber: document.getElementById('storeNumber').value,
      storeHours: document.getElementById('storeHours').value,
      zipCodes: document.getElementById('zipCodes').value,
      industries: result,//document.getElementById('industries').value,
      zipCodeRadius: document.getElementById('zipcoderadius').value      
   };
   console.log('FORM Values = ',formValues);
});

const clearValueForm = (() => {
  formValues = {
      id: '',
      locationCode: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      googleLink: '',
      phoneNumber: '',
      storeNumber: '',
      storeHours: '',
      zipCodes: '',
      industries: '',
      zipCodeRadius: ''
   };

   document.getElementById('id').value= '';
   document.getElementById('locationCode1').value= '';
   document.getElementById('address').value= '';
   document.getElementById('city').value= '';
   document.getElementById('state').value= '';
   document.getElementById('zip').value= '';
   document.getElementById('googleLink').value= '';
   document.getElementById('phoneNumber').value= '';
   document.getElementById('storeNumber').value= '';
   document.getElementById('storeHours').value= '';
   document.getElementById('zipCodes').value= '';
   document.getElementById('industries').value= '';
   document.getElementById('setIndustry').value= '';
});
const onChangeLocationBox = async (e)=>{
  LocationBuilderService.getLocationBuilder(e.target.value).then((resp) =>{
    const selectedLoc =  resp;
//    setLocationCode(e.target.value);
    if (resp.id !== undefined){
          document.getElementById('id').value= resp.id;
      //    document.getElementById('locationCode1').value= resp.locationCode;
          document.getElementById('address').value= resp.address;
          document.getElementById('city').value= resp.city;
          document.getElementById('state').value= resp.state;
          document.getElementById('zip').value= resp.zip;
          document.getElementById('googleLink').value= resp.googleLink;
          document.getElementById('phoneNumber').value= resp.phoneNumber;
          document.getElementById('storeNumber').value= resp.storeNumber;
          document.getElementById('storeHours').value= resp.storeHours;
          document.getElementById('zipCodes').value= resp.zipCodes;
          document.getElementById('industries').value= resp.industries;
          document.getElementById('setIndustry').value = resp.industries;
          console.log(selectedLoc);
    }
    else  {
        document.getElementById('id').value= 0;
      //    document.getElementById('locationCode1').value= resp.locationCode;
          document.getElementById('address').value = '';
          document.getElementById('city').value =  '';
          document.getElementById('state').value =  '';
          document.getElementById('zip').value =  '';
          document.getElementById('googleLink').value =  '';
          document.getElementById('phoneNumber').value =  '';
          document.getElementById('storeNumber').value =  '';
          document.getElementById('storeHours').value =  '';
          document.getElementById('zipCodes').value =  '';
          document.getElementById('industries').value =  '';
          document.getElementById('setIndustry').value = '';
          console.log(selectedLoc);
    }


  }).catch((e) => {
    console.log('Error', e.message);
});    
}
const locs = [];
const selectLocations = (e)=>{
  setLocationCode(e);
  let result = ""
  if (LocationCode !== undefined)  {
    LocationCode.map(data=>{
      result = result + data.industryName.concat(",")          
    })
  }
  result = result.substring(0, result.length-1);
  document.getElementById('setIndustry').value = result;
}

const removeLocations = (e)=>{
  let result = ""
  if (LocationCode !== undefined)  {
    LocationCode.map(data=>{
      result = result + data.industryName.concat(",")          
    })
  }
  result = result.substring(0, result.length-1);
  document.getElementById('setIndustry').value = result;
}
  return (
    <>
      <Container>
      <Row>
<Col md="12">
          <ReactPaginate 
            previousLabel = {'<'} 
            nextLabel = {'>'}
            breakLabel = {'...'}
            pageCount = { Math.ceil(pageCount) }
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}

            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}

            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}

            breakLinkClassName={'page-link'}
            activeClassName={'active'}
        />
          <Table striped bordered hover variant="dark">
          <thead>
                <tr key= { Math.random() }>
                  <th>Code</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Store</th>
                  <th>Hours</th>
                  <th>Timing</th>                  
                  <th>Edit</th>
                </tr>
          </thead>
          <tbody>

          { 
            items.map((loc) => {
            return <>
                  <tr key= { Math.random() }>
                      <td> { loc.locationCode }</td>
                      <td> { loc.city }</td>
                      <td> { loc.state }</td>
                      <td> { loc.storeNumber }</td>
                      <td> { loc.storeHours }</td>
                      <td> { loc.zipCodes }</td>
                      <td><button className="selectButton" onClick={(()=> GetLocation(loc.locationCode))}> Select</button></td>
                  </tr>
               </>
            })                  
          }
          </tbody>
          </Table>
        </Col>

</Row>

          <Row>
                <Col md="10">
                        <div className="heading">
                            <h5 className="mainheading">Location Builder</h5>
                            <hr/>
                        </div>              

        
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>

                          <table> 
                            <tbody>
                              <tr  key= { Math.random() }>
                                <td>                      
                                    <select 
                                      className="select" 
                                      {...register("locationCode1", { required: {value: true, message: "This is required field"} })} 
                                        id="locationCode1"
                                        onChange={(e)=> {
                                        onChangeLocationBox(e);
                                      }}  
                                      >
                                  {
                                      LocationCodeSet.map((d)=>(
                                        <option  key={Math.random()}  value={d.locationCode}>{d.locationCode}</option>
                                      ))
                                  }
                                  </select>
                                  <h2>{errors.locationCode1 && <p>{errors.locationCode1.message}</p>}</h2>                                   
                                </td>
                                <td>
                                    <input className="mediuminput" type="text" {...register("googleLink")}  id="googleLink" placeholder="Google Link"></input>
                                    <h2>{errors.googleLink && <p>{errors.googleLink.message}</p>}</h2>
                                </td>
                              </tr>

                              <tr key= { Math.random() }>
                                <td>
                                        <input className="input" type="text" {...register("address")}  id="address" placeholder="Address"></input>
                                        <h2>{errors.address && <p>{errors.address.message}</p>}</h2>
                                </td>
                                <td>
                                      <input className="input" type="text" {...register("phoneNumber")}  id="phoneNumber" placeholder="Phone Number"></input>
                                      <h2>{errors.phoneNumber && <p>{errors.phoneNumber.message}</p>}</h2>               
                                </td>
                              </tr>

                              <tr key= { Math.random() }>
                                <td>
                                    <input className="input" type="text" {...register("city")}  id="city" placeholder="city"></input>
                                    <h2>{errors.city && <p>{errors.city.message}</p>}</h2>
                                </td>
                                <td>
                                      <input className="input" type="text" {...register("storeNumber")}  id="storeNumber" placeholder="Store Number"></input>
                                      <h2>{errors.storeNumber && <p>{errors.storeNumber.message}</p>}</h2>               
                                </td>
                              </tr>

                              <tr key= { Math.random() }>
                                <td>
                                    <input className="input" type="text" {...register("state")}  id="state" placeholder="State"></input>
                                    <h2>{errors.state && <p>{errors.state.message}</p>}</h2>
                                </td>
                                <td>
                                      {/* <input className="input" type="text" {...register("storeHours", { required: {value: true, message: "This is required field"}})}  id="storeHours" placeholder="Store Hours"></input> */}

                                      <select 
                                        className="select" 
                                        {...register("storeHours")} 
                                          id="storeHours"
                                          onChange={(e)=> {
                                            onStoreHoursChange(e)
                                          }}
                                        >
                                        <option  key={Math.random()}  value="Monday To Friday">Monday To Friday</option> 
                                        <option  key={Math.random()}  value="Saturday">Saturday</option> 
                                      </select>
                                      <h2>{errors.storeHours && <p>{errors.storeHours.message}</p>}</h2>                
                                </td>
                              </tr>

                              <tr key= { Math.random() }>
                                <td>
                                      <input className="input" type="text" {...register("zip")}  id="zip" placeholder="Zip"></input>
                                      <h2>{errors.zip && <p>{errors.zip.message}</p>}</h2>
                                </td>
                                <td>
                                      {/* <input className="mediuminput" defaultValue = "-" type="text" {...register("zipCodes")}  id="zipCodes" placeholder="Zip Codes"></input> */}
                                      <select 
                                        className="select" 
                                        {...register("zipCodes")} 
                                          id="zipCodes"
                                        >
                                        <option  key={Math.random()}  value="7.00 - 17:00">7.00 - 17:00</option> 
                                        <option  key={Math.random()}  value="7:00 - 12:00">7:00 - 12:00</option> 
                                      </select>
                                      <h2>{errors.zipCodes && <p>{errors.zipCodes.message}</p>}</h2>               
                                </td>
                              </tr>
                            </tbody>
                          </table>
                                
                            <input className="radiusinput" type="text"   id="zipcoderadius" placeholder="Zip Code Radius"></input>

                                <label>Industries</label>
                                <div className="longinput">
                                <input id="setIndustry"  disabled className="displayinput"></input>
                                <Multiselect
                                      id = "industries"                                      
                                      displayValue ="industryName"
                                      isObject = {true}
                                      onKeyPressFn = {function noRefCheck(){}}
                                      onRemove  = {(e)  =>  { removeLocations(e) }}
                                      onSearch  = {(e)  =>  {console.log(e)}}
                                      onSelect  = {(e)  =>  { selectLocations(e) }}
                                      options   = { IndustrySetOnly  }
                                      selectedValues = { IndustrySet }
                                      width = "600px"                                      
                                    /> 
                                  </div>                                 
                                    <h2>{errors.industries && <p>{errors.industries.message}</p>}</h2>
 

                          <hr/>
                          <div className="container">
                              <div className="row">
                                  <div className="col md-3 sm-3 xs-2">
                                      <button className="submit" type="submit">Save</button>
                                  </div>
                              </div>
                          </div>
                      </form>  
                </Col>
                <Col md = "2">
            <div className="heading">
            <h5 className="operation">Operations</h5>
            <hr/>
            </div>              

            <div className="container">
                <div className="row">
                    <div className="col md-3 sm-3 xs-2">
                        <button onClick={ updateLocation } className="button" type="button">Update</button>
                    </div>

                    {/* <div className="col md-3 sm-3 xs-2">
                        <button onClick={ deleteProfile } className="button" type="button">Delete</button>
                    </div> */}

                    <div className="col md-3 sm-3 xs-2">
                        <button className="button"  onClick={() => { setValueForm(); setModalOpen(true); } }> Delete </button>
                    </div>

                    {    
                        modalOpen && 
                        <Modal 
                            setOpenModal={ setModalOpen } 
                            setAction = { setAction }
                            formValues = { formValues }
                            formName = "Location Builder"
                            title = "Are you sure to delete ?" 
                            message = "Location Builder will be deleted !" 
                            setRecordAdded = { setRecordAdded }
                            />
                    }            
                </div>
            </div>
            </Col>
          </Row>
      </Container>
    </>
  )
}

export default LocationBuilder