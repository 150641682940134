import React, {  useEffect, useState } from 'react'
import Card from '../forms/ui/Card'
import { Row, Col, Container, Table } from 'react-bootstrap'
import classes from './forms.css'


import { useForm } from 'react-hook-form'
import { string } from 'yup';
import './forms.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import LocationService from '../../services/LocationService'
import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Modal from '../../components/Modal/Modal'
import { boolean, number } from 'yup/lib/locale';
import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'

// *******************************************************************
import ReactPaginate from 'react-paginate'
//import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { industryUri, pageSize } from '../../constants'
// ********************************************************************


let formValues = {
  id: number,
  locationCode: string,
  locationName: string
}

const LocationGrid = () => {
  const auth = useAuth();  
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);
  const [RecordAdded, setRecordAdded] = useState(0);

  // **************************************************************************************************
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setAction] = useState(false);
  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState([]);

  const { 
    register, watch, handleSubmit, 
    // setValue, resetValue,unregister, 
    // resetField, setError, clearErrors, 
    // setFocus, getValues, trigger, control,
    formState: { 
        errors, 
       // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
    } } 
      = useForm({ defaultValues: {  id: 0, locationCode: '', locationName: '' } });

      useEffect(() => {
        const getComments = async () => {
            const data = await LocationService.getAllLocations();  
            const total = data.length;
            const TotalPages = Math.ceil(total/pageSize);
            setPageCount(TotalPages);
            setItems(data);  
            const PageOneFromServer = await fetchComments(1);
            setItems(PageOneFromServer);
        };
    
        getComments();
    
      },[RecordAdded]);     
      
      const fetchComments = async(currentPage) => {
              const data = await LocationService.getPagedLocations(currentPage, pageSize);  
              return data;
          };
            
    
          const handlePageClick = async (data) => {
              let currentPage = data.selected + 1;
              const commentsFromServer = await fetchComments(currentPage);
              setItems(commentsFromServer);
            }
            
      // **************************************************************************************************
  


    const onSubmit = (data, event) => {
      event.preventDefault();
      formValues = {
        id: data.id,
        locationCode: data.locationCode,
        locationName: data.locationName
      };
      LocationService.getLocationCode(data.locationCode.trim().replace("&","And").replace("+","And")).then((data)=>{
      if (data.length !== 0) {
        console.log('Data Exist =>> ',data)
        toast.info('Record Exists ! ',{ position: 'bottom-center'});        
        return;
      }
      else
      {
        LocationService.addLocation(formValues).then((loc)=>{
          console.log('Form Values = ', formValues);
          console.log('Profile = ',loc);
          toast.success('Location Added Successfully',{ position: 'bottom-center'});
          setRecordAdded((previousState)=> previousState + 1);
          clearValueForm();
        }).catch((e) => {
          console.log('Add Location Error', e.message);
          toast.error('Adding Location Error ',{ position: 'bottom-center'})
      });
      } 
    });
  }

  const updateLocation = () =>{
    setValueForm();
      LocationService.editLocation(formValues).then((attach)=>{
      attach.data ? toast.success('Location Edited Successfully',{ position: 'bottom-center'}):toast.info('Location does not exists',{ position: 'bottom-center'});
        setRecordAdded((previousState)=> previousState + 1);
        clearValueForm();
    })
    .catch((e) => {
        console.log('Edit Location Error', e.message);
        toast.error('Edit Location Error ',{ position: 'bottom-center'})
    });
}

  const GetLocation = (selectedLoc) =>{
    LocationService.getLocation(selectedLoc).then((resp) =>{
          const selectedLoc =  resp;
          document.getElementById('id').value= resp.id;
          document.getElementById('locationCode').value= resp.locationCode;
          document.getElementById('locationName').value= resp.locationName;
          console.log(selectedLoc);
      }).catch((e) => {
          console.log('Error', e.message);
      });    
}

const setValueForm = (() => { 
  formValues = {
      id: document.getElementById('id').value,
      locationCode: document.getElementById('locationCode').value,
      locationName: document.getElementById('locationName').value
   };
});

const clearValueForm = (() => {
  formValues = {
      id: '',
      locationCode: '',
      locationName: ''
   };

   document.getElementById('id').value= '';
   document.getElementById('locationCode').value= '';
   document.getElementById('locationName').value= '';
});

  return (
    <>
      <Container>
          <Row>
                <Col md="3">
                        <div className="heading">
                            <h5 className="mainheading">Location Summary</h5>
                            <hr/>
                        </div>              

        
                          <form onSubmit={handleSubmit(onSubmit)}>

                          <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>

                          <input className="input" type="text" {...register("locationCode", { required: {value: true, message: "This is required field"} })}  id="locationCode" placeholder="Location Code"></input>
                          <h2>{errors.locationCode && <p>{errors.locationCode.message}</p>}</h2>


                          <input className="input" type="text" {...register("locationName", { required: {value: true, message: "This is required field"}})}  id="locationName" placeholder="Location Name"></input>
                          <h2>{errors.locationName && <p>{errors.locationName.message}</p>}</h2>


                          <hr/>
                          <div className="container">
                              <div className="row">
                                  <div className="col md-3 sm-3 xs-2">
                                      <button className="submit" type="submit">Save</button>
                                  </div>
                              </div>
                          </div>
                      </form>  
                </Col>
  
  <Col md = "2">
            <div className="heading">
            <h5 className="operation">Operations</h5>
            <hr/>
            </div>              

            <div className="container">
                <div className="row">
                    <div className="col md-3 sm-3 xs-2">
                        <button onClick={ updateLocation } className="button" type="button">Update</button>
                    </div>

                    {/* <div className="col md-3 sm-3 xs-2">
                        <button onClick={ deleteProfile } className="button" type="button">Delete</button>
                    </div> */}

                    <div className="col md-3 sm-3 xs-2">
                        <button className="button"  onClick={() => { setValueForm(); setModalOpen(true); } }> Delete </button>
                    </div>

                    {    
                        modalOpen && 
                        <Modal 
                            setOpenModal={ setModalOpen } 
                            setAction = { setAction }
                            formValues = { formValues }
                            formName = "Location"
                            title = "Are you sure to delete ?" 
                            message = "Location will be deleted !" 
                            setRecordAdded = { setRecordAdded }
                            />
                    }            
                </div>
            </div>
            </Col>

          <Col md="7">
          <ReactPaginate 
            previousLabel = {'<'} 
            nextLabel = {'>'}
            breakLabel = {'...'}
            pageCount = { Math.ceil(pageCount) }
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}

            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}

            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}

            breakLinkClassName={'page-link'}
            activeClassName={'active'}
        />
          <Table striped bordered hover variant="dark">
          <thead>
                <tr key= { Math.random() }>
                  <th>Location Code</th>
                  <th>Location Name</th>
                  <th>Edit</th>
                </tr>
          </thead>
          <tbody>

          { 
            items.map((loc) => {
            return <>
                  <tr key= { Math.random() }>
                      <td> { loc.locationCode }</td>
                      <td> { loc.locationName }</td>
                      <td><button className="selectButton" onClick={(()=> GetLocation(loc.id))}> Select</button></td>
                  </tr>
               </>
            })                  
          }
          </tbody>
          </Table>
        </Col>

          </Row>
      </Container>
    </>
  )
}

export default LocationGrid