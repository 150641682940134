import { productCheckoutUri, apiProductCheckoutUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class ProductCheckoutService {

    static  getAllProductsCheckout() {
        return axiosInstance.get(`/ProductCheckout`)
                    .then((resp)=>resp.data);
    }

    static  getPagedProductCheckout(currentPage, pageSize) {
        return axiosInstance.get(`/ProductCheckout/GetPageProductCheckout?PageNumber=${currentPage}&PageSize=${pageSize}`)
                    .then((resp)=>resp.data);
    }

    static  getProductCheckout(productId) {
        console.log('productID = ', productId);
        console.log('URI = ',`ProductCheckout/GetProductCheckout?ProductCode=${productId}`);
        
        return axiosInstance.get(`ProductCheckout/GetProductCheckout?ProductCode=${productId}`)
                    .then((resp)=>resp.data);
    }
    static  createProductCheckout(payload) {
        return axiosInstance.post(`/ProductCheckout`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static  editProductCheckout(payload) {
        return axiosInstance.patch('ProductCheckout/Update', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
    
    static  deleteProductCheckout(payload) {
        return axiosInstance.post('ProductCheckout/Delete', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static  deleteProductCheckoutSpecial(payload) {
        return axiosInstance.post('ProductCheckout/DeleteSpecial', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default ProductCheckoutService