import { rolesUri } from '../constants';
import axiosInstance from '../services/AxiosInstance';
const axios = require('axios');

export class RoleService {
    static  getAllRoles() {
        return axios.get(rolesUri)
                    .then((resp)=>resp.data);
    }

    static  getRole(roleId) {
        return axios.get(rolesUri + `/GetRole?roleId=${roleId}`)
                    .then((resp)=>resp.data);
    }

    static addRole(payload) {
        console.log('PayLoad = ', payload);
        return axios.post(rolesUri, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editRole(payload) {
        return axios.patch(rolesUri+ "/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteRole(payload) {
        return axios.post(rolesUri + "/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default RoleService