import axios from 'axios';
import { deereInstanceUri } from '../constants';

const accessToken = 'eyJraWQiOiJQZmthOWFLWHVQcENsWVNJaVM4SzA5QlJXdklkaWx6RDAzTENsMjdoaG9zIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULjVVSjFsYUNTUnViMmNsb2d4NUYta1FYU0JpaUxhSktheHRBc3owekZWWG8iLCJpc3MiOiJodHRwczovL3Nzby5qb2huZGVlcmUuY29tL29hdXRoMi9hdXNiMTB5Nmg4MWlvbGhoSjF0NyIsImF1ZCI6ImRjZSIsImlhdCI6MTY3MjM4ODk0NiwiZXhwIjoxNjcyMzkyNTQ2LCJjaWQiOiIwb2FvejVlcWhkRnF2aWR0VzF0NyIsInNjcCI6WyJkY2UiXSwic3ViIjoiMG9hb3o1ZXFoZEZxdmlkdFcxdDcifQ.i9WWLGfyKfUhfpe4Dtq5SEZRrWI2PC28cST8mX9rQVxWWIe_ltgyFlVOojYfDJMGx3rghWFaLQyf7cvH2otggZZsAAM5IdcudMbVZ_j2fqULnGB3Qku9hoa2gX6sCsxIu7avq5iQQCnYDY-QeKvkCk5wwIpeZCvRIqAFnVozU97SaRLhmG0rGFFKcAdBa5TeInltQV5nB5ElK68pxgJ0BH9Y9E6bodNGbEX5Qsh5RIp2NzYVzOp0Y6VFRkpxX-ERBBZvEKjUVxLlETw1ePOLRcasECIwIwpuOdaQ7MRAOOk0RSYPLaaWtXeLHOLqIRDh-BZDLrSfB5PU8pqnKiltsA';
const ClientId = '0oaoz5eqhdFqvidtW1t7';//process.env.ClientId;
const ClientSecret = 'SvpJDdVfxAenLP18wzyohOvgMjyWQMI-rqBDk15w';//process.env.ClientSecret;

const deereInstance =  axios.create({
	baseURL: `${deereInstanceUri}`, 
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Accept': 'application/json',
		Authorization: `Bearer ${accessToken}`,
		ClientId: `${ClientId}`,
		ClientSecret: `${ClientSecret}`
 	},
	credentials: true	
})


// ****************************************************************
// To Send Parameter with baseURL, use following code
// ****************************************************************
// deereInstance.interceptors.request.use((config)=>{
// 	config.params = config.params || {};
// 	config.params['auth'] = accessToken;
// 	return config;
// });

export default  deereInstance