import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import "./Matching.css";
import IVRiversMatchingService from '../../services/IVRiversMatchingService'

import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'

import ReactPaginate from 'react-paginate'
import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import UserService from '../../services/UserService'
import AuthService from '../../services/AuthService'


function MatchingModal({ setOpenModal, title, message, setAction, formValues, formName, setRecordAdded}) {

  // const auth = useAuth();
  // const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);

  // ************************************************************
  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [editPage, setEditPage] = useState(false);
  let [users, setUsers] = useState([]);
  const [formValues1, setFormValues1]=useState({});
  let usersArray = [];

  useEffect(() => {
    const getComments = async () => {
      console.log('message = ', message);
        const data = await IVRiversMatchingService.getRiversMatchingRule(message);
        console.log('data =',data);
        const total = data.length;
        const TotalPages = Math.ceil(total/5);
        setPageCount(TotalPages);
        setItems(data);
    };

    getComments();

  },[]);     
  
  const fetchComments = async(currentPage) => {
          const data = await IVRiversMatchingService.getPagedRiversMatching(currentPage, 5);
          return data;
      };
        

      const handlePageClick = async (data) => {
          let currentPage = data.selected + 1;
          const commentsFromServer = await fetchComments(currentPage);
          setItems(commentsFromServer);
        }
        

  return (

    <div className="modalBackground">
    <ToastContainer />
      <div className="modalContainer">
        <div className="titleCloseBtn">
        </div>
        <div className="title">
          {/* <h5>{ message }</h5> */}
        </div>
        <div className="body">
          <hr/><br/><br/>

          <Container>
              <Row>
                <Col>
                <Table className = "Grid" striped bordered hover variant="dark">
                      <thead>
                            <tr key= { Math.random() }>
                              <th className="tdclass">Field Name</th>
                              <th className="tdclass">Field Value</th>
                            </tr>
                      </thead>
                      <tbody>

                      { 
                        //console.log('Data', users);
                        items.map((item) => {
                          console.log('ITEMS = ',items);
                        return <>
                              <tr key= { Math.random() }>
                                  <td className="tdclass"> { item.fieldName }</td>
                                  <td className="tdclass"> { item.fieldValue }</td>
                              </tr>
                          </>
                        })                  
                      }
                      </tbody>
                      </Table>

                </Col>
              </Row>
        </Container>

        </div>

        <div className="footer">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
            id="cancelBtn"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default MatchingModal;