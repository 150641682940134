import { authUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'


const axios = require('axios');

export class AuthService {
    static  signIn(payload) {
        return axios.post(authUri + '/signIn', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static  signOut(payload) {
        console.log('PayLoad = ', payload);
        return axios.patch(authUri + '/signOut', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static getUser(username){
        return axios.get(authUri + `/Pull?username=${username}`)
                    .then((resp)=>resp.data);
    }

    static getForcedLogoutUser(username){
        return axios.get(authUri + `/ForcedLogout?username=${username}`)
                    .then((resp)=>resp.data);
    }

    static  signOutForce(payload) {
        return axios.patch(authUri + '/ForceSignOut', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

}

export default AuthService