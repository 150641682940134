import React, {useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import AuthService from '../../services/AuthService'
import UserService from '../../services/UserService'
import { ToastContainer } from 'react-toastify';

import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'
import PromotionGrid from './PromotionGrid';
import PromotionBuilder from './PromotionBuilder';
//import Groups from './Groups';

const Promotions = () => {
  const auth = useAuth();  
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);

  useEffect(() => {
    let userLogin = null;
      try
      {
        userLogin = JSON.parse(localStorage.getItem('credentials')).userName;
        AuthService.getForcedLogoutUser(userLogin).then((login)=>{                
            if ((login.loginStatus === 'ForcedLogout') || (login.loginStatus === 'Logout')) {                
                localStorage.removeItem('credentials')
                navigate("/Login");
            }});
        UserService.getUser(userLogin).then((user)=>{
            if (user.status === 'InActive') {                
                localStorage.removeItem('credentials')
                navigate("/Login");
            }});

  
      }
      catch{}
      
      
      if (userLogin === null || userLogin === undefined) {
        setLogin(false);
        navigate('/Login');
      }
      else
      {
        setLogin(true);
      }
    },[]);
  return (
    <>

        {
          JSON.parse(localStorage.getItem('credentials')) && 
          JSON.parse(localStorage.getItem('roles')).role === 'admin' &&

          <>
            <Tabs defaultActiveKey="first">
              <Tab eventKey="first" title="Promotion Summary">
              <div style={{ display: 'block', width: '100%', padding: 0, background: 'white' }}>
                  <PromotionGrid />
              </div>          
              </Tab>

              <Tab eventKey="second" title="Promotion Builder">
              <div style={{ display: 'block', width: '100%', padding: 0, background: 'white' }}>
                  <PromotionBuilder />
              </div>          
              </Tab>

            </Tabs>
            <ToastContainer />
          </>
        }
    </>
  )
}

export default Promotions