import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useForm  } from 'react-hook-form'
import { string } from 'yup';
import { number } from 'yup/lib/locale';
import './forms.css'
import Modal from '../../components/Modal/Modal'
// import { Form, Button } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import ProductService from '../../services/ProductService'

// ************** Pagination Logic *********************************
import ReactPaginate from 'react-paginate'
import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { productUri, pageSize, PortNumber,BaseURL } from '../../constants'
// *******************************************************************


let formValues = {
    id: number,
    pid: string,
    field1: string,
    field2: string,
    field3: string,
    field4: string,
    field5: string,
    field6: string,
    field7: string,
    field8: string,
    field9: string,
    field10: string,
    field11: string,
    field12: string,
}

const ProductsEdit = (props: string[]) => {
    const location = useLocation();
    console.log('location = ', location);
   const [modalOpen, setModalOpen] = useState(false);
   const [modalAction, setAction] = useState(false);

    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState([]);
    const [RecordAdded, setRecordAdded] = useState(0);
      
    const { 
        register, watch, handleSubmit,
        // setValue, resetValue,unregister, 
        // resetField, setError, clearErrors, 
        // setFocus, getValues, trigger, control,
        formState: { 
            errors, 
           // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
        } } 
    = useForm({ defaultValues: { Id: 0, Username: '', Password1: '', RoleId:'', ProfileId: '', Status: 'Inactive' } });

    useEffect(() => {
        const getComments = async () => {
            const res = await fetch(productUri);
            const data = await res.json();
            
            const total = data.length;
            const PageSize = Math.ceil(total/pageSize);
    
            setPageCount(PageSize);
            setItems(data);
            console.log('data = ',data)
            const PageOneFromServer = await fetchComments(1);
            setItems(PageOneFromServer);  
        };
    
        getComments();
    
      },[RecordAdded]);     
      
      const fetchComments = async(currentPage) => {
    
         // console.log('Current Page = ', currentPage, 'Page Size = ', pageSize);
    
        // Page Data returned should be paginated data to display
        const res = await fetch(`${BaseURL}:${PortNumber}/Product/GetPageProduct?PageNumber=${currentPage}&PageSize=${pageSize}`);
              const data = await res.json();
              //setItems(data);
              console.log('Page Data = ',data);
              return data;
          };
            
    
          const handlePageClick = async (data) => {
              let currentPage = data.selected + 1;
              const commentsFromServer = await fetchComments(currentPage);
              setItems(commentsFromServer);
            }
    
    const onSubmit = (data, event) => {
        event.preventDefault();
        formValues = {
            id:0,
            pid: data.pid,
            field1: data.field1,
            field2: data.field2,
            field3: data.field3,
            field4: data.field4,
            field5: data.field5,
            field6: data.field6,
            field7: data.field7,
            field8: data.field8,
            field9: data.field9,
            field10: data.field10,
            field11: data.field11,
            field12: data.field12,
        };        
                
        ProductService.createProduct(formValues).then((product)=>{
            console.log('Product Added =',product);                     
            toast.success('Product Added Successfully',{ position: 'bottom-center'});
            setRecordAdded((previousState)=> previousState - 1);
            clearValuesForm();
        }).catch((e) => {
            console.log('Add Product Error', e.message);
            toast.error('Adding Product Error ',{ position: 'bottom-center'})
          });
    }
    const pullProduct = () => {
        document.getElementById('id').value = props.selectedProduct.id;
        document.getElementById('pid').value = props.selectedProduct.pid;
        document.getElementById('field1').value = props.selectedProduct.field1;
        document.getElementById('field2').value = props.selectedProduct.field2;
        document.getElementById('field3').value = props.selectedProduct.field3;
        document.getElementById('field4').value = props.selectedProduct.field4;
        document.getElementById('field5').value = props.selectedProduct.field5;
        document.getElementById('field6').value = props.selectedProduct.field6;
        document.getElementById('field7').value = props.selectedProduct.field7;
        document.getElementById('field8').value = props.selectedProduct.field8;
        document.getElementById('field9').value = props.selectedProduct.field9;
        document.getElementById('field10').value = props.selectedProduct.field10;
        document.getElementById('field11').value = props.selectedProduct.field11;
        document.getElementById('field12').value = props.selectedProduct.field12;
    }
    const updateProduct = () =>{
        setValuesForm();
         ProductService.editProduct(formValues).then((product)=>{
            product.data ? toast.success('Product Edited Successfully',{ position: 'bottom-center'}):toast.info('Product not exists',{ position: 'bottom-center'});
            setRecordAdded((previousState)=> previousState - 1);
            clearValuesForm();
        })
        .catch((e) => {
            console.log('Edit Product Error', e.message);
            toast.error('Edit Product Error ',{ position: 'bottom-center'})
        });
        }
 
    const deleteProduct = () => {
            setValuesForm();
            ProductService.deleteUser(formValues).then((product)=> {
                product.data ? 
                        toast.success('Product Deleted Successfully',{ position: 'bottom-center'}):
                        toast.info('Product not exists',{ position: 'bottom-center'});
            clearValuesForm();
        }).catch((e) => {
            console.log('Delete Product Error', e.message);
            toast.error('Delete Product Error ',{ position: 'bottom-center'})
        });
    }

    const GetProduct = (selectedProduct) =>{
        ProductService.getProduct(selectedProduct).then((resp) =>{
              document.getElementById('id').value= resp.id;
              document.getElementById('pid').value= resp.pid;
              document.getElementById('field1').value= resp.field1;
              document.getElementById('field2').value= resp.field2;
              document.getElementById('field3').value= resp.field3;
              document.getElementById('field4').value= resp.field4;
              document.getElementById('field5').value= resp.field5;
              document.getElementById('field6').value= resp.field6;
              document.getElementById('field7').value= resp.field7;
              document.getElementById('field8').value= resp.field8;
              document.getElementById('field9').value= resp.field9;
              document.getElementById('field10').value= resp.field10;
              document.getElementById('field11').value= resp.field11;
              document.getElementById('field12').value= resp.field12;
          }).catch((e) => {
              console.log('Error', e.message);
          });    
  }

    const setValuesForm = (()=>{
        formValues = {
            id: document.getElementById('id').value,
            pid: document.getElementById('pid').value,
            field1: document.getElementById('field1').value,
            field2: document.getElementById('field2').value,
            field3: document.getElementById('field3').value,
            field4: document.getElementById('field4').value,
            field5: document.getElementById('field5').value,
            field6: document.getElementById('field6').value,
            field7: document.getElementById('field7').value,
            field8: document.getElementById('field8').value,
            field9: document.getElementById('field9').value,
            field10: document.getElementById('field10').value,
            field11: document.getElementById('field11').value,
            field12: document.getElementById('field12').value
         };
    });

    const clearValuesForm = ()=> 
    {
        document.getElementById('pid').value = '';
        document.getElementById('field1').value = '';
        document.getElementById('field2').value = '';
        document.getElementById('field3').value = '';
        document.getElementById('field4').value = '';
        document.getElementById('field5').value = '';
        document.getElementById('field6').value = '';
        document.getElementById('field7').value = '';
        document.getElementById('field8').value = '';
        document.getElementById('field9').value = '';
        document.getElementById('field10').value = '';
        document.getElementById('field11').value = '';
        document.getElementById('field12').value = '';
    }

  return (
    <>
      <Container>
        <Row>
        <Col  md="10">
                        <div className="heading">
                            <h5 className = "mainheading">Product Additional Fields</h5>
                            <hr/>
                        </div>
                    
                        <form onSubmit={handleSubmit(onSubmit)}>

                        <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>
                        <table>
                        <tr key= { Math.random() }>
                            <td>
                                <input className="input" type="text" {...register("pid", { required: {value: true, message: "This is required one field"}})}  id="pid" placeholder="Product ID"></input>
                                <h2>{errors.ProfileId && <p>{errors.ProfileId.message}</p>}</h2>
                            </td>
                            <td>
                            </td>
                        </tr>

                        <tr key= { Math.random() }>
                            <td>
                                <input className="input" type="text" {...register("field1", { required: {value: true, message: "This is required one field"} })}  id="field1" placeholder="Field 1"></input>
                                <h2>{errors.field1 && <p>{errors.field1.message}</p>}</h2>
                            </td>
                            <td>
                                <input className="input" type="text" {...register("field7", { required: {value: true, message: "This is required one field"} })}  id="field7" placeholder="Field 7"></input>
                                <h2>{errors.field7 && <p>{errors.field7.message}</p>}</h2>
                            </td>
                        </tr>

                        <tr key= { Math.random() }>
                            <td>
                                <input className="input" type="text" {...register("field2", { required: {value: true, message: "This is required one field"} })}  id="field2" placeholder="Field 2"></input>
                                <h2>{errors.field2 && <p>{errors.field2.message}</p>}</h2>
                            </td>
                            <td>
                                <input className="input" type="text" {...register("field8", { required: {value: true, message: "This is required one field"} })}  id="field8" placeholder="Field 8"></input>
                                <h2>{errors.field8 && <p>{errors.field8.message}</p>}</h2>

                            </td>
                        </tr> 



                        <tr key= { Math.random() }>
                            <td>
                                <input className="input" type="text" {...register("field3", { required: {value: true, message: "This is required one field"} })}  id="field3" placeholder="Field 3"></input>
                                <h2>{errors.field3 && <p>{errors.field3.message}</p>}</h2>
                            </td>
                            <td>
                                <input className="input" type="text" {...register("field9", { required: {value: true, message: "This is required one field"} })}  id="field9" placeholder="Field 9"></input>
                                <h2>{errors.field9 && <p>{errors.field9.message}</p>}</h2>

                            </td>
                        </tr> 


                        <tr key= { Math.random() }>
                            <td>
                                <input className="input" type="text" {...register("field4", { required: {value: true, message: "This is required one field"} })}  id="field4" placeholder="Field 4"></input>
                                <h2>{errors.field4 && <p>{errors.field4.message}</p>}</h2>
                            </td>
                            <td>
                                <input className="input" type="text" {...register("field10", { required: {value: true, message: "This is required one field"} })}  id="field10" placeholder="Field 10"></input>
                                <h2>{errors.field10 && <p>{errors.field10.message}</p>}</h2>

                            </td>
                        </tr> 


                        <tr key= { Math.random() }>
                            <td>
                                <input className="input" type="text" {...register("field5", { required: {value: true, message: "This is required one field"} })}  id="field5" placeholder="Field 5"></input>
                                <h2>{errors.field5 && <p>{errors.field5.message}</p>}</h2>
                            </td>
                            <td>
                                <input className="input" type="text" {...register("field11", { required: {value: true, message: "This is required one field"} })}  id="field11" placeholder="Field 11"></input>
                                <h2>{errors.field11 && <p>{errors.field11.message}</p>}</h2>

                            </td>
                        </tr> 


                        <tr key= { Math.random() }>
                            <td>
                                <input className="input" type="text" {...register("field6", { required: {value: true, message: "This is required one field"} })}  id="field6" placeholder="Field 6"></input>
                                <h2>{errors.Field6 && <p>{errors.Field6.message}</p>}</h2>
                            </td>
                            <td>
                                <input className="input" type="text" {...register("field12", { required: {value: true, message: "This is required one field"} })}  id="field12" placeholder="Field 12"></input>
                                <h2>{errors.field12 && <p>{errors.field12.message}</p>}</h2>

                            </td>
                        </tr> 


                        </table>
                        <div className="container">
                            <div className="row">
                                <div className="col md-3 sm-3 xs-2">
                                    <button className="submit" type="submit">Save</button>
                                </div>
                                </div>
                        </div>
                    </form>  
                    <ToastContainer/>                                
             </Col>
             

             <Col md="2">
                 <div className="heading">
                                <h5 className = "operation">Operations</h5>
                            <hr/>
                </div>

             <div className="container">
                         <div className="row">

                                {/* <div className="col md-3 sm-3 xs-2">
                                    <button onClick={ deleteUser } className="button" type="button">Delete</button>
                                </div> */}
                                <div className="col md-3 sm-3 xs-2">
                                    <button className="button"  onClick={() => { setValuesForm(); setModalOpen(true); } }> Delete </button>
                                </div>

                                <div className="col md-3 sm-3 xs-2">
                                    <button 
                                    onClick = { updateProduct } 
                                    className="button" type="button">Update</button>
                                </div>
                                <div className="col md-3 sm-3 xs-2">
                                    <button 
                                    onClick = { pullProduct } 
                                    className="button" type="button">Pull</button>
                                </div>

                                {    
                                    modalOpen && 
                                    <Modal 
                                        setOpenModal={ setModalOpen } 
                                        setAction = { setAction }
                                        formValues = { formValues }
                                        formName = "Product"
                                        title = "Are you sure to delete ?" 
                                        message = "Product will be deleted !" 
                                        setRecordAdded = { setRecordAdded }
                                        />
                                }          
                            </div>
                    </div>
             </Col>
        </Row>

        <Row>
        <Col>
          <hr/>
         <ReactPaginate 
            previousLabel = {'<'} 
            nextLabel = {'>'}
            breakLabel = {'...'}
            pageCount = { Math.ceil(pageCount) }
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}

            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}

            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}

            breakLinkClassName={'page-link'}
            activeClassName={'active'}
        />

          <Table striped bordered hover variant="dark">
          <thead>
                <tr key= { Math.random() }>
                  <th>Product ID</th>
                  <th>Field1</th>
                  <th>Field2</th>
                  <th>Field3</th>
                  <th>Field4</th>
                  <th>Field5</th>
                  <th>Field6</th>
                  <th>Edit</th>
                </tr>
          </thead>
          <tbody>

          { 
            items.map((product) => {
            return <>
                  <tr key= { Math.random() }>
                      <td> { product.pid }</td>
                      <td> { product.field1 }</td>
                      <td> { product.field2 }</td>
                      <td> { product.field3 }</td>
                      <td> { product.field4 }</td>
                      <td> { product.field5 }</td>
                      <td> { product.field5 }</td>
                      <td><button className="selectButton" onClick={(()=> GetProduct(product.pid))}> Select</button></td>
                  </tr>
               </>
            })                  
          }
          </tbody>
          </Table>
        </Col>
        </Row>
      </Container> 


    </>
  )
}

export default ProductsEdit