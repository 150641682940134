import { profilesUri, apiProfilesUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class ProfileService {
    static  getAllProfles() {
               return axiosInstance.get(`/Profile`)
                                    .then((resp)=>resp.data);
    }

    static  getPagedProfles(currentPage, pageSize) {
            return axiosInstance.get(`/Profile/GetPageProfile?PageNumber=${currentPage}&PageSize=${pageSize}`)
                                .then((resp)=>resp.data);
    }
        
    static  getProfile(profileId) {
        return axiosInstance.get(`/Profile/GetProfile?profileId=${profileId}`)
                    .then((resp)=>resp.data);
    }

    static addProfile(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.post(`/Profile`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editProfile(payload) {
        return axiosInstance.patch("/Profile/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteProfile(payload) {
        return axiosInstance.post("/Profile/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default ProfileService