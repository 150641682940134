import React, {  useEffect, useState } from 'react'
import Card from '../forms/ui/Card'
import { Row, Col, Container, Table } from 'react-bootstrap'
import classes from './forms.css'

import { useForm } from 'react-hook-form'
import { string } from 'yup';
import './forms.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import PromotionBuilderService from '../../services/PromotionBuilderService'
import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Modal from '../../components/Modal/Modal'
import { boolean, number } from 'yup/lib/locale';
import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'

// *******************************************************************
import ReactPaginate from 'react-paginate'
//import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { industryUri, pageSize, PromotionIDURI, PageNumber } from '../../constants'
// ********************************************************************
import  MetaData  from '../../services/Data/MetaData'

let formValues = {
  id: number,
  promotionId: string,
  specialOfferTitle: string,
  disclaimer: string,
  promotionType: string,
  amount: number,
  fromDate: Date,
  toDate: Date

}

const PromotionBuilder = () => {
  const auth = useAuth();  
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);
  const [RecordAdded, setRecordAdded] = useState(0);
  // **************************************************************************************************
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setAction] = useState(false);
  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [PromotionIDSet, setPromotionIDSet] = useState([]);
  //  const [promotionIDSett, setPromotionID] = useState([]);
  const [DateRange1, setDateRange1] = useState();
  const [DateRange2, setDateRange2] = useState();
  
  const { 
    register, watch, handleSubmit, 
    // setValue, resetValue,unregister, 
    // resetField, setError, clearErrors, 
    // setFocus, getValues, trigger, control,
    formState: { 
        errors, 
       // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
    } } 
      = useForm({ defaultValues: {  id: 0, promotionId: '', specialOfferTitle: '', disclaimer: '', promotionType: '', amount: 0, dateRange: '' } });

      useEffect(() => {
        const getComments = async () => {
            const data = await PromotionBuilderService.getAllPromotions();  
            const total = data.length;
            const TotalPages = Math.ceil(total/pageSize);
            setPageCount(TotalPages);
            setItems(data);  
            const PageOneFromServer = await fetchComments(1);
            setItems(PageOneFromServer);
            const dataSet = await MetaData.getAllPromotionsId();
            setPromotionIDSet(dataSet);
            console.log('PromotionIDSet = ', PromotionIDSet);

        };
    
        getComments();
    
      },[RecordAdded]);     
      
      const fetchComments = async(currentPage) => {
              const data = await PromotionBuilderService.getPagedPromotions(currentPage, pageSize);  
              return data;
          };
            
    
          const handlePageClick = async (data) => {
              let currentPage = data.selected + 1;
              const commentsFromServer = await fetchComments(currentPage);
              setItems(commentsFromServer);
            }
            
      // **************************************************************************************************
  

    const onSubmit = (data, event) => {
      event.preventDefault();

      var fromdate = new Date(data.fromDate);
      var year = fromdate.toLocaleString("default", { year: "numeric" });
      var month = fromdate.toLocaleString("default", { month: "2-digit" });
      var day = fromdate.toLocaleString("default", { day: "2-digit" });
      // Generate yyyy-mm-dd date string
      var formattedFromDate = year + "-" + month + "-" + day;
      document.getElementById('fromDate').value= formattedFromDate;

      var todate = new Date(data.toDate);
      year = todate.toLocaleString("default", { year: "numeric" });
      month = todate.toLocaleString("default", { month: "2-digit" });
      day = todate.toLocaleString("default", { day: "2-digit" });
      // Generate yyyy-mm-dd date string
      var formattedToDate = year + "-" + month + "-" + day;

      setDateRange1(formattedFromDate);
      setDateRange2(formattedToDate);      

      formValues = {
        id: data.id,
        promotionId: data.promotionId1,
        specialOfferTitle: data.specialOfferTitle1,
        disclaimer: data.disclaimer1,
        promotionType: data.promotionType,
        amount: (data.amount1==="")? 0:data.amount1,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
      };

      PromotionBuilderService.getPromotions(data.promotionId1.trim().replace("&","And").replace("+","And")).then((data)=>{
        if (data.length !== 0) {
               console.log('Data Exist =>> ',data)
               toast.info('Record Exists ! ',{ position: 'bottom-center'}); 
               clearValueForm();       
               return;
        }
        else
        {
          PromotionBuilderService.addPromotions(formValues).then((pro)=>{
            console.log('Form Values = ', formValues);
            console.log('Promotion = ',pro);
            toast.success('Promotion Added Successfully',{ position: 'bottom-center'});
            setRecordAdded((previousState)=> previousState + 1);
            clearValueForm();
            }).catch((e) => {
              console.log('Add Promotion Error', e.message);
              toast.error('Adding Promotion Error ',{ position: 'bottom-center'})
          });
         }
       });
  }

  const updatePromotion = () =>{
    setValueForm();
    console.log('Form Values = ', formValues);
      PromotionBuilderService.editPromotions(formValues).then((attach)=>{
      attach.data ? toast.success('Promotion Edited Successfully',{ position: 'bottom-center'}):toast.info('Promotion does not exists',{ position: 'bottom-center'});
        setRecordAdded((previousState)=> previousState + 1);
        clearValueForm();
    })
    .catch((e) => {
        console.log('Edit Promotion Error', e.message);
        toast.error('Edit Promotion Error ',{ position: 'bottom-center'})
    });
}

  const GetPromotion = (selectedPro) =>{
    PromotionBuilderService.getPromotions(selectedPro).then((resp) =>{
          const selectedLoc =  resp;
          document.getElementById('id').value= resp.id;
          document.getElementById('promotionId1').value= resp.promotionId;
          document.getElementById('specialOfferTitle1').value= resp.specialOfferTitle;
          document.getElementById('disclaimer1').value= resp.disclaimer;
          document.getElementById('promotionType').value= resp.promotionType;
          document.getElementById('amount1').value= resp.amount;
          console.log('From Date = ', resp.fromDate);

          var fromdate = new Date(resp.fromDate);
          var year = fromdate.toLocaleString("default", { year: "numeric" });
          var month = fromdate.toLocaleString("default", { month: "2-digit" });
          var day = fromdate.toLocaleString("default", { day: "2-digit" });
          // Generate yyyy-mm-dd date string
          var formattedFromDate = year + "-" + month + "-" + day;
          document.getElementById('fromDate').value= formattedFromDate;

          var todate = new Date(resp.toDate);
          year = todate.toLocaleString("default", { year: "numeric" });
          month = todate.toLocaleString("default", { month: "2-digit" });
          day = todate.toLocaleString("default", { day: "2-digit" });
          // Generate yyyy-mm-dd date string
          var formattedToDate = year + "-" + month + "-" + day;

          document.getElementById('toDate').value= formattedToDate;
          console.log(selectedLoc);
         // console.log('Data is set for Promotion ID', promotionIDSett);

      }).catch((e) => {
          console.log('Error', e.message);
      });    
}

const setValueForm = (() => { 
  var fromdate = new Date(document.getElementById('fromDate').value);
  var year = fromdate.toLocaleString("default", { year: "numeric" });
  var month = fromdate.toLocaleString("default", { month: "2-digit" });
  var day = fromdate.toLocaleString("default", { day: "2-digit" });
  // Generate yyyy-mm-dd date string
  var formattedFromDate = year + "-" + month + "-" + day;

  var todate = new Date(document.getElementById('toDate').value);
  year = todate.toLocaleString("default", { year: "numeric" });
  month = todate.toLocaleString("default", { month: "2-digit" });
  day = todate.toLocaleString("default", { day: "2-digit" });
  // Generate yyyy-mm-dd date string
  var formattedToDate = year + "-" + month + "-" + day;
  console.log('To Dates ===> ', formattedToDate);
  console.log('From Dates ===> ', formattedFromDate);
  formValues = {
      id: document.getElementById('id').value,
      promotionId: document.getElementById('promotionId1').value,
      specialOfferTitle: document.getElementById('specialOfferTitle1').value,
      disclaimer: document.getElementById('disclaimer1').value,
      promotionType: document.getElementById('promotionType').value,
      amount: document.getElementById('amount1').value,
      fromDate: formattedFromDate,
      toDate: formattedToDate
   };
});

const clearValueForm = (() => {
  formValues = {
      id: '',
      promotionId: '',
      specialOfferTitle: '',
      disclaimer: '',
      promotionType: '',
      amount: '',
      fromDate: '',
      toDate: ''
   };

   document.getElementById('id').value= '';
   //document.getElementById('promotionId1').value= '';
   document.getElementById('specialOfferTitle1').value= '';
   document.getElementById('disclaimer1').value= '';
   document.getElementById('promotionType').value= '';
   document.getElementById('amount1').value= '';
   document.getElementById('fromDate').value= '';
   document.getElementById('toDate').value= '';
});

const onChangeComboBox = async (e)=>{
  PromotionBuilderService.getPromotions(e.target.value).then((resp) =>{
    const selectedLoc =  resp;
    if (resp.id !== undefined) {
      document.getElementById('id').value= resp.id;
      document.getElementById('specialOfferTitle1').value= resp.specialOfferTitle;
      document.getElementById('disclaimer1').value= resp.disclaimer;
      document.getElementById('promotionType').value= resp.promotionType;
      document.getElementById('amount1').value= resp.amount;
      console.log('From Date = ', resp.fromDate);
  
      var fromdate = new Date(resp.fromDate);
      var year = fromdate.toLocaleString("default", { year: "numeric" });
      var month = fromdate.toLocaleString("default", { month: "2-digit" });
      var day = fromdate.toLocaleString("default", { day: "2-digit" });
      // Generate yyyy-mm-dd date string
      var formattedFromDate = year + "-" + month + "-" + day;
      document.getElementById('fromDate').value= formattedFromDate;
  
      var todate = new Date(resp.toDate);
      var year = todate.toLocaleString("default", { year: "numeric" });
      var month = todate.toLocaleString("default", { month: "2-digit" });
      var day = todate.toLocaleString("default", { day: "2-digit" });
      // Generate yyyy-mm-dd date string
      var formattedToDate = year + "-" + month + "-" + day;
  
      document.getElementById('toDate').value= formattedToDate;
      console.log(selectedLoc);
    }
    else
    {

      document.getElementById('id').value= 0;
      document.getElementById('specialOfferTitle1').value= '';
      document.getElementById('disclaimer1').value= '';
      document.getElementById('promotionType').value= '';
      document.getElementById('amount1').value= 0;
      document.getElementById('fromDate').value= '';   
      document.getElementById('toDate').value= '';
      console.log(selectedLoc);

    }

   // console.log('Data is set for Promotion ID', promotionIDSett);

}).catch((e) => {
    console.log('Error', e.message);
});    
}

const DEF_DELAY = 1000;

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms || DEF_DELAY));
}

const onChangeFieldName = async (e)=>{
  e.preventDefault();
  const selected = e.target.value	
  if (selected === "FREE"){
    document.getElementById('amount1').style.visibility = "hidden";
  }
  else 
  { 
    document.getElementById("amount1").style.visibility=  "visible";
  }
}

return (
    <>
      <Container>
          <Row>
                <Col md="3">
                        <div className="heading">
                            <h5 className="mainheading">Promotion Builder</h5>
                            <hr/>
                        </div>              

        
                          <form onSubmit={handleSubmit(onSubmit)}>

                          <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>

                          <select 
                                  className="select" 
                                  {...register("promotionId1", 
                                        { required: {value: true, message: "This is required field"} })} 
                                    id="promotionId1"
                                    onChange={(e)=> {
                                    onChangeComboBox(e);
                            			}}  
                                  >
                              {
                                  PromotionIDSet.map((d)=>(
                          			    <option  key={Math.random()}  value={d.promotionId}>{d.promotionId}</option>
                                  ))
                              }
                              </select>
                              <h2>{errors.promotionId1 && <p>{errors.promotionId1.message}</p>}</h2>



                          <input className="input" type="text" {...register("specialOfferTitle1")}  id="specialOfferTitle1" placeholder="Special Offer Title"></input>
                          <h2>{errors.specialOfferTitle1 && <p>{errors.specialOfferTitle1.message}</p>}</h2>

                          <input className="input" type="text" {...register("disclaimer1")}  id="disclaimer1" placeholder="Disclaimer"></input>
                          <h2>{errors.disclaimer1 && <p>{errors.disclaimer1.message}</p>}</h2>

                          {/* <input className="input" type="text" {...register("promotionType", { required: {value: true, message: "This is required field"} })}  id="promotionType" placeholder="Promotion Type"></input> */}

                          <select 
                              className="select" 
                              {...register("promotionType")}  
                                  id="promotionType"
                                  onChange={(e)=> { onChangeFieldName(e);}
                              }>
                                  <option  key={Math.random()}   value="OFF">% OFF</option>                                           
                                  <option  key={Math.random()}   value="FREE">Free Attachment</option>                                           
                          </select>

                          <h2>{errors.promotionType && <p>{errors.promotionType.message}</p>}</h2>
                          { 
                            <input className="input" type="text" {...register("amount1")}  id="amount1" placeholder="Amount"></input>
                          }
                          <h2>{errors.amount1 && <p>{errors.amount1.message}</p>}</h2>

                          <input className="input" type="date"  {...register("fromDate")}  id="fromDate" placeholder="From Date"></input>
                          <h2>{errors.fromDate && <p>{errors.fromDate.message}</p>}</h2>

                          <input className="input" type="date" {...register("toDate")}  id="toDate" placeholder="To Date"></input>
                          <h2>{errors.toDate && <p>{errors.toDate.message}</p>}</h2>


                          <hr/>
                          <div className="container">
                              <div className="row">
                                  <div className="col md-3 sm-3 xs-2">
                                      <button className="submit" type="submit">Save</button>
                                  </div>
                              </div>
                          </div>
                      </form>  
                </Col>

                <Col md = "2">
            <div className="heading">
            <h5 className="operation">Operations</h5>
            <hr/>
            </div>              

            <div className="container">
                <div className="row">
                    <div className="col md-3 sm-3 xs-2">
                        <button onClick={ updatePromotion } className="button" type="button">Update</button>
                    </div>

                    {/* <div className="col md-3 sm-3 xs-2">
                        <button onClick={ deleteProfile } className="button" type="button">Delete</button>
                    </div> */}

                    <div className="col md-3 sm-3 xs-2">
                        <button className="button"  onClick={() => { setValueForm(); setModalOpen(true); } }> Delete </button>
                    </div>

                    {    
                        modalOpen && 
                        <Modal 
                            setOpenModal={ setModalOpen } 
                            setAction = { setAction }
                            formValues = { formValues }
                            formName = "Promotion Builder"
                            title = "Are you sure to delete ?" 
                            message = "Promotion Builder will be deleted !" 
                            setRecordAdded = { setRecordAdded }
                            />
                    }            
                </div>
            </div>
            </Col>

          <Col md="7">
          <ReactPaginate 
            previousLabel = {'<'} 
            nextLabel = {'>'}
            breakLabel = {'...'}
            pageCount = { Math.ceil(pageCount) }
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}

            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}

            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}

            breakLinkClassName={'page-link'}
            activeClassName={'active'}
        />
          <Table striped bordered hover variant="dark">
          <thead>
                <tr key= { Math.random() }>
                  <th>Promotion Id</th>
                  <th>Offer Title</th>
                  <th>Disclaimer</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Edit</th>
                </tr>
          </thead>
          <tbody>

          { 
            items.map((pro) => {
            return <>
                  <tr key= { Math.random() }>
                      <td> { pro.promotionId }</td>
                      <td> { pro.specialOfferTitle }</td>
                      <td> { pro.disclaimer }</td>
                      <td> { pro.promotionType }</td>
                      <td> { pro.amount }</td>
                      <td> { pro.fromDate }</td>
                      <td><button className="selectButton" onClick={(()=> GetPromotion(pro.promotionId))}> Select</button></td>
                  </tr>
               </>
            })                  
          }
          </tbody>
          </Table>
        </Col>                


          </Row>
      </Container>
    </>
  )
}

export default PromotionBuilder