import React, { useState, useEffect } from 'react'
import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'

import ReactPaginate from 'react-paginate'
import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import UserService from '../../services/UserService'
import AuthService from '../../services/AuthService'

import { productUri, appUri, pageSize } from '../../constants'
import ProductsEdit from './ProductsEdit'
import ProductService from '../../services/ProductService'
import { number, string } from 'yup'
import KPI from './KPI'

const ProductSummary = () => {

    const auth = useAuth();
    const navigate = useNavigate();
    const [isLogin, setLogin] = useState(false);
  
    // ************************************************************
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState([]);
    const [editPage, setEditPage] = useState(false);
    let [users, setUsers] = useState([]);
    const [formValues1, setFormValues1]=useState({});
    let usersArray = [];
    const formValues = {
      id: number,
      produdctCode: string,
      make: string,
      model: string,
      rule : string,
      alert : string,
      published: string
  }
  
    useEffect(() => {
      const getComments = async () => {
          const data = await ProductService.getAllProducts();
          const total = data.length;
          const TotalPages = Math.ceil(total/pageSize);
          setPageCount(TotalPages);
          setItems(data);

      };
  
      getComments();
  
    },[]);     
    
    const fetchComments = async(currentPage) => {
            const data = await ProductService.getPagedProduct(currentPage, pageSize);
            return data;
        };
          
  
        const handlePageClick = async (data) => {
            let currentPage = data.selected + 1;
            const commentsFromServer = await fetchComments(currentPage);
            setItems(commentsFromServer);
          }
          
      
    // useEffect(()=>{
    //   const getUsers = async () => {
    //   UserService.getAllUsers().then((user)=>{
    //     console.log('Users Returned =',user);
    //     // setUsers(user);
    //     setUsers({...users, u:user });
        
        
    //     usersArray = [...user];    
    //     console.log('usersArray Returned =',usersArray);
    //     usersArray.map((user) => {
    //       console.log('Data', user.username);
    //     }); 
  
           
    //   }).catch((e) => {
    //     console.log('Error', e.message);
    //   });
    // };
    //   getUsers();
    // },[]);
  
  
  
    // *************************************************************
    // useEffect(() => {
    //   let userLogin = null;
    //     try
    //     {
    //       userLogin = JSON.parse(localStorage.getItem('credentials')).userName;
    //           AuthService.getForcedLogoutUser(userLogin).then((login)=>{
    //               if ((login.loginStatus === 'ForcedLogout') || (login.loginStatus === 'Logout')) {                
    //                   localStorage.removeItem('credentials')
    //                   navigate("/Login");
    //               }});
    //           UserService.getUser(userLogin).then((user)=>{
    //             if (user.status === 'InActive') {                
    //                 localStorage.removeItem('credentials')
    //                 navigate("/Login");
    //             }});
  
    //     }
    //     catch{}
        
        
    //     if (userLogin === null || userLogin === undefined) {
    //       setLogin(false);
    //       navigate('/Login');
    //     }
    //     else
    //     {
    //       setLogin(true);
    //     }
    //   },[]);
  
      const GetProduct = (itemId) => {
          // Call Navigate To ProductsEdit Form To Add/Edit Values
          // If Already data exists, pull them to the form, and allow user to edit those new fields
         console.log('ItemId = ', itemId); 
         ProductService.getProduct(itemId).then((data)=>{
            formValues.id = data.id;
            formValues.productCode = data.productCode;
            formValues.make = data.make;
            formValues.model = data.model;
            formValues.rule = data.rule;
            formValues.alert = data.alert;
            formValues.published = data.published;
         });
            
         setFormValues1(formValues);  
         console.log('Form Values = ', formValues);         
         // This data should go into dialog box for editing purpose
         // Transfer these values to dialog box and returns back the changes & save

         // use ngb-bootstrap dialog 

      }
  
  return (
    <>
        <hr/>
        <h3>Products Summary</h3>
        <hr/>
        <KPI />
        <Container>
              <Row>
                <Col>
                <Table className = "Grid" striped bordered hover variant="dark">
                      <thead>
                            <tr key= { Math.random() }>
                              <th>Product Code</th>
                              <th>Make</th>
                              <th>Model</th>
                              <th>Rule</th>
                              <th>ALert</th>
                              <th>Published</th>
                              <th>Edit</th>
                            </tr>
                      </thead>
                      <tbody>

                      { 
                        //console.log('Data', users);
                        items.map((item) => {
                          console.log('ITEMS = ',items);
                        return <>
                              <tr key= { Math.random() }>
                                  <td> { item.productCode }</td>
                                  <td> { item.make }</td>
                                  <td> { item.model }</td>
                                  <td> { item.rule }</td>
                                  <td> { item.alert }</td>
                                  <td> { item.published }</td>                                  
                                  <td><button className="selectButton" onClick={(()=> GetProduct(item.productCode))}> Edit</button></td>
                              </tr>
                          </>
                        })                  
                      }
                      </tbody>
                      </Table>

                </Col>
              </Row>
        </Container>
        <hr/>
    </>
  )
}

export default ProductSummary