import { machineSubTypeUri, apiMachineSubTypeUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class MachineSubTypeService {
    static  getAllMachineSubTypes() {
        return axiosInstance.get(`/MachineSubType`)
                    .then((resp)=>resp.data);
    }

    static  getPagedMachineSubType(currentPage, pageSize) {
        return axiosInstance.get(`/MachineSubType/GetPageMachineSubType?PageNumber=${currentPage}&PageSize=${pageSize}`)
                            .then((resp)=>resp.data);
    }

    static  getMachineSubType(machineSubTypeId) {
        return axiosInstance.get(`/MachineSubType/GetMachineSubType?subType=${machineSubTypeId}`)
                    .then((resp)=>resp.data);
    }

    static  getMachineType(MachineType) {
        return axiosInstance.get(`/MachineSubType/GetMachineType?MachineType=${MachineType}`)
                    .then((resp)=>resp.data);
    }

    static addMachineSubType(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.post(`/MachineSubType`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editMachineSubType(payload) {
        return axiosInstance.patch("/MachineSubType/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteMachineSubType(payload) {
        return axiosInstance.post("/MachineSubType/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default MachineSubTypeService