import React, {  useEffect, useState } from 'react'
//import Card from '../forms/ui/Card'
import Card from '../../forms/ui/Card'
import { Row, Col, Container, Table } from 'react-bootstrap'
import classes from '.././forms.css'


import { useForm } from 'react-hook-form'
import { string } from 'yup';
import './../forms.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import ProfileService from '../../../services/ProfileService'
import UserService  from '../../../services/UserService'
import AuthService from '../../../services/AuthService'
import Modal from '../../../components/Modal/Modal'
import { number } from 'yup/lib/locale';
import { useAuth } from '../../../utilities/auth'
import { useNavigate } from 'react-router-dom'


let formValues = {
  id: number,
	productCode: string,
	industry: string,
	machineType: string,
	subMachineType: string,
	make: string,
	modelName: string,
	packageDesc: string,
	inventoryMatching: string,
	riversEMSMatching: string,
	regularPrice: number,
	riversPrice: number,
	validDate: Date,
	advertiseDesc: string,
	disclaimer: string,
	media: string,
	promotionsAvailable: string
}

const BaseMachine = () => {
  const auth = useAuth();  
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);
  const [RecordAdded, setRecordAdded] = useState(0);

  // **************************************************************************************************
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setAction] = useState(false);
  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState([]);

  const { 
    register, watch, handleSubmit, 
    // setValue, resetValue,unregister, 
    // resetField, setError, clearErrors, 
    // setFocus, getValues, trigger, control,
    formState: { 
        errors, 
       // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
    } } 
    = useForm({ defaultValues: { id: 0, productCode: '', industry: '', machineType: '', subMachineType: '', make: '', modelName: '', packageDesc: '', inventoryMatching: '', riversEMSMatching: '', regularPrice: 0, riversPrice: 0, validDate: '1900-01-01', advertiseDesc: '', disclaimer: '', media: '', promotionsAvailable: '' } });

    const onSubmit = (data, event) => {
      event.preventDefault();
      formValues = {
        id: data.id,
        productCode: data.productCode,
        industry: data.industry,
        machineType: data.machineType,
        subMachineType: data.subMachineType,
        make: data.make,
        modelName: data.modelName,
        packageDesc: data.packageDesc,
        inventoryMatching: data.inventoryMatching,
        riversEMSMatching: data.riversEMSMatching,
        regularPrice: data.regularPrice,
        riversPrice: data.riversPrice,
        validDate: data.validDate,
        advertiseDesc: data.advertiseDesc,
        disclaimer: data.disclaimer,
        media: data.media,
        promotionsAvailable: data.promotionsAvailable
      };
      // ProfileService.addProfile(formValues).then((profile)=>{
      //     console.log('Form Values = ', formValues);
      //     console.log('Profile = ',profile);
      //     toast.success('Profile Added Successfully',{ position: 'bottom-center'});
      //     setRecordAdded((previousState)=> previousState + 1);
      //     clearValueForm();
      // }).catch((e) => {
      //     console.log('Add Profile Error', e.message);
      //     toast.error('Adding Profile Error ',{ position: 'bottom-center'})
      // });
  }

  return (
    <>
      <Container>
          <Row>
                <Col md="12">

                    <Card>
                        <div className="heading">
                            <h5 className="mainheading">NEW UNIT - BASE MACHINE 1</h5>
                            <hr/>
                        </div>              

        
                          <form onSubmit={handleSubmit(onSubmit)}>

                          <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>

                          <input className="input" type="text" {...register("productCode", { required: {value: true, message: "This is required field"}})}  id="productCode" placeholder="Product Code"></input>
                          <h2>{errors.productCode && <p>{errors.productCode.message}</p>}</h2>


                          <input className="input" type="text" {...register("industry", { required: {value: true, message: "This is required field"}})}  id="industry" placeholder="Industry"></input>
                          <h2>{errors.industry && <p>{errors.industry.message}</p>}</h2>

                          <input className="input" type="text" {...register("machineType", { required: {value: true, message: "This is required field"}})}  id="machineType" placeholder="Machine Type"></input>
                          <h2>{errors.machineType && <p>{errors.machineType.message}</p>}</h2>

                          <input className="input" type="text" {...register("subMachineType", { required: {value: true, message: "This is required field"}})}  id="subMachineType" placeholder="Sub Machine Type"></input>
                          <h2>{errors.subMachineType && <p>{errors.subMachineType.message}</p>}</h2>


                          <input className="input" type="text" {...register("make", { required: {value: true, message: "This is required field"}})}  id="make" placeholder="Make"></input>
                          <h2>{errors.make && <p>{errors.make.message}</p>}</h2>

                          <input className="input" type="text" {...register("modelName", { required: {value: true, message: "This is required field"}})}  id="modelName" placeholder="Model Name"></input>
                          <h2>{errors.modelName && <p>{errors.modelName.message}</p>}</h2>

                          <input className="input" type="text" {...register("packageDesc", { required: {value: true, message: "This is required field"}})}  id="packageDesc" placeholder="Package Desc"></input>
                          <h2>{errors.packageDesc && <p>{errors.packageDesc.message}</p>}</h2>


                          <input className="input" type="text" {...register("inventoryMatching", { required: {value: true, message: "This is required field"}})}  id="inventoryMatching" placeholder="Inventory Matching"></input>
                          <h2>{errors.inventoryMatching && <p>{errors.inventoryMatching.message}</p>}</h2>

                          <input className="input" type="text" {...register("riversEMSMatching", { required: {value: true, message: "This is required field"}})}  id="riversEMSMatching" placeholder="Rivers EMS Matching"></input>
                          <h2>{errors.riversEMSMatching && <p>{errors.riversEMSMatching.message}</p>}</h2>

                          <input className="input" type="text" {...register("regularPrice", { required: {value: true, message: "This is required field"}})}  id="regularPrice" placeholder="Regular Price"></input>
                          <h2>{errors.regularPrice && <p>{errors.regularPrice.message}</p>}</h2>



                          <input className="input" type="text" {...register("riversPrice", { required: {value: true, message: "This is required field"}})}  id="riversPrice" placeholder="Rivers Price"></input>
                          <h2>{errors.riversPrice && <p>{errors.riversPrice.message}</p>}</h2>

                          <input className="input" type="text" {...register("validDate", { required: {value: true, message: "This is required field"}})}  id="validDate" placeholder="Valid Date"></input>
                          <h2>{errors.validDate && <p>{errors.validDate.message}</p>}</h2>

                          <input className="input" type="text" {...register("advertiseDesc", { required: {value: true, message: "This is required field"}})}  id="advertiseDesc" placeholder="Advertise Desc"></input>
                          <h2>{errors.advertiseDesc && <p>{errors.advertiseDesc.message}</p>}</h2>

                          <input className="input" type="text" {...register("disclaimer", { required: {value: true, message: "This is required field"} })}  id="disclaimer" placeholder="Disclaimer"></input>
                          <h2>{errors.disclaimer && <p>{errors.disclaimer.message}</p>}</h2>

                          <input className="input" type="text" {...register("media", { required: {value: true, message: "This is required field"} })}  id="media" placeholder="Media"></input>
                          <h2>{errors.media && <p>{errors.media.message}</p>}</h2>

                          <input className="input" type="text" {...register("promotionsAvailable", { required: {value: true, message: "This is required field"}})}  id="promotionsAvailable" placeholder="Promotions Available"></input>
                          <h2>{errors.promotionsAvailable && <p>{errors.promotionsAvailable.message}</p>}</h2>


                          <hr/>
                          <div className="container">
                              <div className="row">
                                  <div className="col md-3 sm-3 xs-2">
                                      <button className="submit" type="submit">Save</button>
                                  </div>
                              </div>
                          </div>
                      </form>  
                    </Card>
                </Col>
          </Row>
      </Container>
    </>
  )
}

export default BaseMachine