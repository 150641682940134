import axiosInstance from './AxiosInstance'

export class ProductAttachmentMasterService {
    static  getAllAttachmentMaster() {
        return axiosInstance.get(`/ProductAttachmentMaster`)
                    .then((resp)=>resp.data);
    }

    static  getPagedAttachmentMaster(currentPage, pageSize) {
        return axiosInstance.get(`/ProductAttachmentMaster/GetPageAttachmentMaster?PageNumber=${currentPage}&PageSize=${pageSize}`)
                    .then((resp)=>resp.data);
    }

    static  getProductAttachmentMaster(ProductCode) {
        return axiosInstance.get(`ProductAttachmentMaster/GetAttachmentId?ProductCode=${ProductCode}`)
                    .then((resp)=>resp.data);
    }

    static addProductAttachmentMaster(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.post(`/ProductAttachmentMaster`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editProductAttachmentMaster(payload) {
        return axiosInstance.patch("ProductAttachmentMaster/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteProductAttachmentMaster(payload) {
        return axiosInstance.post("ProductAttachmentMaster/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default ProductAttachmentMasterService