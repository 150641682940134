
import { settingsUri, PortNumber,BaseURL } from '../constants'
import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class SettingsService {
    static  getSettings() {
        return axiosInstance.get(`/Settings`).then((resp)=>resp.data);
    }

    static setSettings(payload) {
        return axiosInstance.patch(`${BaseURL}:${PortNumber}/Settings/Update`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
                }
            });
    }
}

export default SettingsService