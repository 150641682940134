import React, {  useEffect, useState, useRef } from 'react'
import Card from '../forms/ui/Card'
import { Row, Col, Container, Table } from 'react-bootstrap'
import classes from './forms.css'
import { useForm } from 'react-hook-form'
import { string } from 'yup';
import './forms.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import ProductBaseMachineService from '../../services/ProductBaseMachineService'
import DynamicFieldsService from '../../services/DynamicFieldsService';

import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'
import MediaService from '../../services/MediaService'

import Modal  from '../../components/Modal/Modal'

import MatchingModal from '../../components/Modal/MatchingModal'
import MatchingDeereModal from '../Modal/MatchingDeereModal'
import AdditionalModal from '../../components/Modal/AdditionalModal'

import { number } from 'yup/lib/locale';
import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'

// *******************************************************************
import ReactPaginate from 'react-paginate'
import 'bootstrap/dist/css/bootstrap.min.css'
import { profilesUri, pageSize, IndustryURI, MachineSubTypeURI, MakeURI, ProductCodeURI, ModalURI } from '../../constants'
import ProductBaseMachineUpdate from './ProductBaseMachineUpdate'
import MetaData from '../../services/Data/MetaData'
// ********************************************************************
import { IVRiversMatchingService } from '../../services/IVRiversMatchingService'
import { IVDeereMatchingService } from '../../services/IVDeereMatchingService'
import { AdditionalFieldsService } from '../../services/AdditionalFieldsService'

import { MachineTypeService } from '../../services/MachineTypeService'
import { MachineSubTypeService } from '../../services/MachineSubTypeService'

let formValues = {
  id: number,
	productCode: string,
	industry: string,
	machineType: string,
	subMachineType: string,
	make: string,
	modelName: string,
	packageDesc: string,
	inventoryMatching: string,
	riversEMSMatching: string,
	regularPrice: number,
	riversPrice: number,
	validDate: Date,
	validToDate: Date,
	advertiseDesc: string,
	disclaimer: string,
	media: string,
	promotionsAvailable: string,
  status: string,
  slug: string,	
  seoTitle: string,	
  seoDescription: string,	
  seoKeywords: string
}

const ProductBaseMachine = () => {
  const auth = useAuth();  
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);
  const [RecordAdded, setRecordAdded] = useState(0);
  const [formStep, setFormStep] = useState(0);
  const [machineEdit, setMachineEdit] = useState(0);  
  const [PromotionIDSet, setPromotionIDSet] = useState([]);
  const [AdditionalFields,setAdditionalFields]= useState([]);
  const [RuleStatus, setRuleStatus] = useState();
  const [MediaUri, setMediaUri] = useState();
   // **************************************************************************************************
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setAction] = useState(false);
  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState([]);

  const [MachineTypeSet, setMachineTypeSet] = useState([]);
  const [IndustrySet, setIndustrySet] = useState([]);
  const [MachineSubTypeSet, setMachineSubTypeSet] = useState([]);
  const [MakeSet, setMakeSet] = useState([]);
  const [FieldValue, setFieldValue]=useState();
  const [ShowRiversMatch, setShowRiversMatch] = useState(false);
  const [ShowDeereMatch, setShowDeereMatch] = useState(false);
  const [ShowAdditional, setShowAdditional] = useState(false);
  
  const [ems, setEMS] = useState([]);
  const [deere, setDeere] = useState([]);
  const [addon, setAddon] = useState([]);
  const [productmedia, setProductMedia] = useState([]);

  const [RuleCodeEdit, setRuleCode] = useState();
  const [MachineType, setMachineType] = useState();
  const [IndustryName, setIndustryName]= useState();
  const [Transition, setTransition] = useState(0);
  const thisIndustryName = useRef();
  const thisMachingType = useRef();

  // **************************************************************************************************
      useEffect(()=>{
            MachineTypeService.getMachineTypeIndustry(IndustryName).then((data)=>{
              setMachineTypeSet(data);
            })
            setIndustryName(IndustryName);
      },[IndustryName])

      useEffect(()=>{        
         MachineSubTypeService.getMachineType(MachineType).then((data)=>{
          setMachineSubTypeSet(data);
        })
  },[MachineType])

      useEffect(() => {
        const getComments = async () => {
            const data = await ProductBaseMachineService.getAllProductBaseMachines();
            const total = data.length;
            const TotalPages = Math.ceil(total/pageSize);
            setPageCount(TotalPages);
            setItems(data);
            const PageOneFromServer = await fetchComments(1);
            setItems(PageOneFromServer);

            // Loading ComboBox Data
            const dataSet1 = await MetaData.getAllMachineType();
            setMachineTypeSet(dataSet1);

            const dataSet2 = await MetaData.getAllIndustry();
            setIndustrySet(dataSet2);

            const dataSet3 = await MetaData.getAllMachineSubType();
            setMachineSubTypeSet(dataSet3);

            const dataSet4 = await MetaData.getAllMake();
            setMakeSet(dataSet4);

            const dataSet = await MetaData.getAllPromotionsId();
            setPromotionIDSet(dataSet);

            const dataSetAdd = await MetaData.getAdditionalFields();
            setAdditionalFields(dataSetAdd);

            // console.log('MachineTypeSet = ', MachineTypeSet);
            // console.log('IndustrySet = ', IndustrySet);
            // console.log('MachineSubTypeSet = ', MachineSubTypeSet);
            // console.log('MakeSet = ', MakeSet);

        };
    
        getComments();
    
      }, [RecordAdded]);

      useEffect(() => {
        const getData = async () => {
        await IVRiversMatchingService.getRiversMatchingRule(RuleCodeEdit).then(data=>{
          setEMS(data);
        }).catch(error=>{
          console.log('Error ===>>>> ', error);
        });

        await IVDeereMatchingService.getDeereMatchingRule(RuleCodeEdit).then(data=>{
          setDeere(data);
        }).catch(error=>{
          console.log('Error ===>>>> ', error);
        });

        await AdditionalFieldsService.getAdditionalFieldsRule(RuleCodeEdit).then(data=>{
          setAddon(data);
        }).catch(error=>{
          console.log('Error ===>>>> ', error);
        });
      }
  
      getData();

    },[RuleCodeEdit]);

    const fetchComments = async(currentPage) => {
              const data = await ProductBaseMachineService.getPagedProductBaseMachine(currentPage, pageSize);
              return data;
          };
            
    
          const handlePageClick = async (data) => {
              let currentPage = data.selected + 1;
              const commentsFromServer = await fetchComments(currentPage);
              setItems(commentsFromServer);
            }
            
      // **************************************************************************************************
  
  const { 
    register, watch, handleSubmit, 
    // setValue, resetValue,unregister, 
    // resetField, setError, clearErrors, 
    // setFocus, getValues, trigger, control,
    formState: { 
        errors, isValid
       // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating,  submitCount 
    } } 
    = useForm({ 
      mode: "all", 
      defaultValues: { id: 0, productCode: '', modelName: '', packageDesc: '', inventoryMatching: '', riversEMSMatching: '', regularPrice: 0, riversPrice: 0, validDate: '2022-01-01',validToDate: '2022-01-01', advertiseDesc: '', disclaimer: '',  promotionsAvailable: '' } 
        // industry: 'Select Industry', machineType: 'Select Machine Type', subMachineType: 'Select Sub Machine Type', make: 'Select Make'
    });

    useEffect(() => {
      const subscription = watch((data)=>{
          console.log(data);
      })
      return ()=> {
          subscription.unsubscribe();
      }
  }, [watch]) 

  const GetBaseMachine = async (selectedMachine) =>{
    // ProductBaseMachineService.getAllProductBaseMachines().then((data)=>{
    //     console.log('axios Instance Data = ', data);
    // })
    let ID = 0;
    ProductBaseMachineService.getProductBaseMachine(selectedMachine).then(async (resp) =>{
      MediaService.getMedia(resp.id).then(data=>{
        setProductMedia(data);
        console.log('Id Data Returned ', data);
      })

      let med = '';
      MediaService.getMedia(resp.id).then(data=>{              
        data.map(da=>{
          med = med + da.mediaType + ":" + da.mediaURL + ","
        })
        med = med.trim().substring(0,med.length-1);
      })
        setRuleStatus(resp.status);
          setRuleCode(resp.productCode);     
          await sleep(2500)
          console.log('selected Machine = ',selectedMachine);
          console.log('resp = ',resp);          
          document.getElementById('id').value= resp.id;
          ID =  resp.id;
          document.getElementById('productCode').value= resp.productCode;
          document.getElementById('industry').value = resp.industry;
          document.getElementById('machineType').value= resp.machineType;
          document.getElementById('subMachineType').value= resp.subMachineType;
          document.getElementById('make').value= resp.make;
          document.getElementById('status').value= resp.status;
          // document.getElementById('vendorName').value= resp.vendorName;
          document.getElementById('modelName').value= resp.modelName;
          document.getElementById('packageDesc').value= resp.packageDesc;
         // document.getElementById('inventoryMatching').value= resp.inventoryMatching;
         // document.getElementById('riversEMSMatching').value= resp.riversEMSMatching;
          document.getElementById('regularPrice').value= resp.regularPrice;
          document.getElementById('riversPrice').value= resp.riversPrice;

          var validDate1 = new Date(resp.validDate.toString());
          var validDate2 = new Date(resp.validToDate.toString());

          var year = validDate1.toLocaleString("default", { year: "numeric" });
          var month = validDate1.toLocaleString("default", { month: "2-digit" });
          var day = validDate1.toLocaleString("default", { day: "2-digit" });
          // Generate yyyy-mm-dd date string
          var ValidDate1 = year + "-" + month + "-" + day;
          year = validDate2.toLocaleString("default", { year: "numeric" });
          month = validDate2.toLocaleString("default", { month: "2-digit" });
          day = validDate2.toLocaleString("default", { day: "2-digit" });
          var ValidDate2 = year + "-" + month + "-" + day;

          document.getElementById('validDate').value= ValidDate1;
          document.getElementById('validToDate').value= ValidDate2;

          document.getElementById('advertiseDesc').value= resp.advertiseDesc;
          document.getElementById('disclaimer').value= resp.disclaimer;
         // document.getElementById('media').value= resp.media;
          document.getElementById('promotionsAvailable').value= resp.promotionsAvailable;

          document.getElementById('slug').value= resp.slug;
          document.getElementById('seotitle').value= resp.seoTitle;
          document.getElementById('seodescription').value= resp.seoDescription;
          document.getElementById('seokeywords').value= resp.seoKeywords;


          formValues = { 
              id: resp.id,
              productCode: resp.productCode,
              industry: resp.industry,
              machineType: resp.machineType,
              subMachineType: resp.subMachineType,
              make: resp.make,
              modelName: resp.modelName,
              packageDesc: resp.packageDesc,
              inventoryMatching: '-',
              riversEMSMatching: '-',
              regularPrice: resp.regularPrice,
              riversPrice: resp.riversPrice,
              validDate: resp.validDate,
              validToDate: resp.validToDate,
              advertiseDesc: resp.advertiseDesc,
              disclaimer: resp.disclaimer,
              media: med,
              promotionsAvailable: resp.promotionsAvailable,
              status: resp.status,
              slug: resp.slug,	
              seoTitle: resp.seotitle,	
              seoDescription: resp.seodescription,	
              seoKeywords: resp.seokeywords                          
            }

        }).catch((e) => {
          console.log('Error', e.message);
      });    
}

const onChangeComboBox = async (e)=>{

}
  const onSubmit = (data, event) => {
      event.preventDefault();
      
      // ********************************************************
      // Quality Entry Checking
      // ********************************************************

      formValues = {
        id: data.id,
        productCode: data.productCode.trim().replace("&","And").replace("+","And"),
        industry: document.getElementById("industry").value===""?"Select Industry":document.getElementById("industry").value, //data.industry,
        machineType: document.getElementById("machineType").value===""?"Select Machine Type":document.getElementById("machineType").value,// data.machineType,
        subMachineType: data.subMachineType === ""?"Machine Sub Type":data.subMachineType,
        make: data.make === ""  ? "Select Make":data.make,
        modelName: data.modelName==="" ? "-" : data.modelName,
        packageDesc: data.packageDesc === ""? "-" : data.packageDesc,
        inventoryMatching: '-',
        riversEMSMatching: '-',
        regularPrice: data.regularPrice === ""? 0:data.regularPrice,
        riversPrice: data.riversPrice ===""? 0: data.riversPrice,
        validDate: data.validDate,
        validToDate: data.validToDate,
        advertiseDesc: data.advertiseDesc === ""? "-":data.advertiseDesc,
        disclaimer: data.disclaimer === ""? "-":data.disclaimer,
        media: "-",
        promotionsAvailable: data.promotionsAvailable===""? "Select Promotion":data.promotionsAvailable,
        status: data.status,
        slug: data.slug,	
        seotitle: data.seotitle,	
        seoDescription: data.seodescription,	
        seoKeywords: data.seokeywords                          
      };

      console.log('Form Values =>>>> ', formValues);      

      // ********************************************************
      // Entry Exist Checking Routine
      ProductBaseMachineService.getProductBaseMachine(data.productCode.trim().replace("&","And").replace("+","And")).then((data)=>{
        if (data.length !== 0) {
          console.log('Data ',data)
          toast.info('Record Exists ! ',{ position: 'bottom-center'});        
          return;
        }
        else
        {
          ProductBaseMachineService.createProductBaseMachine(formValues).then((data)=>{
            toast.success('Base machine record saved successfully',{ position: 'bottom-center'});        
            setRecordAdded(previousState=>previousState+1);
          }).catch(err=>{
            console.log('error-',err);
          });
        }
      })
      // ********************************************************
  }
  const nextStep = ()=>{
    setFormStep(cur=>cur+1);
  }

  const updateRecord = (e) =>{
      e.preventDefault();

      let med = '';
      MediaService.getMedia(document.getElementById('id').value).then(data=>{              
        data.map(da=>{
          med = med + da.mediaType + ":" + da.mediaURL + ","
        })
            med = med.trim().substring(0,med.length-1);
            setMediaUri(med);
            console.log('Media URL Now => ',med);
            formValues.media = med;
          })

          console.log('MEDIA FORM=>', formValues);
          formValues.id = document.getElementById('id').value
          formValues.productCode = document.getElementById('productCode').value.trim().replace("&","And").replace("+","And")
          formValues.industry = document.getElementById('industry').value=== ""?"Select Industry":document.getElementById('industry').value
          formValues.machineType = document.getElementById('machineType').value===""?"Select Machine Type":document.getElementById('machineType').value
          formValues.subMachineType = document.getElementById('subMachineType').value===""?"Machine Sub Type":document.getElementById('subMachineType').value
          formValues.make = document.getElementById('make').value===""?"Select Make":document.getElementById('make').value
          formValues.modelName = document.getElementById('modelName').value ===""? "-":document.getElementById('modelName').value
          formValues.packageDesc = document.getElementById('packageDesc').value === ""? "-":document.getElementById('packageDesc').value
          formValues.inventoryMatching = '-'
          formValues.riversEMSMatching = '-'
          formValues.regularPrice = document.getElementById('regularPrice').value===""? 0:document.getElementById('regularPrice').value
          formValues.riversPrice = document.getElementById('riversPrice').value===""? 0:document.getElementById('riversPrice').value
          formValues.validDate = document.getElementById('validDate').value
          formValues.validToDate = document.getElementById('validToDate').value
          formValues.advertiseDesc = document.getElementById('advertiseDesc').value ===""? "-":document.getElementById('advertiseDesc').value
          formValues.disclaimer = document.getElementById('disclaimer').value === ""?"-":document.getElementById('disclaimer').value
          //formValues.media: MediaUri!==undefined? MediaUri:'Not Defined',
          formValues.promotionsAvailable = document.getElementById('promotionsAvailable').value===""?"Select Promotion":document.getElementById('promotionsAvailable').value
          formValues.status = document.getElementById('status').value
          formValues.slug = document.getElementById('slug').value
          formValues.seoTitle = document.getElementById('seotitle').value
          formValues.seoDescription = document.getElementById('seodescription').value
          formValues.seoKeywords = document.getElementById('seokeywords').value                     

            console.log('Base Machine Update <<<=>>> **** ', formValues);
            console.log('Promotion 2 = ', document.getElementById('promotionsAvailable').value)
            console.log('SLUG=>',document.getElementById('slug').value);

            if (document.getElementById('productCode').value.trim().replace("&","And").replace("+","And") === "" || document.getElementById('productCode').value.trim().replace("&","And").replace("+","And") === undefined || document.getElementById('productCode') === ""){
              alert('Rule Code cannot be null or undefined');  
              return;
            }  
            else
            {
                ProductBaseMachineService.editProductBaseMachine(formValues).then((basemachine)=>{
                  console.log('Base Machine =>> ', basemachine);
                  basemachine.data ? toast.success('Base Machine Edited Successfully',{ position: 'bottom-center'}):toast.info('Base Machine does not exists',{ position: 'bottom-center'});
                  setRecordAdded((previousState)=> previousState + 1);
                })
              .catch((e) => {
                console.log('Edit Base Machine Error Message', e.message);
                toast.error('Edit Base Machine Error ',{ position: 'bottom-center'})
              });
            }

  }

  const EnableRule = (e) => {
    //e.preventDefault();
    if (document.getElementById('productCode').value === "" || document.getElementById('productCode').value === undefined || document.getElementById('productCode').value === null){
      alert('Please select record first ! ');
      return;
    }
    setRuleStatus("Enable")

    let med = '';
    MediaService.getMedia(document.getElementById('id').value).then(data=>{              
      data.map(da=>{
        med = med + da.mediaType + ":" + da.mediaURL + ","
      })
      med = med.trim().substring(0,med.length-1);

      console.log('Media URL => ',med);              
    })
          formValues = {
            id: document.getElementById('id').value,
            productCode: document.getElementById('productCode').value,
            industry: document.getElementById('industry').value,
            machineType: document.getElementById('machineType').value,
            subMachineType: document.getElementById('subMachineType').value,
            make: document.getElementById('make').value,
            modelName: document.getElementById('modelName').value,
            packageDesc: document.getElementById('packageDesc').value,
            inventoryMatching: '-',
            riversEMSMatching: '-',
            regularPrice: document.getElementById('regularPrice').value,
            riversPrice: document.getElementById('riversPrice').value,
            validDate: document.getElementById('validDate').value,
            validToDate: document.getElementById('validToDate').value,
            advertiseDesc:document.getElementById('advertiseDesc').value,
            disclaimer: document.getElementById('disclaimer').value,
            media: med,
            promotionsAvailable: document.getElementById('promotionsAvailable').value,
            status: 'Enable',
            slug: document.getElementById('slug').value,
            seoTitle: document.getElementById('seotitle').value,	
            seoDescription: document.getElementById('seodescription').value,	
            seoKeywords: document.getElementById('seokeywords').value    
      };

      console.log('Form Values =>>>> ', formValues);
      ProductBaseMachineService.UpdateStatus(formValues).then((data)=>{
        toast.success('Base machine rule enabled successfully',{ position: 'bottom-center'});        
        setRecordAdded(previousState=>previousState+1);
      }).catch(err=>{
        toast.error('Base machine rule enable failed',{ position: 'bottom-center'});        
      });
  }

  const DisableRule = (e) => {
    
    if (document.getElementById('productCode').value === "" || document.getElementById('productCode').value === undefined || document.getElementById('productCode').value === null){
      alert('Please select record first ! ');
      return;
    }
    setRuleStatus("Disable")
    let med = '';
    MediaService.getMedia(document.getElementById('id').value).then(data=>{              
      data.map(da=>{
        med = med + da.mediaType + ":" + da.mediaURL + ","
      })
      med = med.trim().substring(0,med.length-1);

      console.log('Media URL => ',med);              
    })

          formValues = {
            id: document.getElementById('id').value,
            productCode: document.getElementById('productCode').value,
            industry: document.getElementById('industry').value,
            machineType: document.getElementById('machineType').value,
            subMachineType: document.getElementById('subMachineType').value,
            make: document.getElementById('make').value,
            modelName: document.getElementById('modelName').value,
            packageDesc: document.getElementById('packageDesc').value,
            inventoryMatching: '-',
            riversEMSMatching: '-',
            regularPrice: document.getElementById('regularPrice').value,
            riversPrice: document.getElementById('riversPrice').value,
            validDate: document.getElementById('validDate').value,
            validToDate: document.getElementById('validToDate').value,
            advertiseDesc:document.getElementById('advertiseDesc').value,
            disclaimer: document.getElementById('disclaimer').value,
            media: med,
            promotionsAvailable: document.getElementById('promotionsAvailable').value,
            status: 'Disable',
            slug: document.getElementById('slug').value,
            seoTitle: document.getElementById('seotitle').value,	
            seoDescription: document.getElementById('seodescription').value,	
            seoKeywords: document.getElementById('seokeywords').value    
      };

      console.log('Form Values =>>>> ', formValues);
      ProductBaseMachineService.UpdateStatus(formValues).then((data)=>{
        toast.success('Base machine rule disabled successfully',{ position: 'bottom-center'});        
        setRecordAdded(previousState=>previousState+1);
      }).catch(err=>{
        toast.error('Base machine rule disable failed',{ position: 'bottom-center'});        
      });
  }

  const deleteRecord = (e) =>{
    e.preventDefault();
           formValues = { 
                    id: document.getElementById('id').value,
                    productCode: document.getElementById('productCode').value,
                    industry: document.getElementById('industry').value,
                    machineType: document.getElementById('machineType').value,
                    subMachineType: document.getElementById('subMachineType').value,
                    make: document.getElementById('make').value,
                    modelName: document.getElementById('modelName').value,
                    packageDesc: document.getElementById('packageDesc').value,
                    inventoryMatching: '-',
                    riversEMSMatching: '-',
                    regularPrice: document.getElementById('regularPrice').value,
                    riversPrice: document.getElementById('riversPrice').value,
                    validDate: document.getElementById('validDate').value,
                    validToDate: document.getElementById('validToDate').value,
                    advertiseDesc:document.getElementById('advertiseDesc').value,
                    disclaimer: document.getElementById('disclaimer').value,
                    media: document.getElementById('media').value,
                    promotionsAvailable: document.getElementById('promotionsAvailable').value,
                    status: document.getElementById('status').value,
                    slug: document.getElementById('slug').value,
                    seoTitle: document.getElementById('seotitle').value,	
                    seoDescription: document.getElementById('seodescription').value,	
                    seoKeywords: document.getElementById('seokeywords').value    
          }

          if (document.getElementById('productCode').value.trim().replace("&","And").replace("+","And") === "" || document.getElementById('productCode').value.trim().replace("&","And").replace("+","And") === undefined || document.getElementById('productCode') === ""){
            alert('Rule Code cannot be null or undefined');  
            return;
          }  
          else
          {
            setModalOpen(true);
          }

  }

  const renderButton = () => {
    // if (formStep >= 2) {
    return (
                <div className="container">
                <div  className="row">
                <div className="col md-3 sm-3 xs-2">  
                {    
                                modalOpen && 
                                <Modal 
                                    setOpenModal={ setModalOpen } 
                                    setAction = { setAction }
                                    formValues = { formValues }
                                    formName = "Product Base Machine"
                                    title = "Are you sure to delete record ?" 
                                    message = "Product Base Machine will be deleted ** !" 
                                    setRecordAdded = { setRecordAdded }
                                    />
                  }            

                </div>                
                </div>
                    <table>
                      <tr key= { Math.random() }>
                        <td>
                            <button className="btn btn-primary" type="submit">Submit</button>
                        </td>
                        <td>
                            <button className="btn btn-primary" onClick={(e)=> {updateRecord(e)}} >Update</button>              
                        </td>
                        <td>
                            <button className="btn btn-primary" onClick={(e)=> {deleteRecord(e);  }} >Delete</button>
                        </td>                        
                        <td>
                            {(RuleStatus === "Disable") &&
                            <button className="btn btn-primary" onClick={(e)=> {EnableRule(); }} >Enable</button>}
                            {(RuleStatus === "Enable") &&
                            <button className="btn btn-primary" onClick={(e)=> {DisableRule(); }} >Disable</button>}
                        </td>                        
                      </tr>
                    </table>
                </div>
        ) 
    // } 
    // else 
    // {
    //   return (                             
    //     <div className="container">
    //       <div className="row">
    //           <div className="col md-3 sm-3 xs-2">
    //               <button disabled={!isValid} className="btn btn-primary" onClick={nextStep}type="button">Next</button>
    //           </div>
    //       </div>
    //     </div>
    //   )	
    // }
  }

  const onChangeIndustryBox = async (e)=>{  
      e.preventDefault();
      setIndustryName(e.target.value.trim());
      await sleep(2500);    
      document.getElementById('industry').value = e.target.value.trim();
  }
  
  const onChangeAdditionalFieldName = async (e)=>{
    //setPromotionIDSet(e.target.value);
    e.preventDefault();    
    
    const RuleCode = document.getElementById('productCode').value
    const FieldName = document.getElementById('additionalField').value

    if (RuleCode === undefined || RuleCode === null || RuleCode === ""){
      alert('Please select Rule record first ! ')
      return;
    }
    else {
      AdditionalFieldsService.getAdditionalFields(RuleCode,FieldName).then((data)=>{
        console.log(data);
        if (data.fieldValue === undefined){
          document.getElementById('additionalFieldValue').value = '';
          document.getElementById('additionalId').value = 0;
        }else{
          document.getElementById('additionalFieldValue').value = data.fieldValue;
          document.getElementById('additionalId').value = data.id;
        }
      }).catch((error)=>{
        console.log(error);
      })  
    }
  }

  const AddAdditional = async (e)=>{
    //setPromotionIDSet(e.target.value);
    e.preventDefault();

    const RuleCode = document.getElementById('productCode').value;
    const fieldName =document.getElementById('additionalField').value;
    const fieldValue =document.getElementById('additionalFieldValue').value;

    if (RuleCode === undefined || RuleCode === null || RuleCode === ""){
      alert('Please select Rule record first ! ')
      return;
    }
    else {
      if (fieldName === undefined || fieldName === null || fieldName === ""){
        alert('Please select Field first ! ')
        return;
      }
      
      if (fieldValue === undefined || fieldValue === null || fieldValue === ""){
        alert('Please type field value first ! ')
        return;
      }  
      const additionals = {
        id: 0, 
        ruleName: document.getElementById('productCode').value,
        fieldName: document.getElementById('additionalField').value,
        fieldValue: document.getElementById('additionalFieldValue').value,
      }
      console.log('Additional Field = ', additionals)
      AdditionalFieldsService.addAdditionalFields(additionals).then((data)=>{
        toast.success('Additional field added successfully',{ position: 'bottom-center'})
      }).catch((error)=>{
        toast.info('Additional field adding failed',{ position: 'bottom-center'})
      });
    }

      setRuleCode(document.getElementById('productCode').value);

      await sleep(1000);

      await AdditionalFieldsService.getAdditionalFieldsRule(RuleCodeEdit).then(data=>{
        setAddon(data);
      }).catch(error=>{
        console.log('Error ===>>>> ', error);
      });
  }

  const RemoveAdditional = async (e)=>{
    //setPromotionIDSet(e.target.value);
    e.preventDefault();
    const RuleCode = document.getElementById('productCode').value;
    const fieldName =document.getElementById('additionalField').value;
    const fieldValue =document.getElementById('additionalFieldValue').value;

    if (RuleCode === undefined || RuleCode === null || RuleCode === ""){
      alert('Please select Rule record first ! ')
      return;
    }
    else {
      if (fieldName === undefined || fieldName === null || fieldName === ""){
        alert('Please select Field first ! ')
        return;
      }
      
      if (fieldValue === undefined || fieldValue === null || fieldValue === ""){
        alert('Please type field value first ! ')
        return;
      }  
      const additionals = {
        id: document.getElementById('additionalId').value, 
        ruleName: document.getElementById('productCode').value,
        fieldName: document.getElementById('additionalField').value,
        fieldValue: document.getElementById('additionalFieldValue').value,
      }
      console.log('Additional Field = ', additionals)
      AdditionalFieldsService.deleteAdditionalFields(additionals).then((data)=>{
        toast.success('Additional field removed successfully',{ position: 'bottom-center'})
      }).catch((error)=>{
        toast.info('Additional field removing failed',{ position: 'bottom-center'})
      });
    }

    setRuleCode(document.getElementById('productCode').value);
    await sleep(1000);

    await AdditionalFieldsService.getAdditionalFieldsRule(RuleCodeEdit).then(data=>{
      setAddon(data);
    }).catch(error=>{
      console.log('Error ===>>>> ', error);
    });
  }
  const ViewAdditional = async (e)=>{
    //setPromotionIDSet(e.target.value);
    e.preventDefault();
    const RuleCode = document.getElementById('productCode').value;
    if (RuleCode === undefined || RuleCode === null || RuleCode === ""){
      alert('Please select Rule record first ! ')
      return;
    }
    setShowAdditional(true);
  }

  const onChangeFieldName = async (e)=>{
    //setPromotionIDSet(e.target.value);
    e.preventDefault();
    const RuleCode = document.getElementById('productCode').value
    const FieldName = document.getElementById('fieldName').value

    if (RuleCode === undefined || RuleCode === null || RuleCode === ""){
      alert('Please select Rule record first ! ')
      return;
    }
    else {
      console.log('FieldName = ',FieldName);
      console.log('RuleCode = ',RuleCode);
      IVRiversMatchingService.getRiversMatching(RuleCode,FieldName).then((data)=>{
        if (data.fieldValue === undefined){
          document.getElementById('fieldValue').value = '';
          document.getElementById('ruleId').value = 0;
        }else{
          document.getElementById('fieldValue').value = data.fieldValue;
          document.getElementById('ruleId').value = data.id;
        }
      }).catch((error)=>{
        console.log(error);
      })  
    }
  }

  const onChangeMediaField = async (e)=>{
    e.preventDefault();
  }

 
  const onChangeDeereFieldName = async (e)=>{
    //setPromotionIDSet(e.target.value);
    e.preventDefault();
    const RuleCode = document.getElementById('productCode').value
    const FieldName = document.getElementById('deereFieldName').value

    if (RuleCode === undefined || RuleCode === null || RuleCode === ""){
      alert('Please select Rule record first ! ')
      return;
    }
    else {
      console.log('FieldName = ',FieldName);
      console.log('RuleCode = ',RuleCode);
      IVDeereMatchingService.getDeereMatching(RuleCode,FieldName).then((data)=>{
        if (data.fieldValue === undefined){
          document.getElementById('deereFieldValue').value = '';
          document.getElementById('ruleId').value = 0;
        }else{
          document.getElementById('deereFieldValue').value = data.fieldValue;
          document.getElementById('ruleId').value = data.id;
        }
      }).catch((error)=>{
        console.log(error);
      })  
    }
  }

  const onChangeMachineTypeBox = async (e)=>{    

    e.preventDefault();   
      setMachineType(e.target.value.trim())
      await sleep(2500);
      document.getElementById('machineType').value = e.target.value.trim();
  }

  const onChangeMachineSubTypeBox = async (e)=>{
    //setPromotionIDSet(e.target.value);

  }

  const onChangeMakeBox = async (e)=>{
    //setPromotionIDSet(e.target.value);
  }

  const AddRiversMatch = async(e)=>{
    e.preventDefault();

    const RuleCode = document.getElementById('productCode').value;
    const fieldName =document.getElementById('fieldName').value;
    const fieldValue =document.getElementById('fieldValue').value;

    if (RuleCode === undefined || RuleCode === null || RuleCode === ""){
      alert('Please select Rule record first ! ')
      return;
    }
    else {
      if (fieldName === undefined || fieldName === null || fieldName === ""){
        alert('Please select Field first ! ')
        return;
      }
      
      if (fieldValue === undefined || fieldValue === null || fieldValue === ""){
        alert('Please type field value first ! ')
        return;
      }  
      const riverRules = {
        id: 0, 
        ruleCode: document.getElementById('productCode').value,
        fieldName: document.getElementById('fieldName').value,
        fieldValue: document.getElementById('fieldValue').value
      }
      console.log('Rivers Rule = ', riverRules)
      IVRiversMatchingService.addRiversMatching(riverRules).then((data)=>{
        toast.success('New Rule Condition Added Successfully',{ position: 'bottom-center'})
      }).catch((error)=>{
        toast.info('New Rule Condition Adding Failed',{ position: 'bottom-center'})
      });

      setRuleCode(document.getElementById('productCode').value);
      await sleep(1000);
      await IVRiversMatchingService.getRiversMatchingRule(RuleCodeEdit).then(data=>{
        setEMS(data);
      }).catch(error=>{
      });

    }

  }

  const AddMedia = async(e)=>{
    e.preventDefault();
    const RuleCode = document.getElementById('productCode').value
    const MediaField = document.getElementById('mediaField').value
    const MediaURL = document.getElementById('media').value

    const Id = document.getElementById('id').value


    if (RuleCode === undefined || RuleCode === null || RuleCode === ""){
      alert('Please select Rule record first ! ')
      return;
    }
    else     
    {
      console.log('FieldName = ',MediaField);
      console.log('RuleCode = ',RuleCode);

      if (MediaURL === undefined || MediaURL === null || MediaURL === ""){
        alert('Please type media URL first ! ')
        return;
      }

      const mediaForm ={
        id: 0,
        mediaId: Id,
        mediaType:MediaField,
        mediaURL:MediaURL
      }  

      MediaService.addMedia(mediaForm).then((data)=>{
        toast.success('New Media Added Successfully',{ position: 'bottom-center'})
      }).catch((error)=>{
        toast.info('New Media Adding Failed',{ position: 'bottom-center'})
      });
      
      document.getElementById('media').value= '';
      await sleep(2000);
      MediaService.getMedia(Id).then(data=>{
        setProductMedia(data);
        console.log('Id Data Returned ', data);
      })
    } 

  }

  const ViewRiversMatch = async(e)=>{
    e.preventDefault();
    const RuleCode = document.getElementById('productCode').value;
    if (RuleCode === undefined || RuleCode === null || RuleCode === ""){
      alert('Please select Rule record first ! ')
      return;
    }
    setShowRiversMatch(true);
  }

  const RemoveMedia = async(e)=>{
    const payload ={
      id: e,
      mediaId: e,
      mediaType: '',
      mediaURL: ''
    }

    const RuleCode = document.getElementById('productCode').value
    if (RuleCode === undefined || RuleCode === null || RuleCode === ""){
      alert('Please select Rule record first ! ')
      return;
    }

    MediaService.deleteMedia(payload).then(data=>{
      const Id = document.getElementById('id').value
      MediaService.getMedia(Id).then(data=>{
        setProductMedia(data);
        console.log('Id Data Returned ', data);
      })
    })
  }

  const RemoveRiversMatch = async(e)=>{
    e.preventDefault();
 
    const RuleCode = document.getElementById('productCode').value;
    const fieldName =document.getElementById('fieldName').value;
    const fieldValue =document.getElementById('fieldValue').value;

    if (RuleCode === undefined || RuleCode === null || RuleCode === ""){
      alert('Please select Rule record first ! ')  
      return;
    }
    else
    {
      if (fieldName === undefined || fieldName === null || fieldName === ""){
        alert('Please select Field first ! ')
        return;
      }
      
      if (fieldValue === undefined || fieldValue === null || fieldValue === ""){
        alert('Please type field value first ! ')
        return;
      }

      const riverRules = {
        id: document.getElementById('ruleId').value, 
        ruleCode: document.getElementById('productCode').value,
        fieldName: document.getElementById('fieldName').value,
        fieldValue: document.getElementById('fieldValue').value
      }
  
      IVRiversMatchingService.deleteRiversMatching(riverRules).then((data)=>{
        toast.success('New Rule Condition Removed Successfully',{ position: 'bottom-center'})
      }).catch((error)=>{
        toast.info('New Rule Condition Removed Failed',{ position: 'bottom-center'})
      });
    }    

    setRuleCode(document.getElementById('productCode').value);
    await sleep(1000);

    await IVRiversMatchingService.getRiversMatchingRule(RuleCodeEdit).then(data=>{
      setEMS(data);
      console.log('Data =>>> ', data);
    }).catch(error=>{
      console.log('Error ===>>>> ', error);
    });
  }


  // Deere Operation Buttons
  const AddDeereMatch = async(e)=>{
    e.preventDefault();

    const RuleCode = document.getElementById('productCode').value;
    const deereFieldName =document.getElementById('deereFieldName').value;
    const deereFieldValue =document.getElementById('deereFieldValue').value;

    if (RuleCode === undefined || RuleCode === null || RuleCode === ""){
      alert('Please select Rule record first ! ')
      return;
    }
    else {
      if (deereFieldName === undefined || deereFieldName === null || deereFieldName === ""){
        alert('Please select Field first ! ')
        return;
      }
      
      if (deereFieldValue === undefined || deereFieldValue === null || deereFieldValue === ""){
        alert('Please type field value first ! ')
        return;
      }  
      const deereRules = {
        id: 0, 
        ruleCode: document.getElementById('productCode').value,
        fieldName: document.getElementById('deereFieldName').value,
        fieldValue: document.getElementById('deereFieldValue').value
      }
      console.log('Deere Rule = ', deereRules)
      IVDeereMatchingService.addDeereMatching(deereRules).then((data)=>{
        toast.success('New Rule Condition Added Successfully',{ position: 'bottom-center'})
      }).catch((error)=>{
        toast.info('New Rule Condition Adding Failed',{ position: 'bottom-center'})
      });
    }

      setRuleCode(document.getElementById('productCode').value);

      await sleep(1000);

      await IVDeereMatchingService.getDeereMatchingRule(RuleCodeEdit).then(data=>{
        setDeere(data);
        console.log('Data =>>> ', data);
      }).catch(error=>{
        console.log('Error ===>>>> ', error);
      });
      // setRuleCode(document.getElementById('productCode').value);
      // alert('Rule added successfully ');

      // await AdditionalFieldsService.getAdditionalFieldsRule(RuleCodeEdit).then(data=>{
      //   setAddon(data);
      // }).catch(error=>{
      //   console.log('Error ===>>>> ', error);
      // });


  }
  const ViewDeereMatch = async(e)=>{
    e.preventDefault();
    const RuleCode = document.getElementById('productCode').value;
    if (RuleCode === undefined || RuleCode === null || RuleCode === ""){
      alert('Please select Rule record first ! ')
      return;
    }
    setShowDeereMatch(true);
  }

  const RemoveDeereMatch = async(e)=>{
    e.preventDefault();
 
    const RuleCode = document.getElementById('productCode').value;
    const deereFieldName =document.getElementById('deereFieldName').value;
    const deereFieldValue =document.getElementById('deereFieldValue').value;

    if (RuleCode === undefined || RuleCode === null || RuleCode === ""){
      alert('Please select Rule record first ! ')  
      return;
    }
    else
    {
      if (deereFieldName === undefined || deereFieldName === null || deereFieldName === ""){
        alert('Please select Field first ! ')
        return;
      }
      
      if (deereFieldValue === undefined || deereFieldValue === null || deereFieldValue === ""){
        alert('Please type field value first ! ')
        return;
      }

      const deereRules = {
        id: document.getElementById('ruleId').value, 
        ruleCode: document.getElementById('productCode').value,
        fieldName: document.getElementById('deereFieldName').value,
        fieldValue: document.getElementById('deereFieldValue').value
      }
  
      IVDeereMatchingService.deleteDeereMatching(deereRules).then((data)=>{
        toast.success('New Rule Condition Removed Successfully',{ position: 'bottom-center'})
      }).catch((error)=>{
        toast.info('New Rule Condition Removed Failed',{ position: 'bottom-center'})
      });
    }    


    setRuleCode(document.getElementById('productCode').value);
    await sleep(1000);
    await IVDeereMatchingService.getDeereMatchingRule(RuleCodeEdit).then(data=>{
      setDeere(data);
    }).catch(error=>{
      console.log('Error ===>>>> ', error);
    });
    // setRuleCode(document.getElementById('productCode').value);
    // alert('Rule added successfully ');

    // await AdditionalFieldsService.getAdditionalFieldsRule(RuleCodeEdit).then(data=>{
    //   setAddon(data);
    // }).catch(error=>{
    //   console.log('Error ===>>>> ', error);
    // });

  }

const DEF_DELAY = 1000;

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms || DEF_DELAY));
}

  return (
    <> 
      <Container>
        <hr/>
          <Row>
          { ((ems.length > 0) || (deere.length > 0) | (addon.length > 0)) &&
            
            <Col md="2">
            <div className="heading">
                <h5 className="mainheading">Rules</h5>
                <hr/>
            </div>                      
            { ems.length > 0  &&
              <>
              EMS Rules
              <Table className = "FieldsTable" striped bordered hover variant="dark">
                      <thead>
                            <tr key= { Math.random() }>
                              <th className="tdclass">Name</th>
                              <th className="tdclass">Value</th>
                            </tr>
                      </thead>
                      <tbody>

                      { 
                        //console.log('Data', users);
                        ems.map((item) => {
                          console.log('ITEMS = ',items);
                        return <>
                              <tr key= { Math.random() }>
                                  <td className="tdclass"> { item.fieldName }</td>
                                  <td className="tdclass"> { item.fieldValue }</td>
                              </tr>
                          </>
                        })                  
                      }
                      </tbody>
                      </Table></>
                  }

                  { deere.length > 0  &&
                    <>Deere Rules
                      <Table className = "FieldsTable" striped bordered hover variant="dark">
                              <thead>
                                    <tr key= { Math.random() }>
                                      <th className="tdclass">Name</th>
                                      <th className="tdclass">Value</th>
                                    </tr>
                              </thead>
                              <tbody>

                              { 
                                //console.log('Data', users);
                                deere.map((item) => {
                                  console.log('ITEMS = ',items);
                                return <>
                                      <tr key= { Math.random() }>
                                          <td className="tdclass"> { item.fieldName }</td>
                                          <td className="tdclass"> { item.fieldValue }</td>
                                      </tr>
                                  </>
                                })                  
                              }
                              </tbody>
                              </Table></>
    }


        {
           addon.length > 0  &&
            <>Additional Fields
                      <Table className = "FieldsTable" striped bordered hover variant="dark">
                              <thead>
                                    <tr key= { Math.random() }>
                                      <th className="tdclass">Name</th>
                                      <th className="tdclass">Value</th>
                                    </tr>
                              </thead>
                              <tbody>

                              { 
                                //console.log('Data', users);
                                addon.map((item) => {
                                  console.log('ITEMS = ',items);
                                return <>
                                      <tr key= { Math.random() }>
                                          <td className="tdclass"> { item.fieldName }</td>
                                          <td className="tdclass"> { item.fieldValue }</td>
                                      </tr>
                                  </>
                                })                  
                              }
                              </tbody>
                              </Table></>
                    }
              </Col>
       }
                <Col md="4">
                        <div className="heading">
                            <h5 className="mainheading">BASE MACHINE - RULE SETUP</h5>
                            <hr/>
                        </div>                      
                          <form onSubmit={handleSubmit(onSubmit)}>
                          {/* { formStep >= 0 && */}
                          <table>
                            <tr key= { Math.random() }>
                                <td>
                                        <section>
                                        <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>
                                        <input hidden className="input" type="text" id="ruleId"></input>
                                        <input hidden className="input" type="text" id="additionalId"></input>
                                        <input hidden defaultValue = 'Disable' className="input" type="text" {...register("status")}  id="status" placeholder="Status"></input>
                                        <label>Rule Code</label>
                                        <br/>
                                        
                                        <input 
                                          className="input" 
                                          type="text" {...register("productCode", { required: {value: true, message: "This is required field"} }) }  
                                          id="productCode" 
                                          placeholder="Rule Code"
                                          //value = { RuleCodeEdit }
                                          >
                                          </input>
                                        
                                        <h2>{errors.productCode && <p>{errors.productCode.message}</p>}</h2>
                                        <hr/> 

                                        <label>Rivers EMS Matching</label><br/>
                                        <select 
                                          className="select" 
                                            id="fieldName"
                                            onChange={(e)=> {
                                            onChangeFieldName(e);
                                          }}  
                                          >
                                          <option  key={Math.random()}   value="Select">Select Field</option> 
                                          <option  key={Math.random()}   value="equipmentItemId">equipmentItemId</option> 
                                          <option  key={Math.random()}   value="serialNumber">serialNumber</option> 
                                          <option  key={Math.random()}   value="tagNumber">tagNumber</option> 

                                          <option  key={Math.random()}   value="groupName">groupName</option> 
                                          <option  key={Math.random()}   value="categoryName">categoryName</option> 
                                          <option  key={Math.random()}   value="makeName">makeName</option> 
                                          <option  key={Math.random()}   value="modelName">modelName</option> 
                                          
                                          <option  key={Math.random()}   value="advertisedPrice">advertisedPrice</option> 
                                          <option  key={Math.random()}   value="meterReadingHours">meterReadingHours</option> 
                                          <option  key={Math.random()}   value="modelYear">modelYear</option> 
                                          <option  key={Math.random()}   value="store">store</option> 
                                          <option  key={Math.random()}   value="storeShipState">storeShipState</option> 
                                          <option  key={Math.random()}   value="primaryPhone">primaryPhone</option> 
                                          <option  key={Math.random()}   value="isNew">isNew</option> 

                                          <option  key={Math.random()}   value="description">description</option> 
                                          <option  key={Math.random()}   value="storeNumber">storeNumber</option> 
                                          <option  key={Math.random()}   value="isActive">isActive</option> 
                                          <option  key={Math.random()}   value="isHotList">isHotList</option> 
                                          <option  key={Math.random()}   value="attachmentList">attachmentList</option> 
                                          <option  key={Math.random()}   value="makeOrder">makeOrder</option> 
                                          <option  key={Math.random()}   value="ba">ba</option> 
                                      </select>

                                        <input className="input" type="text" id="fieldValue" placeholder="Field Value"></input>
                                        {    
                                ShowRiversMatch && 
                                <MatchingModal 
                                    setOpenModal={ setShowRiversMatch } 
                                    setAction = { setAction }
                                    formValues = { document.getElementById('productCode').value }
                                    formName = "View Rivers Matching"
                                    title = "View Rivers Matching" 
                                    message = {document.getElementById('productCode').value} 
                                    setRecordAdded = { setRecordAdded }
                                    />
                  }                                       
                                        <table>
                                          <tr key= { Math.random() }>
                                            <td>
                                                <button className="ruleButton" onClick={(e)=>{AddRiversMatch(e)}}> Add</button>
                                            </td>
                                            <td>
                                                <button className="ruleButton" onClick={(e)=>{RemoveRiversMatch(e)}}> Remove</button>
                                            </td>
                                            <td>
                                                <button hidden className="ruleButton" onClick={(e)=>{ViewRiversMatch(e)}}> Show</button>
                                            </td>
                                          </tr>
                                        </table>      
                                        <hr/> 

                                        <label>Additional Fields</label><br/>
                                        <select 
                                          className="select" 
                                            id="additionalField"
                                            onChange={(e)=> {
                                            onChangeAdditionalFieldName(e);
                                          }}  
                                          >
                                          <option  key={Math.random()}   value="Select">Select Field</option> 
                                          {
                                            AdditionalFields.map((d)=>(
                                            <option  key={Math.random()}  value={d.fieldName}>{d.fieldName}</option>
                                          ))
                                      }
                                      </select>
                                        <input className="input" type="text" id="additionalFieldValue" placeholder="Field Value"></input>
                                        {    
                                  ShowAdditional && 
                                <AdditionalModal 
                                    setOpenModal={ setShowAdditional } 
                                    setAction = { setAction }
                                    formValues = { document.getElementById('productCode').value }
                                    formName = "View Additionals"
                                    title = "View Additionals" 
                                    message = {document.getElementById('productCode').value} 
                                    setRecordAdded = { setRecordAdded }
                                    />
                  }                                       

                                        <table>
                                          <tr key= { Math.random() }>
                                            <td>
                                                <button className="ruleButton" onClick={(e)=>{AddAdditional(e)}}> Add</button>
                                            </td>
                                            <td>
                                                <button className="ruleButton" onClick={(e)=>{RemoveAdditional(e)}}> Remove</button>
                                            </td>
                                            <td>
                                                <button hidden className="ruleButton" onClick={(e)=>{ViewAdditional(e)}}> Show</button>
                                            </td>
                                          </tr>
                                        </table>      
  
                                          <hr/>
                                        <label>Deere Matching</label><br/>
                                        <select 
                                          className="select" 
                                            id="deereFieldName"
                                            onChange={(e)=> {
                                            onChangeDeereFieldName(e);
                                          }}  
                                          >
                                          <option  key={Math.random()}   value="Select">Select Field</option> 
                                          <option  key={Math.random()}   value="productSku">Product Sku</option> 
                                          <option  key={Math.random()}   value="productBaseCode">Product Base Code</option> 
                                          <option  key={Math.random()}   value="productIndustry">Product Industry</option> 
                                          <option  key={Math.random()}   value="productModelNumber">Product Model Number</option>                                           
                                          <option  key={Math.random()}   value="productModelName">Product Model Name</option>
                                      </select>

                                        <input className="input" type="text" id="deereFieldValue" placeholder="Field Value"></input>
                                        {    
                                ShowDeereMatch && 
                                <MatchingDeereModal 
                                    setOpenModal={ setShowDeereMatch } 
                                    setAction = { setAction }
                                    formValues = { document.getElementById('productCode').value }
                                    formName = "View Deere Matching"
                                    title = "View Deere Matching" 
                                    message = {document.getElementById('productCode').value} 
                                    setRecordAdded = { setRecordAdded }
                                    />
                  }                                       
                                        <table>
                                          <tr key= { Math.random() }>
                                            <td>
                                                <button className="ruleButton" onClick={(e)=>{AddDeereMatch(e)}}> Add</button>
                                            </td>
                                            <td>
                                                <button className="ruleButton" onClick={(e)=>{RemoveDeereMatch(e)}}> Remove</button>
                                            </td>
                                            <td>
                                                <button hidden className="ruleButton" onClick={(e)=>{ViewDeereMatch(e)}}> Show</button>
                                            </td>
                                          </tr>
                                        </table>      
                                        <hr/> 

                                        {/* <input className="input" type="text" {...register("riversEMSMatching")}  id="riversEMSMatching" placeholder="Rivers EMS Matching"></input>
                                        <h2>{errors.riversEMSMatching && <p>{errors.riversEMSMatching.message}</p>}</h2> */}

                                  </section>

                                </td>
                                <td className="hidden">Space Created More Here</td>
                                
                                <td>
                                      <section>

                                      <label>Industry</label>
                                        <br/>
                                        <select 
                                          className="select" 
                                          // {...register("industry")} 
                                            id="industry"
                                            value = {  IndustryName  }
                                            onChange={async (e)=> {
                                            onChangeIndustryBox(e);
                                          }}  
                                          >
                                          <option  key={Math.random()}   value="Select Industry">Select Industry</option> 
                                        {
                                          IndustrySet.map((d)=>(
                                            <option  key={Math.random()}  value={d.industryName}>{d.industryName}</option>
                                          ))
                                      }
                                      </select>
                                      <h2>{errors.industry && <p>{errors.industry.message}</p>}</h2>

                                        <label>Machine Type</label><br/>
                                        <select 
                                          className="select" 
                                          // {...register("machineType")} 
                                            id="machineType"
                                            value = {MachineType}
                                            onChange={async (e)=> {
                                            onChangeMachineTypeBox(e);
                                          }}  
                                          >
                                          <option  key={Math.random()}   value="Select Machine Type">Select Machine Type</option> 
                                      {
                                          MachineTypeSet.map((d)=>(
                                            <option  key={Math.random()}  value={d.type}>{d.type}</option>
                                          ))
                                      }
                                      </select>
                                      <h2>{errors.machineType && <p>{errors.machineType.message}</p>}</h2>

                                      <label>Sub Type</label><br/>

                                      {/* <input list="submachinelist" name="subMachineType" id="subMachineType" 
                                          className="select" 
                                          {...register("subMachineType")} 
                                      />

                                      <datalist 
                                            id="submachinelist"
                                            onChange={(e)=> {
                                            onChangeMachineSubTypeBox(e);
                                          }}  
                                          >
                                          <option  key={Math.random()}   value="Select">Machine Sub Type</option> 
                                              {
                                                MachineSubTypeSet.map((d)=>(
                                                    <option  key={Math.random()}  value={d.subType}>{d.subType}</option>
                                                  ))
                                              }
                                        </datalist> */}

                                        <select 
                                          className="select" 
                                          {...register("subMachineType")} 
                                            id="subMachineType"
                                            onChange={(e)=> {
                                              onChangeMachineSubTypeBox(e);
                                          }}  
                                          >
                                          <option  key={Math.random()}   value="Machine Sub Type">Machine Sub Type</option> 
                                          {
                                                MachineSubTypeSet.map((d)=>(
                                                <option  key={Math.random()}  value={d.subType}>{d.subType}</option>
                                              ))
                                          }
                                        </select>
                              
                                      <h2>{errors.subMachineType && <p>{errors.subMachineType.message}</p>}</h2>


                                      <label>Make</label><br/>
                                      <select 
                                          className="select" 
                                          {...register("make")} 
                                            id="make"
                                            onChange={(e)=> {
                                            onChangeMakeBox(e);
                                          }}  
                                          >
                                          <option  key={Math.random()}   value="Select Make">Select Make</option> 
                                      {
                                          MakeSet.map((d)=>(
                                            <option  key={Math.random()}  value={d.vendorName}>{d.vendorName}</option>
                                          ))
                                      }
                                      </select>
                                      <h2>{errors.make && <p>{errors.make.message}</p>}</h2>

                                      <label>Model Name</label><br/>
                                        <input className="input" type="text" {...register("modelName")}  id="modelName" placeholder="Model Name"></input>
                                      <h2>{errors.modelName && <p>{errors.modelName.message}</p>}</h2>

                                      </section>                                
                                </td>
                                <td className="hidden">Space Created</td>
                                <td>
                                        <section>

                                        <label>Package Desc</label><br/>
                                      <textarea  multiline="true" className="descinput" type="text" {...register("packageDesc")}  id="packageDesc" placeholder="Package Desc"></textarea>
                                      <h2>{errors.packageDesc && <p>{errors.packageDesc.message}</p>}</h2>

                                      <label>Regular Price</label><br/>
                                      <input className="input" type="text" {...register("regularPrice" )}  id="regularPrice" placeholder="Regular Price"></input>
                                      <h2>{errors.regularPrice && <p>{errors.regularPrice.message}</p>}</h2>

                                      <label>Rivers Price</label><br/>
                                      <input className="input" type="text" {...register("riversPrice" )}  id="riversPrice" placeholder="Rivers Price"></input>
                                      <h2>{errors.riversPrice && <p>{errors.riversPrice.message}</p>}</h2>

                                        <label>Valid Date</label><br/>
                                        <input className="input" type="date"  
                                        {...register("validDate")} 
                                          id="validDate" placeholder="Valid Date"></input>
                                        <h2>{errors.validDate && <p>{errors.validDate.message}</p>}</h2>

                                        <label>Valid To Date</label><br/>
                                        <input className="input" type="date"  {...register("validToDate")}  id="validToDate" placeholder="Valid To Date"></input>
                                        <h2>{errors.validToDate && <p>{errors.validToDate.message}</p>}</h2>

                                          </section>
                                        </td>
                                        <td className="hidden">Space Created</td>
                                      <td>
                                        <label>Advertise Desc</label><br/>
                                        <textarea className="descinput" type="text" {...register("advertiseDesc")}  id="advertiseDesc" placeholder="Advertise Desc"></textarea>
                                        <h2>{errors.advertiseDesc && <p>{errors.advertiseDesc.message}</p>}</h2>

                                        <label>Disclaimer</label><br/>
                                        <textarea className="descinput" type="text" {...register("disclaimer")}  id="disclaimer" placeholder="Disclaimer"></textarea>
                                        <h2>{errors.disclaimer && <p>{errors.disclaimer.message}</p>}</h2>

                                      <label>Media</label><br/>
                                      <select 
                                            className="select" 
                                            id="mediaField"
                                            onChange={(e)=> {
                                            onChangeMediaField(e);
                                          }}  
                                          >
                                          <option  key={Math.random()}   value="Select">Select Field</option> 
                                          <option  key={Math.random()}   value="Image">Image</option> 
                                          <option  key={Math.random()}   value="Video">Video</option> 
                                          <option  key={Math.random()}   value="Audio">Audio</option> 
                                      </select>
                                      
                                        {/* <input className="descinput" type="text" {...register("media")}  id="media" placeholder="Media"></input> */}
                                        <textarea  multiline="false" 
                                                  className="descinput" type="text" 
                                                    id="media" placeholder="Media URL">                                                      
                                                    </textarea>
                                        <h2>{errors.media && <p>{errors.media.message}</p>}</h2>
                                        <table>
                                          <tr key= { Math.random() }>
                                            <td>
                                                <button className="ruleButton" onClick={(e)=>{AddMedia(e)}}> Add</button>
                                            </td>
                                          </tr>
                                        </table>     
                                      
                                      
                                        <label>Promotions Available</label><br/>
                                        <select 
                                                className="select" 
                                                {...register("promotionsAvailable")} 
                                                  id="promotionsAvailable"
                                                  onChange={(e)=> {
                                                  onChangeComboBox(e);
                                                }}  
                                                >
                                                <option  key={Math.random()}   value="Select Promotion">Select Promotion</option> 
                                            {
                                                PromotionIDSet.map((d)=>(
                                                  <option  key={Math.random()}  value={d.promotionId}>{d.promotionId}</option>
                                                ))
                                            }
                                            </select>


                                        {/* <input className="input" type="text" {...register("promotionsAvailable", { required: {value: true, message: "This is required field"} })}  id="promotionsAvailable" placeholder="Promotions Available"></input> */}
                                        <h2>{errors.promotionsAvailable && <p>{errors.promotionsAvailable.message}</p>}</h2>
                                      </td>

                                      <td className="hidden">Space Created</td>
                                      <td>
                                      <label>Slug</label><br/>
                                            <input className="input" type="text"  {...register("slug")}  id="slug" placeholder="Slug"></input>
                                            <h2>{errors.slug && <p>{errors.slug.message}</p>}</h2>

                                            <label>SEO Title</label><br/>
                                            <input className="input" type="text"  {...register("seotitle")}  id="seotitle" placeholder="SEO Title"></input>
                                            <h2>{errors.seotitle && <p>{errors.seotitle.message}</p>}</h2>

                                            <label>SEO Description</label><br/>
                                            <input className="input" type="text"  {...register("seodescription")}  id="seodescription" placeholder="SEO Description"></input>
                                            <h2>{errors.seodescription && <p>{errors.seodescription.message}</p>}</h2>

                                            <label>SEO Keywords</label><br/>
                                            <input className="input" type="text"  {...register("seokeywords")}  id="seokeywords" placeholder="SEO Keywords"></input>
                                            <h2>{errors.seokeywords && <p>{errors.seokeywords.message}</p>}</h2>

                                      </td>
                            </tr>

                          </table>
      

                        {/* }
                        { formStep >= 1 && */}
                        {/* }

                        { formStep >= 2 && */}

                          {/* } */}
                          <hr/>
                          {
                             renderButton()
                          }

                      </form>  
                </Col>
          </Row>
          <hr/>
          <Row>
            <Col md="12">
            <ReactPaginate 
            previousLabel = {'<'} 
            nextLabel = {'>'}
            breakLabel = {'...'}
            pageCount = { Math.ceil(pageCount) }
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}

            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}

            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}

            breakLinkClassName={'page-link'}
            activeClassName={'active'}
        />
          <Table striped bordered hover variant="dark">
          <thead>
                <tr key= { Math.random() }>
                  <th>Status</th>
                  <th>Rule Code</th>
                  <th>Industry</th>
                  <th>Machine Type</th>
                  <th>Sub Type</th>
                  <th>Make</th>
                  <th>Model</th>
                  <th>Edit</th>
                </tr>
          </thead>
          <tbody>

          { 
            items.map((base) => {
            return <>
                  <tr key= { Math.random() }>
                      <td> { base.status }</td>
                      <td> { base.productCode }</td>
                      <td> { base.industry }</td>
                      <td> { base.machineType }</td>
                      <td> { base.subMachineType }</td>
                      <td> { base.make }</td>
                      <td> { base.modelName }</td>
                      <td><button className="selectButton" onClick={(()=> GetBaseMachine(base.productCode))}> Select</button></td>
                  </tr>
               </>
            })                  
          }
          </tbody>
          </Table>
            </Col>
          </Row>

          <Row>
            <Col md="12">
            { 
            productmedia.length > 0  &&
              <>
              Media
              <Table className = "FieldsTable" striped bordered hover variant="dark">
                      <thead>
                            <tr key= { Math.random() }>
                              <th className="tdclass">Type</th>
                              <th className="tdclass">URL</th>
                              <th className="mediaButton">Remove</th>
                            </tr>
                      </thead>
                      <tbody>

                      { 
                        //console.log('Data', users);
                        productmedia.map((item) => {
                          console.log('ITEMS = ',items);
                        return <>
                              <tr key= { Math.random() }>
                                  <td className="mediaType"> { item.mediaType }</td>
                                  <td className="mediaURL"> { item.mediaURL }</td>
                                  <button className="mediaButton" onClick={(e)=>{RemoveMedia(item.id)}}>Rem</button>
                              </tr>
                          </>
                        })                  
                      }
                      </tbody>
                      </Table></>
                  }
            </Col>
          </Row>
      </Container>
    </>
  )
}

export default ProductBaseMachine