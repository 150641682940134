import React, {  useEffect, useState }  from 'react'
import {useLocation} from 'react-router-dom';
import { number, string } from 'yup';
import { Row, Col, Container, Table } from 'react-bootstrap'
import classes from './forms.css'
import { useForm } from 'react-hook-form'
import './forms.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import ProductBaseMachineService from '../../services/ProductBaseMachineService'
import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Modal from '../../components/Modal/Modal'
import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'
import MetaData from '../../services/Data/MetaData'
    


const ProductBaseMachineUpdate = () => {
    const location = useLocation();
    const auth = useAuth();  
    const navigate = useNavigate();
    const [isLogin, setLogin] = useState(false);
    const [RecordAdded, setRecordAdded] = useState(0);
    const [formStep, setFormStep] = useState(0);
    const [machineEdit, setMachineEdit] = useState(0);
     // **************************************************************************************************
    const [modalOpen, setModalOpen] = useState(false);
    const [modalAction, setAction] = useState(false);
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState([]);
    const [MachineTypeSet, setMachineTypeSet] = useState([]);
    const [MachineSubTypeSet, setMachineSubTypeSet] = useState([]);
    const [MakeSet, setMakeSet] = useState([]);
    const [IndustrySet, setIndustrySet] = useState([]);
    const [formattedValidDate1, setformattedValidDate1] = useState();
    const [formattedValidDate2, setformattedValidDate2] = useState();


    let formValues = {
      id: number,
      productCode: string,
      industry: string,
      machineType: string,
      subMachineType: string,
      make: string,
      modelName: string,
      packageDesc: string,
      inventoryMatching: string,
      riversEMSMatching: string,
      regularPrice: number,
      riversPrice: number,
      validDate: Date,
      validToDate: Date,
      advertiseDesc: string,
      disclaimer: string,
      media: string,
      promotionsAvailable: string
    }

    const { 
      register, watch, handleSubmit, 
      // setValue, resetValue,unregister, 
      // resetField, setError, clearErrors, 
      // setFocus, getValues, trigger, control,
      formState: { 
          errors, isValid
         // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating,  submitCount 
      } } 
      = useForm();    
      console.log('location.state.data = ',location.state.data.productCode);
      formValues = {
      id: location.state.data.id,
      productCode: location.state.data.productCode,
      industry: location.state.data.industry,
      machineType: location.state.data.machineType,
      subMachineType: location.state.data.subMachineType,
      make: location.state.data.make,
      modelName: location.state.data.modelName,
      packageDesc: location.state.data.packageDesc,
      inventoryMatching: location.state.data.inventoryMatching,
      riversEMSMatching: location.state.data.riversEMSMatching,
      regularPrice:location.state.data.regularPrice,
      riversPrice: location.state.data.riversPrice,
      validDate: location.state.data.validDate,
      validToDate: location.state.data.validToDate,
      advertiseDesc: location.state.data.advertiseDesc,
      disclaimer: location.state.data.disclaimer,
      media: location.state.data.media,
      promotionsAvailable: location.state.data.promotionsAvailable
    }
    console.log('Date ===>>> ',formValues);
    useEffect(() => {
      const getComments = async () => {
          const dataSet3 = await MetaData.getAllIndustry();
          setIndustrySet(dataSet3);
        
          const dataSet1 = await MetaData.getAllMachineType();
          setMachineTypeSet(dataSet1);

          const dataSet2 = await MetaData.getAllMachineSubType();
          setMachineSubTypeSet(dataSet2);        

          const dataSet4 = await MetaData.getAllMake();
          setMakeSet(dataSet4);        

          const timer = setTimeout(() => {
            document.getElementById('machineType').value= formValues.machineType;
            document.getElementById('subMachineType').value= formValues.subMachineType;
            document.getElementById('industry').value = formValues.industry;
            document.getElementById('make').value = formValues.make;

            var validDate1 = new Date(formValues.validDate.toString());
            var validDate2 = new Date(formValues.validToDate.toString());

            var year = validDate1.toLocaleString("default", { year: "numeric" });
            var month = validDate1.toLocaleString("default", { month: "2-digit" });
            var day = validDate1.toLocaleString("default", { day: "2-digit" });
            // Generate yyyy-mm-dd date string
            var ValidDate1 = year + "-" + month + "-" + day;
            setformattedValidDate1(ValidDate1);

            year = validDate2.toLocaleString("default", { year: "numeric" });
            month = validDate2.toLocaleString("default", { month: "2-digit" });
            day = validDate2.toLocaleString("default", { day: "2-digit" });
            var ValidDate2 = year + "-" + month + "-" + day;
            setformattedValidDate2(ValidDate2);
            //console.log()
            document.getElementById('validDate').value = ValidDate1;
            document.getElementById('validToDate').value = ValidDate2;
          }, 1500);
          return () => clearTimeout(timer);
      };
  
      getComments();  

    }, []);


    const onSubmit = (data, event) => {
    event.preventDefault();
          if (isValid){
                console.log('Data Submitted  = >>>>> ',data);
                console.log('Form Values Submitted  = >>>>> ',formValues);
                // *****************************************************************
                // Calling Service Here to Update the Form Values //formValues
                // *****************************************************************
                ProductBaseMachineService.editProductBaseMachine(formValues).then((basemachine)=>{
                console.log('Base Machine = ', basemachine);
                basemachine.data ? toast.success('Base Machine Edited Successfully',{ position: 'bottom-center'}):toast.info('Base Machine does not exists',{ position: 'bottom-center'});
                setRecordAdded((previousState)=> previousState + 1);
                //navigate('/Products'); 
            })
            .catch((e) => {
                console.log('Edit Base Machine Error', e.message);
                toast.error('Edit Base Machine Error ',{ position: 'bottom-center'})
            });
          }    
    }

    
  return (
    <>
        <h1>Update Base Machine {formValues.productCode}</h1>        
        <br/>
        <Container>
          <Row>
                <Col md="12">
                        <div className="heading">
                            <h5 className="mainheading">Edit Unit - Base Machine</h5>
                            <hr/>
                        </div>                      
                          <form onSubmit={handleSubmit(onSubmit)}>
                          <input hidden  defaultValue = { formValues.id} className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>

                          <table>
                            <tbody>
                            <tr key= { Math.random() }>
                                <td>
                                    <input hidden value = { formValues.productCode}  className="input" type="text" {...register("productCode")} id="productCode" placeholder="Product Code"></input>
                                    <input disabled defaultValue = { formValues.productCode}  className="input" type="text" {...register("productCode")} id="productCode" placeholder="Product Code"></input>
                                </td>
                                <td>
                                        <input defaultValue = { formValues.modelName}  className="input" type="text" {...register("modelName")}  id="modelName" placeholder="Model Name"></input>
                                        <h2>{errors.modelName && <p>{errors.modelName.message}</p>}</h2>
                                </td>
                                <td>
                                              <input  defaultValue = { formValues.riversPrice}  className="input" type="text" {...register("riversPrice")}  id="riversPrice" placeholder="Rivers Price"></input>
                                              <h2>{errors.riversPrice && <p>{errors.riversPrice.message}</p>}</h2>
                                </td>
                            </tr>
                            <tr key= { Math.random() }>
                                <td>
                                      <select defaultValue = { formValues.industry}  
                                              className="select" {...register("industry")} 
                                              id="industry">
                                                <option value="tag">Select Industry</option>
                                                {
                                                   IndustrySet.map((d)=>(
                                          			    <option  key={Math.random()}  value={d.industryName}>{d.industryName}</option>
                                                    ))
                                                  }                                              </select>
                                              <h2>{errors.industry && <p>{errors.industry.message}</p>}</h2>
                                </td>
                                <td>
                                              <input defaultValue = { formValues.packageDesc}   className="input" type="text" {...register("packageDesc")}  id="packageDesc" placeholder="Package Desc"></input>
                                              <h2>{errors.packageDesc && <p>{errors.packageDesc.message}</p>}</h2>
                                </td>
                                <td>
                                              <input defaultValue = {formattedValidDate1} className="input" type="date" {...register("validDate")}  id="validDate" placeholder="Valid Date"></input>
                                              <h2>{errors.validDate && <p>{errors.validDate.message}</p>}</h2>
                                </td>


                                <td>
                                              <input defaultValue={ formattedValidDate2 } className="input" type="date" {...register("validToDate")}  id="validToDate" placeholder="Valid To Date"></input>
                                              <h2>{errors.validToDate && <p>{errors.validToDate.message}</p>}</h2>
                                </td>
                            </tr>
                            <tr key= { Math.random() }>
                                <td>
                                        <select 
                                          className="select" 
                                          defaultValue = { formValues.machineType}  
                                          {...register("machineType") } 
                                          id="machineType">
                                          
                                          <option value="tag">Select Machine Type</option>
                                          <option  key={Math.random()}   value="Select">Select Machine Type</option> 
                                            {
                                                MachineTypeSet.map((d)=>(
                                                  <option  key={Math.random()}  value={d.type}>{d.type}</option>
                                                ))
                                            }
                                        </select>
                                          <h2>{errors.machineType && <p>{errors.machineType.message}</p>}</h2>
                                </td>
                                <td>
                                                  <input  defaultValue = { formValues.inventoryMatching}  className="input" type="text" {...register("inventoryMatching")}  id="inventoryMatching" placeholder="Inventory Matching"></input>
                                                  <h2>{errors.inventoryMatching && <p>{errors.inventoryMatching.message}</p>}</h2>
                                </td>
                                <td>
                                              <input defaultValue = { formValues.advertiseDesc} className="input" type="text" {...register("advertiseDesc")}  id="advertiseDesc" placeholder="Advertise Desc"></input>
                                              <h2>{errors.advertiseDesc && <p>{errors.advertiseDesc.message}</p>}</h2>
                                </td>
                            </tr>
                            
                            <tr key= { Math.random() }>
                                <td>
                                        <select 
                                          className="select"
                                          defaultValue = { formValues.subMachineType}   
                                          {...register("subMachineType") } 
                                          id="subMachineType">
                                          
                                          <option value="tag">Select Sub Type</option>
                                          {
                                            MachineSubTypeSet.map((d)=>(
                                            <option  key={Math.random()}  value={d.subType}>{d.subType}</option>
                                          ))
                                      }
                                        </select>
                                          <h2>{errors.subMachineType && <p>{errors.subMachineType.message}</p>}</h2>
                                </td>
                                <td>
                                                  <input defaultValue = { formValues.riversEMSMatching}  className="input" type="text" {...register("riversEMSMatching")}  id="riversEMSMatching" placeholder="Rivers EMS Matching"></input>
                                                  <h2>{errors.riversEMSMatching && <p>{errors.riversEMSMatching.message}</p>}</h2>
                                </td>
                                <td>
                                              <input defaultValue = { formValues.disclaimer} className="input" type="text" {...register("disclaimer")}  id="disclaimer" placeholder="Disclaimer"></input>
                                              <h2>{errors.disclaimer && <p>{errors.disclaimer.message}</p>}</h2>
                                </td>
                            </tr>

                            <tr key= { Math.random() }>
                                <td>
                                      <select defaultValue = { formValues.make} className="select" {...register("make") } id="make">
                                          <option value="tag">Select Make</option>
                                          {
                                            MakeSet.map((d)=>(
                                            <option  key={Math.random()}  value={d.vendorName}>{d.vendorName}</option>
                                          ))
                                      }                                        </select>
                                          <h2>{errors.make && <p>{errors.make.message}</p>}</h2>
                                </td>
                                <td>
                                                  <input defaultValue = { formValues.regularPrice}  className="input" type="text" {...register("regularPrice")}  id="regularPrice" placeholder="Regular Price"></input>
                                                  <h2>{errors.regularPrice && <p>{errors.regularPrice.message}</p>}</h2>
                                </td>
                                <td>
                                              <input defaultValue = { formValues.media} className="input" type="text" {...register("media")}  id="media" placeholder="Media"></input>
                                              <h2>{errors.media && <p>{errors.media.message}</p>}</h2>
                                </td>
                            </tr>

                              <tr key= { Math.random() }>
                                <td>
                                              <input defaultValue = { formValues.promotionsAvailable} className="input" type="text" {...register("promotionsAvailable")}  id="promotionsAvailable" placeholder="Promotions Available"></input>
                                              <h2>{errors.promotionsAvailable && <p>{errors.promotionsAvailable.message}</p>}</h2>
                                </td>
                              </tr>
                              <tr key= { Math.random() }>
                                <td>
                                    <button className="btn btn-primary" type="submit">Update</button>              
                                </td>
                                <td>
                                    <button className="btn btn-primary" type="button" onClick={()=>navigate('/Products')}>Back</button>
                                </td>
                                <td>
                                    <button disabled className="btn btn-primary" type="button" onClick={()=>alert('Delete disabled')}>Delete</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                      </form>  
                </Col>
          </Row>
      </Container>
      <ToastContainer/>


    </>
  )
}

export default ProductBaseMachineUpdate