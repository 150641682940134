import { usersUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class UserService {
    static  getAllUsers() {
        return axios.get(usersUri)
                    .then((resp)=>resp.data);
    }

    static  getUser(username) {
        return axios.get(usersUri + `/GetUser?username=${username}`)
                    .then((resp)=>resp.data);
    }

    static  checkUser(payload) {
        return axios.post(usersUri + `/CheckUser`,JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static addUser(payload) {
        return axios.post(usersUri, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editUser(payload) {
        return axios.patch(usersUri+ "/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteUser(payload) {
        return axios.post(usersUri + "/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
    static deactivateUser(payload) {
        return axios.patch(usersUri + "/Deactivate", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
    static  getAccessToken() {
        return axios.get(usersUri)
                    .then((resp)=>resp.data);
    }

    
}

export default UserService