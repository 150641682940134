import React, {  useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { string } from 'yup';
import '../forms/forms.css'
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import ModalService from '../../services/ModalService'
import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Modal from '../Modal/Modal'

import { number } from 'yup/lib/locale';

import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'

// *******************************************************************
import ReactPaginate from 'react-paginate'
import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { modalUri, pageSize, BaseURL, PortNumber} from '../../constants'
// ********************************************************************

let formValues = {
    id: number,
    modalName: string,
    year: string,
    month: string,
    day: string,
    description1: string
}
const Model = () => {
    const auth = useAuth();  
    const navigate = useNavigate();
    const [isLogin, setLogin] = useState(false);
    const [RecordAdded, setRecordAdded] = useState(0);

    // **************************************************************************************************
    const [modalOpen, setModalOpen] = useState(false);
    const [modalAction, setAction] = useState(false);
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState([]);
  
    useEffect(() => {
      const getComments = async () => {
        //   const res = await fetch(modalUri);
        //   const data = await res.json();
        const data = await ModalService.getAllModals();

          const total = data.length;
          const TotalPages = Math.ceil(total/pageSize);
          setPageCount(TotalPages);
          setItems(data);

          const PageOneFromServer = await fetchComments(1);
          setItems(PageOneFromServer);
      };
  
      getComments();
  
    },[RecordAdded]);     
    
    const fetchComments = async(currentPage) => {
            // const res = await fetch(`${BaseURL}:${PortNumber}/Modal/GetPageModal?PageNumber=${currentPage}&PageSize=${pageSize}`);
            // const data = await res.json();
            const data = await ModalService.getPagedModal(currentPage, pageSize);

            return data;
        };
          
  
        const handlePageClick = async (data) => {
            let currentPage = data.selected + 1;
            const commentsFromServer = await fetchComments(currentPage);
            setItems(commentsFromServer);
          }
          
    // **************************************************************************************************
    const { 
            register, watch, handleSubmit, 
            // setValue, resetValue,unregister, 
            // resetField, setError, clearErrors, 
            // setFocus, getValues, trigger, control,
            formState: { 
                errors, 
               // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
            } } 
        = useForm({ defaultValues: { id: 0, modalName: '', year: '', month: '', day: '', description1: '' } });

    useEffect(() => {
        const subscription = watch((data)=>{
            console.log(data);
        })
        return ()=> {
            subscription.unsubscribe();
        }
    }, [watch]) 

    useEffect(() => {
        let userLogin = null;
          try
          {
            userLogin = JSON.parse(localStorage.getItem('credentials')).userName;
            AuthService.getForcedLogoutUser(userLogin).then((login)=>{                
                if ((login.loginStatus === 'ForcedLogout') || (login.loginStatus === 'Logout')) {                
                    localStorage.removeItem('credentials')
                    navigate("/Login");
                }});
            UserService.getUser(userLogin).then((user)=>{
                if (user.status === 'InActive') {                
                    localStorage.removeItem('credentials')
                    navigate("/Login");
                }});

      
          }
          catch{}
          
          
          if (userLogin === null || userLogin === undefined) {
            setLogin(false);
            navigate('/Login');
          }
          else
          {
            setLogin(true);
          }
        },[]);

    const onSubmit = (data, event) => {
        event.preventDefault();
        formValues = {
            id: 0,
            modalName: data.modalName.trim(),
            year: data.year,
            month: data.month,
            day: data.day,
            description1: data.description1.trim()
        };
        ModalService.addModal(formValues).then((modal)=>{
            console.log('Form Values = ', formValues);
            console.log('Modal = ', modal);
            toast.success('Modal added successfully',{ position: 'bottom-center'});
            setRecordAdded((previousState)=> previousState + 1);
            clearValueForm();
        }).catch((e) => {
            console.log('Add Modal Error', e.message);
            toast.error('Adding Modal Error ',{ position: 'bottom-center'})
        });
    }
    const updateModal = () =>{
        setValueForm();
         ModalService.editModal(formValues).then((modal)=>{
            modal.data ? toast.success('Modal Edited Successfully',{ position: 'bottom-center'}):toast.info('Profile does not exists',{ position: 'bottom-center'});
            setRecordAdded((previousState)=> previousState + 1);
            clearValueForm();
        })
        .catch((e) => {
            console.log('Edit Modal Error', e.message);
            toast.error('Edit Modal Error ',{ position: 'bottom-center'})
        });
    }
    const deleteModal = () =>{
        // setValueForm();
        // ProfileService.deleteProfile(formValues).then((profile)=> {
        //     profile.data ? toast.success('Profile Delete Successfully .... ',{ position: 'bottom-center'}):toast.info('Profile does not exists',{ position: 'bottom-center'});
        //     clearValueForm();
        // }).catch((e) => {
        //     console.log('Delete Profile Error', e.message);
        //     toast.error('Delete Profile Error ',{ position: 'bottom-center'})
        // });
    }

    const setValueForm = (() => { 
        formValues = {
            id: document.getElementById('id').value,
            modalName: document.getElementById('modalName').value,
            year: document.getElementById('year').value,
            month: document.getElementById('month').value,
            day: document.getElementById('day').value,
            description1: document.getElementById('description1').value
         };
    });
 
    const clearValueForm = (() => {
        formValues = {
            id: '',
            modaName: '',
            year: '',
            month: '',
            day: '',
            description1: ''
         };

         document.getElementById('id').value= '';
         document.getElementById('modalName').value= '';
         document.getElementById('year').value= '';
         document.getElementById('month').value= '';
         document.getElementById('day').value= '';
         document.getElementById('description1').value= '';
    });

    const GetModal = (selectedModal) =>{
        ModalService.getModal(selectedModal.trim()).then((resp) =>{
              const selectedModal =  resp;
              document.getElementById('id').value= resp.id;
              document.getElementById('modalName').value= resp.modalName;
              document.getElementById('year').value= resp.year;
              document.getElementById('month').value= resp.month;
              document.getElementById('day').value= resp.day;
              document.getElementById('description1').value= resp.description1;
              console.log(selectedModal);
          }).catch((e) => {
              console.log('Error', e.message);
          });    
  }

    return (
    <>
        {
            localStorage.getItem('credentials') && 
          <>



      <Container>
        <Row>
            <Col md = "4">
                   <div className="heading">
                        <h5 className="mainheading">Modal</h5>
                        <hr/>
                    </div>              

        
                    <form onSubmit={handleSubmit(onSubmit)}>

                    <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>

                    <input className="input" type="text" {...register("modalName", { required: {value: true, message: "This is required field"}})}  id="modalName" placeholder="Modal Name"></input>
                    <h2>{errors.modalName && <p>{errors.modalName.message}</p>}</h2>

                    <input className="input" type="text" {...register("year", { required: {value: true, message: "This is required field"}})}  id="year" placeholder="Year"></input>
                    <h2>{errors.year && <p>{errors.year.message}</p>}</h2>

                    <input className="input" type="text" {...register("month", { required: {value: true, message: "This is required field"}})}  id="month" placeholder="Month"></input>
                    <h2>{errors.month && <p>{errors.month.message}</p>}</h2>

                    <input className="input" type="text" {...register("day", { required: {value: true, message: "This is required field"}})}  id="day" placeholder="Day"></input>
                    <h2>{errors.day && <p>{errors.day.message}</p>}</h2>

                    <input className="input" type="text" {...register("description1", { required: {value: true, message: "This is required field"}})}  id="description1" placeholder="Description"></input>
                    <h2>{errors.description1 && <p>{errors.description1.message}</p>}</h2>

                    <hr/>
                    <div className="container">
                        <div className="row">
                            <div className="col md-3 sm-3 xs-2">
                                <button className="submit" type="submit">Save</button>
                            </div>
                        </div>
                    </div>
                </form>  
                <hr/>
            </Col>

            <Col md = "2">
            <div className="heading">
            <h5 className="operation">Operations</h5>
            <hr/>
            </div>              

            <div className="container">
                <div className="row">
                    <div className="col md-3 sm-3 xs-2">
                        <button onClick={ updateModal } className="button" type="button">Update</button>
                    </div>

                    {/* <div className="col md-3 sm-3 xs-2">
                        <button onClick={ deleteProfile } className="button" type="button">Delete</button>
                    </div> */}

                    <div className="col md-3 sm-3 xs-2">
                        <button className="button"  onClick={() => { setValueForm(); setModalOpen(true); } }> Delete </button>
                    </div>

                    {    
                        modalOpen && 
                        <Modal 
                            setOpenModal={ setModalOpen } 
                            setAction = { setAction }
                            formValues = { formValues }
                            formName = "Modal"
                            title = "Are you sure to delete ?" 
                            message = "Modal will be deleted !" 
                            setRecordAdded = { setRecordAdded }
                            />
                    }            
                </div>
            </div>
            </Col>

          <Col md="6">
          <ReactPaginate 
            previousLabel = {'<'} 
            nextLabel = {'>'}
            breakLabel = {'...'}
            pageCount = { Math.ceil(pageCount) }
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}

            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}

            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}

            breakLinkClassName={'page-link'}
            activeClassName={'active'}
        />
          <Table striped bordered hover variant="dark">
          <thead>
                <tr key= { Math.random() }>
                  <th>Modal</th>
                  <th>Year</th>
                  <th>Month</th>
                  <th>Day</th>
                  <th>Description</th>
                  <th>Edit</th>
                </tr>
          </thead>
          <tbody>

          { 
            items.map((modal) => {
            return <>
                  <tr key= { Math.random() }>
                      <td> { modal.modalName }</td>
                      <td> { modal.year }</td>
                      <td> { modal.month }</td>
                      <td> { modal.day }</td>
                      <td> { modal.description1 }</td>
                      <td><button className="selectButton" onClick={(()=> GetModal(modal.modalName))}> Select</button></td>
                  </tr>
               </>
            })                  
          }
          </tbody>
          </Table>
        </Col>
        </Row>
      </Container>      

            </>        
        }
    </>
  )
}

export default Model