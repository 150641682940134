import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { string } from 'yup';
import './forms.css'
import bcrypt from 'bcryptjs'
import  AuthService  from '../../services/AuthService'
import UserService from '../../services/UserService'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { useAuth } from '../../utilities/auth'
import { useCookies } from "react-cookie";
import Cookies from 'universal-cookie';

let loginValues = {
    username: string,
    loginDate: Date,
    logoutDate: Date,
    loginStatus: string
}
  
const Login = () => {
const auth = useAuth();
const [passwordMatch,setPasswordMatch] = useState(false);

const { 
            register, watch, handleSubmit, 
            // setValue, resetValue,unregister, 
            // resetField, setError, clearErrors, 
            // setFocus, getValues, trigger, control,
            formState: { 
                errors, 
               // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
            } } 
        = useForm({ defaultValues: { firstName: '', lastName: '' } });

    useEffect(() => {
        const subscription = watch((data)=>{
            console.log(data);
        })
        return ()=> {
            subscription.unsubscribe();
        }
    }, [watch]) 

    // useEffect(()=>{
            // AuthService.getForcedLogoutUser(userLogin).then((login)=>{
            //     if (login.loginStatus === 'ForcedLogout') {                
            //         localStorage.removeItem('credentials')
            //         navigate("/Login");
            //     }});

    // },[])

    const onSubmit = (data, event) => {
        event.preventDefault();
        //console.log('User Name = ', data.userName);
        //const encrypted = bcrypt.hashSync(data.password, 10);        
        //localStorage.setItem('credentials',JSON.stringify({ userName: data.userName, password: encrypted }));
        //const decrypted = JSON.parse(localStorage.getItem('credentials'));
        //console.log('Retrieved From Local',decrypted);
        //console.log('Encrypted =', encrypted);

        // LogIn Logic here
        console.log('User = ', data.userName);   
        console.log('Password = ', data.password);   
        const payload ={
            username: data.userName, 
            password1: data.password
        }
        UserService.checkUser(payload).then((tokenReceived) => {
            console.log('TokenReceived From Database =', tokenReceived);   


            loginValues = {
                username: data.userName,
                loginDate: new Date(),
                logoutDate: new Date("1900","01","00"),
                loginStatus: 'Login'
            }

            console.log('Login Values = ',loginValues);
            // console.log('user.password1',user.password1);
            // console.log('data.password',data.password)

            // bcrypt.compare(data.password,user.password1).then((data) => {
            //     setPasswordMatch(data);
            // });
            
//            if ((user.username === data.userName) && (!passwordMatch) && (user.status === 'Active'))
            if ((tokenReceived))
            {
                console.log('Username is authorized');
                AuthService.signIn(loginValues).then((signIndata)=>{
                    // console.log('user.username = ', user.username);
                    console.log('User signIn successfully.... = ', signIndata);                    
                    auth.login(data.userName);
                    localStorage.setItem('credentials',JSON.stringify({ userName: data.userName, password: tokenReceived }));
                    //localStorage.setItem('roles',JSON.stringify({ role: user.roleId }));
                    localStorage.setItem('roles',JSON.stringify({ role: 'admin' }));

                      const cookies = new Cookies();
                      cookies.set('Username', data.userName, { 
                        path: '/',
                        // 30 Days = 720 Hours or 12*720 = 1 Year
                        expires: new Date(new Date().getTime() + 12*720 * 60 * 60 * 1000),
                        sameSite: 'strict',
                    });
                      cookies.set('Password',tokenReceived, { 
                        path: '/',
                        expires: new Date(new Date().getTime() + 12*720 * 60 * 60 * 1000),
                        // 30 Days = 720 Hours or 12*720 = 1 Year
                        sameSite: 'strict',
                        // httpOnly: true, secure:true 
                        // Http Only can only set at Node or .Net Server API Call
                    });

                    console.log('Username = ',cookies.get('Username')); // Pacman
                      console.log('Password = ',cookies.get('Password')); // Pacman
                    //console.log('Cookie = ',cookies.get("Username"));

                      // Cookies.set("Username",data.userName, {
                    //     expires: new Date(Date.Now + 2589000000),
                    //     httpOnly: true 
                    // })
                    // Cookies.get("user")
                    // Cookies.remove("user")

                    navigate("/");
                });                              
            }
            else
            {
                toast.info('Username/password incorrect or In-Active user',{ position: 'bottom-center'});
                localStorage.removeItem('credentials');
                localStorage.removeItem('roles');
            }
        });

    }
    const navigate = useNavigate();
return (
    <>
        <div className="heading">
            <h2>Login Form</h2>
            <hr/>
        </div>
        
                <form onSubmit={handleSubmit(onSubmit)}>
                <input className="input" type="text" {...register("userName", { required: {value: true, message: "This is required one field"}, minLength: { value: 5, message: "5 is minimum length" } })}  id="userName" placeholder="User Name"></input>
                <h2>{errors.userName && <p>{errors.userName.message}</p>}</h2>

                <input className="input" type="password"   {...register("password", { required: {value: true, message:"This is required"} })} id="password" placeholder="Password"></input>
                <h2>{errors.password && <p>{errors.password.message}</p>}</h2>

                <button className="submit" type="submit">SignIn</button>
            </form>  
    </>
  )
}

export default Login










// const SignOut = (()=>{
    //            // Logout Logic here
    //            var username = document.getElementById('userName').value;

    //            AuthService.getUser(username).then((user)=>{
    //             console.log('User Id =', user);   
    //             loginValues = {
    //                 Id: user.id,
    //                 username: username,
    //                 LoginDate: user.loginDate,
    //                 LogoutDate: new Date(),
    //                 loginStatus: 'Logout'
    //             }

    //             if (user !== null) {
    //                 AuthService.signOut(loginValues).then((data)=>{
    //                     console.log('User signOut successfully = ',data);
    //                 });                              
    //             }   
    //         });    
    // })




                {/* {
                   auth.user && <button className="button" type="button" onClick={SignOut}>SignOut</button>
                } */}
