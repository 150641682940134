import { productDeliveryUri, apiProductDeliveryUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class ProductDeliveryService {

    static  getAllProductsDelivery() {
        return axiosInstance.get('/ProductDelivery')
                    .then((resp)=>resp.data);
    }

    static  getPagedProductsDelivery(currentPage, pageSize) {
        return axiosInstance.get(`/ProductDelivery/GetPageProductDelivery?PageNumber=${currentPage}&PageSize=${pageSize}`)
                    .then((resp)=>resp.data);
    }

    static  getProductDelivery(productId) {
        console.log('productID = ', productId);
        console.log('URI = ',`ProductDelivery/GetProductDelivery?ProductCode=${productId}`);
        
        return axiosInstance.get(`ProductDelivery/GetProductDelivery?ProductCode=${productId}`)
                    .then((resp)=>resp.data);
    }
    static  createProductDelivery(payload) {
        return axiosInstance.post(`/ProductDelivery`, JSON.stringify(payload), {            
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static  editProductDelivery(payload) {
        return axiosInstance.patch('ProductDelivery/Update', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
    
    static  deleteProductDelivery(payload) {
        return axiosInstance.post('ProductDelivery/Delete', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static  deleteProductDeliverySpecial(payload) {
        return axiosInstance.post('ProductDelivery/DeleteSpecial', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default ProductDeliveryService