import React, {  useEffect, useState } from 'react'
import axios from 'axios'

import Card from '../forms/ui/Card'
import { Row, Col, Container, Table } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { string } from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import ProductBaseMachineService from '../../services/ProductBaseMachineService'
import DeereCategoryService from '../../services/DeereCategoryService';

import DeereAPIBufferService from '../../services/DeereAPIBufferService'

import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Modal from '../../components/Modal/Modal'
import { number } from 'yup/lib/locale';
import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'
import { getDeereAccessToken, getRiversAccessToken } from '../../services/SpecialService'
// *******************************************************************
import ReactPaginate from 'react-paginate'
import 'bootstrap/dist/css/bootstrap.min.css'
import { profilesUri, pageSize } from '../../constants'
import MetaData from '../../services/Data/MetaData'
//import taxanomyApi from '../../services/QuickService'

//import App from '../../services/QuickService'

// ********************************************************************

import { EMSAPI } from '../../constants'

import { EMSApiService } from '../../services/EMSApiService'
import { DeereCollectionService } from '../../services/DeereCollectionService'

import { OutboundDeereService } from '../../services/OutboundDeereService'

import  Records  from '../../deer.json'
//import { OutboundEMSService } from '../../services/OutboundEMSService'
import './style.css'
import { IVRiversMatchingService } from '../../services/IVRiversMatchingService'
import { IVDeereMatchingService } from '../../services/IVDeereMatchingService'

let specifications = '';
let features = '';

let deereRecordValues = {
    id: number,
    status: string,
    ruleCode: string,
    language: string,
    country: string,
    sku: string,
    modelNumber: string,
    modelName: string,
    productOverview: string,
    baseCode: string,
    productSystemValue: string,
    optionalLinks: string,
    altText: string,
    defaultURL: string,
    imageURLS: string,
    title: string,
    keywords: string,
    metaDesc: string,
    productPrice: number,
    currency: string,
    disclaimer: string,
    denomLabel: string,
    titleQuestion: string,
    componentDesc: string
}

let formValues = {
    id: number,
    status: string,
    ruleCode: string,
    language: string,
    country: string,
    productSku: string,
    productModelNumber: string,
    productModelName: string,
    productBaseCode: string,
    productSummary: string,
    productOverview: string,
    productIndustry: string,
    productPath: string,
    productImage: string,
    categoryIndustry: string,
    recommendedByPercentage: string,
    recommendedByLabel: string,
    bazaarVoiceStarRatingUrl: string,
    productPriceDisclaimer: string,
    startingAtLabel: string,
    productSystemValue: string,
    productPriceDenominationLabel: string,
    imageGalleryContainer: string,
    disclaimer: string,
    disclaimerContainer: string,
    title: string,
    description: string,
    keywords: string,
    specifications: string,
    features: string
}

const ProcessNewDeereAPI = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [DeereEdit, setDeereEdit] = useState(0);
    const [DeereView, setDeereView] = useState(0);
    const [TheCategoryName, setCategoryName] = useState();
    const [isLogin, setLogin] = useState(false);
    const [SwitchPublished, setSwitchPublished] = useState(0);
    
    const [RecordAdded, setRecordAdded] = useState(0);
    const [check1, setCheck1] = useState();
    const [RuleSet, setRuleSet] = useState([]);
    const [DeereCategories, setDeereCategories]=useState([]);
    const [DeereIndustryCategory, setDeereIndustryCategory]=useState([]);

    let BulkSet = [];
    let filterToSearch = {}

     // **************************************************************************************************
    const [items, setItems] = useState([]);

    const [pageCount, setPageCount] = useState([]);
    const [Published, setPublished]=useState('Not Published');
    const [PublishedDeere, setPublsihedDeere] = useState(0);
    const [FilteredRecordState,setFilteredRecordState] = useState();

    const [fields, setFields] = useState([]);
    const [uri, setUri] = useState('');
    const keys = [];

    const [readyDeere, setReadyDeere] = useState(0);

    const [SelectedRecord, setSelectedRecord] = useState();
    const [SelectedLogic,setSelectedLogic] = useState("AND");
    const [PublishedRecord, setPublishedRecord] = useState([]);
    const [BufferedRecord, setBufferedRecord] = useState();

    const [RuleName, setRuleName] = useState();

    const [Condition1, setCondition1] = useState();
    const [Condition2, setCondition2] = useState();
    const [Condition3, setCondition3] = useState();
    const [Condition4, setCondition4] = useState(false);
    const [Condition5, setCondition5] = useState(false);
    const [totalAPIProcessed, setTotalAPIProcessed] = useState(0);
    const [taxonomyData, setTaxonomyData] = useState([]);
    const [finalResult, setFinalResult] = useState([]);
    const [finalPrint, setFinalPrint] = useState();

    const [MetaDataTitle,setMetaDataTitle] = useState();
    const [MetaDataDescription,setMetaDataDescription] = useState();
    const [MetaDataKeywords,setMetaDataKeywords] = useState();
    const [ProductOverview,setProductOverview] = useState();
    const [ProductDescription,setProductDescription] = useState();
    const [ProductBaseCode,setProductBaseCode] = useState();
    const [ProductModelNumber,setProductModelNumber] = useState();
    const [RecommendedByPercentage,setRecommendedByPercentage] = useState();
    const [RecommendedByLabel,setRecommendedByLabel] = useState();
    const [BazaarVoiceStarRatingUrl,setBazaarVoiceStarRatingUrl] = useState();
    const [StartingAtLabel,setStartingAtLabel] = useState();
    const [ProductSystemValue,setProductSystemValue] = useState();
    const [ProductPriceDenominationLabel,setProductPriceDenominationLabel] = useState();
    const [DisclaimerContainer,setDisclaimerContainer] = useState();
    const [ProductImage,setProductImage] = useState();
    const [CategoryIndustry,setCategoryIndustry] = useState();
    const [ProductModelName,setProductModelName] = useState();
    const [ProductPath,setProductPath] = useState();
    const [ProductIndustry,setProductIndustry] = useState();
    const [ProductSKU,setProductSKU] = useState();
    const [ImageGalleryContainer,setImageGalleryContainer] = useState();
    const [FORMVALUE,SetFORMVALUE] = useState({});
    const [TotalFound, setTotalFound]=useState('');
    const [Specifications, SetSpecifications] = useState('');
    const [Features, SetFeatures] = useState('');

    const [TotalRecordsFound, setTotalRecordsFound]=useState(0);
    const [TotalRecordsPages, setTotalRecordsPages]=useState(0);

    let intermediateResultSet = {}
    let Acategories = [];

    // **************************************************************************************************
        useEffect(() => {
          const getComments = async () => {
              // const data = await EMSApiService.getAll();
              //const thedata = await OutboundDeereService.getAllDeere();

              if (DeereIndustryCategory === null){
                const thedata = await DeereAPIBufferService.getAPIIndustry('');
                const total = thedata.length;
                const TotalPages = Math.ceil(total/pageSize);
                setTotalFound(total + ' Un-published in ' + TotalPages + ' Pages');
                setTotalRecordsFound(total);
                setTotalRecordsPages(TotalPages);

                setPageCount(TotalPages);
                setFinalResult(thedata);
                console.log('Data = ',thedata);
              }
              else
              {
                const thedata = await DeereAPIBufferService.getAPIIndustry(DeereIndustryCategory);
                const total = thedata.length;
                const TotalPages = Math.ceil(total/pageSize);
                setPageCount(TotalPages);
                setFinalResult(thedata);
                console.log('Data = ',thedata);
              }

              const PageOneFromServer = await fetchComments(1);
              setItems(PageOneFromServer);

          };
          getComments();

          MetaData.getAllCategories().then(data=>{
            setDeereCategories(data);
            console.log('DA=>',data);
          });

          MetaData.getAllRules().then(data=> {
            setRuleSet(data.filter(d =>
                { return(
                        (d.status === "Enable") ) }));
            });

        }, [RecordAdded]);

        // useEffect(()=>{
        //     console.log('ProductModelNumber = ',ProductModelNumber);
        // },ProductModelNumber)

        // useEffect(() => {
        //     //console.log('Effective Final Data  => ',finalResult);
        // },[finalResult]);

        // useEffect(()=>{
        //     console.log('Effective Final Data  => ',finalResult);
        //     //setSelectedRecord(finalResult);
        //     setFinalResult(finalResult);
        // },[finalResult])

        const fetchComments = async(currentPage) => {
                let data
                if (DeereIndustryCategory !== null){
                    data = await DeereAPIBufferService.getPagedAPI(currentPage, pageSize, DeereIndustryCategory);
                }
                else
                {
                    data = await DeereAPIBufferService.getPagedAPI(currentPage, pageSize, 'construction');
                }
                return data;
            };


            const handlePageClick = async (data) => {
                console.log('DATA.......',data)
                let currentPage = data.selected + 1;
                const commentsFromServer = await fetchComments(currentPage);
                setFinalResult(commentsFromServer);
                //setItems(commentsFromServer);
              }

        // **************************************************************************************************

    const {
      register, watch, handleSubmit,
      // setValue, resetValue,unregister,
      // resetField, setError, clearErrors,
      // setFocus, getValues, trigger, control,
      formState: {
          errors, isValid
         // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating,  submitCount
      } }
      = useForm({
        mode: "all",
        // defaultValues: { id: 0, productCode: '', modelName: '', packageDesc: '', inventoryMatching: '', riversEMSMatching: '', regularPrice: 0, riversPrice: 0, validDate: '1900-01-01', advertiseDesc: '', disclaimer: '', media: '', promotionsAvailable: '' }
      });

      useEffect(() => {
        const published =(async ()=>{
            await OutboundDeereService.getStatusDeere('Buffered').then(data=>{
                setPublishedRecord(data);
            });

            //setPublishedRecord({...PublishedRecord,thedata});
                
            // const thebuffer =  OutboundDeereService.getStatusDeere("Buffered");
            // setBufferedRecord(thebuffer);                
        })
    }, [])

    function convertXmlToJson(xmlString) {
        const jsonData = {};
        for (const result of xmlString.matchAll(/(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm)) {
            const key = result[1] || result[3];
            const value = result[2] && convertXmlToJson(result[2]); //recusrion
            jsonData[key] = ((value && Object.keys(value).length) ? value : result[2]) || null;
        }
        return jsonData;
    }

    const  PublishDeere = async () => {
        console.log('Record Phone = >> ',deereRecordValues);
                if (deereRecordValues){
                    OutboundDeereService.createDeere(deereRecordValues).then(()=>{
                        setRecordAdded(deereRecordValues);
                        console.log('Record Created ===> ',deereRecordValues);
                        setPublished('Published');
                        toast.success('Published Successfully',{ position: 'bottom-center'});
                    }).catch((e) => {
                        console.log('Adding EMS Record Error', e.message, deereRecordValues.sku);
                    })
                }

                const thedata = await OutboundDeereService.getAllDeere();
                console.log('The Length 2=',thedata);
                setPublishedRecord(thedata);
                //setPublishedRecord({...PublishedRecord,thedata});    
                OutboundDeereService.getAllDeere().then((data)=>{
                    setPublsihedDeere(data.length);
                })
    }

    const nullIf = (value)=>{
        if (value=== null) {
            return '-';
        }
        else
        {
            var thevalue = value !== undefined? value.toString():"-"
            return thevalue;
        }
    }

    const ProcessAPIURL = async (URIPath) =>{
        console.log(URIPath);
    }

    const  PublishLogicalDeere = async () => {
        formValues ={
            id: number,
            status: string,
            ruleCode: string,
            language: string,
            country: string,
            productSku: string,
            productModelNumber: string,
            productModelName: string,
            productBaseCode: string,
            productSummary: string,
            productOverview: string,
            productIndustry: string,
            productPath: string,
            productImage: string,
            categoryIndustry: string,
            recommendedByPercentage: string,
            recommendedByLabel: string,
            bazaarVoiceStarRatingUrl: string,
            productPriceDisclaimer: string,
            startingAtLabel: string,
            productSystemValue: string,
            productPriceDenominationLabel: string,
            imageGalleryContainer: string,
            disclaimer: string,
            disclaimerContainer: string,
            title: string,
            description: string,
            keywords: string,
            specifications: string,
            features: string
        }

        const deletedResult = await DeereAPIBufferService.deleteAPI();
        console.log('Records Deleted => ', deletedResult)

        // Here place API Call for all the URL in Buffer and get data to be pushed
        let URLResults;
        let counter = 0;
        const theCategories = await DeereCategoryService.getAllCategoryies();

        theCategories.map(async (cats)=>{
            // Starts Here
            // ********************************************************************
            URLResults = await DeereAPIBufferService.getAPICategory(cats.categoryName)
            //console.log('THE CAT NAME =>',cats.categoryName);
            //console.log('URLResults ======>',cats,URLResults);
            URLResults.map(async (data)=>{
                //console.log('URL => ', data.apiPath);

                await axios.get(data.apiPath)
                .then(res => {
                  const results = res.data.Page;
                  if (results !== undefined){
                    setTotalAPIProcessed(counter++);;
                  }
                    const FullImageObject1 = results['product-summary'].ImageGalleryContainer;
                    const ImageJson = JSON.stringify(FullImageObject1);
                    const ImageSmall = results['product-summary'].ImageGalleryContainer.ImageSamll !== undefined ? results['product-summary'].ImageGalleryContainer.ImageSamll:'No Small Image';
                    const ImageMedium = results['product-summary'].ImageGalleryContainer.ImageMedium !==undefined ? results['product-summary'].ImageGalleryContainer.ImageMedium:'No Medium Image';
                    const ImageLarge = results['product-summary'].ImageGalleryContainer.ImageLarge !==undefined ? results['product-summary'].ImageGalleryContainer.ImageLarge:'No Large Image';
                    const ImageThumbnail = results['product-summary'].ImageGalleryContainer.ImageThumbnail !==undefined ? results['product-summary'].ImageGalleryContainer.ImageThumbnail:'No Thumbnail';

                    formValues = {
                        id: 0,
                        status: 'Published',
                        ruleCode: 'All',
                        language: 'en',
                        country: 'us',
                        productSku: nullIf(results.analytics.MetaData['product-sku']),
                        productModelNumber: nullIf(results['product-summary'].ProductModelNumber),
                        productModelName: nullIf(ProductModelName),
                        productBaseCode: nullIf(ProductBaseCode),
                        productSummary: nullIf(ProductDescription),
                        productOverview: nullIf(ProductOverview),
                        productIndustry: nullIf(results.analytics.MetaData['product-industry']),
                        productPath: nullIf(results.analytics.MetaData['product-path']),
                        productImage: nullIf(results.analytics.MetaData['product-image']),
                        categoryIndustry: nullIf(results.analytics.MetaData['category-industry']),
                        recommendedByPercentage: nullIf(results['product-summary'].RecommendedByPercentage),
                        recommendedByLabel: nullIf(results['product-summary'].RecommendedByLabel),
                        bazaarVoiceStarRatingUrl: nullIf(results['product-summary'].BazaarVoiceStarRatingUrl),
                        productPriceDisclaimer: '-',
                        startingAtLabel: nullIf(results['product-summary'].StartingAtLabel),
                        productSystemValue: nullIf(results['product-summary'].ProductSystemValue),
                        productPriceDenominationLabel: nullIf(ProductPriceDenominationLabel),
                        imageGalleryContainer: nullIf(ImageJson),//nullIf(ImageSmall + "," + ImageMedium + "," + ImageLarge + "," + ImageThumbnail),
                        disclaimer: '-',
                        disclaimerContainer: results.disclaimer.DisclaimerContainer !== undefined ? nullIf(results.disclaimer.DisclaimerContainer.Description):'-',
                        title: nullIf(results.MetaData.Title),
                        description: nullIf(results.MetaData.Description),
                        keywords: nullIf(results.MetaData.Keywords),
                        specifications: 'A3',
                        features: 'B3'
                    }

                    //console.log('Form Value For ==>' + data.apiPath ,formValues);
                    if (formValues){
                        BulkSet.push(formValues);
                    }
                })


                console.log('Inserting BulkSet Now = >> ',BulkSet);
                await OutboundDeereService.bulkDeere(BulkSet).then(()=>{
                    //setRecordAdded(BulkSet);
                    console.log('Record Created For ',data.product, ' ==>',BulkSet);
                    //setPublished('Published');
                }).catch((e) => {
                    console.log('Adding Deere Record Error', e.message, formValues.sku);
                })

                // Record Insertion Logic Ends Here

            })


            // ********************************************************************
            // Ends Here
        })

        toast.success('Published Successfully',{ position: 'bottom-center'});


        // URLResults.map(async (rec)=> {
        //     await ProcessAPIURL(rec.apiPath)
        // });


        //     const timer = setTimeout(async () => {
        //     const thedata = await OutboundDeereService.getAllDeere();
        //     setPublishedRecord(thedata);
        //     OutboundDeereService.getAllDeere().then((data)=>{
        //         setPublsihedDeere(data.length);
        //     })
        //     }, 1000);
        //   return () => clearTimeout(timer);

        }

    const  UnPublishSingleDeere =   (BufferId) => {
        DeereAPIBufferService.UnPublishAPI(BufferId).then((record)=>{
            console.log('Records are updated for API Buffer => ',record)
        }).catch((error)=>{
            console.log(error)
        })

        CheckProcessedIndustry(DeereIndustryCategory);

    }

    const  PublishSingleDeere =   async (BufferId,API) => {
        DeereAPIBufferService.PublishAPI(BufferId).then((record)=> {
            console.log('Record published @ API Buffer => ',record)
        }).catch((error)=>{
            console.log(error)
        })

        CheckProcessedIndustry(DeereIndustryCategory);
        const thedata = await DeereAPIBufferService.getAPIIndustry(DeereIndustryCategory);
        const total = thedata.length;
        const TotalPages = Math.ceil(total/pageSize);
        setPageCount(TotalPages);
      //  setItems(thedata);
        setFinalResult(thedata);
        toast.success('Published Successfully',{ position: 'bottom-center'});

        formValues = {
            id: number,
            status: string,
            ruleCode: string,
            language: string,
            country: string,
            productSku: string,
            productModelNumber: string,
            productModelName: string,
            productBaseCode: string,
            productSummary: string,
            productOverview: string,
            productIndustry: string,
            productPath: string,
            productImage: string,
            categoryIndustry: string,
            recommendedByPercentage: string,
            recommendedByLabel: string,
            bazaarVoiceStarRatingUrl: string,
            productPriceDisclaimer: string,
            startingAtLabel: string,
            productSystemValue: string,
            productPriceDenominationLabel: string,
            imageGalleryContainer: string,
            disclaimer: string,
            disclaimerContainer: string,
            title: string,
            description: string,
            keywords: string,
            specifications: string,
            features: string
        }

        // Call API here

        await axios.get(API)
        .then(res => {
          const results = res.data.Page;

          if (results !== undefined) {
            console.log('The Results of API =', results)
          }

            const FullImageObject1 = results['product-summary'].ImageGalleryContainer;
            const ImageJson = JSON.stringify(FullImageObject1);

            const ImageSmall = results['product-summary'].ImageGalleryContainer.ImageSamll !== undefined ? results['product-summary'].ImageGalleryContainer.ImageSamll:'No Small Image';
            const ImageMedium = results['product-summary'].ImageGalleryContainer.ImageMedium !==undefined ? results['product-summary'].ImageGalleryContainer.ImageMedium:'No Medium Image';
            const ImageLarge = results['product-summary'].ImageGalleryContainer.ImageLarge !==undefined ? results['product-summary'].ImageGalleryContainer.ImageLarge:'No Large Image';
            const ImageThumbnail = results['product-summary'].ImageGalleryContainer.ImageThumbnail !==undefined ? results['product-summary'].ImageGalleryContainer.ImageThumbnail:'No Thumbnail';

            const accessCode = document.getElementById('accesscode').value

            formValues.id = 0;
                formValues.status = 'Buffered';
                formValues.ruleCode = 'All';
                formValues.language = 'en';
                formValues.country = 'us';
                formValues.productSku = nullIf(results.analytics.MetaData['product-sku']);
//                productModelNumber: nullIf(results['product-summary'].ProductModelNumber),
                formValues.productModelNumber = nullIf(results.analytics.MetaData['product-model-number']);
                formValues.productModelName = nullIf(results.analytics.MetaData['product-model-name']);
                formValues.productBaseCode = nullIf(results.analytics.MetaData['product-base-code']);
                formValues.productSummary = nullIf(results['product-summary'].Description);
                formValues.productOverview = nullIf(results['product-summary'].ProductOverview);
                formValues.productIndustry = nullIf(results.analytics.MetaData['product-industry']);
                formValues.productPath = nullIf(results.analytics.MetaData['product-path']);
                formValues.productImage = nullIf(results.analytics.MetaData['product-image']);
                formValues.categoryIndustry = nullIf(results.analytics.MetaData['category-industry']);
                formValues.recommendedByPercentage = nullIf(results['product-summary'].RecommendedByPercentage);
                formValues.recommendedByLabel = nullIf(results['product-summary'].RecommendedByLabel);
                formValues.bazaarVoiceStarRatingUrl = nullIf(results['product-summary'].BazaarVoiceStarRatingUrl);
                formValues.productPriceDisclaimer = '-';
                formValues.startingAtLabel = nullIf(results['product-summary'].StartingAtLabel);
                formValues.productSystemValue = nullIf(results['product-summary'].ProductSystemValue);
                formValues.productPriceDenominationLabel = nullIf(results['product-summary'].ProductPriceDenominationLabel);
                formValues.imageGalleryContainer = nullIf(ImageJson); //nullIf(ImageSmall + "," + ImageMedium + "," + ImageLarge + "," + ImageThumbnail),
                formValues.disclaimer = '-';
                formValues.disclaimerContainer = results.disclaimer.DisclaimerContainer !== undefined ? nullIf(results.disclaimer.DisclaimerContainer.Description):'-';
                formValues.title = nullIf(results.MetaData.Title);
                formValues.description = nullIf(results.MetaData.Description);
                formValues.keywords = nullIf(results.MetaData.Keywords);
                formValues.specifications = 'Initialized';
                formValues.features = 'Initialized';
                
                console.log('FORM VALUES ARE After ===>',formValues);

                if (formValues){
                    OutboundDeereService.createDeere(formValues).then((data)=>{
                    setRecordAdded(formValues);
                    console.log('Features Created ===> ',data);
                    setPublished('Published');
                    }).catch((e) => {
                    console.log('Adding Features Error', e.message, formValues.sku);
                    })
                }



                const authAxio1 = axios.create({
                    baseURL: 'https://servicesext.deere.com/ddf/api/component',
                    headers: {
                        Authorization: `Bearer ${accessCode}`,
                    },
                });
    
                authAxio1.post('https://servicesext.deere.com/ddf/api/component', {
                    "sku": results.analytics.MetaData['product-sku'],
                    "languageCode": "en",
                    "countryCode": "us",
                    "component": "specifications"
                })
                .then((res)=>{
                    specifications = JSON.stringify(res.data.Specifications);
                    console.log('Component Specifications: =>',specifications);
                    formValues.specifications = nullIf(JSON.stringify(res.data.Specifications) !== undefined || JSON.stringify(res.data.Specifications) !== null ? JSON.stringify(res.data.Specifications):'-');                
                    console.log('FORM VALUES ARE at 1 ===>',formValues);

                    const payload = {
                        SKU: results.analytics.MetaData['product-sku'],
                        Features: formValues.specifications,
                        UpdateSwitch: "2"
                    }

                    OutboundDeereService.editDeereFeature(payload).then(()=>{
                            console.log('Sepcifications Updated ===> ');
                        }).catch((e) => {
                            console.log('Sepcifications Updates Error', e.message, formValues.sku);
                        })
                })
                
                authAxio1.post('https://servicesext.deere.com/ddf/api/component', {
                    "sku": results.analytics.MetaData['product-sku'],
                    "languageCode": "en",
                    "countryCode": "us",
                    "component": "features"
                })
                .then((res)=>{
                    features = JSON.stringify(res.data.Features);
                    console.log('Component Features: =>',features);
                    formValues.features = nullIf(JSON.stringify(res.data.Features) !== undefined || JSON.stringify(res.data.Features) !== null ? JSON.stringify(res.data.Features) : '-');                            
                    console.log('FORM VALUES ARE at 2 ===>',formValues);

                    const payload = {
                        SKU: results.analytics.MetaData['product-sku'],
                        Features: formValues.features,
                        UpdateSwitch: "1"
                    }

                    OutboundDeereService.editDeereFeature(payload).then(()=>{
                            console.log('Sepcifications Updated ===> ');
                        }).catch((e) => {
                            console.log('Sepcifications Updates Error', e.message, formValues.sku);
                        })

                })



        })

        if (DeereIndustryCategory !== null){
            const theIndustryBuffer = await DeereAPIBufferService.getAPIIndustry(DeereIndustryCategory);
            const total1 = theIndustryBuffer.length;
            const TotalPages1 = Math.ceil(total1/pageSize);
            // console.log('Buffer + Length =',theIndustryBuffer, total, ' Pages = ', TotalPages);
            setPageCount(TotalPages1);
            setFinalResult(theIndustryBuffer);
        }

        await DisplayOutboundDeere('Published');
}

const ClearOutboundDeere = async () => {
    await OutboundDeereService.deleteAllDeere();

    const thedata = await OutboundDeereService.getAllDeere();
    console.log('The Length 2=',thedata);
    setPublishedRecord(thedata);
    //setPublishedRecord({...PublishedRecord,thedata});    
    OutboundDeereService.getAllDeere().then((data)=>{
        setPublsihedDeere(data.length);
    })
    toast.success('Cleared Successfully',{ position: 'bottom-center'});

}
    const  DisplayOutboundDeere = async (State) => {
        await OutboundDeereService.getStatusDeere(State).then(data=>{
            console.log('The Length 3=',data, State);
            setPublishedRecord(data);
        });
        //setPublishedRecord({...PublishedRecord,thedata});    
        if (State === 'Published'){
            setSwitchPublished(1);
        }
        else 
        {
            setSwitchPublished(0);
        }

        // OutboundDeereService.getStatusDeere('Buffered').then((data)=>{
        //     setPublsihedDeere(data.length);
        // })

}

const DEF_DELAY = 1000;

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms || DEF_DELAY));
}


const ProcessDeereIndustry = async(e)=>{
    setDeereIndustryCategory(e);

    const theIndustryBuffer = await DeereAPIBufferService.getAPIIndustry(e);
    const total = theIndustryBuffer.length;
    const TotalPages = Math.ceil(total/pageSize);
    setTotalFound(total + ' Un-published in ' + TotalPages + ' Pages');
    setTotalRecordsFound(total);
    setTotalRecordsPages(TotalPages);

    //console.log('Buffer + Length =',theIndustryBuffer, total, ' Pages = ', TotalPages);
    setPageCount(TotalPages);
    setFinalResult(theIndustryBuffer);

    MetaData.getIndustryCategoryies(e).then(async data=>{
        setDeereCategories(data);
        data.map(async (theCat)=>{
           // Here pushing all respective categories of selected industry in BufferAPI if it does not exists
           // Duplication process is working fine in API and not pushing again
            await ProcessDeere(e,theCat.categoryName)
            //console.log('DA=>',theCat.categoryName);
        })
      });

    await  CheckProcessedIndustry(e);

}

const CheckProcessedDeere = async (e)=>{
    const theResults = await DeereAPIBufferService.getAPICategory(e);
    console.log(theResults);
    setFinalResult(theResults);
    // Newly added
    const total = theResults.length;
    const TotalPages = Math.ceil(total/pageSize);
    setPageCount(TotalPages);
    setFinalResult(theResults);

    setTotalFound(total + ' Un-published in ' + TotalPages + ' Pages');
    setTotalRecordsFound(total);
    setTotalRecordsPages(TotalPages);

}

const CheckProcessedIndustry = async (e)=>{
    const theResults = await DeereAPIBufferService.getAPIIndustry(e);
    console.log(theResults);
    setFinalResult(theResults);
}
    const  ProcessDeere = async (IndustryName, categoryName) => {

    setCategoryName(categoryName);
    console.log('Processing Deere')

    const accessCode = document.getElementById('accesscode').value

    await taxonomyApi(accessCode,IndustryName,categoryName);
    // await productSummaryApi(accessCode)
    // await mediaApi(accessCode)
    // await metadataApi(accessCode)
    // await productPriceApi(accessCode)
    // await componentApi(accessCode)

    // const thedata = await OutboundDeereService.getAllDeere();
    // setPublishedRecord(thedata);
    // //setSelectedRecord(thedata);
    // OutboundDeereService.getAllDeere().then((data)=>{
    //     setPublsihedDeere(data.length);
    // })


    // const theAccessCode = await getDeereAccessToken()
    // console.log('Access Code ===>>> ', theAccessCode);

    // const accessCode = await DeereCollectionService.getAccessTokenV2();
    // console.log('Access Code ===>>> ', accessCode);

    // const AC = DeereCollectionService.Oauth2AccessToken();
    // console.log('AC = > ', AC);

    // const accessCode = await DeereCollectionService.Oauth2AccessToken();
    // console.log('Access Code ===>>> ', accessCode);

    // const taxonomy = await DeereCollectionService.getTaxonomy(payload1);
    // console.log('Taxonomy ===>>> ', taxonomy);

    //   const products = await DeereCollectionService.getProducts(payload1);
    //   console.log('Products = ', products);


}

const productSummaryApi = async (tokenStr) => {
    const webApiUrl = 'https://servicesext.deere.com/ddf/api/productsummary';

    const authAxio = axios.create({
        baseURL: webApiUrl,
        headers: {
            Authorization: `Bearer ${tokenStr}`,
        },
    });
    // bb20lxf, pr11xf
    // bb20xf
    // bb50lxf
    // bb50xf
    // bl11xf
    await authAxio.post(webApiUrl, {
        "sku": "bb50lxf",
        "languageCode": "en",
        "countryCode": "us"
    })
    .then((res)=>{
        console.log('Product Summary Data direct => ',res.data);
        const productSummaryData = res.data;
        const BaseCode = res.data.BaseCode
        const ProductModelName = res.data.ProductModelName
        const ProductModelNumber = res.data.ProductModelNumber
        const ProductOverview = res.data.ProductOverview
        const ProductSystemValue = res.data.ProductSystemValue

        console.log(BaseCode + ', ', ProductModelName + ", " + ProductModelNumber + ", " + ProductOverview + ", " + ProductSystemValue);
        let Object1 = {
            BaseCode, ProductModelName, ProductModelNumber, ProductOverview, ProductSystemValue
        }

        intermediateResultSet = {...intermediateResultSet, BaseCode, ProductModelName, ProductModelNumber, ProductOverview, ProductSystemValue}

        //setFinalResult([intermediateResultSet]);

        return productSummaryData;
    })
    .catch (function (error) {
    console.log(error);
    return error;
    });
}

const mediaApi = async (tokenStr) => {
    const webApiUrl = 'https://servicesext.deere.com/ddf/api/media';

    const authAxio = axios.create({
        baseURL: webApiUrl,
        headers: {
            Authorization: `Bearer ${tokenStr}`,
        },
    });
    // bb20lxf, pr11xf
    // bb20xf
    // bb50lxf
    // bb50xf
    // bl11xf
    await authAxio.post(webApiUrl, {
        "sku": "bb50lxf",
        "languageCode":"en",
        "countryCode": "us",
        "mediaType": "images"
    })
    .then((res)=>{
        console.log('Media data direct => ',res.data);
        const mediaData = res.data;
        const altText = res.data.altText;;
        const imageURL = res.data.imageURLs;
        const defaults = res.data.default;
        const imageURLs = imageURL[0].altText + "," + imageURL[0]["768x432"] + "," + imageURL[0]["1024x576"] + "," + imageURL[0]["1366x768"]
        console.log('altText => ' + altText)
        console.log('defaults => ' + defaults);
        console.log('imageURLs => ' + imageURLs);

        //console.log(imageURL[0])
        // console.log(imageURL[0].altText);
        // console.log(imageURL[0]["768x432"]);
        // console.log(imageURL[0]["1024x576"]);
        // console.log(imageURL[0]["1366x768"]);
        let Object2 = {
            altText,defaults,imageURLs
        };
        intermediateResultSet = {...intermediateResultSet, altText,defaults,imageURLs }
        //setFinalResult([intermediateResultSet]);

        return mediaData;
    })
    .catch (function (error) {
        console.log(error);
       return error;
    });
}


const metadataApi = async (tokenStr) => {
    const webApiUrl = 'https://servicesext.deere.com/ddf/api/metadata';

    const authAxio = axios.create({
        baseURL: webApiUrl,
        headers: {
            Authorization: `Bearer ${tokenStr}`,
        },
    });
    // bb20lxf, pr11xf
    // bb20xf
    // bb50lxf
    // bb50xf
    // bl11xf
    await authAxio.post(webApiUrl, {
        "sku": "bb50lxf",
        "languageCode":"en",
        "countryCode": "us"
    })
    .then((res)=>{
        console.log('Metadata direct => ',res.data);
        const metaData = res.data;
        const  Title = res.data.Title;
        const Keywords = res.data.Keywords;
        const Description = res.data.Description;
        console.log(Title + ", " + Keywords + ", " + Description);

        let Object3 = {
            Title,Keywords,Description
        };
        intermediateResultSet = {...intermediateResultSet, Title,Keywords,Description }

        //setFinalResult([intermediateResultSet]);


        return metaData;
    })
    .catch (function (error) {
        console.log(error);
       return error;
    });
}

const productPriceApi = async (tokenStr) => {
    const webApiUrl = 'https://servicesext.deere.com/ddf/api/productprice';

    const authAxio = axios.create({
        baseURL: webApiUrl,
        headers: {
            Authorization: `Bearer ${tokenStr}`,
        },
    });
    // bb20lxf, pr11xf
    // bb20xf
    // bb50lxf
    // bb50xf
    // bl11xf
    await authAxio.post(webApiUrl, {
        "sku": "bb50lxf",
        "languageCode":"en",
        "countryCode": "us"
    })
    .then((res)=>{
        console.log('Product Price direct => ',res.data);
        const productPriceData = res.data;
        const currency = res.data.currency;
        const denominationLabel = res.data.denominationLabel;
        const disclaimer = res.data.disclaimer;
        const productPrice = res.data.productPrice;
        console.log(currency + ", " + denominationLabel + ", " + disclaimer + ", " + productPrice);

       let Object4 = {
            currency,denominationLabel,disclaimer, productPrice
        };
        intermediateResultSet = {...intermediateResultSet, currency,denominationLabel,disclaimer, productPrice }

        //setFinalResult([intermediateResultSet]);

        return productPriceData;
    })
    .catch (function (error) {
        console.log(error);
       return error;
    });
}

const componentApi = async (tokenStr) => {
    const webApiUrl = 'https://servicesext.deere.com/ddf/api/component';

    const authAxio = axios.create({
        baseURL: webApiUrl,
        headers: {
            Authorization: `Bearer ${tokenStr}`,
        },
    });
    // bb20lxf, pr11xf
    // bb20xf
    // bb50lxf
    // bb50xf
    // bl11xf

    await authAxio.post(webApiUrl, {
        "sku": "bb50lxf",
        "languageCode":"en",
        "countryCode": "us",
        "component": "features"
    })
    .then((res)=>{
        console.log('Component direct => ',res.data.Features);
        let componentData = {}
        res.data.Features.map((da)=>{
            componentData = da.TitleQuestion + "," + componentData
        });
        console.log('componentData = ',componentData);

        let Object5 = {
            componentData
        };
        intermediateResultSet = {...intermediateResultSet, componentData }
        //setFinalResult([intermediateResultSet]);
        setFinalPrint(true);
        return componentData;
    })
    .catch (function (error) {
       return error;
    });
}

const taxonomyApi= async (tokenStr,IndustryName, catName) => {
    let payloads = []
    let categories = []
    let en_us_category;
    console.log('Processing Started');

    let X_Industry = IndustryName;
    let X_category = '-';
    let X_level = '-';
    let X_name = '-';
    let X_path = '-';
    let X_level1path = '-';
    let X_level1sku = '-';
    let X_level1nonproductsrootname = '-';
    let X_level1nonproductsrootpath = '-';
    let X_level2path = '-';
    let X_level2sku = '-';
    let X_product = '-';
    let X_apipath = '-';
    let X_Status = 'UnPublished'
    setFinalResult([]);


    const webApiUrl = 'https://servicesext.deere.com/ddf/api/taxonomy';

        const authAxio = axios.create({
                baseURL: webApiUrl,
                headers: {
                    Authorization: `Bearer ${tokenStr}`,
                },
            });

            await authAxio.post(webApiUrl, {
                "languageCode": "en",
                "countryCode": "us"
            })
            .then((res)=>{
                const dataJson = convertXmlToJson(res.data);
                if (catName === 'en_us_sprayers'){
                    en_us_category = dataJson.en_us_sprayers;
                }
                if (catName === 'en_us_articulated_dump_trucks'){
                    en_us_category = dataJson.en_us_articulated_dump_trucks;
                }
                if (catName === 'en_us_excavators'){
                    en_us_category = dataJson.en_us_excavators;
                }
                if (catName === 'en_us_dozers'){
                    en_us_category = dataJson.en_us_dozers;
                }
                if (catName === 'en_us_motor_graders'){
                    en_us_category = dataJson.en_us_motor_graders;
                }
                if (catName === 'en_us_scraper_systems'){
                    en_us_category = dataJson.en_us_scraper_systems;
                }
                if (catName === 'en_us_mowers'){
                    en_us_category = dataJson.en_us_mowers;
                }
                if (catName === 'en_us_gator_utility_vehicles'){
                    en_us_category = dataJson.en_us_gator_utility_vehicles;
                }
                if (catName === 'en_us_gator_utility_vehicles_product_list'){
                    en_us_category = dataJson.en_us_gator_utility_vehicles_product_list;
                }
                if (catName === 'en_us_wheeled_feller_bunchers'){
                    en_us_category = dataJson.en_us_wheeled_feller_bunchers;
                }
                if (catName === 'en_us_wheeled_harvesters'){
                    en_us_category = dataJson.en_us_wheeled_harvesters;
                }

                if (catName === 'en_us_knuckleboom_loaders'){
                    en_us_category = dataJson.en_us_knuckleboom_loaders;
                }
                if (catName === 'en_us_forwarders'){
                    en_us_category = dataJson.en_us_forwarders;
                }
                if (catName === 'en_us_skidders'){
                    en_us_category = dataJson.en_us_skidders;
                }
                if (catName === 'en_us_swing_machines'){
                    en_us_category = dataJson.en_us_swing_machines;
                }
                if (catName === 'en_us_tracked_feller_bunchers'){
                    en_us_category = dataJson.en_us_tracked_feller_bunchers;
                }
                if (catName === 'en_us_shovel_loggers'){
                    en_us_category = dataJson.en_us_shovel_loggers;
                }
                if (catName === 'en_us_tracked_harvesters'){
                    en_us_category = dataJson.en_us_tracked_harvesters;
                }
                if (catName === 'en_us_tillage_equipment_product_list'){
                    en_us_category = dataJson.en_us_tillage_equipment_product_list;
                }
                if (catName === 'en_us_application_equipment'){
                    en_us_category = dataJson.en_us_application_equipment;
                }
                if (catName === 'en_us_cutters_and_shredders'){
                    en_us_category = dataJson.en_us_cutters_and_shredders;
                }
                if (catName === 'en_us_application_equipment_product_list'){
                    en_us_category = dataJson.en_us_application_equipment_product_list;
                }
                if (catName === 'en_us_planting_equipment'){
                    en_us_category = dataJson.en_us_planting_equipment;
                }
                if (catName === 'en_us_tillage_equipment'){
                    en_us_category = dataJson.en_us_tillage_equipment;
                }
                if (catName === 'en_us_hay_and_forage'){
                    en_us_category = dataJson.en_us_hay_and_forage;
                }

                if (catName === 'en_us_seeding_equipment'){
                    en_us_category = dataJson.en_us_seeding_equipment;
                }
                if (catName === 'en_us_tractors'){
                    en_us_category = dataJson.en_us_tractors;
                }
                if (catName === 'en_us_harvesting_equipment'){
                    en_us_category = dataJson.en_us_harvesting_equipment;
                }

                X_category = catName;
                X_level = 'RootLevel'

                // 24 Nodes have internal nodes to get data in sheet
                console.log('en_us_sprayers = ', en_us_category);
                console.log('Keys Of en_us_sprayers = ',Object.keys(en_us_category))
                console.log('Values Of en_us_sprayers = ',Object.values(en_us_category))

                Object.keys(en_us_category).map(data=>{
                    console.log('Data Value Seems => ', data);
                    if (data === 'path'){
                        // This root level data comes only once
                        X_path = en_us_category.path;
                        console.log('Level 1 Path = ',en_us_category.path)
                    }
                    if (data === 'name'){
                        // This root level data comes only once
                        X_name = en_us_category.name;
                        console.log('Level 1 Name = ',en_us_category.name)
                    }


                    // ************* DONE *************
                    // Level-1 Products Details
                    // ************* DONE *************
                    if (data === 'products'){
                        // console.log('Products Keys = ', Object.keys(en_us_sprayers.products))
                        // Object.keys(en_us_sprayers.products).map(pkeys=>{
                        //     console.log('Product Keys =',pkeys);
                        // })
                        // console.log('Products Values = ',Object.values(en_us_sprayers.products))
                        console.log('Null Products = > ', en_us_category.products);
                        if (en_us_category.products !== null || en_us_category.products !== undefined) {
                            Object.entries(en_us_category.products).map(pdata=> {
                                console.log('Level 1 Product Key = en_us_'+ pdata[1].sku)
                                console.log('Level 1 SKU =',pdata[1].sku);
                                console.log('Level 1 Path In Nulls = ', pdata[1].path)

                                X_level = 'Level-1 Products'
                                X_level1sku = pdata[1].sku;
                                X_level1path = pdata[1].path;
                                X_product = pdata[0]
                                X_apipath = 'https://www.deere.com' + X_level1path + 'index.json'
                                X_level2sku = '-';
                                X_level2path = '-';
                                X_level1nonproductsrootname = '-';
                                X_level1nonproductsrootpath = '-';

                                console.log('Level1 Processing',X_apipath);

                                const payload = {
                                    id: 0,
                                    industry: X_Industry,
                                    category:X_category,
                                    level:X_level,
                                    name:X_name,
                                    path:X_path,
                                    level1path:X_level1path,
                                    level1sku:X_level1sku,
                                    level1nonproductsrootname:X_level1nonproductsrootname,
                                    level1nonproductsrootpath:X_level1nonproductsrootpath,
                                    level2path:X_level2path,
                                    level2sku:X_level2sku,
                                    product:X_product,
                                    apipath:X_apipath,
                                    status: X_Status
                                }

                                if (payload) {
                                    payloads.push(payload);
                                }
                            })
                        }

                    }

                    // ************* DONE *************
                    // Level-1 None Products Nodes
                    // ************* DONE *************
                    if (data !== 'products' && data !== 'path' && data !=='name') {
                        X_level1nonproductsrootname = en_us_category[data].name;
                        X_level1nonproductsrootpath = en_us_category[data].path;

                        console.log('Level 1 ** Non Products Name =>',en_us_category[data].name);
                        console.log('Level 1 ** Non Products Path  =>',en_us_category[data].path);
                        console.log('Level 1 ** Non Products, Products =>>>',en_us_category[data].products);

                    // Level-2 = [Level-1 None Products Node] Details

                    Object.keys(en_us_category[data].products).map((da)=>{
                            console.log('Level 2 = ** Level 1 Non Products, Products SKU => ',Object.values(en_us_category[data].products[da])[0]);
                            console.log('Level 2 = ** Level 1 Non Products, Products PATH => ',Object.values(en_us_category[data].products[da])[1]);

                            X_level1sku = '-';
                            X_level1path = '-';
                            X_level = 'Level-2 Products'
                            console.log('Level2 Processing', X_apipath)

                            X_level2sku = Object.values(en_us_category[data].products[da])[0];
                            X_level2path = Object.values(en_us_category[data].products[da])[1];

                            X_product = da;
                            X_apipath = 'https://www.deere.com' + X_level2path + 'index.json'
                            console.log('API Path,X_level2path = ',X_apipath);

                            const payload = {
                                id: 0,
                                industry: X_Industry,
                                category:X_category,
                                level:X_level,
                                name:X_name,
                                path:X_path,
                                level1path:X_level1path,
                                level1sku:X_level1sku,
                                level1nonproductsrootname:X_level1nonproductsrootname,
                                level1nonproductsrootpath:X_level1nonproductsrootpath,
                                level2path:X_level2path,
                                level2sku:X_level2sku,
                                product:X_product,
                                apipath:X_apipath,
                                status: X_Status
                            }

                            if (payload) {
                                payloads.push(payload);
                            }

                            if (Object.values(en_us_category[data].products[da]).length > 2) {
                                // This has not yet reached
                                console.log('FOUND PRODUCTS',Object.values(en_us_category[data].products[da]))
                            }
                            // Products If Any
                            // Object.values(en_us_sprayers[data].products[da])[3].map((Internal)=>{
                            //     Internal.map((IData)=>{
                            //         console.log('Products => ', IData);
                            //     })
                            // })
                        })
                    }
                    console.log('Pushed Products = **** >> ', payloads);
            })


                 // ProcessAPIRecords()

                // setTaxonomyData(convertXmlToJson(res.data));
                // return taxonomyData;

            })
            .catch (function (error) {
            //console.log(error);
            setTaxonomyData(error)
            return error;
            });

            //console.log('SET FINAL RESULT => ',payloads);
                    // ******************************************************
                    // Here Record the data for Deere API Buffers
                    // ******************************************************
                    console.log('Now Payloads =>>',payloads)
                    // console.log('Level-2 Products Records',payload);
                    DeereAPIBufferService.bulkDeere(payloads).then((record)=>{
                        console.log('Records are added with Path and Product To API Buffer => ',record)
                    }).catch((error)=>{
                        console.log(error)
                    })
                    // ******************************************************

        }

const ProcessAPIRecords = ()=>{
    DeereAPIBufferService.getAllAPI((record)=>{
        // Here Call API using Axio and get all the fields of interest and Insert in DeereDataRecords

    })
}

const onSubmit = (data, event) => {
    event.preventDefault();
    console.log('Data => ', data);
}

const onChangeLogic = (e)=>{
    setSelectedLogic(e.target.value.toString());
}

const onChangeRule = (e) => {
    e.preventDefault();
    console.log('Target Rule = ',e.target.value);
    setRuleName(e.target.value);
}

const onChangeComboBox = (e) => {
	const selectedSKU = e.target.value;
    const selectedRecordState = finalResult.filter(d => { return (d.sku.toString() === selectedSKU) });
    setItems(selectedRecordState);
    setSelectedRecord(selectedRecordState);

    OutboundDeereService.getDeereModelName(e.target.value).then((data)=>{
        if (data.field25)
        {
            setPublished(data.field25);
        }
        else
        {
            setPublished('Not Published');
        }
    }).catch((err)=>{
        console.log(err.message);
    })
    // if (check1){
    //     setSelectedRecord(Records.matches)
    // }

    console.log('Selected = ',selectedRecordState);
  //  console.log('selectedRecordState = ',SelectedRecord);
    selectedRecordState.map((rec) => {
        deereRecordValues = {
            id: 0,
            status: 'Published',
            ruleCode: rec.ruleCode.toString(),
            language: rec.language.toString(),
            country: rec.country.toString(),
            sku: rec.sku.toString(),
            modelNumber: rec.modelNumber.toString(),
            modelName: rec.modelName.toString(),
            productOverview: rec.productOverview.toString(),
            baseCode: rec.baseCode.toString(),
            productSystemValue: rec.productSystemValue.toString(),
            optionalLinks: rec.optionalLinks.toString(),
            altText: rec.altText.toString(),
            defaultURL: rec.defaultURL.toString(),
            imageURLS: rec.imageURLS.toString(),
            title: rec.title.toString(),
            keywords: rec.keywords.toString(),
            metaDesc: rec.metDesc.toString(),
            productPrice: rec.productPrice,
            currency: rec.currency.toString(),
            disclaimer: rec.disclaimer.toString(),
            denomLabel: rec.denomLabel.toString(),
            titleQuestion: rec.titleQuestion.toString(),
            componentDesc: rec.componentDesc.toString()
        }

        console.log(deereRecordValues);
    })


};

const onCheckBoxChange = (e) => {
    setCheck1(e.target.checked);
    if (e.target.checked){
        // const selectedRecordState = Records.matches
        // setItems(selectedRecordState);
        // setSelectedRecord(selectedRecordState);
        // ProcessDeere();
        const selectedRecordState = Records;
        setItems(selectedRecordState);
        setSelectedRecord(selectedRecordState);
        setFilteredRecordState(selectedRecordState);
        setFinalResult(selectedRecordState);
    }
    else
    {
        setFinalResult([]);
        setSelectedRecord([])
    }
}

const ChangeStatusEdit = async (SKU,Status) =>{
    let payload = {
        sku: string,
        features: string,
        updateSwitch: string
    }

    if (Status === 'Published'){
        payload = {
            sku:SKU,
            features:'-',
            updateSwitch:'2'
        }
    }

    if (Status === 'Buffered'){
        payload = {
            sku:SKU,
            features:'-',
            updateSwitch:'1'
        }
    }

    const thedata = await OutboundDeereService.setDeereStatus(payload);
    toast.success('Operation Successful',{ position: 'bottom-center'});

}

const GetDeereEdit = (SKU) =>{
    formValues = {
            id: number,
            status: string,
            ruleCode: string,
            language: string,
            country: string,
            productSku: string,
            productModelNumber: string,
            productModelName: string,
            productBaseCode: string,
            productSummary: string,
            productOverview: string,
            productIndustry: string,
            productPath: string,
            productImage: string,
            categoryIndustry: string,
            recommendedByPercentage: string,
            recommendedByLabel: string,
            bazaarVoiceStarRatingUrl: string,
            productPriceDisclaimer: string,
            startingAtLabel: string,
            productSystemValue: string,
            productPriceDenominationLabel: string,
            imageGalleryContainer: string,
            disclaimer: string,
            disclaimerContainer: string,
            title: string,
            description: string,
            keywords: string,
            specifications: string,
            features: string

        }
    console.log('SKU = ', SKU);
    OutboundDeereService.getDeereSKU(SKU).then((data)=>{
        formValues = {
            id: data.id,
            status: nullIf(data.status),
            ruleCode: nullIf(data.ruleCode),
            language: nullIf(data.language),
            country: nullIf(data.country),
            productSku: nullIf(data.productSku),
            productModelNumber: nullIf(data.productModelNumber),
            productModelName: nullIf(data.productModelName),
            productBaseCode: nullIf(data.productBaseCode),
            productSummary: nullIf(data.productSummary),
            productOverview: nullIf(data.productOverview),
            productIndustry: nullIf(data.productIndustry),
            productPath: nullIf(data.productPath),
            productImage: nullIf(data.productImage),
            categoryIndustry: nullIf(data.categoryIndustry),
            recommendedByPercentage: nullIf(data.recommendedByPercentage),
            recommendedByLabel: nullIf(data.recommendedByLabel),
            bazaarVoiceStarRatingUrl: nullIf(data.bazaarVoiceStarRatingUrl),
            productPriceDisclaimer: nullIf(data.productPriceDisclaimer),
            startingAtLabel: nullIf(data.startingAtLabel),
            productSystemValue: nullIf(data.productSystemValue),
            productPriceDenominationLabel: nullIf(data.productPriceDenominationLabel),
            imageGalleryContainer: nullIf(data.imageGalleryContainer),
            disclaimer: nullIf(data.disclaimer),
            disclaimerContainer: nullIf(data.disclaimerContainer),
            title: nullIf(data.title),
            description: nullIf(data.description),
            keywords: nullIf(data.keywords),
            specifications: data.specifications,
            features: data.features
        }

        setDeereEdit(1);
        if (DeereEdit===1){
            console.log('Form >>> Attacheed',formValues)
            navigate('/DeereEdit', {state:{data:formValues}});
        }
    })
}

const ApplySearch = () =>{

        if (SelectedLogic === "AND"){
            const selectedRecordState = Records
            .filter(d =>
                { return(
                                (d.groupName === Condition1)
                                && (d.categoryName === Condition2)
                                && (d.modelName === Condition3)
                                && (d.isActive === Condition4)
                                && (d.isHotList === Condition5)
                    )
                        });

            setItems(selectedRecordState);
            setSelectedRecord(selectedRecordState);
            setFilteredRecordState(selectedRecordState);
        }
        if (SelectedLogic === "OR"){
            const selectedRecordState = Records
            .filter(d =>
                { return(
                        (d.groupName === Condition1)
                    || (d.categoryName === Condition2)
                    || (d.modelName === Condition3)
                    || (d.isActive === Condition4)
                    || (d.isHotList === Condition5)


                    )
                        });
            setItems(selectedRecordState);
            setSelectedRecord(selectedRecordState);
            setFilteredRecordState(selectedRecordState);
        }
}

function canSearch(searchString) {
    return searchString;
}

// *************************************************************
// Filtering methds using Objects To Search
// *************************************************************
function filterRecordsOne(records, filter) {
    var result = [];
    for (var prop in filter) {
        if (filter.hasOwnProperty(prop)) {
            //at the first iteration prop will be address
            for (var i = 0; i < 1; i++) {
                console.log('Checking = ',records[i][prop], filter[prop]);
                if (records[i][prop] === filter[prop]) {
                    result.push(records[i]);
                }
            }
        }
    }
    return result;
}

// *************************************************************
function filterRecords(records, filter) {
    var result = [];
    records.filter((item)=> {
        for (var key in filter) {
          if (item[key] === undefined || item[key] !== filter[key]){
            return null;
          }
            else {
                console.log('Pushing')
                result.push(item);
            }
        }
        console.log("RES = ", result);
        return result;
    })
}


  const ApplyNewSearch = async (e) =>{
    e.preventDefault();
    console.log('Target Rule = ',e.target.value);
    setRuleName(e.target.value);

    await OutboundDeereService.getAllDeere().then(data=>{
        console.log('BUFF=>',data);
        setBufferedRecord(data);
    });

    var searchString = '';
    var newSearchString = [];

    if (e.target.value === undefined || e.target.value === "" || e.target.value === null){
        return null;
    }
    else
    {
        IVDeereMatchingService.getDeereMatchingRule(e.target.value).then((data)=>{
            console.log('Rule =>', e.target.value);
            console.log('Rule Data =>', data);
            
        data.map(da=> {
            let theFieldValue = ''
            theFieldValue = da.fieldValue
            console.log('theFieldValue', theFieldValue);

            if (da.fieldName === "language"){
                filterToSearch.language = theFieldValue
            }
            if (da.fieldName === "country"){
                filterToSearch.country = theFieldValue
            }
            if (da.fieldName === "productSku"){
                filterToSearch.productSku = theFieldValue
            }
            if (da.fieldName === "productIndustry"){
                filterToSearch.productIndustry = theFieldValue
            }

            if (da.fieldName === "productModelNumber"){
                filterToSearch.productModelNumber = theFieldValue
            }
            if (da.fieldName === "productModelName"){
                filterToSearch.productModelName = theFieldValue
            }
            if (da.fieldName === "productOverview"){
                filterToSearch.productOverview = theFieldValue
            }
            if (da.fieldName === "baseCode"){
                filterToSearch.baseCode = theFieldValue
            }
            if (da.fieldName === "productSystemValue"){
                filterToSearch.productSystemValue = theFieldValue
            }

            if (da.fieldName === "optionalLinks"){
                filterToSearch.optionalLinks = theFieldValue
            }

            if (da.fieldName === "altText"){
                filterToSearch.altText = theFieldValue
            }

            if (da.fieldName === "defaultURL"){
                filterToSearch.defaultURL = theFieldValue
            }

            if (da.fieldName === "imageURLS"){
                filterToSearch.imageURLS = theFieldValue
            }

            if (da.fieldName === "title"){
                filterToSearch.title =  theFieldValue
            }

            if (da.fieldName === "keywords"){
                filterToSearch.keywords = theFieldValue
            }

            if (da.fieldName === "metaDesc"){
                filterToSearch.metaDesc = theFieldValue
            }

            if (da.fieldName === "productPrice"){
                filterToSearch.productPrice = theFieldValue
            }

            if (da.fieldName === "currency"){
                filterToSearch.currency = theFieldValue
            }

            if (da.fieldName === "disclaimer"){
                filterToSearch.disclaimer = theFieldValue
            }

            if (da.fieldName === "denomLabel"){
                filterToSearch.denomLabel=  theFieldValue
            }

            if (da.fieldName === "titleQuestion"){
                filterToSearch.titleQuestion =  theFieldValue
            }

            if (da.fieldName === "componentDesc"){
                filterToSearch.componentDesc = theFieldValue
            }
            console.log('Filter To Search = ',filterToSearch);


        });
        let selectedRecordState;
        //ProcessDeere();
        if (Object.keys(filterToSearch).length > 0) {
            console.log('Published Records Result=',BufferedRecord);
             selectedRecordState = BufferedRecord.filter(rec => {
                return Object.keys(filterToSearch).every(filter => {
                    return filterToSearch[filter] === rec[filter]
                });
            })
        }  
        else 
        {
                    filterToSearch = {
                    productSku: 0
                }
            selectedRecordState = BufferedRecord.filter(rec => {
                return Object.keys(filterToSearch).every(filter => {
                    return filterToSearch[filter] === rec[filter]
                });
            })

        }

        console.log('Result Set = ',selectedRecordState.length);

        setItems(selectedRecordState);
        setSelectedRecord(selectedRecordState);
        setFilteredRecordState(selectedRecordState);
        console.log('Filtered = >>>',selectedRecordState)
        console.log('The Length 5=',selectedRecordState);


        // await OutboundDeereService.getStatusDeere(State).then(data=>{
        //     console.log('The Length 3=',data, State);
        //     setPublishedRecord(data);
        // });

        setPublishedRecord(selectedRecordState)
        //setPublishedRecord({...PublishedRecord,selectedRecordState});    
    })
}
}
const booleanify = (value: string): boolean => {
    const truthy: string[] = [
        'true',
        'True',
        '1'
    ]

    return truthy.includes(value)
  }

  const GetDeereView = (Id) =>{
    formValues = {
            id: number,
            status: string,
            ruleCode: string,
            language: string,
            country: string,
            productSku: string,
            productModelNumber: string,
            productModelName: string,
            productBaseCode: string,
            productSummary: string,
            productOverview: string,
            productIndustry: string,
            productPath: string,
            productImage: string,
            categoryIndustry: string,
            recommendedByPercentage: string,
            recommendedByLabel: string,
            bazaarVoiceStarRatingUrl: string,
            productPriceDisclaimer: string,
            startingAtLabel: string,
            productSystemValue: string,
            productPriceDenominationLabel: string,
            imageGalleryContainer: string,
            disclaimer: string,
            disclaimerContainer: string,
            title: string,
            description: string,
            keywords: string,
            specifications: string,
            features: string

    }


    finalResult.map((record)=> {
        if (record.id === Id) {
            console.log('The Record = ',record);
        formValues = {
            productSku: nullIf(record.productSku),
            productModelNumber: nullIf(record.productModelNumber),
            productModelName: nullIf(record.productModelName),
            productBaseCode: nullIf(record.productBaseCode),
            productSummary: nullIf(record.productSummary),
            productOverview: nullIf(record.productOverview),
            productIndustry: nullIf(record.ProductOverview),
            productPath: nullIf(record.productPath),
            productImage: nullIf(record.productImage),
            categoryIndustry: nullIf(record.categoryIndustry),
            recommendedByPercentage: nullIf(record.recommendedByPercentage),
            recommendedByLabel: nullIf(record.recommendedByLabel),
            bazaarVoiceStarRatingUrl: nullIf(record.bazaarVoiceStarRatingUrl),
            productPriceDisclaimer: nullIf(record.productPriceDisclaimer),
            startingAtLabel: nullIf(record.startingAtLabel),
            productSystemValue: nullIf(record.productSystemValue),
            productPriceDenominationLabel: nullIf(record.productPriceDenominationLabel),
            imageGalleryContainer: nullIf(record.imageGalleryContainer),
            disclaimer: nullIf(record.disclaimer),
            disclaimerContainer: nullIf(record.disclaimerContainer),
            title: nullIf(record.Title),
            description: nullIf(record.description),
            keywords: nullIf(record.keywords),
            specifications: 'A2',
            features: 'B2'
        }
            console.log('Record Retrieved => ',formValues);
            setDeereView(1);
            if (DeereView===1){
                console.log('Form<<',formValues)
                navigate('/DeereView', {state:{data:formValues}});
            }
        }
    });

}


return (
    <>
        <hr/>
        <Container>
        <Row>
                <Col md="3">
                        <div className="heading">
                            {/* <h6>Deere Instream = { readyDeere } / { PublishedDeere } </h6>
                             {totalAPIProcessed > 0 && <h5>Total Published:  { totalAPIProcessed }</h5>} */}
                            {/* <button hidden  className="theButtons" onClick={(e)=> ProcessDeere(e)}>Process Deere</button>
                            {
                                    (Published==='Published')? <h6>&nbsp;</h6>:<button hidden className="theButtons" onClick={()=> PublishDeere()}>Publish</button>
                            } */}
                            <table className="table table-primary">
                                <tr>
                                    <td><h5>Un-Published :</h5></td><td><h5>{TotalRecordsFound}</h5></td>
                                </tr>
                                <tr>
                                    <td><h5>Pages :</h5></td><td><h5>{TotalRecordsPages}</h5></td>
                                </tr>
                            </table>
                        </div>
                        <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <section>
                            <div className="container m-5">

                            <input hidden className="checkbox"
                                         type="checkbox"
                                         onClick={(e)=> onCheckBoxChange(e)}
                                         checked = {check1}
                                         {...register("requestOnly", { required: {value: true, message: "This is required field"} })}
                                         id="AllSKU"
                                         placeholder="All SKU">
                            </input>

                            <select hidden
                                className="custom-select"
                                {...register("EMSKEYS")} id="EMSKEYS"
                                onChange={(e)=> {
                                    onChangeComboBox(e);
                                }}
                            >
                            {
                                Records.map((record)=>
                                (
                                    <option key={Math.random()} value={record.sku}>{record.sku}</option>
                                ))
                            }
                            </select>

                            <select hidden
                                className="custom-select"
                                {...register("Logic")} id="Logic"
                                onChange={(e)=> {
                                    onChangeLogic(e);
                                }}
                            >
                                    <option key={Math.random()}  value="AND">AND</option>
                                    <option  key={Math.random()}  value="OR">OR</option>
                            </select>

                            <select
                                className="custom-select"
                                id="DeereIndustry"
                                onChange={async (e)=> {
                                    ProcessDeereIndustry(e.target.value);
                                }}
                            >
                                <option  key = "construction"  value = "construction">construction</option>
                                <option  key = "farm-ag"  value = "farm-ag">farm-ag</option>
                                <option  key = "forestry"  value = "forestry">forestry</option>
                                <option  key = "home-ranch"  value = "home-ranch">home-ranch</option>
                            </select>

                            <select
                                className="custom-select"
                                id="DeereCategories"
                                onChange={async (e)=> {
                                    CheckProcessedDeere(e.target.value);
                                }}
                            >

                            {
                                DeereCategories.map((record)=>
                                (
                                    <option  key={record.categoryName}  value={record.categoryName}>{record.categoryName}</option>
                                ))
                            }
                            </select>


                            {
                                (RuleName ==="Open") &&
                                <div>
                                <input   type="text" onChange={(e)=> setCondition1(e.target.value)} placeholder="Group Name"></input>
                                <input   type="text" onChange={(e)=> setCondition2(e.target.value)} placeholder="Category Name"></input>
                                <input   type="text" onChange={(e)=> setCondition3(e.target.value)} placeholder="Model Name"></input>

                                <div>
                                    <h6>Is Active</h6>
                                    <input className="checkbox" type="checkbox"
                                            onClick={(e)=> setCondition4(e.target.checked)}></input>
                                </div>
                                <div>
                                    <h6>Is Hot List</h6>
                                    <input className="checkbox" type="checkbox"
                                            onClick={(e)=> setCondition5(e.target.checked)}></input>
                                </div>
                                <button className="btn btn-primary" onClick={ApplyNewSearch} >Search</button>
                                <hr/>
                                </div>
                            }

                            {/* <td><button className="btn btn-primary" onClick={(()=> PublishLogicalDeere())}>Publish All</button></td> */}

                            </div>
                            </section>
                      </form>

                        </div>
                </Col>

                <Col md="9">
                <h6>Inbound Deere API</h6>
                <input className = 'accessInput'  type="password" id='accesscode'  placeholder="Access Code"/>
                <hr/>
                <ReactPaginate
                        previousLabel = {'<'}
                        nextLabel = {'>'}
                        breakLabel = {'...'}
                        pageCount = { Math.ceil(pageCount) }
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}

                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}

                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}

                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}

                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                    <Table striped bordered hover variant="dark">
                    <thead>
                            <tr key= { Math.random() }>
                                <th>Category</th>
                                <th>Product</th>
                                {/* <th>API Path</th> */}
                                <th>Status</th>
                                <th className="buttons">Publish</th>
                            </tr>
                    </thead>
                    <tbody>

                    {
                        finalResult !== undefined && finalResult.map((pro) => {
                        return <>
                            <tr key= { pro.id }>
                                <td> { pro.name }</td>
                                <td> { pro.product }</td>
                                {/* <td> { pro.apiPath }</td> */}
                                <td> { pro.status }</td>

                                {/* <td><button className="viewnewwButton buttons" onClick={(()=> GetDeereView(pro.id))}>View</button></td> */}

                                {/* <td><button className="viewnewwButton buttons" onClick={(()=> UnPublishSingleDeere(pro.id))}>Un-Publish</button></td> */}
                                <td><button className="viewnewwButton buttons" onClick={(()=> PublishSingleDeere(pro.id,pro.apiPath))}>Publish</button></td>
                            </tr>
                        </>
                        })
                    }
                    </tbody>
                    </Table>
                    {/* <img width="400px" height="400px" src={uri} alt="" /> */}
                </Col>
          </Row>
          <hr/>
            <td>
                <button className="theButtons" onClick={()=> DisplayOutboundDeere('Published')}>View Published</button>
            </td>
            <td>
                <button className="theButtons" onClick={()=> DisplayOutboundDeere('Buffered')}>View Buffered</button>
            </td>
            <td>
                <button className="theButtons" onClick={()=> ClearOutboundDeere()}>Clear Outbound</button>
            </td>
            <td className="theButtons">
                <select
                        className="custom-select"
                        {...register("Rules")}
                        id="Rules"
                        value = { RuleName }
                        onChange={(e)=> {
                            ApplyNewSearch(e);
                        }}
                    >

                    {
                        RuleSet.map((record)=>
                        (
                            <option  key={Math.random()}  value={record.productCode}>{record.productCode}</option>
                        ))
                    }
                </select>
            </td>
          <hr/>
          <Row>
            <Col md="12">
            <Table striped bordered hover variant="dark">
                    <thead>
                            <tr key= { Math.random() }>
                                <th>Status</th>
                                <th>SKU</th>
                                <th>Industry</th>
                                <th>Model</th>
                                <th>Title</th>
                                <th>Keywords</th>
                                <th className="editbutton">Edit</th>
                            </tr>
                    </thead>
                    <tbody>

                    {
                        (PublishedRecord !== undefined) && PublishedRecord.map((pro) => {
                            console.log('Catch This => ',PublishedRecord);
                        return <>
                            <tr key= { Math.random() }>
                            <td> { pro.status }</td>
                                <td> { pro.productSku }</td>
                                <td> { pro.productIndustry }</td>
                                <td> { pro.productModelNumber }</td>
                                <td> { pro.title }</td>
                                <td> { pro.keywords }</td>
                                <td>
                                    <button className="editButton editbutton" onClick={(()=> ChangeStatusEdit(pro.productSku,pro.status))}>
                                    {
                                        SwitchPublished===1 ? 'Un-Publish':'Publish'
                                    }
                                    
                                    
                                    </button>
                                </td>
                            </tr>
                        </>
                        })
                    }
                    </tbody>
                    </Table>

            </Col>
          </Row>
      </Container>
    </>
  )
}

export default ProcessNewDeereAPI