import React, {  useEffect, useState } from 'react'
import axios from 'axios'

import Card from '../forms/ui/Card'
import { Row, Col, Container, Table } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { string } from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import ProductBaseMachineService from '../../services/ProductBaseMachineService'
import DeereAPIBufferService from '../../services/DeereAPIBufferService'

import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Modal from '../../components/Modal/Modal'
import { number } from 'yup/lib/locale';
import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'
import { getDeereAccessToken, getRiversAccessToken } from '../../services/SpecialService'
// *******************************************************************
import ReactPaginate from 'react-paginate'
import 'bootstrap/dist/css/bootstrap.min.css'
import { profilesUri, pageSize } from '../../constants'
import MetaData from '../../services/Data/MetaData'
//import taxanomyApi from '../../services/QuickService'

//import App from '../../services/QuickService'

// ********************************************************************

import { EMSAPI } from '../../constants'

import { EMSApiService } from '../../services/EMSApiService'
import { DeereCollectionService } from '../../services/DeereCollectionService'

import { OutboundDeereService } from '../../services/OutboundDeereService'

import  Records  from '../../deer.json'
//import { OutboundEMSService } from '../../services/OutboundEMSService'
import './style.css'
import { IVRiversMatchingService } from '../../services/IVRiversMatchingService'
import { IVDeereMatchingService } from '../../services/IVDeereMatchingService'

let deereRecordValues = {
    id: number,
    status: string, 
    ruleCode: string, 
    language: string, 
    country: string, 
    sku: string, 
    modelNumber: string, 
    modelName: string, 
    productOverview: string, 
    baseCode: string, 
    productSystemValue: string, 
    optionalLinks: string, 
    altText: string,
    defaultURL: string, 
    imageURLS: string, 
    title: string, 
    keywords: string, 
    metaDesc: string, 
    productPrice: number, 
    currency: string, 
    disclaimer: string, 
    denomLabel: string, 
    titleQuestion: string, 
    componentDesc: string    
}
const ProcessDeereAPI = () => {
    const auth = useAuth();  
    const navigate = useNavigate();
    const [DeereEdit, setDeereEdit] = useState(0);
    const [DeereView, setDeereView] = useState(0);

    const [isLogin, setLogin] = useState(false);

    const [RecordAdded, setRecordAdded] = useState(0);
    const [check1, setCheck1] = useState();
    const [RuleSet, setRuleSet] = useState([]);

    let BulkSet = [];
    let filterToSearch = {}

     // **************************************************************************************************
    const [items, setItems] = useState([]);

    const [pageCount, setPageCount] = useState([]);
    const [Published, setPublished]=useState('Not Published');
    const [PublishedDeere, setPublsihedDeere] = useState(0);  
    const [FilteredRecordState,setFilteredRecordState] = useState();

    const [fields, setFields] = useState([]);
    const [uri, setUri] = useState('');
    const keys = [];

    const [readyDeere, setReadyDeere] = useState(0);

    const [SelectedRecord, setSelectedRecord] = useState();
    const [SelectedLogic,setSelectedLogic] = useState("AND");
    const [PublishedRecord, setPublishedRecord] = useState();

    const [RuleName, setRuleName] = useState();

    const [Condition1, setCondition1] = useState();
    const [Condition2, setCondition2] = useState();
    const [Condition3, setCondition3] = useState();
    const [Condition4, setCondition4] = useState(false);
    const [Condition5, setCondition5] = useState(false);

    const [taxonomyData, setTaxonomyData] = useState([]);
    const [finalResult, setFinalResult] = useState([]);
    const [finalPrint, setFinalPrint] = useState();
    let intermediateResultSet = {}
 
    // **************************************************************************************************
        useEffect(() => {
          const getComments = async () => {
              const data = await EMSApiService.getAll();
              const total = data.length;
              const TotalPages = Math.ceil(total/pageSize);
              setPageCount(TotalPages);
              setItems(data);
              console.log('Data = ',data);

              const PageOneFromServer = await fetchComments(1);
              setItems(PageOneFromServer);
              

          };      
          getComments();          
      
          MetaData.getAllRules().then(data=> {                                        
            setRuleSet(data.filter(d => 
                { return(
                        (d.status === "Enable") ) }));
            });            

        }, [RecordAdded]);


        useEffect(() => {
            //console.log('Effective Final Data  => ',finalResult);   
        },[finalResult]);

        useEffect(()=>{
            console.log('Effective Final Data  => ',finalResult);   
            setSelectedRecord(finalResult);
        },[finalPrint])

        const fetchComments = async(currentPage) => {            
                const data = await EMSApiService.getPagedEMS(currentPage, pageSize);
                return data;
            };
              
      
            const handlePageClick = async (data) => {
                let currentPage = data.selected + 1;
                const commentsFromServer = await fetchComments(currentPage);
                setItems(commentsFromServer);
              }
              
        // **************************************************************************************************
    
    const { 
      register, watch, handleSubmit, 
      // setValue, resetValue,unregister, 
      // resetField, setError, clearErrors, 
      // setFocus, getValues, trigger, control,
      formState: { 
          errors, isValid
         // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating,  submitCount 
      } } 
      = useForm({ 
        mode: "all", 
        // defaultValues: { id: 0, productCode: '', modelName: '', packageDesc: '', inventoryMatching: '', riversEMSMatching: '', regularPrice: 0, riversPrice: 0, validDate: '1900-01-01', advertiseDesc: '', disclaimer: '', media: '', promotionsAvailable: '' } 
      });
  
      useEffect(() => {
        const subscription = watch((data)=>{
            console.log(data);
        })
        return ()=> {
            subscription.unsubscribe();
        }
    }, [watch]) 

    function convertXmlToJson(xmlString) {
        const jsonData = {};
        for (const result of xmlString.matchAll(/(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm)) {
            const key = result[1] || result[3];
            const value = result[2] && convertXmlToJson(result[2]); //recusrion
            jsonData[key] = ((value && Object.keys(value).length) ? value : result[2]) || null;
        }
        return jsonData;
    }

    const  PublishDeere = async () => {
        console.log('Record Phone = >> ',deereRecordValues);
                if (deereRecordValues){
                    OutboundDeereService.createDeere(deereRecordValues).then(()=>{      
                        setRecordAdded(deereRecordValues);
                        console.log('Record Created ===> ',deereRecordValues);
                        setPublished('Published');
                        toast.success('Published Successfully',{ position: 'bottom-center'});                                                  
                    }).catch((e) => {
                        console.log('Adding EMS Record Error', e.message, deereRecordValues.sku);
                    })    
                }
                
                const thedata = await OutboundDeereService.getAllDeere();
                setPublishedRecord(thedata);
                OutboundDeereService.getAllDeere().then((data)=>{
                    setPublsihedDeere(data.length);
                })            
    }

    const nullIf = (value)=>{
        if (value=== null) {
            return '-';
        }
        else
        {
            var thevalue = value !== undefined? value.toString():"-"
            return thevalue;
        }
    }   
    const  PublishLogicalDeere = () => {
        let formValues ={
            id: number,
            status: string, 
            ruleCode: string, 
            language: string, 
            country: string, 
            sku: string, 
            modelNumber: string, 
            modelName: string, 
            productOverview: string, 
            baseCode: string, 
            productSystemValue: string, 
            optionalLinks: string, 
            altText: string,
            defaultURL: string, 
            imageURLS: string, 
            title: string, 
            keywords: string, 
            metaDesc: string, 
            productPrice: number, 
            currency: string, 
            disclaimer: string, 
            denomLabel: string, 
            titleQuestion: string, 
            componentDesc: string
        }

        
        if (check1){
            Records.map((rec)=> {
                console.log('rec => ',rec)
                formValues = {
                    id: 0,
                    status: 'Published', 
                    ruleCode: RuleName, 
                    language: 'en', 
                    country: 'us', 
                    sku: nullIf(rec.SKU), 
                    modelNumber: nullIf(rec.ProductModelNumber), 
                    modelName: nullIf(rec.ProductModelName), 
                    productOverview: nullIf(rec.ProductOverview), 
                    baseCode: nullIf(rec.BaseCode), 
                    productSystemValue: nullIf(rec.ProductSystemValue), 
                    optionalLinks: '-', 
                    altText: nullIf(rec.altText),
                    defaultURL: nullIf(rec.defaults), 
                    imageURLS: nullIf(rec.imageURLs), 
                    title: nullIf(rec.Title), 
                    keywords: nullIf(rec.Keywords), 
                    metaDesc: nullIf(rec.Description), 
                    productPrice: rec.productPrice===null? 0:rec.productPrice, 
                    currency: nullIf(rec.currency), 
                    disclaimer: nullIf(rec.disclaimer), 
                    denomLabel: nullIf(rec.denominationLabel), 
                    titleQuestion: '-', 
                    componentDesc: nullIf(rec.componentData)
                }        
                console.log('Record formation 1 => ',formValues);

                if (formValues){
                    BulkSet.push(formValues);        
                }
            });

            console.log('BulkSet = >> ',BulkSet);
            OutboundDeereService.bulkDeere(BulkSet).then(()=>{      
                setRecordAdded(BulkSet);
                console.log('Record Created ===> ',BulkSet);
                setPublished('Published');
                toast.success('Published Successfully',{ position: 'bottom-center'});                                                  
            }).catch((e) => {
                console.log('Adding Deere Record Error', e.message, formValues.sku);
            })    


            const timer = setTimeout(async () => {
            const thedata = await OutboundDeereService.getAllDeere();
                setPublishedRecord(thedata);
                OutboundDeereService.getAllDeere().then((data)=>{
                    setPublsihedDeere(data.length);
                })            
                }, 1000);
          return () => clearTimeout(timer);
        }
        else{
            FilteredRecordState.map((rec)=> {
                formValues = {
                    id: 0,
                    status: 'Published', 
                    ruleCode:  RuleName, 
                    language: 'en', 
                    country: 'us', 
                    sku: nullIf(rec.SKU), 
                    modelNumber: nullIf(rec.ProductModelNumber), 
                    modelName: nullIf(rec.ProductModelName), 
                    productOverview: nullIf(rec.ProductOverview), 
                    baseCode: nullIf(rec.BaseCode), 
                    productSystemValue: nullIf(rec.ProductSystemValue), 
                    optionalLinks: '-', 
                    altText: nullIf(rec.altText),
                    defaultURL: nullIf(rec.defaults), 
                    imageURLS: nullIf(rec.imageURLs), 
                    title: nullIf(rec.Title), 
                    keywords: nullIf(rec.Keywords), 
                    metaDesc: nullIf(rec.Description), 
                    productPrice: rec.productPrice===null? 0:rec.productPrice, 
                    currency: nullIf(rec.currency), 
                    disclaimer: nullIf(rec.disclaimer), 
                    denomLabel: nullIf(rec.denominationLabel), 
                    titleQuestion: '-', 
                    componentDesc: nullIf(rec.componentData)
                }        
                console.log('Record formation 2 => ',formValues);

                if (formValues){
                    BulkSet.push(formValues);        
                }
            });
            
            console.log('BulkSet = >> ',BulkSet);
            OutboundDeereService.bulkDeere(BulkSet).then(()=>{      
                setRecordAdded(BulkSet);
                console.log('Record Created ===> ',BulkSet);
                setPublished('Published');
                toast.success('Published Successfully',{ position: 'bottom-center'});                                                  
            }).catch((e) => {
                console.log('Adding Deere Record Error', e.message, formValues.sku);
            })    


            const timer = setTimeout(async () => {
            const thedata = await OutboundDeereService.getAllDeere();
                setPublishedRecord(thedata);
                OutboundDeereService.getAllDeere().then((data)=>{
                    setPublsihedDeere(data.length);
                })            
                }, 1000);
          return () => clearTimeout(timer);
        }        
}



    const  PublishSingleDeere =  (SKU) => {
        let formValues ={
            id: number,
            status: string, 
            ruleCode: string, 
            language: string, 
            country: string, 
            sku: string, 
            modelNumber: string, 
            modelName: string, 
            productOverview: string, 
            baseCode: string, 
            productSystemValue: string, 
            optionalLinks: string, 
            altText: string,
            defaultURL: string, 
            imageURLS: string, 
            title: string, 
            keywords: string, 
            metaDesc: string, 
            productPrice: number, 
            currency: string, 
            disclaimer: string, 
            denomLabel: string, 
            titleQuestion: string, 
            componentDesc: string        
        }
        console.log('SKU = ', SKU);
    
        Records.map((rec)=> {
            if (rec.sku === SKU) {
                formValues = {
                    id: 0,
                    status: 'Published', 
                    ruleCode: RuleName, 
                    language: 'en', 
                    country: 'us', 
                    sku: nullIf(rec.SKU), 
                    modelNumber: nullIf(rec.ProductModelNumber), 
                    modelName: nullIf(rec.ProductModelName), 
                    productOverview: nullIf(rec.ProductOverview), 
                    baseCode: nullIf(rec.BaseCode), 
                    productSystemValue: nullIf(rec.ProductSystemValue), 
                    optionalLinks: '-', 
                    altText: nullIf(rec.altText),
                    defaultURL: nullIf(rec.defaults), 
                    imageURLS: nullIf(rec.imageURLs), 
                    title: nullIf(rec.Title), 
                    keywords: nullIf(rec.Keywords), 
                    metaDesc: nullIf(rec.Description), 
                    productPrice: rec.productPrice===null? 0:rec.productPrice, 
                    currency: nullIf(rec.currency), 
                    disclaimer: nullIf(rec.disclaimer), 
                    denomLabel: nullIf(rec.denominationLabel), 
                    titleQuestion: '-', 
                    componentDesc: nullIf(rec.componentData)
                }        
            console.log('Recorded formation => ',formValues);
            }});

            if (formValues){
                OutboundDeereService.createDeere(formValues).then(()=>{      
                setRecordAdded(formValues);
                console.log('Record Created ===> ',formValues);
                setPublished('Published');
                toast.success('Published Successfully',{ position: 'bottom-center'});                                                  
            }).catch((e) => {
                console.log('Adding Deere Record Error', e.message, formValues.sku);
            })    
        }
        
        const timer = setTimeout(async () => {
            const thedata = await OutboundDeereService.getAllDeere();
            setPublishedRecord(thedata);
            OutboundDeereService.getAllDeere().then((data)=>{
                setPublsihedDeere(data.length);
            })            
            }, 1000);
          return () => clearTimeout(timer);

}

const ClearOutboundDeere = async () => {
    await OutboundDeereService.deleteAllDeere();

    const thedata = await OutboundDeereService.getAllDeere();
    setPublishedRecord(thedata);

    OutboundDeereService.getAllDeere().then((data)=>{
        setPublsihedDeere(data.length);
    })
    toast.success('Cleared Successfully',{ position: 'bottom-center'});                                                  

}
    const  DisplayOutboundDeere = async () => {
        const thedata = await OutboundDeereService.getAllDeere();
        setPublishedRecord(thedata);
        OutboundDeereService.getAllDeere().then((data)=>{
            setPublsihedDeere(data.length);
        })
    
}

const DEF_DELAY = 1000;

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms || DEF_DELAY));
}


    const  ProcessDeere = async () => {
    console.log('Processing Deere')
    const accessCode = document.getElementById('accesscode').value

    await taxonomyApi(accessCode);
    // await productSummaryApi(accessCode)
    // await mediaApi(accessCode)
    // await metadataApi(accessCode)
    // await productPriceApi(accessCode)
    // await componentApi(accessCode)

    // const thedata = await OutboundDeereService.getAllDeere();
    // setPublishedRecord(thedata);
    // //setSelectedRecord(thedata);
    // OutboundDeereService.getAllDeere().then((data)=>{
    //     setPublsihedDeere(data.length);
    // })            


    // const theAccessCode = await getDeereAccessToken()    
    // console.log('Access Code ===>>> ', theAccessCode);

    // const accessCode = await DeereCollectionService.getAccessTokenV2();
    // console.log('Access Code ===>>> ', accessCode);

    // const AC = DeereCollectionService.Oauth2AccessToken();
    // console.log('AC = > ', AC);

    // const accessCode = await DeereCollectionService.Oauth2AccessToken();
    // console.log('Access Code ===>>> ', accessCode);    

    // const taxonomy = await DeereCollectionService.getTaxonomy(payload1);
    // console.log('Taxonomy ===>>> ', taxonomy);
  
    //   const products = await DeereCollectionService.getProducts(payload1);
    //   console.log('Products = ', products);


}

const productSummaryApi = async (tokenStr) => {
    const webApiUrl = 'https://servicesext.deere.com/ddf/api/productsummary';

    const authAxio = axios.create({
        baseURL: webApiUrl,
        headers: {
            Authorization: `Bearer ${tokenStr}`,
        },
    });
    // bb20lxf, pr11xf
    // bb20xf
    // bb50lxf
    // bb50xf
    // bl11xf
    await authAxio.post(webApiUrl, {
        "sku": "bb50lxf",
        "languageCode": "en",
        "countryCode": "us"            
    })
    .then((res)=>{                    
        console.log('Product Summary Data direct => ',res.data);        
        const productSummaryData = res.data;
        const BaseCode = res.data.BaseCode
        const ProductModelName = res.data.ProductModelName
        const ProductModelNumber = res.data.ProductModelNumber
        const ProductOverview = res.data.ProductOverview
        const ProductSystemValue = res.data.ProductSystemValue

        console.log(BaseCode + ', ', ProductModelName + ", " + ProductModelNumber + ", " + ProductOverview + ", " + ProductSystemValue);
        let Object1 = {
            BaseCode, ProductModelName, ProductModelNumber, ProductOverview, ProductSystemValue               
        }

        intermediateResultSet = {...intermediateResultSet, BaseCode, ProductModelName, ProductModelNumber, ProductOverview, ProductSystemValue}
        
        setFinalResult([intermediateResultSet]);
 
        return productSummaryData;
    })
    .catch (function (error) {
    console.log(error);
    return error;
    });
}

const mediaApi = async (tokenStr) => {
    const webApiUrl = 'https://servicesext.deere.com/ddf/api/media';

    const authAxio = axios.create({
        baseURL: webApiUrl,
        headers: {
            Authorization: `Bearer ${tokenStr}`,
        },
    });
    // bb20lxf, pr11xf
    // bb20xf
    // bb50lxf
    // bb50xf
    // bl11xf
    await authAxio.post(webApiUrl, {
        "sku": "bb50lxf",    
        "languageCode":"en",
        "countryCode": "us",
        "mediaType": "images"          
    })
    .then((res)=>{                    
        console.log('Media data direct => ',res.data);        
        const mediaData = res.data;
        const altText = res.data.altText;;
        const imageURL = res.data.imageURLs;
        const defaults = res.data.default;
        const imageURLs = imageURL[0].altText + "," + imageURL[0]["768x432"] + "," + imageURL[0]["1024x576"] + "," + imageURL[0]["1366x768"]
        console.log('altText => ' + altText)
        console.log('defaults => ' + defaults);
        console.log('imageURLs => ' + imageURLs);

        //console.log(imageURL[0])
        // console.log(imageURL[0].altText);
        // console.log(imageURL[0]["768x432"]);
        // console.log(imageURL[0]["1024x576"]);
        // console.log(imageURL[0]["1366x768"]);
        let Object2 = {
            altText,defaults,imageURLs
        };
        intermediateResultSet = {...intermediateResultSet, altText,defaults,imageURLs }
        setFinalResult([intermediateResultSet]);

        return mediaData;
    })
    .catch (function (error) {
        console.log(error);
       return error;
    });
}


const metadataApi = async (tokenStr) => {
    const webApiUrl = 'https://servicesext.deere.com/ddf/api/metadata';

    const authAxio = axios.create({
        baseURL: webApiUrl,
        headers: {
            Authorization: `Bearer ${tokenStr}`,
        },
    });
    // bb20lxf, pr11xf
    // bb20xf
    // bb50lxf
    // bb50xf
    // bl11xf
    await authAxio.post(webApiUrl, {
        "sku": "bb50lxf",    
        "languageCode":"en",
        "countryCode": "us"
    })
    .then((res)=>{                    
        console.log('Metadata direct => ',res.data);        
        const metaData = res.data;
        const  Title = res.data.Title;
        const Keywords = res.data.Keywords;
        const Description = res.data.Description;
        console.log(Title + ", " + Keywords + ", " + Description);

        let Object3 = {
            Title,Keywords,Description
        };
        intermediateResultSet = {...intermediateResultSet, Title,Keywords,Description }

        setFinalResult([intermediateResultSet]);


        return metaData;
    })
    .catch (function (error) {
        console.log(error);
       return error;
    });
}

const productPriceApi = async (tokenStr) => {
    const webApiUrl = 'https://servicesext.deere.com/ddf/api/productprice';

    const authAxio = axios.create({
        baseURL: webApiUrl,
        headers: {
            Authorization: `Bearer ${tokenStr}`,
        },
    });
    // bb20lxf, pr11xf
    // bb20xf
    // bb50lxf
    // bb50xf
    // bl11xf
    await authAxio.post(webApiUrl, {
        "sku": "bb50lxf",    
        "languageCode":"en",
        "countryCode": "us"
    })
    .then((res)=>{                    
        console.log('Product Price direct => ',res.data);        
        const productPriceData = res.data;
        const currency = res.data.currency;
        const denominationLabel = res.data.denominationLabel;
        const disclaimer = res.data.disclaimer;
        const productPrice = res.data.productPrice;
        console.log(currency + ", " + denominationLabel + ", " + disclaimer + ", " + productPrice);

       let Object4 = {
            currency,denominationLabel,disclaimer, productPrice
        };
        intermediateResultSet = {...intermediateResultSet, currency,denominationLabel,disclaimer, productPrice }

        setFinalResult([intermediateResultSet]);

        return productPriceData;
    })
    .catch (function (error) {
        console.log(error);
       return error;
    });
}

const componentApi = async (tokenStr) => {
    const webApiUrl = 'https://servicesext.deere.com/ddf/api/component';

    const authAxio = axios.create({
        baseURL: webApiUrl,
        headers: {
            Authorization: `Bearer ${tokenStr}`,
        },
    });
    // bb20lxf, pr11xf
    // bb20xf
    // bb50lxf
    // bb50xf
    // bl11xf

    await authAxio.post(webApiUrl, {
        "sku": "bb50lxf",    
        "languageCode":"en",
        "countryCode": "us",
        "component": "features"
    })
    .then((res)=>{                    
        console.log('Component direct => ',res.data.Features);   
        let componentData = {}     
        res.data.Features.map((da)=>{
            componentData = da.TitleQuestion + "," + componentData            
        });
        console.log('componentData = ',componentData);

        let Object5 = {
            componentData
        };
        intermediateResultSet = {...intermediateResultSet, componentData }
        setFinalResult([intermediateResultSet]);
        setFinalPrint(true);
        return componentData;
    })
    .catch (function (error) {
       return error;
    });
}

const taxonomyApi= async (tokenStr) => {
    
    console.log('Processing Started');
    
    let X_category = '-';
    let X_level = '-';
    let X_name = '-';
    let X_path = '-';
    let X_level1path = '-';
    let X_level1sku = '-';
    let X_level1nonproductsrootname = '-'; 
    let X_level1nonproductsrootpath = '-';
    let X_level2path = '-';
    let X_level2sku = '-';
    let X_product = '-';
    let X_apipath = '-';                    
    
    const webApiUrl = 'https://servicesext.deere.com/ddf/api/taxonomy';

        const authAxio = axios.create({
                baseURL: webApiUrl,
                headers: {
                    Authorization: `Bearer ${tokenStr}`,
                },
            });

            await authAxio.post(webApiUrl, {
                "languageCode": "en",
                "countryCode": "us"            
            })
            .then((res)=>{                    
                const dataJson = convertXmlToJson(res.data);

                const en_us_sprayers = dataJson.en_us_sprayers;
                X_category = 'en_us_sprayers'
                X_level = 'RootLevel'

                // 24 Nodes have internal nodes to get data in sheet
                console.log('en_us_sprayers = ', en_us_sprayers);
                console.log('Keys Of en_us_sprayers = ',Object.keys(en_us_sprayers))
                console.log('Values Of en_us_sprayers = ',Object.values(en_us_sprayers))

                Object.keys(en_us_sprayers).map(data=>{

                    if (data === 'path'){
                        // This root level data comes only once
                        X_path = en_us_sprayers.path;
                        console.log('Level 1 Path = ',en_us_sprayers.path)
                    }
                    if (data === 'name'){
                        // This root level data comes only once
                        X_name = en_us_sprayers.name;
                        console.log('Level 1 Name = ',en_us_sprayers.name)
                    }


                    // ************* DONE *************
                    // Level-1 Products Details
                    // ************* DONE *************
                    if (data === 'products'){
                        // console.log('Products Keys = ', Object.keys(en_us_sprayers.products))
                        // Object.keys(en_us_sprayers.products).map(pkeys=>{
                        //     console.log('Product Keys =',pkeys);
                        // })
                        // console.log('Products Values = ',Object.values(en_us_sprayers.products))
                        Object.values(en_us_sprayers.products).map(pdata=> {
                            X_level = 'Level-1 Products'
                            X_level1sku = pdata.sku;
                            X_level1path = pdata.path;
                            X_product = 'Product Name Key'
                            X_apipath = 'https://www.deere.com' + X_level1path + 'index.json'

                            console.log('Level1 Processing',X_apipath);

                            console.log('Level 1 Product Key = en_us_'+ pdata.sku)
                            console.log('Level 1 SKU =',pdata.sku);
                            console.log('Level 1 Path = ', pdata.path)

                    // // Here Record the data for Deere API Buffers                    
                    // // ******************************************************
                    
                    //     const payload = {
                    //         id: 0,
                    //         category:X_category,
                    //         level:X_level,
                    //         name:X_name,
                    //         path:X_path,
                    //         level1path:X_level1path,
                    //         level1sku:X_level1sku,
                    //         level1nonproductsrootname:X_level1nonproductsrootname, 
                    //         level1nonproductsrootpath:X_level1nonproductsrootpath,
                    //         level2path:X_level2path,
                    //         level2sku:X_level2sku,
                    //         product:X_product,
                    //         apipath:X_apipath                                        
                    //     }
                    //     console.log('Level-1 ** Products Records',payload);
                    //     DeereAPIBufferService.addAPI(payload).then((record)=>{
                    //         //console.log('Record Added To API Buffer => ',record)
                    //     }).catch((error)=>{
                    //         console.log(error)
                    //     })
                    //     // ******************************************************
                        })
                    }

                    // ************* DONE *************
                    // Level-1 None Products Nodes 
                    // ************* DONE *************
                    if (data !== 'products' && data !== 'path' && data !=='name') {
                        X_level1nonproductsrootname = en_us_sprayers[data].name;
                        X_level1nonproductsrootpath = en_us_sprayers[data].products;

                        console.log('Level 1 ** Non Products Name =>',en_us_sprayers[data].name);
                        console.log('Level 1 ** Non Products Path  =>',en_us_sprayers[data].path);
                        console.log('Level 1 ** Non Products, Products =>>>',en_us_sprayers[data].products);

                    // Level-2 = [Level-1 None Products Node] Details 
                    Object.keys(en_us_sprayers[data].products).map((da)=>{                            
                            console.log('Level 2 = ** Level 1 Non Products, Products SKU => ',Object.values(en_us_sprayers[data].products[da])[0]);
                            console.log('Level 2 = ** Level 1 Non Products, Products PATH => ',Object.values(en_us_sprayers[data].products[da])[1]);

                            X_level1sku = '-';
                            X_level1path = '-';                            
                            X_level = 'Level-2 Products'
                            console.log('Level2 Processing', X_apipath)

                            X_level2sku = Object.values(en_us_sprayers[data].products[da][0]);
                            X_level2path = Object.values(en_us_sprayers[data].products[da][1]);

                            X_product = 'Product Name Key'
                            X_apipath = 'https://www.deere.com' + X_level2path + 'index.json'
                            console.log('API Path = ',X_apipath);

                    // // ******************************************************
                    // // Here Record the data for Deere API Buffers                    
                    // // ******************************************************
                    
                    //         const payload = {
                    //             id: 0,
                    //             category:X_category,
                    //             level:X_level,
                    //             name:X_name,
                    //             path:X_path,
                    //             level1path:X_level1path,
                    //             level1sku:X_level1sku,
                    //             level1nonproductsrootname:X_level1nonproductsrootname, 
                    //             level1nonproductsrootpath:X_level1nonproductsrootpath,
                    //             level2path:X_level2path,
                    //             level2sku:X_level2sku,
                    //             product:X_product,
                    //             apipath:X_apipath                                        
                    //         }

                    //         console.log('Level-2 Products Records',payload);
                    //         DeereAPIBufferService.addAPI(payload).then((record)=>{
                    //             //console.log('Record Added To API Buffer => ',record)
                    //         }).catch((error)=>{
                    //             console.log(error)
                    //         })
                    //         // ******************************************************


                            if (Object.values(en_us_sprayers[data].products[da]).length > 2) {
                                // This has not yet reached
                                console.log('FOUND PRODUCTS',Object.values(en_us_sprayers[data].products[da]))
                            }
                            // Products If Any
                            // Object.values(en_us_sprayers[data].products[da])[3].map((Internal)=>{                            
                            //     Internal.map((IData)=>{
                            //         console.log('Products => ', IData);
                            //     })                        
                            // })
                        })

                    }

                   // ProcessAPIRecords()
                })


                // *********************************************************************************************
                // More Keys
                // *********************************************************************************************

                const en_us_articulated_dump_trucks = dataJson.en_us_articulated_dump_trucks;                
                const en_us_excavators = dataJson.en_us_excavators;
                const en_us_dozers = dataJson.en_us_dozers;                
                const en_us_motor_graders = dataJson.en_us_motor_graders;
                const en_us_scraper_systems = dataJson.en_us_scraper_systems;                
                const en_us_mowers = dataJson.en_us_mowers;
                const en_us_gator_utility_vehicles = dataJson.en_us_gator_utility_vehicles;
                const en_us_gator_utility_vehicles_product_list = dataJson.en_us_gator_utility_vehicles_product_list;
                const en_us_wheeled_feller_bunchers = dataJson.en_us_wheeled_feller_bunchers;
                const en_us_wheeled_harvesters = dataJson.en_us_wheeled_harvesters;
                const en_us_knuckleboom_loaders = dataJson.en_us_knuckleboom_loaders;
                const en_us_forwarders = dataJson.en_us_forwarders;
                const en_us_skidders = dataJson.en_us_skidders;
                const en_us_swing_machines = dataJson.en_us_swing_machines;
                const en_us_tracked_feller_bunchers = dataJson.en_us_tracked_feller_bunchers;                                
                const en_us_shovel_loggers = dataJson.en_us_shovel_loggers;
                const en_us_tracked_harvesters = dataJson.en_us_tracked_harvesters;
                const en_us_application_equipment = dataJson.en_us_application_equipment;
                const en_us_cutters_and_shredders = dataJson.en_us_cutters_and_shredders;
                const en_us_application_equipment_product_list = dataJson.en_us_application_equipment_product_list;
                const en_us_tillage_equipment = dataJson.en_us_tillage_equipment;
                const en_us_seeding_equipment = dataJson.en_us_seeding_equipment;
                const en_us_harvesting_equipment = dataJson.en_us_harvesting_equipment;

                
                
                // console.log('en_us_articulated_dump_trucks = ', en_us_articulated_dump_trucks);
                // console.log('en_us_excavators = ', en_us_excavators);
                // console.log('en_us_dozers = ', en_us_dozers);
                // console.log('en_us_motor_graders = ', en_us_motor_graders);
                // console.log('en_us_scraper_systems = ', en_us_scraper_systems);
                // console.log('en_us_mowers = ', en_us_mowers);
                // console.log('en_us_gator_utility_vehicles = ', en_us_gator_utility_vehicles);
                // console.log('en_us_gator_utility_vehicles_product_list = ', en_us_gator_utility_vehicles_product_list);
                // console.log('en_us_wheeled_feller_bunchers = ', en_us_wheeled_feller_bunchers);
                // console.log('en_us_wheeled_harvesters = ', en_us_wheeled_harvesters);
                // console.log('en_us_knuckleboom_loaders = ', en_us_knuckleboom_loaders);
                // console.log('en_us_forwarders = ', en_us_forwarders);
                // console.log('en_us_skidders = ', en_us_skidders);
                // console.log('en_us_swing_machines = ', en_us_swing_machines);
                // console.log('en_us_tracked_feller_bunchers = ', en_us_tracked_feller_bunchers);
                // console.log('en_us_shovel_loggers = ', en_us_shovel_loggers);
                // console.log('en_us_tracked_harvesters = ', en_us_tracked_harvesters);
                // console.log('en_us_application_equipment = ', en_us_application_equipment);
                // console.log('en_us_cutters_and_shredders = ', en_us_cutters_and_shredders);
                // console.log('en_us_application_equipment_product_list = ', en_us_application_equipment_product_list);
                // console.log('en_us_tillage_equipment = ', en_us_tillage_equipment);
                // console.log('en_us_seeding_equipment = ', en_us_seeding_equipment);
                // console.log('en_us_harvesting_equipment = ', en_us_harvesting_equipment);

                // 4 Nodes need more elaboration
                // en_us_tractors
                // en_us_hay_and_forage
                // en_us_planting_equipment
                // en_us_tillage_equipment_product_list

                setTaxonomyData(convertXmlToJson(res.data));
                return taxonomyData;
            })
            .catch (function (error) {
            //console.log(error);
            setTaxonomyData(error)
            return error;
            });

}

const ProcessAPIRecords = ()=>{
    DeereAPIBufferService.getAllAPI((record)=>{
        // Here Call API using Axio and get all the fields of interest and Insert in DeereDataRecords

    })
}

const onSubmit = (data, event) => {
    event.preventDefault();
    console.log('Data => ', data);
}

const onChangeLogic = (e)=>{
    setSelectedLogic(e.target.value.toString());
}

const onChangeRule = (e) => {
    e.preventDefault();
    console.log('Target Rule = ',e.target.value);
    setRuleName(e.target.value);
}

const onChangeComboBox = (e) => {
	const selectedSKU = e.target.value;
    const selectedRecordState = finalResult.filter(d => { return (d.sku.toString() === selectedSKU) });
    setItems(selectedRecordState);    
    setSelectedRecord(selectedRecordState);

    OutboundDeereService.getDeereModelName(e.target.value).then((data)=>{        
        if (data.field25)
        {
            setPublished(data.field25);
        }
        else
        {
            setPublished('Not Published');
        }
    }).catch((err)=>{
        console.log(err.message);
    })
    // if (check1){
    //     setSelectedRecord(Records.matches)
    // }

    console.log('Selected = ',selectedRecordState);
  //  console.log('selectedRecordState = ',SelectedRecord);
    selectedRecordState.map((rec) => {
        deereRecordValues = {
            id: 0,
            status: 'Published', 
            ruleCode: rec.ruleCode.toString(), 
            language: rec.language.toString(), 
            country: rec.country.toString(), 
            sku: rec.sku.toString(), 
            modelNumber: rec.modelNumber.toString(), 
            modelName: rec.modelName.toString(), 
            productOverview: rec.productOverview.toString(), 
            baseCode: rec.baseCode.toString(), 
            productSystemValue: rec.productSystemValue.toString(), 
            optionalLinks: rec.optionalLinks.toString(), 
            altText: rec.altText.toString(),
            defaultURL: rec.defaultURL.toString(), 
            imageURLS: rec.imageURLS.toString(), 
            title: rec.title.toString(), 
            keywords: rec.keywords.toString(), 
            metaDesc: rec.metDesc.toString(), 
            productPrice: rec.productPrice, 
            currency: rec.currency.toString(), 
            disclaimer: rec.disclaimer.toString(), 
            denomLabel: rec.denomLabel.toString(), 
            titleQuestion: rec.titleQuestion.toString(),  
            componentDesc: rec.componentDesc.toString()
        }

        console.log(deereRecordValues);
    })


};

const onCheckBoxChange = (e) => {
    setCheck1(e.target.checked);
    if (e.target.checked){
        // const selectedRecordState = Records.matches
        // setItems(selectedRecordState);    
        // setSelectedRecord(selectedRecordState);      
        // ProcessDeere();
        const selectedRecordState = Records;        
        setItems(selectedRecordState);    
        setSelectedRecord(selectedRecordState);                
        setFilteredRecordState(selectedRecordState);   
        setFinalResult(selectedRecordState);
    } 
    else
    {    
        setFinalResult([]);
        setSelectedRecord([])
    }
}

const GetDeereEdit = (SKU) =>{
    let formValues = {
            id: number,
            status: string, 
            ruleCode: string, 
            language: string, 
            country: string, 
            sku: string, 
            modelNumber: string, 
            modelName: string, 
            productOverview: string, 
            baseCode: string, 
            productSystemValue: string, 
            optionalLinks: string, 
            altText: string,
            defaultURL: string, 
            imageURLS: string, 
            title: string, 
            keywords: string, 
            metaDesc: string, 
            productPrice: number, 
            currency: string, 
            disclaimer: string, 
            denomLabel: string, 
            titleQuestion: string, 
            componentDesc: string
        }
    console.log('SKU = ', SKU);
    OutboundDeereService.getDeereSKU(SKU).then((data)=>{
        formValues = {
            id: data.id,
            status: data.status, 
            ruleCode: data.ruleCode, 
            language: data.language, 
            country: data.country, 
            sku: data.productSku, 
            modelNumber: data.productModelNumber, 
            modelName: data.productModelName, 
            productOverview: data.productOverview, 
            baseCode: data.productBaseCode, 
            productSystemValue: data.productSystemValue, 
            title: data.title, 
            disclaimer: data.disclaimer, 
            denomLabel: data.productPriceDenominationLabel, 

            metaDesc: data.metaDesc, 
            currency: data.currency,            
            optionalLinks: data.optionalLinks, 
            altText: data.altText,
            defaultURL: data.defaultURL, 
            imageURLS: data.imageURLS, 
            keywords: data.keywords, 
            productPrice: data.productPrice, 
            titleQuestion: data.titleQuestion, 
            componentDesc: data.componentDesc

// bazaarVoiceStarRatingUrl
// categoryIndustry          
// disclaimerContainer
// features
// imageGalleryContainer
// keywords
// productImage
// productIndustry
// productPath
// productPriceDisclaimer
// productSummary
// recommendedByLabel
// recommendedByPercentage
// specifications
// startingAtLabel
        }

        setDeereEdit(1);
        if (DeereEdit===1){
            console.log('Form >>> Attacheed',formValues)
            navigate('/DeereEdit', {state:{data:formValues}});
        }    
    })
}

const ApplySearch = () =>{    

        if (SelectedLogic === "AND"){
            const selectedRecordState = Records
            .filter(d => 
                { return(
                                (d.groupName === Condition1)                     
                                && (d.categoryName === Condition2)   
                                && (d.modelName === Condition3)
                                && (d.isActive === Condition4)
                                && (d.isHotList === Condition5)
                    )                    
                        });

            setItems(selectedRecordState);    
            setSelectedRecord(selectedRecordState);                
            setFilteredRecordState(selectedRecordState);   
        }
        if (SelectedLogic === "OR"){
            const selectedRecordState = Records
            .filter(d => 
                { return(
                        (d.groupName === Condition1)                     
                    || (d.categoryName === Condition2)   
                    || (d.modelName === Condition3)
                    || (d.isActive === Condition4)
                    || (d.isHotList === Condition5)

                                                                           
                    )                    
                        });
            setItems(selectedRecordState);    
            setSelectedRecord(selectedRecordState); 
            setFilteredRecordState(selectedRecordState);     
        }
}

function canSearch(searchString) {
    return searchString;
}

// *************************************************************
// Filtering methds using Objects To Search
// *************************************************************
function filterRecordsOne(records, filter) {
    var result = [];
    for (var prop in filter) {
        if (filter.hasOwnProperty(prop)) {    
            //at the first iteration prop will be address
            for (var i = 0; i < 1; i++) {
                console.log('Checking = ',records[i][prop], filter[prop]);
                if (records[i][prop] === filter[prop]) {
                    result.push(records[i]);
                }
            }
        }
    }
    return result;
}

// *************************************************************
function filterRecords(records, filter) {
    var result = [];
    records.filter((item)=> {
        for (var key in filter) {
          if (item[key] === undefined || item[key] !== filter[key]){
            return null;
          }
            else {
                console.log('Pushing')
                result.push(item);
            }    
        }
        console.log("RES = ", result);
        return result;
    })
}


  const ApplyNewSearch = (e) =>{    
    e.preventDefault();
    console.log('Target Rule = ',e.target.value);
    setRuleName(e.target.value);
 
    var searchString = ''; 
    var newSearchString = [];

    if (e.target.value === undefined || e.target.value === "" || e.target.value === null){
        return null;
    }
    else 
    {
        IVDeereMatchingService.getDeereMatchingRule(e.target.value).then((data)=>{       
        data.map(da=> {
            let theFieldValue = ''
            theFieldValue = da.fieldValue
            console.log('theFieldValue', theFieldValue);

            if (da.fieldName === "language"){
                filterToSearch.language = theFieldValue          
            }
            if (da.fieldName === "country"){
                filterToSearch.country = theFieldValue             
            }
            if (da.fieldName === "sku"){
                filterToSearch.sku = theFieldValue             
            }
            if (da.fieldName === "ProductModelNumber"){
                filterToSearch.ProductModelNumber = theFieldValue             
            }
            if (da.fieldName === "ProductModelName"){
                filterToSearch.ProductModelName = theFieldValue            
            }
            if (da.fieldName === "productOverview"){
                filterToSearch.productOverview = theFieldValue             
            }
            if (da.fieldName === "baseCode"){
                filterToSearch.baseCode = theFieldValue             
            }
            if (da.fieldName === "ProductSystemValue"){
                filterToSearch.ProductSystemValue = theFieldValue             
            }

            if (da.fieldName === "optionalLinks"){
                filterToSearch.optionalLinks = theFieldValue             
            }

            if (da.fieldName === "altText"){
                filterToSearch.altText = theFieldValue             
            }
            
            if (da.fieldName === "defaultURL"){
                filterToSearch.defaultURL = theFieldValue             
            }

            if (da.fieldName === "imageURLS"){
                filterToSearch.imageURLS = theFieldValue             
            }

            if (da.fieldName === "title"){
                filterToSearch.title =  theFieldValue          
            }

            if (da.fieldName === "keywords"){
                filterToSearch.keywords = theFieldValue          
            }

            if (da.fieldName === "metaDesc"){
                filterToSearch.metaDesc = theFieldValue             
            }

            if (da.fieldName === "productPrice"){
                filterToSearch.productPrice = theFieldValue             
            }

            if (da.fieldName === "currency"){
                filterToSearch.currency = theFieldValue             
            }

            if (da.fieldName === "disclaimer"){
                filterToSearch.disclaimer = theFieldValue             
            }

            if (da.fieldName === "denomLabel"){
                filterToSearch.denomLabel=  theFieldValue             
            }

            if (da.fieldName === "titleQuestion"){
                filterToSearch.titleQuestion =  theFieldValue             
            }

            if (da.fieldName === "componentDesc"){
                filterToSearch.componentDesc = theFieldValue             
            }
            console.log('Filter To Search = ',filterToSearch);
        });
        let selectedRecordState;
        //ProcessDeere();
        if (Object.keys(filterToSearch).length > 0) {
             selectedRecordState = Records.filter(rec => {
                return Object.keys(filterToSearch).every(filter => {
                    return filterToSearch[filter] === rec[filter]
                });
            })
        }  else {
            filterToSearch = {
                sku: 0
            }
            selectedRecordState = Records.filter(rec => {
                return Object.keys(filterToSearch).every(filter => {
                    return filterToSearch[filter] === rec[filter]
                });
            })

        }

        console.log('Result Set = ',selectedRecordState.length);    
                
        setItems(selectedRecordState);    
        setSelectedRecord(selectedRecordState);                
        setFilteredRecordState(selectedRecordState);   
        console.log('Filtered = >>>',selectedRecordState)
        setFinalResult(selectedRecordState)
    })
}

}
const booleanify = (value: string): boolean => {
    const truthy: string[] = [
        'true',
        'True',
        '1'
    ]
  
    return truthy.includes(value)
  }

  const GetDeereView = (SKU) =>{
    let formValues = {
            id: number,
            status: string, 
            ruleCode: string, 
            language: string, 
            country: string, 
            sku: string, 
            modelNumber: string, 
            modelName: string, 
            productOverview: string, 
            baseCode: string, 
            productSystemValue: string, 
            optionalLinks: string, 
            altText: string,
            defaultURL: string, 
            imageURLS: string, 
            title: string, 
            keywords: string, 
            metaDesc: string, 
            productPrice: number, 
            currency: string, 
            disclaimer: string, 
            denomLabel: string, 
            titleQuestion: string, 
            componentDesc: string

    }
    console.log('SKU = ', SKU);

    Records.map((record)=> {
        if (record.sku === SKU) {
            console.log('The Record = ',record);
        formValues = {
            sku: record.SKU, 
            modelNumber: record.ProductModelNumber, 
            modelName: record.ProductModelName, 
            productOverview: record.ProductOverview, 
            baseCode: record.BaseCode, 
            productSystemValue: record.ProductSystemValue, 
            optionalLinks: record.optionalLinks, 
            altText: record.altText,
            defaultURL: record.defaults, 
            imageURLS: record.imageURLs, 
            title: record.Title, 
            keywords: record.Keywords, 
            metaDesc: record.Description, 
            productPrice: record.productPrice, 
            currency: record.currency, 
            disclaimer: record.disclaimer, 
            denomLabel: record.denominationLabel, 
            titleQuestion: record.Title, 
            componentDesc: record.componentData
        }
            console.log('Rec Retrieved => ',formValues);
            setDeereView(1);
            if (DeereView===1){
                console.log('Form<<',formValues)
                navigate('/DeereView', {state:{data:formValues}});
            }    
        }
    });

}


return (
    <>
        <hr/>
        <Container>
        <Row>
                <Col md="3">        
                        <div className="heading">
                            <h6>Deere Instream = { readyDeere } / { PublishedDeere } </h6> 
                            <button  className="theButtons" onClick={()=> ProcessDeere()}>Process Deere</button>                    
                            {
                                    (Published==='Published')? <h6>&nbsp;</h6>:<button hidden className="theButtons" onClick={()=> PublishDeere()}>Publish</button>                            
                            }
                        </div>      
                        <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <section>
                            <div className="container m-5">

                            <input hidden className="checkbox" 
                                         type="checkbox" 
                                         onClick={(e)=> onCheckBoxChange(e)}                                             
                                         checked = {check1}  
                                         {...register("requestOnly", { required: {value: true, message: "This is required field"} })}  
                                         id="AllSKU" 
                                         placeholder="All SKU">
                            </input>

                            <select hidden
                                className="custom-select"
                                {...register("EMSKEYS")} id="EMSKEYS"
                                onChange={(e)=> {
                                    onChangeComboBox(e);
                                }}
                            >
                            {
                                Records.map((record)=>
                                ( 
                                    <option key={Math.random()} value={record.sku}>{record.sku}</option>
                                ))
                            }
                            </select>

                            <select hidden
                                className="custom-select"
                                {...register("Logic")} id="Logic"
                                onChange={(e)=> {
                                    onChangeLogic(e);
                                }}
                            >
                                    <option key={Math.random()}  value="AND">AND</option>
                                    <option  key={Math.random()}  value="OR">OR</option>
                            </select> 
                            <select 
                                className="custom-select"
                                {...register("Rules")} 
                                id="Rules"
                                value = { RuleName }
                                onChange={(e)=> {
                                    ApplyNewSearch(e);
                                }}
                            >

                            {
                                RuleSet.map((record)=>
                                ( 
                                    <option  key={Math.random()}  value={record.productCode}>{record.productCode}</option>
                                ))
                            }

                            </select>   
                            <h2>{errors.EMSKEYS && <p>{errors.EMSKEYS.message}</p>}</h2>
                            {
                                (RuleName ==="Open") && 
                                <div>
                                <input   type="text" onChange={(e)=> setCondition1(e.target.value)} placeholder="Group Name"></input>
                                <input   type="text" onChange={(e)=> setCondition2(e.target.value)} placeholder="Category Name"></input>
                                <input   type="text" onChange={(e)=> setCondition3(e.target.value)} placeholder="Model Name"></input>

                                <div>    
                                    <h6>Is Active</h6>
                                    <input className="checkbox" type="checkbox" 
                                            onClick={(e)=> setCondition4(e.target.checked)}></input>            
                                </div>
                                <div>
                                    <h6>Is Hot List</h6>
                                    <input className="checkbox" type="checkbox" 
                                            onClick={(e)=> setCondition5(e.target.checked)}></input>
                                </div>
                                <button className="btn btn-primary" onClick={ApplyNewSearch} >Search</button>
                                <hr/>    
                                </div>
                            }

                            <td><button className="btn btn-primary" onClick={(()=> PublishLogicalDeere())}>Publish All</button></td>

                            </div>
                            </section>
                      </form>  

                        </div>
                </Col>

                <Col md="9">
                <h6>Inbound Deere AP</h6>
                <hr/>          
                <ReactPaginate 
                        previousLabel = {'<'} 
                        nextLabel = {'>'}
                        breakLabel = {'...'}
                        pageCount = { Math.ceil(pageCount) }
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}

                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}

                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}

                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}

                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                    <Table striped bordered hover variant="dark">
                    <thead>
                            <tr key= { Math.random() }>
                                <th>No.</th>
                                <th>Model</th>
                                <th>Title</th>
                                <th>Overview</th>
                                <th>System</th>
                                <th>Price</th>
                                <th className="buttons">View</th>
                                <th className="buttons">Publish</th>
                            </tr>
                    </thead>
                    <tbody>

                    { 
                        finalResult !== undefined && finalResult.map((pro) => {
                        return <>
                            <tr key= { Math.random() }>
                                <td> { pro.ProductModelNumber }</td>
                                <td> { pro.ProductModelName }</td>
                                <td> { pro.Title }</td>
                                <td> { pro.ProductOverview }</td>
                                <td> { pro.ProductSystemValue }</td>
                                <td> { pro.productPrice }</td>
                                <td><button className="viewnewwButton buttons" onClick={(()=> GetDeereView(pro.sku))}>View</button></td>
                                <td><button className="viewnewwButton buttons" onClick={(()=> PublishSingleDeere(pro.sku))}>Publish</button></td>        
                            </tr>
                        </>
                        })                  
                    }
                    </tbody>
                    </Table>
                    {/* <img width="400px" height="400px" src={uri} alt="" /> */}
                </Col>
          </Row>
          <hr/>          
            <td>
                <button className="theButtons" onClick={()=> DisplayOutboundDeere()}>View Outbound</button>
            </td>
            <td>
                <button className="theButtons" onClick={()=> ClearOutboundDeere()}>Clear Outbound</button>                            
            </td>
          <hr/>          
          <Row>
            <Col md="12">
            <Table striped bordered hover variant="dark">
                    <thead>
                            <tr key= { Math.random() }>
                                <th>Status</th>
                                <th>Model No</th>
                                <th>Model Name</th>
                                <th>Title</th>
                                <th>Keywords</th>
                                <th>Price</th>
                                <th>Currency</th>
                                <th>Componenet</th>
                                <th className="editbutton">Edit</th>
                            </tr>
                    </thead>
                    <tbody>

                    { 
                        PublishedRecord !== undefined && PublishedRecord.map((pro) => {
                        return <>
                            <tr key= { Math.random() }>
                                <td> {pro.status}</td>
                                <td> { pro.modelNumber }</td>
                                <td> { pro.modelName }</td>
                                <td> { pro.title }</td>
                                <td> { pro.keywords }</td>
                                <td> { pro.productPrice }</td>
                                <td> { pro.currency }</td>
                                <td> { pro.componentDesc }</td>
                                <td><button className="editButton editbutton" onClick={(()=> GetDeereEdit(pro.productSku))}>Edit</button></td>
                            </tr>
                        </>
                        })                  
                    }
                    </tbody>
                    </Table>

            </Col>
          </Row>
      </Container>
    </>
  )
}

export default ProcessDeereAPI