
import React, { useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useForm  } from 'react-hook-form'
import './style.css'

const OutboundEMSAPIView=()=> {
    const location = useLocation();
    const navigate = useNavigate();
    const [check1, setCheck1] = useState();
    const [check2, setCheck2] = useState();
    const [check3, setCheck3] = useState();

    const { 
      register, watch, handleSubmit,
      // setValue, resetValue,unregister, 
      // resetField, setError, clearErrors, 
      // setFocus, getValues, trigger, control,
      formState: { 
          errors, 
         // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
      } } 
  = useForm(
    //{ defaultValues: { Id: 0, Username: '', Password1: '', RoleId:'', ProfileId: '', Status: 'Inactive' } }
    );

    const onSubmit = (data, event) => {
      event.preventDefault();
      alert('Form Update Successful ! ');        
  }
  
    const CancelAction = ((e) => {
      e.preventDefault();
      navigate('/Configurations');
    })

    const booleanify = (value: string): boolean => {
        const truthy: string[] = [
            'true',
            'True',
            '1'
        ]
      
        return truthy.includes(value)
      }


      return (
            <>
                  <Container>
                    <Row>
                    <Col  md="10">
                        <div className="heading">
                            <h5 className = "mainheading">EMS API View Form</h5>
                            <hr/>
                        </div>
                    
                        <form onSubmit={handleSubmit(onSubmit)}>

                        <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>
                        <table>

                        <tr key= { Math.random() }>
                            <td>
                                <label className="BlueLabel">Equipment ID</label>
                                <input disabled defaultValue = { location.state.data.equipmentItemId } className="input" type="text" {...register("equipmentItemId")}  id="equipmentItemId" placeholder="equipmentItemId"></input>
                                <h2>{errors.equipmentItemId && <p>{errors.equipmentItemId.message}</p>}</h2>
                            </td>
                            <td>
                            <label  className="BlueLabel">Serial Number</label>
                            <input disabled  defaultValue = { location.state.data.serialNumber } className="input" type="text" {...register("serialNumber")}  id="serialNumber" placeholder="serialNumber"></input>
                                <h2>{errors.serialNumber && <p>{errors.serialNumber.message}</p>}</h2>
                            </td>
                            <td>
                            <label className="BlueLabel">Tag Number</label>
                            <input disabled  defaultValue = { location.state.data.tagNumber } className="input" type="text" {...register("tagNumber")}  id="tagNumber" placeholder="tagNumber"></input>
                                <h2>{errors.tagNumber && <p>{errors.tagNumber.message}</p>}</h2>
                            </td>

                        </tr>

                        <tr key= { Math.random() }>
                            <td>
                            <label className="BlueLabel">Group Name</label>
                            <input disabled  defaultValue = { location.state.data.groupName } className="input" type="text" {...register("groupName")}  id="groupName" placeholder="groupName"></input>
                                <h2>{errors.groupName && <p>{errors.groupName.message}</p>}</h2>
                            </td>
                            <td>
                            <label className="BlueLabel">Category Name</label>
                            <input disabled  defaultValue = { location.state.data.categoryName } className="input" type="text" {...register("categoryName")}  id="categoryName" placeholder="categoryName"></input>
                                <h2>{errors.categoryName && <p>{errors.categoryName.message}</p>}</h2>
                            </td>
                            <td>
                            <label className="BlueLabel">Make Name</label>
                            <input disabled  defaultValue = { location.state.data.makeName } className="input" type="text" {...register("makeName")}  id="makeName" placeholder="makeName"></input>
                                <h2>{errors.makeName && <p>{errors.makeName.message}</p>}</h2>
                            </td>

                        </tr> 




                        <tr key= { Math.random() }>
                            <td>
                            <label className="BlueLabel">Modal Name</label>
                            <input disabled  defaultValue = { location.state.data.modelName } className="input" type="text" {...register("modelName")}  id="modelName" placeholder="modelName"></input>
                                <h2>{errors.modelName && <p>{errors.modelName.message}</p>}</h2>
                            </td>
                            <td>
                            <label className="BlueLabel">Advertised Price</label>
                            <input disabled  defaultValue = { location.state.data.advertisedPrice } className="input" type="text" {...register("advertisedPrice")}  id="advertisedPrice" placeholder="advertisedPrice"></input>
                                <h2>{errors.advertisedPrice && <p>{errors.advertisedPrice.message}</p>}</h2>
                            </td>
                            <td>
                            <label className="BlueLabel">Reading Hours</label>
                            <input disabled  defaultValue = { location.state.data.meterReadingHours } className="input" type="text" {...register("meterReadingHours")}  id="meterReadingHours" placeholder="meterReadingHours"></input>
                                <h2>{errors.meterReadingHours && <p>{errors.meterReadingHours.message}</p>}</h2>
                            </td>

                        </tr> 


                        <tr key= { Math.random() }>
                            <td>
                            <label className="BlueLabel">Modal Year</label>
                            <input disabled   defaultValue = { location.state.data.modelYear } className="input" type="text" {...register("modelYear")}  id="modelYear" placeholder="modelYear"></input>
                                <h2>{errors.modelYear && <p>{errors.modelYear.message}</p>}</h2>
                            </td>
                            <td>
                            <label className="BlueLabel">Store</label>
                            <input disabled  defaultValue = { location.state.data.store } className="input" type="text" {...register("store")}  id="store" placeholder="store"></input>
                                <h2>{errors.store && <p>{errors.store.message}</p>}</h2>
                            </td>
                            <td>
                            <label className="BlueLabel">Store Ship State</label>
                            <input disabled   defaultValue = { location.state.data.storeShipState } className="input" type="text" {...register("storeShipState")}  id="storeShipState" placeholder="storeShipState"></input>
                                <h2>{errors.storeShipState && <p>{errors.storeShipState.message}</p>}</h2>
                            </td>
                        </tr> 



                        <tr key= { Math.random() }>
                            <td>
                            <label className="BlueLabel">Primary Phone</label>
                            <input disabled  defaultValue = { location.state.data.phone } className="input" type="text" {...register("phone")}  id="phone" placeholder="phone"></input>
                                <h2>{errors.phone && <p>{errors.phone.message}</p>}</h2>
                            </td>
                            {/* <td>
                            <label className="BlueLabel">isNew</label>
                             <input disabled   defaultValue = { location.state.data.isNew } className="input" type="text" {...register("field15")}  id="field15" placeholder="Field 15"></input>
                                <h2>{errors.field15 && <p>{errors.field15.message}</p>}</h2>
                            </td> */}
                            <td>
                            <label  className="BlueLabel">Is New.........</label>
                              <input disabled className="checkbox" type="checkbox" onClick={(e)=> setCheck1(e.target.checked)} checked={booleanify(location.state.data.isNew.toString())}  {...register("isNew")}  id="isNew" placeholder="isNew"></input>
                              <h2>{errors.isNew && <p>{errors.isNew.message}</p>}</h2>
                          </td>


                            <td>
                            <label className="BlueLabel">Description</label>
                            <input disabled   defaultValue = { location.state.data.description } className="input" type="text" {...register("description")}  id="description" placeholder="description"></input>
                                <h2>{errors.description && <p>{errors.description.message}</p>}</h2>
                            </td> 
                        </tr> 


                        <tr key= { Math.random() }>
                            <td>
                            <label className="BlueLabel">Store Number</label>
                            <input disabled   defaultValue = { location.state.data.storeNumber } className="input" type="text" {...register("storeNumber")}  id="storeNumber" placeholder="storeNumber"></input>
                                <h2>{errors.storeNumber && <p>{errors.storeNumber.message}</p>}</h2>
                            </td>
                            {/* <td>
                            <label className="BlueLabel">Is Active</label>
                            <input disabled  defaultValue = { location.state.data.isActive } className="input" type="text" {...register("field18")}  id="field18" placeholder="Field 18"></input>
                                <h2>{errors.field18 && <p>{errors.field18.message}</p>}</h2>
                            </td> */}
                            <td>
                            <label className="BlueLabel">Is Active.....</label>
                              <input disabled className="checkbox" type="checkbox" onClick={(e)=> setCheck2(e.target.checked)} checked={booleanify(location.state.data.isActive.toString())}  {...register("isActive")}  id="isActive" placeholder="isActive"></input>
                              <h2>{errors.isActive && <p>{errors.isActive.message}</p>}</h2>
                          </td>



                            {/* <td>
                            <label className="BlueLabel">Is Hot List</label>
                            <input  disabled  defaultValue = { location.state.data.isHotList } className="input" type="text" {...register("field19")}  id="field19" placeholder="Field 19"></input>
                                <h2>{errors.field19 && <p>{errors.field19.message}</p>}</h2>
                            </td> */}

                            <td>
                            <label className="BlueLabel">Is HotList..</label>
                              <input disabled className="checkbox" type="checkbox" onClick={(e)=> setCheck3(e.target.checked)} checked={booleanify(location.state.data.isHotList.toString())}  {...register("isHotList")}  id="isHotList" placeholder="isHotList"></input>
                              <h2>{errors.isHotList && <p>{errors.isHotList.message}</p>}</h2>
                          </td>

                        </tr> 


                        <tr key= { Math.random() }>
                            <td>
                            <label className="BlueLabel">Attachment List</label>
                            <input disabled  defaultValue = { location.state.data.attachmentList } className="input" type="text" {...register("attachmentList")}  id="attachmentList" placeholder="attachmentList"></input>
                                <h2>{errors.attachmentList && <p>{errors.attachmentList.message}</p>}</h2>
                            </td>
                            <td>
                            <label className="BlueLabel">Make Order</label>
                            <input  disabled  defaultValue = { location.state.data.makeOrder } className="input" type="text" {...register("makeOrder")}  id="makeOrder" placeholder="makeOrder"></input>
                                <h2>{errors.makeOrder && <p>{errors.makeOrder.message}</p>}</h2>
                            </td>

                            <td>
                            <label className="BlueLabel">BA</label>
                            <input  disabled  defaultValue = { location.state.data.ba } className="input" type="text" {...register("ba")}  id="ba" placeholder="ba"></input>
                                <h2>{errors.ba && <p>{errors.ba.message}</p>}</h2>
                            </td>
                        </tr>  
                        
                        </table>
                        <div className="container">
                            <div className="row">
                                <div className="col md-3 sm-3 xs-2">
                                    <span>
                                      <button className="submit" type="submit">Update</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>  
                    <ToastContainer/>                                
             </Col>
            <Col  md="2">
                        <div className="heading">
                            <h5 className = "operation">Operation</h5>
                            <hr/>
                        </div>

                <span>
                  <button className="viewButton" onClick={(e)=>CancelAction(e)} type="button">Cancel</button>
                </span>
                <hr/>
                <span>
                    <img src= { location.state.data.url } width="250" height="250"/>
                </span>
              </Col>

             </Row>
             </Container>
            </>
        );
  }
 
export default OutboundEMSAPIView;