import React, {  useEffect, useState } from 'react'
import Card from '../forms/ui/Card'
import { Row, Col, Container, Table } from 'react-bootstrap'
import classes from './forms.css'


import { useForm } from 'react-hook-form'
import { string } from 'yup';
import './forms.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import ProfileService from '../../services/ProfileService'
import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Modal from '../../components/Modal/Modal'
import { boolean, number } from 'yup/lib/locale';
import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'
import ProductAttachmentService from '../../services/ProductAttachmentService'
import ProductBaseMachineService from '../../services/ProductBaseMachineService'
import ProductAttachmentMediaService from '../../services/ProductAttachmentMediaService'
import ProductAttachmentMasterService from '../../services/ProductAttachmentMasterService'

// *******************************************************************
import ReactPaginate from 'react-paginate'
//import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { industryUri, pageSize } from '../../constants'
import MetaData from '../../services/Data/MetaData'
// ********************************************************************


let formValues = {
  id: number,
  productCode: string,
  name: string,
  price: number,
  advertisingDesc: string,
  media: string,
  deereMatching: string,
  riversEMSMatching: string
}

const ProductAttachment = () => {
  const auth = useAuth();  
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);
  const [RecordAdded, setRecordAdded] = useState(0);
  const [ProductCode, setProductCode] = useState();
  
  // **************************************************************************************************
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setAction] = useState(false);
  const [items, setItems] = useState([]);
  const [media,setMedia] = useState([]);

  const [pageCount, setPageCount] = useState([]);
  const [ProductSet, setProductSet] = useState([]);
  const [RuleSet, setRuleSet] = useState([]);
  const [PackageDesc, setPackageDesc] = useState();
  const [ModelName, setModalName] = useState();
  const [MasterId, setMasterId] = useState();

  const { 
    register, watch, handleSubmit, 
    // setValue, resetValue,unregister, 
    // resetField, setError, clearErrors, 
    // setFocus, getValues, trigger, control,
    formState: { 
        errors, 
       // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
    } } 
    = useForm(
      { 
      //  defaultValues: {  id: 0, productCode: '', name: '', price: 0, advertisingDesc: '', media: '', deereMatching: '', riversEMSMatching: '' } 
      });

    useEffect(() => {
      const getComments = async () => {
          const data = await ProductAttachmentService.getAllProductsAttachment();
          const total = data.length;
          const TotalPages = Math.ceil(total/pageSize);
          setPageCount(TotalPages);
          setItems(data);

          const PageOneFromServer = await fetchComments(1);
          setItems(PageOneFromServer);
          const dataSet = await MetaData.getAllProduct();
          setProductSet(dataSet);

      };
  
      getComments();
  
      MetaData.getAllRules().then(data=> {
        console.log('data == >>>> ',data);
        setRuleSet(data);
        });       


    },[RecordAdded]);     
    
    const fetchComments = async(currentPage) => {
            const data = await ProductAttachmentService.getPagedProductAttachment(currentPage, pageSize);
            return data;
        };
          
  
        const handlePageClick = async (data) => {
            let currentPage = data.selected + 1;
            const commentsFromServer = await fetchComments(currentPage);
            setItems(commentsFromServer);
          }
          
    // **************************************************************************************************



    const onSubmit = (data, event) => {
      event.preventDefault();
      if (data.productCode1 === '' || data.productCode1 === 'Select'){
        alert('Please Select Rule Code ! ');
        return;
      } 
      if (MasterId === '' || MasterId === undefined){
        alert('Please Select Rule Code ! ');
        return;
      }
      //console.log('data.name=',data.name);

      // *******************************************************************
      // Duplicates Name Controll
      // *******************************************************************
      // ProductAttachmentService.getAttachmentName(data.name).then(da=>{
      //   console.log('DATA RECORDS ====',da);
      //   console.log(da.data);
      //   if (da.length > 0 || da === undefined){
      //     console.log('da1 === >>>',da.length);
      //     alert('The same name exists in database, choose different name !')
      //     return;
      //   }
      //   else
      //   {
      //     console.log('da2 === >>>',da.length);
      //     console.log(da.data);
      //   }
      // })

      formValues = {
        id: 0,
        productCode: data.productCode1,
        masterId: MasterId,
        name: data.name,
        price: data.price,
        advertisingDesc: data.advertisingDesc,
      };
      console.log(formValues);

      ProductAttachmentService.createProductAttachment(formValues).then((attach)=>{
        console.log('Form Values = ', formValues);
        console.log('Attachment = ',attach);
        toast.success('Product Attachment Added Successfully',{ position: 'bottom-center'});
        setRecordAdded((previousState)=> previousState + 1);
      }).catch((e) => {
        console.log('Adding Attachment Error', e.message);
        toast.error('Adding Attachment Error ',{ position: 'bottom-center'})
    });
  }


  const updateAttachment = () =>{
    setValueForm();
    console.log('Changes In Attachment = ',formValues);
     ProductAttachmentService.editProductAttachment(formValues).then((attach)=>{
      attach.data ? toast.success('Attachment Edited Successfully',{ position: 'bottom-center'}):toast.info('Attachment does not exists',{ position: 'bottom-center'});
        setRecordAdded((previousState)=> previousState + 1);
        clearValueForm();
    })
    .catch((e) => {
        console.log('Edit Attachment Error', e.message);
        toast.error('Edit Attachment Error ',{ position: 'bottom-center'})
    });
}

const deleteAttachment = () =>{
  setValueForm();
  console.log('Changes In Attachment = ',formValues);
   ProductAttachmentService.deleteProductAttachment(formValues).then((attach)=>{
    attach.data ? toast.success('Attachment Deleted Successfully',{ position: 'bottom-center'}):toast.info('Attachment does not exists',{ position: 'bottom-center'});
      setRecordAdded((previousState)=> previousState + 1);
      clearValueForm();
  })
  .catch((e) => {
      console.log('Delete Attachment Error', e.message);
      toast.error('Delete Attachment Error ',{ position: 'bottom-center'})
  });
}

const RemoveProductMedia = (selectedmedia) =>{

  console.log('Id to remove = ', selectedmedia);

  const payload ={
    "id": selectedmedia,
    "productAttachmentId": 0,
    "mediaType": "",
    "mediaURL": ""
  }

  ProductAttachmentMediaService.deleteProductMedia(payload).then(async rec=>{
  //  console.log('data = ', rec.data);
    toast.success('Media Removed Successfully',{ position: 'bottom-center'});
    ProductAttachmentMediaService.getProductMedia(rec.data.productAttachmentId).then((data)=>{
      console.log('data=>>',data)
      setMedia(data);
    });
    await sleep(2000);
 
  })

};

const DEF_DELAY = 1000;

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms || DEF_DELAY));
}

  const GetProductAttachment = async (selectedAttachment) =>{

    ProductAttachmentService.getProductAttachment(selectedAttachment).then(async (resp) =>{
      console.log('response = ',resp)
      ProductAttachmentMediaService.getProductMedia(resp.id).then((data)=>{
        console.log('Media = ',data)
        setMedia(data);
      });
      
      await sleep(1000);

     // const selectedAttachment =  resp;
          document.getElementById('id').value= resp.id;
          document.getElementById('productCode1').value= resp.productCode;
          document.getElementById('name').value= resp.name;
          document.getElementById('price').value= resp.price;
          document.getElementById('advertisingDesc').value= resp.advertisingDesc;
          //document.getElementById('media1').value= resp.media;
          //document.getElementById('deereMatching').value= resp.deereMatching;
          //document.getElementById('riversEMSMatching').value= resp.riversEMSMatching;
          console.log(selectedAttachment);          
      }).catch((e) => {
          console.log('Error', e.message);
      });    
}

const setValueForm = (() => { 
  formValues = {
      id: document.getElementById('id').value,
      productCode: document.getElementById('productCode1').value,
      name: document.getElementById('name').value,
      price: document.getElementById('price').value,
      advertisingDesc: document.getElementById('advertisingDesc').value,
      //media: document.getElementById('media1').value,
      //deereMatching: document.getElementById('deereMatching').value,
      //riversEMSMatching: document.getElementById('riversEMSMatching').value
   };
});

const clearValueForm = (() => {
  formValues = {
      id: '',
      productCode: '',
      name: '',
      price: '',
      advertisingDesc: '',
      media: '',
      deereMatching: '',
      riversEMSMatchin: ''
   };

   document.getElementById('id').value= '';
   document.getElementById('productCode1').value= '';
   document.getElementById('name').value= '';
   document.getElementById('price').value= '';
   document.getElementById('advertisingDesc').value= '';
  //  document.getElementById('media1').value= '';
  //  document.getElementById('deereMatching').value= '';
  //  document.getElementById('riversEMSMatching').value= '';
});

const AddMedia = (e)=>{
  e.preventDefault();
  if (document.getElementById('productCode1').value==="" || document.getElementById('productCode1').value=== undefined){
    alert('Please Select Rule Code ! ');
    return;
  }
  if (document.getElementById('mediaType').value==="" || document.getElementById('mediaType').value=== undefined){
    alert('Please Select Media Type ! ');
    return;
  }
  if (document.getElementById('media1').value==="" || document.getElementById('media1').value=== undefined){
    alert('Please Type Media URL ! ');
    return;
  }

  if (document.getElementById('media1').value !== "" && document.getElementById('productCode1').value !== "" && document.getElementById('mediaType').value !== ""){
    const payload = {
      Id: 0,
      productAttachmentId: document.getElementById('id').value,
      MediaType: document.getElementById('mediaType').value,
      MediaURL: document.getElementById('media1').value,
      };
    
      ProductAttachmentMediaService.createProductMedia(payload).then((rec)=>{
        console.log('Data Added', rec);
        document.getElementById('media1').value  = '';
//        toast.success('Product Media Added Successfully',{ position: 'bottom-center'});

        ProductAttachmentMediaService.getProductMedia(rec.data.productAttachmentId).then((data)=>{
          setMedia(data);
        });
    })
  } else {
    return;
  }
}

const onChangeProductBox = async (e)=>{
  ProductBaseMachineService.getProductBaseMachine(e).then(data=>{
    setPackageDesc(data.packageDesc);
    setModalName(data.modelName);

    ProductAttachmentMasterService.getProductAttachmentMaster(e).then(da=>{
      console.log(da.id, da.productCode);
      setMasterId(da.id);
    })      
  })

  setProductCode(e);
                document.getElementById('id').value = 0;
                document.getElementById('name').value = '';
                document.getElementById('price').value = '';
                document.getElementById('advertisingDesc').value = '';

  // ProductAttachmentMediaService.getProductMedia(e).then((data)=>{
  //   setMedia(data);
  //   console.log('Media Data = >',data)
  // });

  // ProductAttachmentService.getProductAttachment(e).then((resp) =>{
  //   const selectedAttachment =  resp;
  //         if (resp.id !== undefined){
  //               document.getElementById('id').value= resp.id;
  //               document.getElementById('name').value= resp.name;
  //               document.getElementById('price').value= resp.price;
  //               document.getElementById('advertisingDesc').value= resp.advertisingDesc;
  //               // document.getElementById('media1').value= resp.media;
  //               // document.getElementById('deereMatching').value= resp.deereMatching;
  //               // document.getElementById('riversEMSMatching').value= resp.riversEMSMatching;  
  //         }
  //         else
  //         {
  //               document.getElementById('id').value = 0;
  //               document.getElementById('name').value = '';
  //               document.getElementById('price').value = '';
  //               document.getElementById('advertisingDesc').value = '';
  //               // document.getElementById('media1').value = '';
  //               // document.getElementById('deereMatching').value = '';
  //               // document.getElementById('riversEMSMatching').value = '';  
  //         }
  //         console.log(selectedAttachment);
  //     }).catch((e) => {
  //         console.log('Error', e.message);
  //     });    

    }

  return (
    <>
      <Container>
      <Row>
              <Col md="12">
              <ReactPaginate 
                previousLabel = {'<'} 
                nextLabel = {'>'}
                breakLabel = {'...'}
                pageCount = { Math.ceil(pageCount) }
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}

                containerClassName={'pagination justify-content-center'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}

                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}

                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}

                breakLinkClassName={'page-link'}
                activeClassName={'active'}
            />
              <Table striped bordered hover variant="dark">
              <thead>
                    <tr key= { Math.random() }>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Advertise</th>
                      <th>Edit</th>
                    </tr>
              </thead>
              <tbody>

              { 
                items.map((attach) => {
                return <>
                      <tr key= { Math.random() }>
                          <td> { attach.productCode }</td>
                          <td> { attach.name }</td>
                          <td> { attach.price }</td>
                          <td> { attach.advertisingDesc }</td>
                          <td className="tinyinput"><button className="selectButton" onClick={(()=> GetProductAttachment(attach.id))}> Select</button></td>
                      </tr>
                  </>
                })                  
              }
              </tbody>
              </Table>
            </Col>
          </Row>

          <Row>
                <Col md="4">
                        <div className="heading">
                            <h5 className="mainheading">Attachments</h5>
                            <hr/>
                        </div>              

        
                          <form onSubmit={handleSubmit(onSubmit)}>

                          <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>

                          <label>Rule Code</label>
                              <select 
                                className="custom-select"
                                {...register("productCode1")} 
                                  id="productCode1"
                                  value = { ProductCode }
                                  onChange={(e)=> {
                                    onChangeProductBox(e.target.value);
                            			  }
                                }
                                >
                                <option  key={Math.random()}   value="Select">Select Rule</option> 
                                {
                                RuleSet.map((record)=>
                                ( 
                                    <option  key={Math.random()}  value={record.productCode}>{record.productCode}</option>
                                ))
                                }
                                </select>   

                              <h2>{errors.productCode1 && <p>{errors.productCode1.message}</p>}</h2>
                              <div className="Details">  
                                    {ModelName}
                                </div>

                          <label>Name</label>
                          <input className="input" type="text" {...register("name")}  id="name" placeholder="Name"></input>
                          <h2>{errors.name && <p>{errors.name.message}</p>}</h2>

                          <label>Price</label>
                          <input className="input" type="text" {...register("price")}  id="price" placeholder="Price"></input>
                          <h2>{errors.price && <p>{errors.price.message}</p>}</h2>

                          <label>Adv Desc</label>

                          <input className="shortinput" type="text" {...register("advertisingDesc")}  id="advertisingDesc" placeholder="Advertising Desc"></input>
                          <h2>{errors.advertisingDesc && <p>{errors.advertisingDesc.message}</p>}</h2>

                          <label>Media Type</label>
                          <select className="custom-select" id="mediaType">
                                <option  key={Math.random()}   value="Image">Image</option> 
                                <option  key={Math.random()}   value="Audio">Audio</option> 
                                <option  key={Math.random()}   value="Video">Video</option> 
                          </select>   

                          <label>Media</label>
                          <input  className="shortinput" type="text" id="media1" placeholder="Media"></input>
                          <table>
                              <tr key= { Math.random() }>
                                <td>
                                    <button className="ruleButton" onClick={(e)=>{AddMedia(e)}}> Add</button>
                                </td>
                              </tr>
                            </table>      


                          <label hidden>Deere</label>
                          <input hidden className="shortinput" type="text"  id="deereMatching" placeholder="Deere Matching"></input>
                          <h2>{errors.deereMatching && <p>{errors.deereMatching.message}</p>}</h2>

                          <label hidden>Rivers EMS</label>
                          <input hidden className="shortinput" type="text"  id="riversEMSMatching" placeholder="Rivers EMS Matching"></input>
                          <h2>{errors.riversEMSMatching && <p>{errors.riversEMSMatching.message}</p>}</h2>

                          <hr/>
                          <div className="container">
                              <div className="row">
                                  <div className="col md-3 sm-3 xs-2">
                                      <table>
                                        <tbody>
                                          <tr key= { Math.random() }>
                                            <td>
                                                <button className="btn btn-primary" type="submit">Save</button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                  </div>
                              </div>
                          </div>
                      </form>  
                </Col>

            <Col md = "2">
            <div className="heading">
            <h5 className="operation">Operations</h5>
            <hr/>
            </div>              

            <div className="container">
                <div className="row">
                    <div className="col md-3 sm-3 xs-2">
                        <button onClick={ updateAttachment } className="button" type="button">Update</button>
                    </div>

                    <div className="col md-3 sm-3 xs-2">
                        <button onClick={ deleteAttachment } className="button" type="button">Delete</button>
                    </div>

                    <div className="col md-3 sm-3 xs-2">
                        <button hidden className="button"  onClick={() => { setValueForm(); setModalOpen(true); } }> Delete </button>
                    </div>

                    {    
                        modalOpen && 
                        <Modal 
                            setOpenModal={ setModalOpen } 
                            setAction = { setAction }
                            formValues = { formValues }
                            formName = "Product Attachment"
                            title = "Are you sure to delete ?" 
                            message = "Product Attachment will be deleted !" 
                            setRecordAdded = { setRecordAdded }
                            />
                    }            
                </div>
            </div>
            </Col>
            <Col md="6">
            <div className="heading">
            <h5 className="operation">Media</h5>
            <hr/>
            </div>              
            <Table striped bordered hover variant="dark">
              <thead>
                    <tr key= { Math.random() }>
                      <th>Item Id</th>
                      <th>Type</th>
                      <th>URL</th>
                      <th>Edit</th>
                    </tr>
              </thead>
              <tbody>

              { 
                media.map((med) => {
                return <>
                      <tr key= { Math.random() }>
                          <td> { med.productAttachmentId }</td>
                          <td> { med.mediaType }</td>
                          <td> { med.mediaURL }</td>
                          <td className="tinyinput"><button className="selectButton" onClick={(()=> RemoveProductMedia(med.id))}> Remove</button></td>
                      </tr>
                  </>
                })                  
              }
              </tbody>
              </Table>
            </Col>

          </Row>

      </Container>
    </>
  )
}

export default ProductAttachment