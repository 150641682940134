import React, { useState, useRef } from "react";
import {
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import './stepper.css'
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { string } from "yup/lib/locale";
import { number } from "yup";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Industry And Machine",
    "Display Name",
    "Inventory Matching",
    "Pricing And Promotion",
    "Description",
    "Media",
  ];
}
const IndustryAndMachine = (formValues) => {
  const industry = useRef();
  const machineType = useRef();
  const subMachineType = useRef();
  
  const setVal = ()=> {
    formValues = { ...formValues, industry: industry}
    console.log('parameter received = ', formValues);
  }

  return (
    <>        
        <input hidden className="input" type="text"  id="id" placeholder="ID"></input>

        <input className="input" type="text"  id="productCode" placeholder="Product Code"></input>

        <select className="select"  id="industry" ref={industry}>
          <option value="tag">Select Industry</option>
          <option value="construction">Construction</option>
          <option value="roadbuilding">Road Building</option>
          <option value="forestry">Forestry</option>
        </select>

    <select className="select"  id="machineType" ref={machineType}>
        <option value="tag">Select Machine Type</option>
        <option value="lawn">Lawn Aerator</option>
        <option value="air">Air Compressor</option>
        <option value="utility">Utility Vehicles</option>
        </select>

        <select className="select" id="subMachineType" ref={subMachineType} onChange={setVal()}>
            <option value="tag">Select Sub Type</option>
            <option value="sub1">Sub Type 1</option>
            <option value="sub2">Sub Type 2</option>
            <option value="sub3">Sub Type 3</option>
        </select>
    </>
  );
};
const DisplayName = () => {
  const make = useRef();
  const modelName = useRef();
  const packageDesc = useRef();
  return (
    <>
        <select className="select" id="make" ref={make}>
            <option value="tag">Select Make</option>
            <option value="make1">Make 1</option>
            <option value="make2">Make 2</option>
            <option value="make3">Make 3</option>
        </select>


        <input className="input" type="text"  id="modelName"  ref={modelName} placeholder="Model Name"></input>
        <input className="input" type="text"  id="packageDesc"  ref={packageDesc}  placeholder="Package Description"></input>

    </>
  );
};

const InventoryMatching = () => {
  const inventoryMatching = useRef();
  const riversEMSMatching = useRef();
  return (
    <>
        <input className="input" type="text" ref={inventoryMatching} id="inventoryMatching" placeholder="Inventory Matching"></input>
        <input className="input" type="text"  ref={riversEMSMatching}  id="riversEMSMatching" placeholder="Rivers EMS Matching"></input>
    </>
  );
};
const PricingAndPromotion = () => {
  const regularPrice = useRef();
  const riversPrice = useRef();
  const validDate = useRef();
  return (
    <>
        <input className="input" type="text"  ref={regularPrice} id="regularPrice" placeholder="Regular Price"></input>
        <input className="input" type="text"  ref={riversPrice} id="riversPrice" placeholder="Rivers Price"></input>
        <input className="input" type="text"  ref={validDate}  id="validDate" placeholder="Valid Date"></input>

    </>
  );
};


const Description = () => {
    const advertiseDesc = useRef();
    const disclaimer = useRef();
    const promotionsAvailable = useRef();
      return (
      <>
        <input className="input" type="text"  ref={advertiseDesc}  id="advertiseDesc" placeholder="Advertise Description"></input>
        <input className="input" type="text"  ref={disclaimer} id="disclaimer" placeholder="Disclaimer"></input>
        <input className="input" type="text"  ref={promotionsAvailable}  id="promotionsAvailable" placeholder="Promotions Available"></input>
      </>
    );
  };

  
  const Media = () => {
    const media = useRef();
    return (
      <>
        <input className="input" type="text"  ref={media}   id="media" placeholder="media"></input>
      </>
    );
  };
  
function getStepContent(step, formValues) {
  switch (step) {
    case 0:
      return <IndustryAndMachine formValues = { formValues } />;
    case 1:
      return <DisplayName />;
    case 2:
      return <InventoryMatching />;
    case 3:
      return <PricingAndPromotion />;
    case 4:
        return <Description />;
    case 5:
        return <Media />;
        default:
      return "unknown step";
  }
}

const BaseMachinesStepper = () => {
  let formValues = {
    id: number,
    productCode: string,
    industry: string,
    machineType: string,
    subMachineType: string,
    make: string,
    modelName: string,
    packageDesc: string,
    inventoryMatching: string,
    riversEMSMatching: string,
    regularPrice: number,
    riversPrice: number,
    validDate: Date,
    advertiseDesc: string,
    disclaimer: string,
    promotionsAvailable: string,
    media: string,
}
 formValues = {
  id: 11,
  productCode: 'P0001',
  industry: 'Aviation',
  machineType: 'Machine Type',
  subMachineType: 'Sub Machine Type',
  make: 'Make',
  modelName: 'Model Name',
  packageDesc: 'Package Desc',
  inventoryMatching: 'IV Match',
  riversEMSMatching: 'EMS Match',
  regularPrice: 12000,
  riversPrice: 134000,
  validDate: '1/1/2020',
  advertiseDesc: 'Adv Desc',
  disclaimer: 'Disclaimer',
  promotionsAvailable: 'Available',
  media: 'Media',
}

  const classes = useStyles();
  const methods = useForm({
    mode: "all",
    defaultValues: {
        id: 0,
        productCode: '',
        industry: '',
        machineType: '',
        subMachineType: '',
        make: '',
        modelName: '',
        packageDesc: '',
        inventoryMatching: '',
        riversEMSMatching: '',
        regularPrice: 0,
        riversPrice: 0,
        validDate: new Date(),
        advertiseDesc: '',
        disclaimer: '',
        promotionsAvailable: '',
        media: '',
    },
  });
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();

  const isStepOptional = (step) => {
    return step === 1 || step === 2;
  };

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = (data) => {
    console.log('Data Next = ', data);

    formValues = {
      id: data.id,
      productCode: data.productCode,
      industry: data.industry,
      machineType: data.machineType,
      subMachineType: data.subMachineType,
      make: data.make,
      modelName: data.modelName,
      packageDesc: data.packageDesc,
      inventoryMatching: data.inventoryMatching,
      riversEMSMatching: data.riversEMSMatching,
      regularPrice: data.regularPrice,
      riversPrice: data.riversPrice,
      validDate: data.validDate,
      advertiseDesc: data.advertiseDesc,
      disclaimer: data.disclaimer,
      media: data.media,
      promotionsAvailable: data.promotionsAvailable
    }


    if (activeStep === steps.length - 1) {
      fetch("https://jsonplaceholder.typicode.com/comments")
        .then((data) => data.json())
        .then((res) => {
          console.log(res);
          setActiveStep(activeStep + 1);
        });
    } else {
      setActiveStep(activeStep + 1);
      setSkippedSteps(
        skippedSteps.filter((skipItem) => skipItem !== activeStep)
      );
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
  };

  // const onSubmit = (data) => {
  //   console.log(data);
  // };
  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography
                variant="caption"
                align="center"
                style={{ display: "block" }}
              >
                optional
              </Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography variant="h3" align="center">
          Thank You
        </Typography>
      ) : (
        <>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(()=> handleNext(formValues))}>
              {getStepContent(activeStep, formValues)}

              <Button
                className={classes.button}
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                back

              </Button>
              {isStepOptional(activeStep) && (
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                >
                  skip
                </Button>
              )}

              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                // onClick={handleNext}
                type="submit"
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>

            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
};

export default BaseMachinesStepper;
