
import axiosInstance from '../services/AxiosInstance'

export class AdditionalFieldsService {
    static  getAllAdditionalFields() {
        return axiosInstance.get(`/AdditionalFields`)
                    .then((resp)=>resp.data);
    }

    static  getPagedAdditionalFields(currentPage, pageSize) {
        return axiosInstance.get(`/AdditionalFields/GetPageAdditionalFields?PageNumber=${currentPage}&PageSize=${pageSize}`)
                    .then((resp)=>resp.data);
    }

    static  getAdditionalFields(RuleName, FieldName) {
        return axiosInstance.get(`AdditionalFields/GetAdditionalFields?RuleName=${RuleName}&fieldName=${FieldName}`)
                    .then((resp)=>resp.data);
    }

    static  getAdditionalFieldsRule(RuleName) {
        return axiosInstance.get(`AdditionalFields/GetAdditionalFieldsBase?RuleName=${RuleName}`)
                    .then((resp)=>resp.data);
    }

    static addAdditionalFields(payload) {
        console.log('AdditionalFields = ', payload);
        return axiosInstance.post(`/AdditionalFields`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editAdditionalFields(payload) {
        return axiosInstance.patch("AdditionalFields/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteAdditionalFields(payload) {
        return axiosInstance.post("AdditionalFields/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteAdditionalFieldsSpecial(payload) {
        return axiosInstance.post("AdditionalFields/DeleteSpecial", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default AdditionalFieldsService