import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class MediaService {
    static  getAllMedia() {
        return axiosInstance.get(`/ProductBaseMachineMedia`)
                    .then((resp)=>resp.data);
    }

    static  getPagedMedia(currentPage, pageSize) {
        return axiosInstance.get(`/ProductBaseMachineMedia/GetPageProductMedia?PageNumber=${currentPage}&PageSize=${pageSize}`)
                    .then((resp)=>resp.data);
    }

    static  getMedia(MediaId) {
        return axiosInstance.get(`ProductBaseMachineMedia/GetProductMedia?Id=${MediaId}`)
                    .then((resp)=>resp.data);
    }

    static  getMediaId(Id) {
        return axiosInstance.get(`ProductBaseMachineMedia/GetProductMediaId?Id=${Id}`)
                    .then((resp)=>resp.data);
    }

    static addMedia(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.post(`/ProductBaseMachineMedia`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editMedia(payload) {
        return axiosInstance.patch("ProductBaseMachineMedia/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteMedia(payload) {
        return axiosInstance.post("ProductBaseMachineMedia/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default MediaService