import React from 'react'
// const App = () => {
//   return (
//   <div><h1>Bold is Better</h1></div>
//   )
// }

function App() {
  return (
    <div className="App">
      </div>
  );
}
export default App;
