import React, {useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import AuthService from '../../services/AuthService'
import UserService from '../../services/UserService'

import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'
import Industry from './Industry';
import MachineType from './MachineType';
import MachineSubTypes from './MachineSubTypes';
import Make from './Make';
import Store from './Store';
import Model from './Model';
//import Groups from './Groups';

const MasterData = () => {
  const auth = useAuth();  
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);

  useEffect(() => {
    let userLogin = null;
      try
      {
        userLogin = JSON.parse(localStorage.getItem('credentials')).userName;
        AuthService.getForcedLogoutUser(userLogin).then((login)=>{                
            if ((login.loginStatus === 'ForcedLogout') || (login.loginStatus === 'Logout')) {                
                localStorage.removeItem('credentials')
                navigate("/Login");
            }});
        UserService.getUser(userLogin).then((user)=>{
            if (user.status === 'InActive') {                
                localStorage.removeItem('credentials')
                navigate("/Login");
            }});

  
      }
      catch{}
      
      
      if (userLogin === null || userLogin === undefined) {
        setLogin(false);
        navigate('/Login');
      }
      else
      {
        setLogin(true);
      }
    },[]);
  return (
    <>

        {
          JSON.parse(localStorage.getItem('credentials')) && 
          JSON.parse(localStorage.getItem('roles')).role === 'admin' &&

          <>
            <Tabs defaultActiveKey="first">
              <Tab eventKey="first" title="Industry">
              <div style={{ display: 'block', width: '100%', padding: 0, background: 'white' }}>
                  <Industry />
              </div>          
              </Tab>

              <Tab eventKey="second" title="Machine Type">
              <div style={{ display: 'block', width: '100%', padding: 0, background: 'white' }}>
                  <MachineType />
              </div>          
              </Tab>

              <Tab eventKey="third" title="Machine Sub Type">
              <div style={{ display: 'block', width: '100%', padding: 0, background: 'white' }}>
                  <MachineSubTypes />
              </div>          
              </Tab>

              <Tab eventKey="fourth" title="Make">
              <div style={{ display: 'block', width: '100%', padding: 0, background: 'white' }}>
                  <Make />
              </div>          
              </Tab>

              {/* <Tab eventKey="fifth" title="Models">
              <div style={{ display: 'block', width: '100%', padding: 0, background: 'white' }}>
                  <Model />
              </div>          
              </Tab>

              <Tab eventKey="sixth" title="Stores">
              <div style={{ display: 'block', width: '100%', padding: 0, background: 'white' }}>
                  <Store />
              </div>          
              </Tab> */}

            </Tabs>
          </>
        }
    </>
  )
}

export default MasterData