import { locationUri, apiLocationUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class LocationService {
    static  getAllLocations() {
        return axiosInstance.get(`/Location`)
                    .then((resp)=>resp.data);
    }

    static  getPagedLocations(currentPage, pageSize) {
        return axiosInstance.get(`/Location/GetPageLocation?PageNumber=${currentPage}&PageSize=${pageSize}`)
                            .then((resp)=>resp.data);
    }

    static  getLocation(locationId) {
        return axiosInstance.get(`Location/GetLocation?LocationId=${locationId}`)
                    .then((resp)=>resp.data);
    }

    static  getLocationCode(locationId) {
        return axiosInstance.get(`Location/GetLocationCode?LocationId=${locationId}`)
                    .then((resp)=>resp.data);
    }

    static addLocation(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.post(`/Location`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editLocation(payload) {
        return axiosInstance.patch("Location/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deleteLocation(payload) {
        return axiosInstance.post("Location/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default LocationService