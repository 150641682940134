import React, {  useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { string } from 'yup';
import '../forms/forms.css'
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import StoreService from '../../services/StoreService'
import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Modal from '../../components/Modal/Modal'

import { number } from 'yup/lib/locale';

import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'

// *******************************************************************
import ReactPaginate from 'react-paginate'
import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { storeUri, pageSize, PortNumber, BaseURL} from '../../constants'
// ********************************************************************

let formValues = {
    id: number,
    storeCode: string,
    storeName: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    country: string,
    person: string,
    contact: string,
    email: string
}

const Store = () => {
    const auth = useAuth();  
    const navigate = useNavigate();
    const [isLogin, setLogin] = useState(false);
    const [RecordAdded, setRecordAdded] = useState(0);

    // **************************************************************************************************
    const [modalOpen, setModalOpen] = useState(false);
    const [modalAction, setAction] = useState(false);
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState([]);
  
    useEffect(() => {
      const getComments = async () => {
        //   const res = await fetch(storeUri);
        //   const data = await res.json();
          const data = await StoreService.getAllStores();
          const total = data.length;
          const TotalPages = Math.ceil(total/pageSize);
          setPageCount(TotalPages);
          setItems(data);

          const PageOneFromServer = await fetchComments(1);
          setItems(PageOneFromServer);
      };
  
      getComments();
  
    },[RecordAdded]);     
    
    const fetchComments = async(currentPage) => {
            // const res = await fetch(`${BaseURL}:${PortNumber}/Store/GetPageStore?PageNumber=${currentPage}&PageSize=${pageSize}`);
            // const data = await res.json();
            const data = await StoreService.getPagedStore(currentPage, pageSize);

            return data;
        };
          
  
        const handlePageClick = async (data) => {
            let currentPage = data.selected + 1;
            const commentsFromServer = await fetchComments(currentPage);
            setItems(commentsFromServer);
          }
          
    // **************************************************************************************************
    const { 
            register, watch, handleSubmit, 
            // setValue, resetValue,unregister, 
            // resetField, setError, clearErrors, 
            // setFocus, getValues, trigger, control,
            formState: { 
                errors, 
               // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
            } } 
        = useForm({ defaultValues: { id: 0, storeCode:'', storeName:'', addressLine1:'', addressLine2:'', city:'', country:'', person:'', contact: '', email: '' } });

    useEffect(() => {
        const subscription = watch((data)=>{
            console.log(data);
        })
        return ()=> {
            subscription.unsubscribe();
        }
    }, [watch]) 

    useEffect(() => {
        let userLogin = null;
          try
          {
            userLogin = JSON.parse(localStorage.getItem('credentials')).userName;
            AuthService.getForcedLogoutUser(userLogin).then((login)=>{                
                if ((login.loginStatus === 'ForcedLogout') || (login.loginStatus === 'Logout')) {                
                    localStorage.removeItem('credentials')
                    navigate("/Login");
                }});
            UserService.getUser(userLogin).then((user)=>{
                if (user.status === 'InActive') {                
                    localStorage.removeItem('credentials')
                    navigate("/Login");
                }});

      
          }
          catch{}
          
          
          if (userLogin === null || userLogin === undefined) {
            setLogin(false);
            navigate('/Login');
          }
          else
          {
            setLogin(true);
          }
        },[]);

    const onSubmit = (data, event) => {
        event.preventDefault();
        formValues = {
            id: 0,
            vendorName: data.vendorName,
            description: data.description
        };
        StoreService.addStore(formValues).then((store)=>{
            console.log('Form Values = ', formValues);
            console.log('Store = ', store);
            toast.success('Store added successfully',{ position: 'bottom-center'});
            setRecordAdded((previousState)=> previousState + 1);
            clearValueForm();
        }).catch((e) => {
            console.log('Add Store Error', e.message);
            toast.error('Adding Store Error ',{ position: 'bottom-center'})
        });
    }
    const updateStore = () =>{
        setValueForm();
        StoreService.editStore(formValues).then((store)=>{
            store.data ? toast.success('Store Edited Successfully',{ position: 'bottom-center'}):toast.info('Profile does not exists',{ position: 'bottom-center'});
            setRecordAdded((previousState)=> previousState + 1);
            clearValueForm();
        })
        .catch((e) => {
            console.log('Edit Store Error', e.message);
            toast.error('Edit Store Error ',{ position: 'bottom-center'})
        });
    }
    const deleteStore = () =>{
        // setValueForm();
        // ProfileService.deleteProfile(formValues).then((profile)=> {
        //     profile.data ? toast.success('Profile Delete Successfully .... ',{ position: 'bottom-center'}):toast.info('Profile does not exists',{ position: 'bottom-center'});
        //     clearValueForm();
        // }).catch((e) => {
        //     console.log('Delete Profile Error', e.message);
        //     toast.error('Delete Profile Error ',{ position: 'bottom-center'})
        // });
    }

    const setValueForm = (() => { 
        formValues = {
            id: document.getElementById('id').value,
            storeCode: document.getElementById('storeCode').value,
            storeName: document.getElementById('storeName').value,
            addressLine1: document.getElementById('addressLine1').value,
            addressLine2: document.getElementById('addressLine2').value,
            city: document.getElementById('city').value,
            country: document.getElementById('country').value,
            person: document.getElementById('person').value,
            contact: document.getElementById('contact').value,
            email: document.getElementById('email').value,
         };
    });
 
    const clearValueForm = (() => {
        formValues = {
            id: '',
            storeCode: '',
            storeName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            country: '',
            person: '',
            contact: '',
            email: ''
         };

         document.getElementById('id').value= '';
         document.getElementById('storeCode').value= '';
         document.getElementById('storeName').value= '';
         document.getElementById('addressLine1').value= '';
         document.getElementById('addressLine2').value= '';
         document.getElementById('city').value= '';
         document.getElementById('country').value= '';
         document.getElementById('person').value= '';
         document.getElementById('contact').value= '';
         document.getElementById('email').value= '';
        });

    const GetStore = (selectedStore) =>{
        StoreService.getStore(selectedStore).then((resp) =>{
              const selectedMake =  resp;
              document.getElementById('id').value= resp.id;
              document.getElementById('storeCode').value= resp.storeCode;
              document.getElementById('storeName').value= resp.storeName;
              document.getElementById('addressLine1').value= resp.addressLine1;
              document.getElementById('addressLine2').value= resp.addressLine2;
              document.getElementById('city').value= resp.city;
              document.getElementById('country').value= resp.country;
              document.getElementById('person').value= resp.person;
              document.getElementById('contact').value= resp.contact;
              document.getElementById('email').value= resp.email;

              console.log(selectedMake);
          }).catch((e) => {
              console.log('Error', e.message);
          });    
  }

    return (
    <>
        {
            localStorage.getItem('credentials') && 
          <>



      <Container>
        <Row>
            <Col md = "10">
                   <div className="heading">
                        <h5 className="mainheading">Store</h5>
                        <hr/>
                    </div>              

        
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>

                    <table>
                    <tbody>
                    <tr key= { Math.random() }>
                      <td>
                          <input className="input" type="text" {...register("storeCode", { required: {value: true, message: "This is required field"}})}  id="storeCode" placeholder="Store Code"></input>
                          <h2>{errors.storeCode && <p>{errors.storeCode.message}</p>}</h2>
                      </td>
                      <td>
                          <input className="input" type="text" {...register("storeName", { required: {value: true, message: "This is required field"}})}  id="storeName" placeholder="Store Name"></input>
                          <h2>{errors.storeName && <p>{errors.storeName.message}</p>}</h2>
                      </td>
                      </tr>

                      <tr key= { Math.random() }>
                      <td>
                          <input className="input" type="text" {...register("addressLine1", { required: {value: true, message: "This is required field"}})}  id="addressLine1" placeholder="Address Line 1"></input>
                          <h2>{errors.addressLine1 && <p>{errors.addressLine1.message}</p>}</h2>
                      </td>
                      <td>
                          <input className="input" type="text" {...register("addressLine2", { required: {value: true, message: "This is required field"}})}  id="addressLine2" placeholder="Address Line 2"></input>
                          <h2>{errors.addressLine2 && <p>{errors.addressLine2.message}</p>}</h2>
                      </td>
                    </tr>

                    <tr key= { Math.random() }>
                      <td>
                            <input className="input" type="text" {...register("city", { required: {value: true, message: "This is required field"} })}  id="city" placeholder="City"></input>
                            <h2>{errors.city && <p>{errors.city.message}</p>}</h2>
                      </td>

                      <td>
                            <input className="input" type="text" {...register("country", { required: {value: true, message: "This is required field"}})}  id="country" placeholder="Country"></input>
                            <h2>{errors.country && <p>{errors.country.message}</p>}</h2>                      
                      </td>
                    </tr>

                    <tr key= { Math.random() }>
                      <td>
                            <input className="input" type="text" {...register("person", { required: {value: true, message: "This is required field"} })}  id="person" placeholder="Person"></input>
                            <h2>{errors.person && <p>{errors.person.message}</p>}</h2>
                      </td>
                      <td>
                            <input className="input" type="text" {...register("contact", { required: {value: true, message: "This is required field"} })}  id="contact" placeholder="Contact"></input>
                            <h2>{errors.contact && <p>{errors.contact.message}</p>}</h2>
                      </td>
                    </tr>

                    <tr key= { Math.random() }>
                      <td>
                            <input className="input" type="text" {...register("email", { required: {value: true, message: "This is required field"}})}  id="email" placeholder="Email"></input>
                            <h2>{errors.email && <p>{errors.email.message}</p>}</h2>
                      </td>                      
                    </tr>
                </tbody>
                    </table>

                    <hr/>
                    <div className="container">
                        <div className="row">
                            <div className="col md-3 sm-3 xs-2">
                                <button className="submit" type="submit">Save</button>
                            </div>
                        </div>
                    </div>
                </form>  
                <hr/>
            </Col>

            <Col md = "2">
            <div className="heading">
            <h5 className="operation">Operations</h5>
            <hr/>
            </div>              

            <div className="container">
                <div className="row">
                    <div className="col md-3 sm-3 xs-2">
                        <button onClick={ updateStore } className="button" type="button">Update</button>
                    </div>

                    {/* <div className="col md-3 sm-3 xs-2">
                        <button onClick={ deleteProfile } className="button" type="button">Delete</button>
                    </div> */}

                    <div className="col md-3 sm-3 xs-2">
                        <button className="button"  onClick={() => { setValueForm(); setModalOpen(true); } }> Delete </button>
                    </div>

                    {    
                        modalOpen && 
                        <Modal 
                            setOpenModal={ setModalOpen } 
                            setAction = { setAction }
                            formValues = { formValues }
                            formName = "Store"
                            title = "Are you sure to delete ?" 
                            message = "Make will be deleted !" 
                            setRecordAdded = { setRecordAdded }
                            />
                    }            
                </div>
            </div>
            </Col>
        </Row>


        <Row>
        <Col>
          <ReactPaginate 
            previousLabel = {'<'} 
            nextLabel = {'>'}
            breakLabel = {'...'}
            pageCount = { Math.ceil(pageCount) }
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}

            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}

            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}

            breakLinkClassName={'page-link'}
            activeClassName={'active'}
        />
          <Table striped bordered hover variant="dark">
          <thead>
                <tr key= { Math.random() }>
                  <th>Code</th>
                  <th>Store</th>
                  <th>Line 1</th>
                  <th>Line 2</th>
                  <th>City</th>
                  <th>Country</th>
                  <th>Person</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Edit</th>
                </tr>
          </thead>
          <tbody>

          { 
            items.map((store) => {
            return <>
                  <tr key= { Math.random() }>
                      <td> { store.storeCode }</td>
                      <td> { store.storeName }</td>
                      <td> { store.addressLine1 }</td>
                      <td> { store.addressLine2 }</td>
                      <td> { store.city }</td>
                      <td> { store.country }</td>
                      <td> { store.person }</td>
                      <td> { store.contact }</td>
                      <td> { store.email }</td>
                      <td><button className="selectButton" onClick={(()=> GetStore(store.storeName))}> Select</button></td>
                  </tr>
               </>
            })                  
          }
          </tbody>
          </Table>
        </Col>

        </Row>

      </Container>      

            </>        
        }
    </>
  )
}

export default Store