import React, {  useEffect, useState } from 'react'
import Card from '../forms/ui/Card'
import { Row, Col, Container, Table, Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';

import classes from './forms.css'

import { useForm, Controller } from "react-hook-form";
import { TextField, Checkbox } from "@material-ui/core";
import { string } from 'yup';
import './forms.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import ProductAvailabilityService from '../../services/ProductAvailabilityService'
import ProductDeliveryService from '../../services/ProductDeliveryService'
import Multiselect from 'multiselect-react-dropdown'

import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Modal from '../../components/Modal/Modal'
import { boolean, number } from 'yup/lib/locale';
import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'
import ProductBaseMachineService from '../../services/ProductBaseMachineService'

// *******************************************************************
import ReactPaginate from 'react-paginate'
//import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { industryUri, pageSize } from '../../constants'
import MetaData from '../../services/Data/MetaData';
// ********************************************************************


let formValues1 = {
  id: number,
  productCode: string,
  requestOnly: string,
  orderWithoutStock: string,
  inStock: string,
  preOrder: string,
  preOrderLocations: string 
}

let formValues2 = {
  id: number,
  productCode: string,
  pickupInStore: string,
  deliveryToHome: string,
  transferToNearest: string,
  shipToAddress: string     
}


const ProductAvailability = () => {
  const auth = useAuth();  
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);
  const [RecordAdded, setRecordAdded] = useState(0);
  const [RuleSet, setRuleSet] = useState([]);

  // **************************************************************************************************
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setAction] = useState(false);
  const [items, setItems] = useState([]);
  const [items1, setItems1] = useState([]);
  
  const [pageCount, setPageCount] = useState([]);
  const [ProductSet, setProductSet] = useState([]);
  const [check1, setCheck1] = useState();
  const [check2, setCheck2] = useState();
  const [check3, setCheck3] = useState();
  const [check4, setCheck4] = useState();
  const [check5, setCheck5] = useState();
  const [check6, setCheck6] = useState();
  const [check7, setCheck7] = useState();
  const [check8, setCheck8] = useState();
  const [check9, setCheck9] = useState();
  const [ProductCode,setProductCode]=useState();
  const [AvailId,setAvailId]=useState();
  const [DeliId,setDeliId]=useState();
  const [PackageDesc, setPackageDesc] = useState();
  const [ModelName, setModalName] = useState();
  const [IndustrySet, setIndustrySet] = useState([]);
  const [IndustrySetOnly, setIndustrySetOnly] = useState([]);


  const [LocationCode, setLocationCode] = useState();

  const [LocationCodeSet, setLocationCodeSet] = useState();  
  const [LocationCodeSetOnly, setLocationCodeSetOnly] = useState();
  
  let selected = []

  const { 
    register, watch, handleSubmit, control,reset,
    // setValue, resetValue,unregister, 
    // resetField, setError, clearErrors, 
    // setFocus, getValues, trigger, control,
    formState: { 
        errors, 
       // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
    } } 
    = useForm({ 
    //  defaultValues: {  id: 0, productCode: 'P00001', requestOnly: false, orderWithoutStock: false, inStock: false, preOrder: false, preOrderLocations: false } 
    });

    useEffect(() => {
      const getComments = async () => {
          const data = await ProductAvailabilityService.getAllProductsAvailability();
          console.log('data = ',data);
          const total = data.length;
          const TotalPages = Math.ceil(total/pageSize);
          setPageCount(TotalPages);
          setItems(data);
          const PageOneFromServer = await fetchComments(1);
          setItems(PageOneFromServer);


          const data1 = await ProductDeliveryService.getAllProductsDelivery();
          console.log('data = ',data1);
          const total1 = data1.length;
          const TotalPages1 = Math.ceil(total1/pageSize);
          setPageCount(TotalPages1);
          setItems1(data1);
          const PageOneFromServer1 = await fetchComments1(1);
          setItems1(PageOneFromServer1);


          const dataSet = await MetaData.getAllProduct();
          setProductSet(dataSet);

          const dataSet2 = await MetaData.getAllIndustry();
          setIndustrySet(dataSet2);

          const dataSet3 = await MetaData.getAllLocationCode();
          let dataset = []
          console.log('dataSet3 = ',dataSet3);
          dataSet3.map(data=>{            
            dataset = [...dataset, {id:data.id,"locationCode": data.locationCode, "locationName":data.locationName}]
          })
          setLocationCodeSetOnly(dataset);
      };
  
      getComments();

      MetaData.getAllRules().then(data=> {
        console.log('data == >>>> ',data);
        setRuleSet(data);
        });       

      },[RecordAdded]);     
    
    const fetchComments = async(currentPage) => {
            const data = await ProductAvailabilityService.getPagedProductAvailability(currentPage, pageSize);
            return data;
        };
          
    const fetchComments1 = async(currentPage) => {
          const data = await ProductDeliveryService.getAllProductsDelivery(currentPage, pageSize);
          return data;
      };
            
  
        const handlePageClick = async (data) => {
            let currentPage = data.selected + 1;
            const commentsFromServer = await fetchComments(currentPage);
            setItems(commentsFromServer);
          }

        const handlePageClick1 = async (data) => {
            let currentPage = data.selected + 1;
            const commentsFromServer = await fetchComments1(currentPage);
            setItems(commentsFromServer);
          }
          
    // **************************************************************************************************
    const DEF_DELAY = 1000;

    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms || DEF_DELAY));
    }
    
    const selectLocations = async (e)=>{
      setLocationCodeSet(e);
      console.log('Locations Selected Are ',LocationCodeSet);
      console.log(e);

      let result = ""
      if (LocationCodeSet !== undefined)  {
        LocationCodeSet.map(data=>{
          result = result + data.locationCode.concat(",")          
        })
      }
      result = result.substring(0, result.length-1);
      console.log('preOrderLocations = ',result);
      //await sleep(1000);
      document.getElementById('preOrderLocations').value = result;
    }
    
    const removeLocations = (e)=>{
      let result = ""
      console.log('Removed = > ',e);
      console.log(LocationCodeSet)
      if (LocationCodeSet !== undefined)  {
        LocationCodeSet.map(data=>{
          result = result + data.locationCode.concat(",")          
        })
      }
      result = result.substring(0, result.length-1);
      document.getElementById('preOrderLocations').value = result;
      
    }

    const onSubmit = (data, event) => {
      event.preventDefault();  
      console.log(data.requestOnly.toString());   
      if (data.productCode2 === '' || data.productCode2 === 'Select'){
        alert('Please Select Rule Code ! ');
        return;
      } 

      formValues1 = {
        id: 0,
        productCode: data.productCode2,
        requestOnly: data.requestOnly.toString(),
        orderWithoutStock: data.orderWithoutStock.toString(),
        inStock: data.inStock.toString(),
        preOrder: data.preOrder.toString(),
        preOrderLocations: document.getElementById('preOrderLocations').value,
      };

      formValues2 = {
        id: 0,
        productCode: data.productCode2,
        pickupInStore: data.pickupInStore.toString(),
        deliveryToHome: data.deliverToHome.toString(),
        transferToNearest: data.transferToNearest.toString(),
        shipToAddress: data.shipToAddress.toString()      
      };


      ProductAvailabilityService.getProductAvailability(data.productCode2.trim().replace("&","And").replace("+","And")).then((data)=>{
        if (data.length !== 0) {
          console.log('Data Exist =>> ',data)
          toast.info('Record Exists ! ',{ position: 'bottom-center'});        
          return;
        }
        else
        {
                  ProductAvailabilityService.createProductAvailability(formValues1).then((avail)=>{
                    console.log('Availability = ',avail);
                    toast.success('Availability Added Successfully',{ position: 'bottom-center'});
                  }).catch((e) => {
                        toast.error('Adding Availability Error ',{ position: 'bottom-center'})
                  });
                    console.log('Form Values 1 = ',formValues1);
          
                ProductDeliveryService.createProductDelivery(formValues2).then((deli)=>{
                  console.log('Delivery = ',deli);
                  toast.success('Delivery Added Successfully',{ position: 'bottom-center'});
                }).catch((e) => {
                  toast.error('Adding Delivery Error ',{ position: 'bottom-center'})
                });
                  setRecordAdded((previousState)=> previousState + 1);
                  clearValueForm();
            }
      });
  }

  const updateAvailability = () =>{
    setValueForm();
    console.log('Form1 = ', formValues1);
    console.log('Form2 = ', formValues2);
     ProductAvailabilityService.editProductAvailability(formValues1).then((avail)=>{
        avail.data ? toast.success('Availability Edited Successfully',{ position: 'bottom-center'}):toast.info('Availability does not exists',{ position: 'bottom-center'});
        setRecordAdded((previousState)=> previousState + 1);
    })
    .catch((e) => {
        toast.error('Edit Availability Error ',{ position: 'bottom-center'})
    });
    ProductDeliveryService.editProductDelivery(formValues2).then((deli)=>{
      deli.data ? toast.success('Delivery Edited Successfully',{ position: 'bottom-center'}):toast.info('Delivery does not exists',{ position: 'bottom-center'});
        setRecordAdded((previousState)=> previousState + 1);
    })
    .catch((e) => {
        toast.error('Edit Availability Error ',{ position: 'bottom-center'})
    });
    clearValueForm();
  }

const booleanify = (value: string): boolean => {
  const truthy: string[] = [
      'true',
      'True',
      '1'
  ]

  return truthy.includes(value)
}
  const GetProductAvailability = (selectedProduct) =>{
    
    ProductBaseMachineService.getProductBaseMachine(selectedProduct).then(data=>{
      setPackageDesc("Package Desc : " + data.packageDesc);
      setModalName("Modal Name : " + data.modelName);
    })

    ProductAvailabilityService.getProductAvailability(selectedProduct).then((resp1) =>{
        if (resp1.id !== undefined) {
              setAvailId(resp1.id);
              setProductCode(resp1.productCode);
              setCheck1(booleanify(resp1.requestOnly));
              setCheck2(booleanify(resp1.orderWithoutStock));
              setCheck3(booleanify(resp1.inStock));
              setCheck4(booleanify(resp1.preOrder));
              // ********************************************************************************************************
              // Here Logic To Create Object From CSV String And Assign To selectedValues as Array Of Object
              // ********************************************************************************************************
              document.getElementById('preOrderLocations').value = resp1.preOrderLocations;
              resp1.preOrderLocations.split(/\s*,\s*/).forEach(function(myString) {
                selected = [...selected, {"location": myString}]
            });
              setLocationCodeSet(selected)
        }
        else 
        {
              setAvailId(0);
              setProductCode(selectedProduct);
              setCheck1(false);
              setCheck2(false);
              setCheck3(false);
              setCheck4(false);
              // Here Make Object As Null
              document.getElementById('preOrderLocations').value = '';
        }

      }).catch((e) => {
          console.log('Error', e.message);
      });    

      ProductDeliveryService.getProductDelivery(selectedProduct).then((resp2) =>{
        if (resp2.id !== undefined) {
              setDeliId(resp2.id);
              setCheck6(booleanify(resp2.pickupInStore));
              setCheck7(booleanify(resp2.deliveryToHome));
              setCheck8(booleanify(resp2.transferToNearest));
              setCheck9(booleanify(resp2.shipToAddress));                  
        }
        else {
              setDeliId(0);
              setCheck6(false);
              setCheck7(false);
              setCheck8(false);
              setCheck9(false);                  
        }
    }).catch((e) => {
        console.log('Error', e.message);
    });    
}

const setValueForm = (() => { 
  formValues1 = {
      id: AvailId,
      productCode: ProductCode,
      requestOnly: check1.toString(),
      orderWithoutStock: check2.toString(),
      inStock: check3.toString(),
      preOrder: check4.toString(),
      preOrderLocations: document.getElementById('preOrderLocations').value,
    };

    formValues2 = {
      id: DeliId,
      productCode: ProductCode,
      pickupInStore: check6.toString(),
      deliveryToHome: check7.toString(),
      transferToNearest: check8.toString(),
      shipToAddress: check9.toString()
    };
});

const clearValueForm = (() => {
  formValues1 = {
      id: '',
      productCode: '',
      requestOnly: '',
      orderWithoutStock: '',
      inStock: '',
      preOrder: '',
      preOrderLocations: '',
   };

   formValues2 = {
      id: '',
      productCode: '',
      pickupInStore: '',
      deliveryToHome: '',
      transferToNearest: '',
      shipToAddress: ''     
   };


   document.getElementById('id').value= '';
   document.getElementById('productCode2').value= '';
   document.getElementById('requestOnly').value= '';
   document.getElementById('orderWithoutStock').value= '';
   document.getElementById('inStock').value= '';
   document.getElementById('preOrder').value= '';

   document.getElementById('preOrderLocations').value= '';

   document.getElementById('pickupInStore').value= '';
   document.getElementById('deliveryToHome').value= '';  
   document.getElementById('transferToNearest').value= '';
   document.getElementById('shipToAddress').value= '';
});
const SetCheckBox1 = (e) =>{
  e.preventDefault()
  setCheck1(e.target.checked)
}
const SetCheckBox2 = (e) =>{
  e.preventDefault()
  setCheck2(e.target.checked)
}
const SetCheckBox3 = (e) =>{
  e.preventDefault()
  setCheck3(e.target.checked)
}
const SetCheckBox4 = (e) =>{
  e.preventDefault()
  setCheck4(e.target.checked)
}
const SetCheckBox5 = (e) =>{
  e.preventDefault()
  setCheck5(e.target.checked)
}
const SetCheckBox6 = (e) =>{
  e.preventDefault()
  setCheck6(e.target.checked)
}
const SetCheckBox7 = (e) =>{
  e.preventDefault()
  setCheck7(e.target.checked)
}
const SetCheckBox8 = (e) =>{
  e.preventDefault()
  setCheck8(e.target.checked)
}
const SetCheckBox9 = (e) =>{
  e.preventDefault()
  setCheck9(e.target.checked)
}

return (
    <>
      <Container>

      <Row>

      {/* <Col md="6">
            <ReactPaginate 
                    previousLabel = {'<'} 
                    nextLabel = {'>'}
                    breakLabel = {'...'}
                    pageCount = { Math.ceil(pageCount) }
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick1}

                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}

                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}

                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}

                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
              <Table striped bordered hover variant="dark">
                <thead>
                      <tr key= { Math.random() }>
                          <th>Code</th>
                          <th>Pickup</th>
                          <th>Deliver</th>
                          <th>Transfer</th>
                          <th>Ship</th>
                    </tr>
                  </thead>
                <tbody>
                        { 
                            items1.map((del) => {
                            return <>
                                  <tr key= { Math.random() }>
                                      <td> { del.productCode }</td>
                                      <td> { del.pickupInStore }</td>
                                      <td> { del.deliveryToHome }</td>
                                      <td> { del.transferToNearest }</td>
                                      <td> { del.shipToAddress }</td>
                                  </tr>
                              </>
                            })                  
                          }
                  </tbody>                  
                
              </Table>
    </Col> */}

      <Col md="12">
          <ReactPaginate 
            previousLabel = {'<'} 
            nextLabel = {'>'}
            breakLabel = {'...'}
            pageCount = { Math.ceil(pageCount) }
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}

            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}

            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}

            breakLinkClassName={'page-link'}
            activeClassName={'active'}
        />
          <Table striped bordered hover variant="dark">
          <thead>
                <tr key= { Math.random() }>
                  <th>Code</th>
                  <th>Request Only</th>
                  <th>Buy Online</th>
                  <th>Locations</th>
                  <th>Edit</th>
                </tr>
          </thead>
          <tbody>

          { 
            items.map((avail) => {
            return <>
                  <tr key= { Math.random() }>
                      <td> { avail.productCode }</td>
                      <td> { avail.requestOnly }</td>
                      <td> { avail.inStock }</td>
                      <td> { avail.preOrder }</td>
                      <td><button className="selectButton" onClick={(()=> GetProductAvailability(avail.productCode))}> Select</button></td>
                  </tr>
               </>
            })                  
          }
          </tbody>
          </Table>
    </Col>

</Row>

          <Row>
                <Col md="10">
                        <div className="heading">
                            <h5 className="mainheading">Availability</h5>
                            <hr/>
                        </div>              

        
                          <form onSubmit={handleSubmit(onSubmit)}>

                          <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>

                          <label htmlFor="productCode2">Rule Code</label>

                              <select 
                                className="custom-select"
                                {...register("productCode2")} 
                                  id="productCode2"
                                 value = { ProductCode }
                                  onChange={(e)=> {
                                    GetProductAvailability(e.target.value);
                            			  }
                                }
                                >
                                <option  key={Math.random()}   value="Select">Select Rule</option> 
                                {
                                RuleSet.map((record)=>
                                ( 
                                    <option  key={Math.random()}  value={record.productCode}>{record.productCode}</option>
                                ))
                                }
                                </select>   
                                <div className="Details">  
                                     {PackageDesc}<br/> {ModelName}
                                </div>
                                <hr/>

                          {/* <select 
                                  className="select" 
                                  {...register("productCode2", { required: {value: true, message: "This is required field"} })} 
                                    id="productCode2"
                                    value = { ProductCode }
                                    onChange={(e)=> {
                                    GetProductAvailability(e.target.value);
                            			  }
                                  }  
                                  >
                                  <option  key={Math.random()}   value="Select">Select Product</option> 
                              {
                                  ProductSet.map((d)=>(
                          			    <option  key={Math.random()}  value={d.productCode}>{d.productCode}</option>
                                  ))
                              }
                              </select> */}
                              <h2>{errors.productCode2 && <p>{errors.productCode2.message}</p>}</h2>


                          <h4><label>Delivery Options</label></h4>
                            <hr/>
                        <span>
                          <input className="checkbox" type="checkbox"  onClick={(e)=> setCheck6(e.target.checked)}  checked={check6}  {...register("pickupInStore")}  id="pickupInStore" placeholder="Pickup In Store"></input>
                          <label htmlFor="pickupInStore">Pickup In Store</label>
                          <h2>{errors.pickupInStore && <p>{errors.pickupInStore.message}</p>}</h2>
                          </span>


                          <span>
                          <input className="checkbox" type="checkbox"  onClick={(e)=> setCheck7(e.target.checked)}  checked={check7}  {...register("deliverToHome")}  id="deliveryToHome" placeholder="Delivery To Home"></input>
                          <label htmlFor="deliveryToHome">Deliver To Home</label>
                          <h2>{errors.deliveryToHome && <p>{errors.deliveryToHome.message}</p>}</h2>
                          </span>


                          <span>
                          <input className="checkbox" type="checkbox"  onClick={(e)=> setCheck8(e.target.checked)}  checked={check8}  {...register("transferToNearest")}  id="transferToNearest" placeholder="Transfer To Nearest Store"></input>
                          <label htmlFor="transferToNearest">Transfer To Store</label>
                          <h2>{errors.transferToNearest && <p>{errors.transferToNearest.message}</p>}</h2>
                          </span>


                          <span>
                          <input className="checkbox" type="checkbox"  onClick={(e)=> setCheck9(e.target.checked)}  checked={check9} {...register("shipToAddress")}  id="shipToAddress" placeholder="Ship To Address"></input>
                          <label htmlFor="shipToAddress">Ship To Address</label>
                          <h2>{errors.shipToAddress && <p>{errors.shipToAddress.message}</p>}</h2>
                          </span>
                      
                      
                          {/* <input {...register("checkbox")} type="checkbox" value="A" />
                          <input {...register("checkbox")} type="checkbox" value="B" />
                          <input {...register("checkbox")} type="checkbox" value="C" /> */}
      
                          {/* <input {...register("radio")} type="radio" value="A" />
                          <input {...register("radio")} type="radio" value="B" />
                          <input {...register("radio")} type="radio" value="C" /> */}


                          <hr/>  
                          <h4><label>Availability Options</label></h4>                          
                          <hr/>
                          <span>
                          <input className="checkbox" type="checkbox" onClick={(e)=> setCheck1(e.target.checked)}  checked={check1}  {...register("requestOnly")}  id="requestOnly" placeholder="Request Only"></input>
                          <label htmlFor="requestOnly">Request Only</label>
                          <h2>{errors.requestOnly && <p>{errors.requestOnly.message}</p>}</h2>
                          </span>

                          <input hidden className="checkbox" type="checkbox"  onClick={(e)=> setCheck2(e.target.checked)}  checked={check2}  {...register("orderWithoutStock")}  id="orderWithoutStock" placeholder="Order Without Stock"></input>
                          <label hidden htmlFor="orderWithoutStock">Order Without Stock</label>
                          <h2>{errors.orderWithoutStock && <p>{errors.orderWithoutStock.message}</p>}</h2>

                          <span>
                          <input className="checkbox" type="checkbox"  onClick={(e)=> setCheck3(e.target.checked)}  checked={check3}  {...register("inStock")}  id="inStock" placeholder="In Stock"></input>
                          <label htmlFor="inStock">Buy Online</label>
                          <h2>{errors.inStock && <p>{errors.inStock.message}</p>}</h2>
                          </span>


                          <span>
                          <input className="checkbox" type="checkbox"  onClick={(e)=> setCheck4(e.target.checked)}  checked={check4} {...register("preOrder")}  id="preOrder" placeholder="Pre Order"></input>
                          <label htmlFor="preOrder">Pre Order</label>
                          <h2>{errors.preOrder && <p>{errors.preOrder.message}</p>}</h2>
                          </span>


                          <span>
                          {/* <input className="checkbox" type="checkbox"  onClick={(e)=> setCheck5(e.target.checked)}  checked={check5}  {...register("preOrderLocations")}  id="preOrderLocations" placeholder="Pre Order Locations"></input> */}
                          { (check3 || check4) &&
                          <>
                           <label>Locations</label>
                                <div className="longinput">
                                <input id="preOrderLocations"  defaultValue = "Locations" disabled className="displayinput"></input>
                                <Multiselect
                                      id = "locationCode"                                      
                                      displayValue ="locationName"
                                      isObject = {true}
                                      onKeyPressFn = {function noRefCheck(){}}
                                      onRemove  = {(e)  =>  { removeLocations(e) }}
                                      onSearch  = {(e)  =>  {console.log(e)}}
                                      onSelect  = {(e)  =>  { selectLocations(e) }}
                                      //selectedValues = { LocationCodeSet }
                                      options   = { LocationCodeSetOnly }
                                      width = "600px"                                      
                                    /> 
                                  </div>                                 
                              </>
                          }
                          <h2>{errors.preOrderLocations && <p>{errors.preOrderLocations.message}</p>}</h2>
                          </span>

                          
                          <hr/>
                          <div className="container">
                              <div className="row">
                                  <div className="col md-3 sm-3 xs-2">
                                      <button className="submit" type="submit">Save</button>
                                  </div>
                              </div>
                          </div>
                          {/* <Controller
                            name="checkbox"
                            onClick={(e)=> console.log('e = ',e.target.value)}
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => <Checkbox {...field} />}
                            /> */}

                      </form>  
                </Col>
                

  <Col md = "2">
    <div className="heading">
    <h5 className="operation">Operations</h5>
    <hr/>
    </div>              

    <div className="container">
        <div className="row">
            <div className="col md-3 sm-3 xs-2">
                <button onClick={ updateAvailability } className="button" type="button">Update</button>
            </div>

            {/* <div className="col md-3 sm-3 xs-2">
                <button className="button"  onClick={() => { setValueForm(); setModalOpen(true); } }> Delete </button>
            </div> */}

            {    
                modalOpen && 
                <Modal 
                    setOpenModal={ setModalOpen } 
                    setAction = { setAction }
                    formValues = { formValues1 }
                    formName = "Product Availability"
                    title = "Are you sure to delete ?" 
                    message = "Product Availability will be deleted !" 
                    setRecordAdded = { setRecordAdded }
                    />
            }            
        </div>
    </div>
  </Col>
</Row>
      </Container>
    </>
  )
}

export default ProductAvailability