import { productUri, apiProductUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class ProductService {

    static  getAllProducts() {
        return axiosInstance.get('/Product')
                    .then((resp)=>resp.data);
    }

    static  getPagedProduct(currentPage, pageSize) {
        return axiosInstance.get(`/Product/GetPageProduct?PageNumber=${currentPage}&PageSize=${pageSize}`)
                    .then((resp)=>resp.data);
    }

    static  getProduct(productId) {
        console.log('productID = ', productId);
        console.log('URI = ',`Product/GetProduct?ProductCode=${productId}`);
        
        return axiosInstance.get(`Product/GetProduct?ProductCode=${productId}`)
                    .then((resp)=>resp.data);
    }
    static  createProduct(payload) {
        return axiosInstance.post(productUri, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static  editProduct(payload) {
        return axiosInstance.patch('Product/Update', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
    
    static  deleteProduct(payload) {
        return axiosInstance.post('Product/Delete', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default ProductService