import { EMSDETAILAPI, EMSAPI } from '../constants'
import axiosInstance from '../services/AxiosInstance'
import axios from 'axios'

export class EMSApiService {
    //let EMSAPI = `https://inspect.jamesriverequipment.com/EMSMarketingConsoleAPI/api/EquipmentItems/GetCards`;
    //https://inspect.jamesriverequipment.com/EMSMarketingConsoleAPI/api/EquipmentItems/GetCards
    static  getAll() {
        return axios.get('https://inspect.jamesriverequipment.com/EMSMarketingConsoleAPI/api/EquipmentItems/GetMachineDetails?id=478864')
    //  return axios.get(`https://inspect.jamesriverequipment.com/EMSMarketingConsoleAPI/api/EquipmentItems/GetCards`)
                    .then((resp)=>resp.data);
    }

    static  getPagedEMS(currentPage, pageSize) {
        return axiosInstance.get(`/GetCards/?PageNumber=${currentPage}&PageSize=${pageSize}`)
                    .then((resp)=>resp.data);
    }

    static  getEMSDetail(UniqueID) {
        return axiosInstance.get(`/GetMachineDetails?id=${UniqueID}`)
                    .then((resp)=>resp.data);
    }
}

export default EMSApiService