
import React, { useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useForm  } from 'react-hook-form'
import './style.css'
import { OutboundDeereService } from '../../services/OutboundDeereService'
import { string } from 'yup';


const OutboundDeereAPIEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { 
        register, watch, handleSubmit,
        // setValue, resetValue,unregister, 
        // resetField, setError, clearErrors, 
        // setFocus, getValues, trigger, control,
        formState: { 
            errors, 
           // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
        } } 
    = useForm(
      //{ defaultValues: { Id: 0, Username: '', Password1: '', RoleId:'', ProfileId: '', Status: 'Inactive' } }
      );

    const onSubmit = (data, event) => {
        event.preventDefault();
        let formValues = {}
        OutboundDeereService.getDeereSKU(location.state.data.sku).then((da)=>{
            console.log('Data Pulled => ', da);
            formValues = {
                id: da.id,
                status: da.status, 
                ruleCode: da.ruleCode, 
                language: da.language, 
                country: da.country, 
                currency: da.currency,
                sku: da.sku, 
                modelNumber: data.modelNumber, 
                modelName: data.modelName, 
                productOverview: data.productOverview, 
                baseCode: data.baseCode, 
                productSystemValue: data.productSystemValue, 
                optionalLinks: data.optionalLinks, 
                altText: data.altText,
                defaultURL: data.defaultURL, 
                imageURLS: data.imageURLS, 
                title: data.title, 
                keywords: data.keywords, 
                metaDesc: data.metaDesc, 
                productPrice: data.productPrice, 
                disclaimer: data.disclaimer, 
                denomLabel: data.denomLabel, 
                titleQuestion: da.titleQuestion, 
                componentDesc: data.componentDesc 
            }

            OutboundDeereService.editDeere(formValues).then((data)=>{
                toast.success('Data Updated Successfully',{ position: 'bottom-center'});
            })
    
        })    
    }

    const CancelAction = ((e) => {
        e.preventDefault();
        navigate('/Configurations');
      })
    
    const UpdateDeereOutbound = ((e)=>{
        const payload = {
            id: document.getElementById('id').value,
            productSku: document.getElementById('id').value
        }
        OutboundDeereService.editDeereFeature(payload).then(rec =>{

        }).catch(err=>{

        })
    });  
    const UnPublish = ((e) => {
        e.preventDefault();            
            OutboundDeereService.deleteDeere(document.getElementById('sku').value)
            .then(nav =>
                { 
                    toast.success('Un Published Successfully',{ position: 'bottom-center'});                    
                })
            .catch((error)=>{
                console.log('Error in un-publishing = ',error)
            });
        });
    

    const booleanify = (value: string): boolean => {
        const truthy: string[] = [
            'true',
            'True',
            '1'
        ]
      
        return truthy.includes(value)
      }

  return (
    <>
         <Container>
            <Row>
                <Col  md="10">
                                <div className="heading">
                                    <h5 className = "mainheading">Deere API Edit Form</h5>
                                    <hr/>
                                </div>
                            
                                <form onSubmit={handleSubmit(onSubmit)}>
                                <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>
                                <table>
                                    <tr>
                                        <td>
                                            <label className="BlueLabel">SKU</label>
                                            <input disabled defaultValue = { location.state.data.productSku } className="input" type="text" {...register("sku")}  id="sku" ></input>
                                            <h2>{errors.sku && <p>{errors.sku.message}</p>}</h2>
                                        </td>
                                        <td>
                                        <label className="BlueLabel">Model Number</label><br/>
                                        <input  defaultValue = { location.state.data.productModelNumber }  {...register("modelNumber")}  className="input" type="text"  id="modelNumber"></input>
                                    </td>    
                                    <td>
                                        <label className="BlueLabel">Model Name</label><br/>
                                        <input  defaultValue = { location.state.data.productModelName } {...register("modelName")}  className="input" type="text"  id="modelName"></input>
                                    </td>    
                                </tr>

                                <tr key= { Math.random() }>
                                    <td>
                                        <label className="BlueLabel">Title</label><br/>
                                        <input  defaultValue = { location.state.data.title }  {...register("title")}  className="input" type="text"  id="title"></input>
                                    </td>    
                                    <td>
                                        <label className="BlueLabel">Meta Desc</label><br/>
                                        <input  defaultValue = { location.state.data.description } {...register("metaDesc")}  className="input" type="text"  id="metaDesc"></input>
                                    </td>    
                                    <td>
                                        <label className="BlueLabel">Price Disclaimer</label><br/>
                                        <input  defaultValue = { location.state.data.productPriceDisclaimer }  {...register("altText")}  className="input" type="text"  id="altText"></input>
                                    </td>    
                                </tr>

                                <tr key= { Math.random() }>
                                    <td>
                                        <label className="BlueLabel">Disclaimer</label><br/>
                                        <input  defaultValue = { location.state.data.disclaimer }   {...register("disclaimer")}  className="input" type="text"  id="disclaimer"></input>
                                    </td>    

                                    <td>
                                        <label className="BlueLabel">System Value</label><br/>
                                        <input  defaultValue = { location.state.data.productSystemValue }   {...register("productSystemValue")}  className="input" type="text"  id="productSystemValue"></input>
                                    </td>    
                                    <td>
                                        <label className="BlueLabel">Category Industry</label><br/>
                                        <input  defaultValue = { location.state.data.categoryIndustry }  {...register("categoryIndustry")}  className="input" type="text"  id="categoryIndustry"></input>
                                    </td>    
                                </tr>


                                <tr key= { Math.random() }>
                                    <td>
                                        <label className="BlueLabel">Product Industry</label><br/>
                                        <input  defaultValue = { location.state.data.productIndustry } {...register("productIndustry")}  className="input" type="text"  id="productIndustry"></input>
                                    </td>    
                                    <td>
                                        <label className="BlueLabel">Base Code</label><br/>
                                        <input  defaultValue = { location.state.data.productBaseCode } {...register("baseCode")}  className="input" type="text"  id="baseCode"></input>
                                    </td>    
                                    <td>
                                        <label className="BlueLabel">Denom Label</label><br/>
                                        <input  defaultValue = { location.state.data.productPriceDenominationLabel } {...register("denomLabel")} className="input" type="text"  id="denomLabel"></input>
                                    </td>    
                                </tr>

    
                            </table>

                                        <label className="BlueLabel">Product Image</label>
                                        <input  defaultValue = { location.state.data.productImage } {...register("optionalLinks")} className="LongLabel" type="text"  id="optionalLinks"></input>
                                        <label className="BlueLabel">Product Overview</label>
                                        <input  defaultValue = { location.state.data.productOverview }  {...register("productOverview")} className="LongLabel" type="text"  id="productOverview"></input>
                                        <label className="BlueLabel">Keywords</label>
                                        <input  defaultValue = { location.state.data.keywords }  {...register("keywords")}  className="LongLabel" type="text"  id="keywords"></input>

                                        <label className="BlueLabel">Product Features</label>
                                        <input  defaultValue = { location.state.data.features }  {...register("features")} className="LongLabel" type="text"  id="features"></input>

                                        <label className="BlueLabel">Product Summary</label>
                                        <input  defaultValue = { location.state.data.productSummary }  {...register("defaultURL")}  className="LongLabel" type="text"  id="defaultURL"></input>


                                        <div className="container">
                                            <div className="row">
                                                <div className="col md-3 sm-3 xs-2">
                                                    <span>
                                                    <button className="submit" onClick={(e)=>UpdateDeereOutbound(e)} type="submit">Update</button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                </form>
                </Col>
                <Col  md="2">
                        <div className="heading">
                            <h5 className = "operation">Operation</h5>
                            <hr/>
                        </div>

                        <span>
                        <button className="viewButton" onClick={(e)=>CancelAction(e)} type="button">Cancel</button>
                        </span>
                        <span>
                        <button className="viewButton" onClick={(e)=>UnPublish(e)} type="button">UnPublish</button>
                        </span>
              </Col>
            </Row>
            <ToastContainer/>
        </Container>
    </>
  )
}

export default OutboundDeereAPIEdit