import React, {  useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { string } from 'yup';
import '../forms/forms.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import IndustryService from '../../services/IndustryService'
import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Modal from '../../components/Modal/Modal'

import { number } from 'yup/lib/locale';

import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'

// *******************************************************************
import ReactPaginate from 'react-paginate'
import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { industryUri, pageSize, PortNumber, BaseURL } from '../../constants'
// ********************************************************************

let formValues = {
    id: number,
    industryName: string,
    description: string
}
const Industry = () => {
    const auth = useAuth();  
    const navigate = useNavigate();
    const [isLogin, setLogin] = useState(false);
    const [RecordAdded, setRecordAdded] = useState(0);

    // **************************************************************************************************
    const [modalOpen, setModalOpen] = useState(false);
    const [modalAction, setAction] = useState(false);
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState([]);
  
    useEffect(() => {
      const getComments = async () => {
        //   const res = await fetch(industryUri);
        //   const data = await res.json();
          const data = await IndustryService.getAllIndustries();

          const total = data.length;
          const TotalPages = Math.ceil(total/pageSize);
          setPageCount(TotalPages);
          setItems(data);

          const PageOneFromServer = await fetchComments(1);
          setItems(PageOneFromServer);
      };
  
      getComments();
  
    },[RecordAdded]);     
    
    const fetchComments = async(currentPage) => {
            // const res = await fetch(`${BaseURL}:${PortNumber}/Industry/GetPageIndustry?PageNumber=${currentPage}&PageSize=${pageSize}`);
            // const data = await res.json();
            const data = await IndustryService.getPagedIndustry(currentPage, pageSize);

            return data;
        };
          
  
        const handlePageClick = async (data) => {
            let currentPage = data.selected + 1;
            const commentsFromServer = await fetchComments(currentPage);
            setItems(commentsFromServer);
          }
          
    // **************************************************************************************************
    const { 
            register, watch, handleSubmit, 
            // setValue, resetValue,unregister, 
            // resetField, setError, clearErrors, 
            // setFocus, getValues, trigger, control,
            formState: { 
                errors, 
               // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
            } } 
        = useForm({ defaultValues: { id: 0, industryName: '', description: '' } });

    useEffect(() => {
        const subscription = watch((data)=>{
            console.log(data);
        })
        return ()=> {
            subscription.unsubscribe();
        }
    }, [watch]) 

    useEffect(() => {
        let userLogin = null;
          try
          {
            userLogin = JSON.parse(localStorage.getItem('credentials')).userName;
            AuthService.getForcedLogoutUser(userLogin).then((login)=>{                
                if ((login.loginStatus === 'ForcedLogout') || (login.loginStatus === 'Logout')) {                
                    localStorage.removeItem('credentials')
                    navigate("/Login");
                }});
            UserService.getUser(userLogin).then((user)=>{
                if (user.status === 'InActive') {                
                    localStorage.removeItem('credentials')
                    navigate("/Login");
                }});

      
          }
          catch{}
          
          
          if (userLogin === null || userLogin === undefined) {
            setLogin(false);
            navigate('/Login');
          }
          else
          {
            setLogin(true);
          }
        },[]);

    const onSubmit = (data, event) => {
        event.preventDefault();
        formValues = {
            id: 0,
            industryName: data.industryName.trim(),
            description: data.description.trim()
        };
        IndustryService.addIndustry(formValues).then((industry)=>{
            console.log('Form Values = ', formValues);
            console.log('Industry = ',industry);
            toast.success('Industry Added Successfully',{ position: 'bottom-center'});
            setRecordAdded((previousState)=> previousState + 1);
            clearValueForm();
        }).catch((e) => {
            console.log('Add Industry Error', e.message);
            toast.error('Adding Industry Error ',{ position: 'bottom-center'})
        });
    }
    const updateIndustry = () =>{
        setValueForm();
         IndustryService.editIndustry(formValues).then((industry)=>{
            industry.data ? toast.success('Industry Edited Successfully',{ position: 'bottom-center'}):toast.info('Profile does not exists',{ position: 'bottom-center'});
            setRecordAdded((previousState)=> previousState + 1);
            clearValueForm();
        })
        .catch((e) => {
            console.log('Edit Industry Error', e.message);
            toast.error('Edit Industry Error ',{ position: 'bottom-center'})
        });
    }
    const deleteIndustry = () =>{
        // setValueForm();
        // ProfileService.deleteProfile(formValues).then((profile)=> {
        //     profile.data ? toast.success('Profile Delete Successfully .... ',{ position: 'bottom-center'}):toast.info('Profile does not exists',{ position: 'bottom-center'});
        //     clearValueForm();
        // }).catch((e) => {
        //     console.log('Delete Profile Error', e.message);
        //     toast.error('Delete Profile Error ',{ position: 'bottom-center'})
        // });
    }

    const setValueForm = (() => { 
        formValues = {
            id: document.getElementById('id').value,
            industryName: document.getElementById('industryName').value,
            description: document.getElementById('description').value
         };
    });
 
    const clearValueForm = (() => {
        formValues = {
            id: '',
            industryName: '',
            description: ''
         };

         document.getElementById('id').value= '';
         document.getElementById('industryName').value= '';
         document.getElementById('description').value= '';
    });

    const GetIndustry = (selectedIndustry) =>{
        selectedIndustry = selectedIndustry.trim()
        IndustryService.getIndustry(selectedIndustry).then((resp) =>{
              const selectedProfile =  resp;
              document.getElementById('id').value= resp.id;
              document.getElementById('industryName').value= resp.industryName;
              document.getElementById('description').value= resp.description;
              console.log(selectedProfile);
          }).catch((e) => {
              console.log('Error', e.message);
          });    
  }

    return (
    <>
        {
            localStorage.getItem('credentials') && 
          <>



      <Container>
        <Row>
            <Col md = "4">
                   <div className="heading">
                        <h5 className="mainheading">Industries</h5>
                        <hr/>
                    </div>              

        
                    <form onSubmit={handleSubmit(onSubmit)}>

                    <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>

                    <input className="shortinput" type="text" {...register("industryName", { required: {value: true, message: "This is required field"}})}  id="industryName" placeholder="Industry Name"></input>
                    <h2>{errors.industryName && <p>{errors.industryName.message}</p>}</h2>

                    <input className="shortinput" type="text" {...register("description", { required: {value: true, message: "This is required field"}})}  id="description" placeholder="Description"></input>
                    <h2>{errors.description && <p>{errors.description.message}</p>}</h2>

                    <hr/>
                    <div className="container">
                        <div className="row">
                            <div className="col md-3 sm-3 xs-2">
                                <button className="submit" type="submit">Save</button>
                            </div>
                        </div>
                    </div>
                </form>  
                <hr/>
            </Col>

            <Col md = "2">
            <div className="heading">
            <h5 className="operation">Operations</h5>
            <hr/>
            </div>              

            <div className="container">
                <div className="row">
                    <div className="col md-3 sm-3 xs-2">
                        <button onClick={ updateIndustry } className="button" type="button">Update</button>
                    </div>

                    {/* <div className="col md-3 sm-3 xs-2">
                        <button onClick={ deleteProfile } className="button" type="button">Delete</button>
                    </div> */}

                    <div className="col md-3 sm-3 xs-2">
                        <button className="button"  onClick={() => { setValueForm(); setModalOpen(true); } }> Delete </button>
                    </div>

                    {    
                        modalOpen && 
                        <Modal 
                            setOpenModal={ setModalOpen } 
                            setAction = { setAction }
                            formValues = { formValues }
                            formName = "Industry"
                            title = "Are you sure to delete ?" 
                            message = "Industry will be deleted !" 
                            setRecordAdded = { setRecordAdded }
                            />
                    }            
                </div>
            </div>
            </Col>

          <Col md="6">
          <ReactPaginate 
            previousLabel = {'<'} 
            nextLabel = {'>'}
            breakLabel = {'...'}
            pageCount = { Math.ceil(pageCount) }
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}

            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}

            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}

            breakLinkClassName={'page-link'}
            activeClassName={'active'}
        />
          <Table striped bordered hover variant="dark">
          <thead>
                <tr key= { Math.random() }>
                  <th>Industry</th>
                  <th>Description</th>
                  <th className="theTh">Edit</th>
                </tr>
          </thead>
          <tbody>

          { 
            items.map((indus) => {
            return <>
                  <tr key= { Math.random() }>
                      <td> { indus.industryName }</td>
                      <td> { indus.description }</td>
                      <td  className="theTh"><button className="selectButton" onClick={(()=> GetIndustry(indus.industryName))}> Select</button></td>
                  </tr>
               </>
            })                  
          }
          </tbody>
          </Table>
        </Col>
        </Row>
      </Container>      

            </>        
        }
    </>
  )
}

export default Industry