
export const pageSize = 5;

// export const PortNumber = 8082; // 5001 is from direct API Code Run, 8082 is on IIS App
// export const BaseURL = "https://localhost";

// export const BaseURL = "http://68.142.177.151";
export const BaseURL = "https://marketingconsole.jamesriverequipment.com"; 
export const PortNumber = 44312;


export const EMSAPI = "https://inspect.jamesriverequipment.com/EMSMarketingConsoleAPI/api/EquipmentItems";
export const EMSDETAILAPI = "https://inspect.jamesriverequipment.com/EMSMarketingConsoleAPI/api/EquipmentItems";
export const DEEREAPI = "https://inspect.jamesriverequipment.com/EMSMarketingConsoleAPI/api/EquipmentItems";


// Base URL
export const RiverAPIURL = "http://localhost:2000";
export const DeereAPIURL = "http://localhost:2500";

// export const baseUri = 'http://localhost:3000';
// export const appUri = 'http://localhost:3000';
// export const baseUri = 'https://4riversapp-4rdevteam.vercel.app/';
// export const appUri = 'https://4riversapp-4rdevteam.vercel.app/';

export const baseUri = 'https://4riversapp.vercel.app/';
export const appUri = 'https://4riversapp.vercel.app/';


export const apiUri = `${RiverAPIURL}/RiversAPI?_page=1&_limit=${pageSize}`;
export const apiDeerUri = `${DeereAPIURL}/DeerAPI?_page=1&_limit=${pageSize}`;


// AUTHENTICATION API
export const authUri = `${BaseURL}:${PortNumber}/Auth`;
export const authLoginsUri = `${BaseURL}:${PortNumber}/Auth/ShowLogins`;

// USERS API
export const apiUsersUri = `${BaseURL}:${PortNumber}/Users/GetPageUser?PageNumber=1&PageSize=${pageSize}`;
export const usersPagedUri = `${BaseURL}:${PortNumber}/Users/GetPageUser`;
export const usersUri = `${BaseURL}:${PortNumber}/Users`;

// ROLES API
export const apiRolesUri = `${BaseURL}:${PortNumber}/Roles/GetPageRole?PageNumber=1&PageSize=${pageSize}`;
export const rolesPagedUri = `${BaseURL}:${PortNumber}/Roles/GetPageRole`;
export const rolesUri = `${BaseURL}:${PortNumber}/Roles`;

// SETTINGS API
export const settingsUri = `${BaseURL}:${PortNumber}/Settings`;

// PROFILE API
export const apiProfilesUri = `${BaseURL}:${PortNumber}/Profile/GetPageProfile?PageNumber=1&PageSize=${pageSize}`;
export const profilesPagedUri = `${BaseURL}:${PortNumber}/Profile/GetPageProfile`;
export const profilesUri = `${BaseURL}:${PortNumber}/Profile`;

// Industry API
export const apiIndustryUri = `${BaseURL}:${PortNumber}/Industry/GetPageIndustry?PageNumber=1&PageSize=${pageSize}`;
export const industryPagedUri = `${BaseURL}:${PortNumber}/Industry/GetPageIndustry`;
export const industryUri = `${BaseURL}:${PortNumber}/Industry`;

// Machine Type API
export const apiMachineTypeUri = `${BaseURL}:${PortNumber}/MachineType/GetPageMachineType?PageNumber=1&PageSize=${pageSize}`;
export const machineTypePagedUri = `${BaseURL}:${PortNumber}/MachineType/GetPageMachineType`;
export const machineTypeUri = `${BaseURL}:${PortNumber}/MachineType`;


// Machine Sub Types API
export const apiMachineSubTypeUri = `${BaseURL}:${PortNumber}/MachineSubType/GetPageMachineSubType?PageNumber=1&PageSize=${pageSize}`;
export const machineSubTypePagedUri = `${BaseURL}:${PortNumber}/MachineSubType/GetPageMachineSubType`;
export const machineSubTypeUri = `${BaseURL}:${PortNumber}/MachineSubType`;


// Make API
export const apiMakeUri = `${BaseURL}:${PortNumber}/Make/GetPageMake?PageNumber=1&PageSize=${pageSize}`;
export const makePagedUri = `${BaseURL}:${PortNumber}/Make/GetPageMake`;
export const makeUri = `${BaseURL}:${PortNumber}/Make`;

// Modales API
export const apiModalUri = `${BaseURL}:${PortNumber}/Modal/GetPageModal?PageNumber=1&PageSize=${pageSize}`;
export const modalPagedUri = `${BaseURL}:${PortNumber}/Modal/GetPageModal`;
export const modalUri = `${BaseURL}:${PortNumber}/Modal`;

// Stores API
export const apiStoreUri = `${BaseURL}:${PortNumber}/Store/GetPageStore?PageNumber=1&PageSize=${pageSize}`;
export const storePagedUri = `${BaseURL}:${PortNumber}/Store/GetPageStore`;
export const storeUri = `${BaseURL}:${PortNumber}/Store`;

// Store Hours API
export const apiStoreHoursUri = `${BaseURL}:${PortNumber}/StoreHours/GetPageStoreHours?PageNumber=1&PageSize=${pageSize}`;
export const storeHoursPagedUri = `${BaseURL}:${PortNumber}/StoreHours/GetPageStoreHours`;
export const storeHoursUri = `${BaseURL}:${PortNumber}/StoreHours`;

// ******************************** Products ******************************************************** // 
// ************************************************************************************************** // 

// Products API
export const apiProductUri = `${BaseURL}:${PortNumber}/Product/GetPageProduct?PageNumber=1&PageSize=${pageSize}`;
export const productPagedUri = `${BaseURL}:${PortNumber}/Product/GetPageProduct`;
export const productUri = `${BaseURL}:${PortNumber}/Product`;

// Products Extra Fields API
export const apiProductExtraUri = `${BaseURL}:${PortNumber}/Product/GetPageProductExtra?PageNumber=1&PageSize=${pageSize}`;
export const productExtraPagedUri = `${BaseURL}:${PortNumber}/ProductExtra/GetPageProductExtra`;
export const productExtraUri = `${BaseURL}:${PortNumber}/ProductExtra`;

// Products Dynamics Fields API
export const apiProductDynamicsUri = `${BaseURL}:${PortNumber}/ProductDynamics/GetPageProduct?PageNumber=1&PageSize=${pageSize}`;
export const productDynamicsPagedUri = `${BaseURL}:${PortNumber}/ProductDynamics/GetPageProductDynamics`;
export const productDynamicsUri = `${BaseURL}:${PortNumber}/ProductDynamics`;

// Product Base Machine API
export const apiProductBaseMachineUri = `${BaseURL}:${PortNumber}/ProductBaseMachine/GetPageProductBaseMachine?PageNumber=1&PageSize=${pageSize}`;
export const productBaseMachinePagedUri = `${BaseURL}:${PortNumber}/ProductBaseMachine/GetPageProductBaseMachine`;
export const productBaseMachineUri = `${BaseURL}:${PortNumber}/ProductBaseMachine`;


// Product Availability API
export const apiProductAvailabilityUri = `${BaseURL}:${PortNumber}/ProductAvailability/GetPageProductAvailability?PageNumber=1&PageSize=${pageSize}`;
export const productAvailabilityPagedUri = `${BaseURL}:${PortNumber}/ProductAvailability/GetPageProductAvailability`;
export const productAvailabilityUri = `${BaseURL}:${PortNumber}/ProductAvailability`;


// Product Attachments API
export const apiProductAttachmentUri = `${BaseURL}:${PortNumber}/ProductAttachment/GetPageProductAttachment?PageNumber=1&PageSize=${pageSize}`;
export const productAttachmentPagedUri = `${BaseURL}:${PortNumber}/ProductAttachment/GetPageProductAttachment`;
export const productAttachmentUri = `${BaseURL}:${PortNumber}/ProductAttachment`;


// Product Checkout API
export const apiProductCheckoutUri = `${BaseURL}:${PortNumber}/ProductCheckout/GetPageProductCheckout?PageNumber=1&PageSize=${pageSize}`;
export const productCheckoutPagedUri = `${BaseURL}:${PortNumber}/ProductCheckout/GetPageProductCheckout`;
export const productCheckoutUri = `${BaseURL}:${PortNumber}/ProductCheckout`;

// ******************************** Promotions ******************************************************** // 
// ************************************************************************************************** // 

// Promotions
export const apiPromotionUri = `${BaseURL}:${PortNumber}/Promotion/GetPagePromotion?PageNumber=1&PageSize=${pageSize}`;
export const promotionPagedUri = `${BaseURL}:${PortNumber}/Promotion/GetPagePromotion`;
export const promotionUri = `${BaseURL}:${PortNumber}/Promotion`;

// Promotions Builder
export const apiPromotionBuilderUri = `${BaseURL}:${PortNumber}/PromotionBuilder/GetPagePromotionBuilder?PageNumber=1&PageSize=${pageSize}`;
export const promotionBuilderPagedUri = `${BaseURL}:${PortNumber}/PromotionBuilder/GetPagePromotionBuilder`;
export const promotionBuilderUri = `${BaseURL}:${PortNumber}/PromotionBuilder`;


// ******************************** Locations ******************************************************** // 
// ************************************************************************************************** // 

// Locations
export const apiLocationUri = `${BaseURL}:${PortNumber}/Location/GetPageLocation?PageNumber=1&PageSize=${pageSize}`;
export const locationPagedUri = `${BaseURL}:${PortNumber}/Location/GetPageLocation`;
export const locationUri = `${BaseURL}:${PortNumber}/Location`;

// export const apiLocationUri = `https://riversapi.azurewebsites.net/Location/GetPageLocation?PageNumber=1&PageSize=${pageSize}`;
// export const locationPagedUri = `https://riversapi.azurewebsites.net/Location/GetPageLocation`;
// export const locationUri = `https://riversapi.azurewebsites.net/Location`;


// Locations Builder
export const apiLocationBuilderUri = `${BaseURL}:${PortNumber}/LocationBuilder/GetPageLocationBuilder?PageNumber=1&PageSize=${pageSize}`;
export const locationBuilderPagedUri = `${BaseURL}:${PortNumber}/LocationBuilder/GetPageLocationBuilder`;
export const locationBuilderUri = `${BaseURL}:${PortNumber}/LocationBuilder`;

export const INVENTORY = "INVENTORY";
export const INVENTORYENRICHMENT = "INVENTORYENRICHMENT";
export const GHOSTENRICHMENT = "GHOSTENRICHMENT";
export const ORPHANENRICHMENT = "ORPHANENRICHMENT";
// ************************************************************************************************
// Metadata Master Combos
// ************************************************************************************************
export const PromotionIDURI =`${BaseURL}:${PortNumber}/Promotion`
export const LocationCodeURI = `${BaseURL}:${PortNumber}/Location`
export const MachineTypeURI = `${BaseURL}:${PortNumber}/MachineType`
// ************************************************************************************************
// Base Machine Form
// ************************************************************************************************
export const IndustryURI = `${BaseURL}:${PortNumber}/Industry`
export const MachineSubTypeURI = `${BaseURL}:${PortNumber}/MachineSubType`
export const MakeURI = `${BaseURL}:${PortNumber}/Make`
export const ModalURI = `${BaseURL}:${PortNumber}/Modal`
export const ProductCodeURI = `${BaseURL}:${PortNumber}/Product`


// Deere API Endpoint Base
export const deereInstanceUri = `https://servicesext.deere.com/`
export const carouselInstanceUri = 'https://servicesext.deere.com/ddf/api/carouseljson'