import React, {  useEffect, useState } from 'react'
import Card from '../forms/ui/Card'
import { Row, Col, Container, Table } from 'react-bootstrap'
import classes from './forms.css'


import { useForm } from 'react-hook-form'
import { string } from 'yup';
import './forms.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import ProductCheckoutService from '../../services/ProductCheckoutService'
import UserService  from '../../services/UserService'
import AuthService from '../../services/AuthService'
import Modal from '../../components/Modal/Modal'
import { boolean, number } from 'yup/lib/locale';
import { useAuth } from '../../utilities/auth'
import { useNavigate } from 'react-router-dom'
import ProductBaseMachineService from '../../services/ProductBaseMachineService'

// *******************************************************************
import ReactPaginate from 'react-paginate'
//import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { industryUri, pageSize } from '../../constants'
import MetaData from '../../services/Data/MetaData'
// ********************************************************************


let formValues = {
    id: number,
    productCode: string,
    url: string,
    textH1: string,
    textH2: string,
    disclaimer: string,
    amount: number
}

const ProductCheckout = () => {
  const auth = useAuth();  
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);
  const [RecordAdded, setRecordAdded] = useState(0);
  const [ProductCode, setProductCode] = useState();
  // **************************************************************************************************
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setAction] = useState(false);
  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [ProductSet, setProductSet] = useState([]);
  const [RuleSet, setRuleSet] = useState([]);
  const [ModelName, setModalName] = useState();
  const [PackageDesc, setPackageDesc] = useState();

  const { 
    register, watch, handleSubmit, 
    // setValue, resetValue,unregister, 
    // resetField, setError, clearErrors, 
    // setFocus, getValues, trigger, control,
    formState: { 
        errors, 
       // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
    } } 
      = useForm({ defaultValues: {  id: 0, productCode: '', url: '', textH1: '', textH2: '', disclaimer: '', amount: 0 } });


      useEffect(() => {
        const getComments = async () => {
            const data = await ProductCheckoutService.getAllProductsCheckout();  
            const total = data.length;
            const TotalPages = Math.ceil(total/pageSize);
            setPageCount(TotalPages);
            setItems(data);
  
            const PageOneFromServer = await fetchComments(1);
            setItems(PageOneFromServer);
            const dataSet = await MetaData.getAllProduct();
            setProductSet(dataSet);
        };
    
        getComments();

        MetaData.getAllRules().then(data=> {
          console.log('data == >>>> ',data);
          setRuleSet(data);
          });       
  
    
      },[RecordAdded]);     
      
      const fetchComments = async(currentPage) => {
              const data = await ProductCheckoutService.getPagedProductCheckout(currentPage, pageSize);  
              return data;
          };
            
    
          const handlePageClick = async (data) => {
              let currentPage = data.selected + 1;
              const commentsFromServer = await fetchComments(currentPage);
              setItems(commentsFromServer);
            }
            
      // **************************************************************************************************
  
    const onSubmit = (data, event) => {
      event.preventDefault();
      if (data.productCode3 === '' || data.productCode3 === 'Select'){
        alert('Please Select Rule Code ! ');
        return;
      } 

      formValues = {
        id: 0,
        productCode: data.productCode3,
        url: data.url,
        textH1: data.textH1,
        textH2: data.textH2,
        disclaimer: data.disclaimer1,
        amount: data.amount        
      };

      ProductCheckoutService.getProductCheckout(data.productCode3.trim().replace("&","And").replace("+","And")).then((data)=>{
        if (data.length !== 0) {
          console.log('Data Exist =>> ',data)
          toast.info('Record Exists ! ',{ position: 'bottom-center'});        
          return;
        }
        else
        { 
                ProductCheckoutService.createProductCheckout(formValues).then((check)=>{
                  console.log('Form Values = ', formValues);
                  console.log('Checkout = ',check);
                  toast.success('Checkout Added Successfully',{ position: 'bottom-center'});
                  setRecordAdded((previousState)=> previousState + 1);
                  clearValueForm();
                }).catch((e) => {
                  console.log('Add Checkout Error', e.message);
                  toast.error('Adding Checkout Error ',{ position: 'bottom-center'})
              });
          }
      });
  }

  const updateCheckout = () =>{
    setValueForm();
     ProductCheckoutService.editProductCheckout(formValues).then((check)=>{
      check.data ? toast.success('Checkout Edited Successfully',{ position: 'bottom-center'}):toast.info('Checkout does not exists',{ position: 'bottom-center'});
        setRecordAdded((previousState)=> previousState + 1);
        clearValueForm();
    })
    .catch((e) => {
        console.log('Edit Checkout Error', e.message);
        toast.error('Edit Checkout Error ',{ position: 'bottom-center'})
    });
}
  const GetProductCheckout = (selectedCheckout) =>{
    ProductCheckoutService.getProductCheckout(selectedCheckout).then((resp) =>{
      console.log('Disclaimer == ', resp);
          const selectedCheckout =  resp;
          document.getElementById('id').value= resp.id;
          document.getElementById('productCode3').value= resp.productCode;
          document.getElementById('url').value= resp.url;
          document.getElementById('textH1').value= resp.textH1;
          document.getElementById('textH2').value= resp.textH2;
          document.getElementById('disclaimer1').value= resp.disclaimer;
          document.getElementById('amount').value= resp.amount;
          console.log(selectedCheckout);
      }).catch((e) => {
          console.log('Error', e.message);
      });    
}

  const setValueForm = (() => { 
    formValues = {
        id: document.getElementById('id').value,
        productCode: document.getElementById('productCode3').value,
        url: document.getElementById('url').value,
        textH1: document.getElementById('textH1').value,
        textH2: document.getElementById('textH2').value,
        disclaimer: document.getElementById('disclaimer1').value,
        amount: document.getElementById('amount').value
     };
  });
  
  const clearValueForm = (() => {
    formValues = {
        id: '',
        productCode: '',
        url: '',
        textH1: '',
        textH2: '',
        disclaimer: '',
        amount: ''
     };
  
     document.getElementById('id').value= '';
     document.getElementById('productCode3').value= '';
     document.getElementById('url').value= '';
     document.getElementById('textH1').value= '';
     document.getElementById('textH2').value= '';
     document.getElementById('disclaimer1').value= '';
     document.getElementById('amount').value= '';
  });
  
  const onChangeProductBox = async (e)=>{
    //setPromotionIDSet(e.target.value);
    ProductBaseMachineService.getProductBaseMachine(e).then(data=>{
      setPackageDesc(data.packageDesc);
      setModalName(data.modelName);
    })
    setProductCode(e);

    ProductCheckoutService.getProductCheckout(e).then((resp) =>{
      const selectedCheckout =  resp;
      if (resp.id !== undefined)
      {
              document.getElementById('id').value= resp.id;
        //      document.getElementById('productCode3').value= resp.productCode;
              document.getElementById('url').value= resp.url;
              document.getElementById('textH1').value= resp.textH1;
              document.getElementById('textH2').value= resp.textH2;
              document.getElementById('disclaimer1').value= resp.disclaimer;
              document.getElementById('amount').value= resp.amount;
              console.log(selectedCheckout);              
      } else 
      {
              document.getElementById('id').value = 0;
              document.getElementById('url').value= '';
              document.getElementById('textH1').value = '';
              document.getElementById('textH2').value = '';
              document.getElementById('disclaimer1').value = '';
              document.getElementById('amount').value = '';
              console.log(selectedCheckout);             
      }

  }).catch((e) => {
      console.log('Error', e.message);
  });    

}
  
  return (
    <>
      <Container>
      <Row>
          <Col md="12">
              <ReactPaginate 
                previousLabel = {'<'} 
                nextLabel = {'>'}
                breakLabel = {'...'}
                pageCount = { Math.ceil(pageCount) }
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}

                containerClassName={'pagination justify-content-center'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}

                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}

                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}

                breakLinkClassName={'page-link'}
                activeClassName={'active'}
            />
              <Table striped bordered hover variant="dark">
              <thead>
                    <tr key= { Math.random() }>
                      <th>Code</th>
                      <th>Text H1</th>
                      <th>Text H2</th>
                      <th>Disclaimer</th>
                      <th>Amount</th>
                      <th>Edit</th>
                    </tr>
              </thead>
              <tbody>

              { 
                items.map((check) => {
                return <>
                      <tr key= { Math.random() }>
                          <td> { check.productCode }</td>
                          <td> { check.textH1 }</td>
                          <td> { check.textH2 }</td>
                          <td> { check.disclaimer }</td>
                          <td> { check.amount }</td>
                          <td><button className="selectButton" onClick={(()=> GetProductCheckout(check.productCode))}> Select</button></td>
                      </tr>
                  </>
                })                  
              }
              </tbody>
              </Table>
            </Col>

      </Row>
          <Row>
                <Col md="10">

                        <div className="heading">
                            <h5 className="mainheading">Checkout</h5>
                            <hr/>
                        </div>              

        
                          <form onSubmit={handleSubmit(onSubmit)}>

                          <input hidden className="input" type="text" {...register("id")}  id="id" placeholder="ID"></input>

                          <label>Rule Code</label>
                              <select 
                                className="custom-select"
                                {...register("productCode3")} 
                                  id="productCode3"
                                  value = { ProductCode }
                                  onChange={(e)=> {
                                    onChangeProductBox(e.target.value);
                            			  }
                                }
                                >
                                <option  key={Math.random()}   value="Select">Select Rule</option> 
                                {
                                RuleSet.map((record)=>
                                ( 
                                    <option  key={Math.random()}  value={record.productCode}>{record.productCode}</option>
                                ))
                                }
                                </select>   
                              <h2>{errors.productCode3 && <p>{errors.productCode3.message}</p>}</h2>

                              <div className="Details">  
                                    {ModelName}
                                </div>

                              <label>URL</label>
                          <input className="mediuminput" type="text" {...register("url", { required: {value: true, message: "This is required field"} })}  id="url" placeholder="URL"></input>
                          <h2>{errors.url && <p>{errors.url.message}</p>}</h2>

                          <label>Text H1</label>
                          <input className="shortinput" type="text" {...register("textH1", { required: {value: true, message: "This is required field"} })}  id="textH1" placeholder="Text H1"></input>
                          <h2>{errors.textH1 && <p>{errors.textH1.message}</p>}</h2>

                          <label>Text H2</label>
                          <input className="shortinput" type="text" {...register("textH2", { required: {value: true, message: "This is required field"} })}  id="textH2" placeholder="Text H2"></input>
                          <h2>{errors.textH2 && <p>{errors.textH2.message}</p>}</h2>


                          <label>Disclaimer</label>
                          <input className="shortinput" type="text" {...register("disclaimer1", { required: {value: true, message: "This is required field"} })}  id="disclaimer1" placeholder="Disclaimer"></input>
                          <h2>{errors.disclaimer1 && <p>{errors.disclaimer1.message}</p>}</h2>

                          <label>Amount</label>
                          <input className="input" type="text" {...register("amount", { required: {value: true, message: "This is required field"} })}  id="amount" placeholder="Amount"></input>
                          <h2>{errors.amount && <p>{errors.amount.message}</p>}</h2>


                          <hr/>
                          <div className="container">
                              <div className="row">
                                  <div className="col md-3 sm-3 xs-2">
                                      <button className="submit" type="submit">Save</button>
                                  </div>
                              </div>
                          </div>
                      </form>  
                </Col>

                <Col md = "2">
            <div className="heading">
            <h5 className="operation">Operations</h5>
            <hr/>
            </div>              

            <div className="container">
                <div className="row">
                    <div className="col md-3 sm-3 xs-2">
                        <button onClick={ updateCheckout } className="button" type="button">Update</button>
                    </div>

                    {/* <div className="col md-3 sm-3 xs-2">
                        <button onClick={ deleteProfile } className="button" type="button">Delete</button>
                    </div> */}

                    <div className="col md-3 sm-3 xs-2">
                        <button hidden className="button"  onClick={() => { setValueForm(); setModalOpen(true); } }> Delete </button>
                    </div>

                    {    
                        modalOpen && 
                        <Modal 
                            setOpenModal={ setModalOpen } 
                            setAction = { setAction }
                            formValues = { formValues }
                            formName = "Product Checkout"
                            title = "Are you sure to delete ?" 
                            message = "Product Checkout will be deleted !" 
                            setRecordAdded = { setRecordAdded }
                            />
                    }            
                </div>
            </div>
            </Col>

          </Row>
      </Container>
    </>
  )
}

export default ProductCheckout