
import React, { useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useForm  } from 'react-hook-form'
import './style.css'



const OutboundDeereAPIView = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { 
        register, watch, handleSubmit,
        // setValue, resetValue,unregister, 
        // resetField, setError, clearErrors, 
        // setFocus, getValues, trigger, control,
        formState: { 
            errors, 
           // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
        } } 
    = useForm(
      //{ defaultValues: { Id: 0, Username: '', Password1: '', RoleId:'', ProfileId: '', Status: 'Inactive' } }
      );
  
      const onSubmit = (data, event) => {
        event.preventDefault();

    }

    const CancelAction = ((e) => {
        e.preventDefault();
        navigate('/Configurations');
      })
  
      const booleanify = (value: string): boolean => {
          const truthy: string[] = [
              'true',
              'True',
              '1'
          ]
        
          return truthy.includes(value)
        }


        return (
    <>
                <Container>
                    <Row>
                        <Col  md="10">
                            <div className="heading">
                                <h5 className = "mainheading">Deere API View Form</h5>
                                <hr/>
                            </div>

                            <table>
                                <tr key= { Math.random() }>
                                    <td>
                                        <label className="BlueLabel">SKU</label><br/>
                                        <input disabled defaultValue = { location.state.data.sku } className="input" type="text"  id="sku"></input>
                                    </td>    
                                    <td>
                                        <label className="BlueLabel">Model Number</label><br/>
                                        <input disabled defaultValue = { location.state.data.modelNumber } className="input" type="text"  id="modelNumber"></input>
                                    </td>    
                                    <td>
                                        <label className="BlueLabel">Model Name</label><br/>
                                        <input disabled defaultValue = { location.state.data.modelName } className="input" type="text"  id="modelName"></input>
                                    </td>    
                                </tr>

                                <tr key= { Math.random() }>
                                    <td>
                                        <label className="BlueLabel">Title</label><br/>
                                        <input disabled defaultValue = { location.state.data.title } className="input" type="text"  id="title"></input>
                                    </td>    
                                    <td>
                                        <label className="BlueLabel">Meta Desc</label><br/>
                                        <input disabled defaultValue = { location.state.data.metaDesc } className="input" type="text"  id="metaDesc"></input>
                                    </td>    
                                    <td>
                                        <label className="BlueLabel">Alt Text</label><br/>
                                        <input disabled defaultValue = { location.state.data.altText } className="input" type="text"  id="altText"></input>
                                    </td>    
                                </tr>

                                <tr key= { Math.random() }>
                                    <td>
                                        <label className="BlueLabel">Disclaimer</label><br/>
                                        <input disabled defaultValue = { location.state.data.disclaimer } className="input" type="text"  id="disclaimer"></input>
                                    </td>    

                                    <td>
                                        <label className="BlueLabel">System Value</label><br/>
                                        <input disabled defaultValue = { location.state.data.productSystemValue } className="input" type="text"  id="productSystemValue"></input>
                                    </td>    
                                    <td>
                                        <label className="BlueLabel">Component Desc</label><br/>
                                        <input disabled defaultValue = { location.state.data.componentDesc } className="input" type="text"  id="componentDesc"></input>
                                    </td>    
                                </tr>


                                <tr key= { Math.random() }>
                                    <td>
                                        <label className="BlueLabel">Product Price</label><br/>
                                        <input disabled defaultValue = { location.state.data.productPrice } className="input" type="text"  id="productPrice"></input>
                                    </td>    
                                    <td>
                                        <label className="BlueLabel">Base Code</label><br/>
                                        <input disabled defaultValue = { location.state.data.baseCode } className="input" type="text"  id="baseCode"></input>
                                    </td>    
                                    <td>
                                        <label className="BlueLabel">Denom Label</label><br/>
                                        <input disabled defaultValue = { location.state.data.denomLabel } className="input" type="text"  id="denomLabel"></input>
                                    </td>    
                                </tr>

                                <tr key= { Math.random() }>
                                    <td>
                                        <label className="BlueLabel">Optional Links</label><br/>
                                        <input disabled defaultValue = { location.state.data.optionalLinks } className="input" type="text"  id="optionalLinks"></input>
                                    </td>    
                                    {/* <td>
                                        <label className="BlueLabel">Title Question</label><br/>
                                        <input disabled defaultValue = { location.state.data.titleQuestion } className="input" type="text"  id="titleQuestion"></input>
                                    </td>     */}
                                </tr>
    
                            </table>

                                        <label className="BlueLabel">Product Overview</label><br/>
                                        <input disabled defaultValue = { location.state.data.productOverview } className="LongLabel" type="text"  id="productOverview"></input>
                                        <label className="BlueLabel">Keywords</label><br/>
                                        <input disabled defaultValue = { location.state.data.keywords } className="LongLabel" type="text"  id="keywords"></input><br/>
                                        <label className="BlueLabel">Image URLS</label><br/>
                                        <input disabled defaultValue = { location.state.data.imageURLS } className="LongLabel" type="text"  id="imageURLS"></input><br/>
                                        <label className="BlueLabel">Default URL</label><br/>
                                        <input disabled defaultValue = { location.state.data.defaultURL } className="LongLabel" type="text"  id="defaultURL"></input>


                        </Col>
                        <Col md="2">
                            <div className="heading">
                                <h5 className = "operation">Operation</h5>
                                <hr/>
                            </div>                            
                            <span>
                            <button className="viewButton" onClick={(e)=>CancelAction(e)} type="button">Cancel</button>
                            </span>
                            <hr/>
                        </Col>
                        </Row>
                    </Container>
    </>
  )
}

export default OutboundDeereAPIView