import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { PortNumber, BaseURL } from '../constants';
import MetaData from '../services/Data/MetaData'

//const axiosInstance = axios.create({baseURL: `https://localhost:${PortNumber}/`,});
// testuser:UserPassword 
// const accessToken = "dGVzdHVzZXJAcml2ZXJzLmNvbTpVc2VyUGFzc3dvcmQ="


// rajeshlohana@gmail.com:UserPassword 
 const accessToken = 'TWFya2V0aW5nQ29uc29sZVVzZXI6Wzk5YjVQY358Rl9BQlc3cC5AYlsrTjc3QjQzYW1aSlY=';
//  const accessToken1 = await MetaData.getAccessToken('testuser@rivers.com','UserPassword');
//  console.log('Access Token 1 ==>>>>> ', accessToken1);
// const username = 'MarketingConsoleUser';
// const password = '[99b5Pc~|F_ABW7p.@b[+N77B43amZJV';

const liveInstance = axios.create({
	baseURL: `https://inspect.jamesriverequipment.com/EMSMarketingConsoleAPI/api/EquipmentItems/GetCards`,
	//baseURL: `https://riversapi.azurewebsites.net`,
	headers: {
		Authorization: `Basic ${accessToken}`
 	},
//	credentials: true
})

// *****************************************************
// Not Recommended Approach
// *****************************************************
// axios.interceptors.request.use(config=>{
// 	config.headers.authorization = `Bearer ${accessToken}`
// 	return config;
// }, error=>{
// 	return Promise.reject(error);
// })


export default  liveInstance