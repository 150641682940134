
import { productAttachmentUri, apiProductAttachementUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class OutboundDeereService {

    static  getAllDeere() {
        return axiosInstance.get(`/OutboundDeere`)
                    .then((resp)=>resp.data);
    }

    static  getStatusDeere(Status) {
        return axiosInstance.get(`/OutboundDeere/GetStatus?Status=${Status}`)
                    .then((resp)=>resp.data);
    }

    static  getPagedDeere(currentPage, pageSize) {
        return axiosInstance.get(`/OutboundDeere/GetPageDeere?PageNumber=${currentPage}&PageSize=${pageSize}`)
                    .then((resp)=>resp.data);
    }

    static  getDeereRuleCode(RuleCode) {
        return axiosInstance.get(`OutboundDeere/GetRuleCode?RuleCode=${RuleCode}`)
                    .then((resp)=>resp.data);
    }
    static  getDeereSKU(SKU) {
        return axiosInstance.get(`OutboundDeere/GetSKU?SKU=${SKU}`)
                    .then((resp)=>resp.data);
    }

    static  getDeereModelNumber(ModelNumber) {
        return axiosInstance.get(`OutboundDeere/GetModelNumber?ModelNumber=${ModelNumber}`)
                    .then((resp)=>resp.data);
    }

    static  getDeereModelName(ModelName) {
        return axiosInstance.get(`OutboundDeere/GetModelName?ModelName=${ModelName}`)
                    .then((resp)=>resp.data);
    }

    static async bulkDeere(payload) {
        console.log('Bulk Ka Payload = ',payload);
        return await axiosInstance.post(`/OutboundDeere/RangeRover`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static  createDeere(payload) {
        console.log('JSON Version = ',payload);
        return axiosInstance.post(`/OutboundDeere`, payload, {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    // static  editDeereSpecification(SKU,Specification) {
    //     return axiosInstance.post(`OutboundDeere/Specification?SKU=${SKU}&Specification=${Specification}`, {
    //         headers: { 
    //             'Content-Type': 'application/json', 
    //             dataType: "json" 
    //         }
    //       });
    // }
    static  editDeereFeature(payload) {
        return axiosInstance.post(`OutboundDeere/Update`,JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static  setDeereStatus(payload) {
        return axiosInstance.patch(`OutboundDeere/UpdateStatus`,JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    

    
    static  deleteDeere(sku) {
        return axiosInstance.post(`OutboundDeere/Delete?SKU=${sku}`,{}, {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static  deleteAllDeere(payload) {
        return axiosInstance.post('OutboundDeere/DeleteAll', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default OutboundDeereService