import { promotionBuilderUri, apiPromotionBuilderUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class PromotionBuilderService {
    static  getAllPromotions() {
        return axiosInstance.get(`/PromotionBuilder`)
                    .then((resp)=>resp.data);
    }
    static  getAllPromotionsId() {
        return axiosInstance.get(`/PromotionBuilder`)
                    .then((resp)=>resp.data.promotionId);
    }

    static  getPagedPromotions(currentPage, pageSize) {
        return axiosInstance.get(`/PromotionBuilder/GetPagePromotionBuilder?PageNumber=${currentPage}&PageSize=${pageSize}`)
                            .then((resp)=>resp.data);
    }

    static  getPromotions(promotionId) {
        return axiosInstance.get(`/PromotionBuilder/GetPromotionBuilder?PromotionId=${promotionId}`)
                            .then((resp)=>resp.data);
    }

    static addPromotions(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.post(`/PromotionBuilder`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static editPromotions(payload) {
        return axiosInstance.patch("PromotionBuilder/Update", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static deletePromotions(payload) {
        return axiosInstance.post("/PromotionBuilder/Delete", JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default PromotionBuilderService