import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { string } from 'yup';
import './forms.css'
import Modal from '../../components/Modal/Modal'
import bcrypt from 'bcryptjs'
// import { Form, Button } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import UserService from '../../services/UserService'
import { number } from 'yup/lib/locale';

// ************** Pagination Logic *********************************
import ReactPaginate from 'react-paginate'
import { Row, Col, Container, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { usersUri, pageSize, PortNumber, BaseURL } from '../../constants'
// *******************************************************************

// https://ibaslogic.com/simple-guide-to-react-form/#:~:text=React%20needs%20an%20onChange%20handler%20to%20keep%20track,that%20will%20re-render%20the%20state%20using%20setFname%20function.

let formValues = {
    Id: number,
    Username: string,
    Password1: string,
    RoleId:string,
    ProfileId: string,
    Status: string
}
  
const User = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalAction, setAction] = useState(false);
    const [RecordAdded, setRecordAdded] = useState(0);

    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState([]);
  
    let [users, setUsers] = useState([]);

    useEffect(() => {
        const getComments = async () => {
            const res = await fetch(usersUri);
            const data = await res.json();
    
            const total = data.length;
            const PageSize = Math.ceil(total/pageSize);
    
            setPageCount(PageSize);
            setItems(data);

            const PageOneFromServer = await fetchComments(1);
            setItems(PageOneFromServer);  
        };
    
        getComments();
    
      },[RecordAdded]);     
      
      const fetchComments = async(currentPage) => {
    
         // console.log('Current Page = ', currentPage, 'Page Size = ', pageSize);
    
        // Page Data returned should be paginated data to display
        const res = await fetch(`${BaseURL}:${PortNumber}/Users/GetPageUser?PageNumber=${currentPage}&PageSize=${pageSize}`);
              const data = await res.json();
              //setItems(data);
              console.log('Page Data = ',data);
              return data;
          };
            
    
          const handlePageClick = async (data) => {
              let currentPage = data.selected + 1;
              const commentsFromServer = await fetchComments(currentPage);
              setItems(commentsFromServer);
            }
    
            const GetUser = (selectedUser) =>{
                UserService.getUser(selectedUser).then((resp) =>{
                      const selectedName =  resp;        
                      document.getElementById('Id').value= resp.id;
                      document.getElementById('Username').value= resp.username;
                      document.getElementById('Password1').value= resp.password1;
                      document.getElementById('RoleId').value= resp.roleId;
                      document.getElementById('ProfileId').value= resp.profileId;
                      console.log(selectedName);
                  }).catch((e) => {
                      console.log('Error', e.message);
                  });    
          }
        
    // ******************************************************************
    // Using useForm to manage forms data
    // ******************************************************************
    const { 
            register, watch, handleSubmit,
            // setValue, resetValue,unregister, 
            // resetField, setError, clearErrors, 
            // setFocus, getValues, trigger, control,
            formState: { 
                errors, 
               // isDirty, dirtyFields, touchedFields, isSubmitting, isSubmitted, isSubmitSuccessful, isValidating, isValid, submitCount 
            } } 
        = useForm({ defaultValues: { Id: 0, Username: '', Password1: '', RoleId:'', ProfileId: '', Status: 'Inactive' } });

    // ******************************************************************
    // Using useQuery hook Or we can use useState for each field
    //const { formValues } = useQuery([]);
    // ******************************************************************


    useEffect(() => {
        const subscription = watch((data)=>{
            console.log(data);
        })
        return ()=> {
            subscription.unsubscribe();
        }
    }, [watch]) 


    const onSubmit = (data, event) => {
        event.preventDefault();
        formValues = {
            Id:0,
            Username: data.Username,
            Password1: data.Password1,      //bcrypt.hashSync(data.Password1, 10),
            RoleId: data.RoleId,
            ProfileId: data.ProfileId,
            Status: 'Active'
        };        
        
        UserService.addUser(formValues).then((user)=>{
            console.log('User Added =',user);                     
            toast.success('User Added Successfully',{ position: 'bottom-center'});
            clearValueForm();
        }).catch((e) => {
            console.log('Add User Error', e.message);
            toast.error('Adding User Error ',{ position: 'bottom-center'})
          });
    }

    const updateUser = () =>{
        setValueForm();
         UserService.editUser(formValues).then((user)=>{
            user.data ? toast.success('User Edited Successfully',{ position: 'bottom-center'}):toast.info('User not exists',{ position: 'bottom-center'});
            clearValueForm();
        })
        .catch((e) => {
            console.log('Edit User Error', e.message);
            toast.error('Edit User Error ',{ position: 'bottom-center'})
        });
        }
 
    const deleteUser = () => {
        setRecordAdded((previousState)=> previousState - 1);

        // setValueForm();
        // UserService.deleteUser(formValues).then((user)=> {
        //     user.data ? toast.success('User Deleted Successfully',{ position: 'bottom-center'}):toast.info('User not exists',{ position: 'bottom-center'});
        //     clearValueForm();
        // }).catch((e) => {
        //     console.log('Delete User Error', e.message);
        //     toast.error('Delete User Error ',{ position: 'bottom-center'})
        // });
    }

    const deactivateUser = () => {
        formValues = {
            Id: document.getElementById('Id').value,
            Username: document.getElementById('Username').value,
            Password1: document.getElementById('Password1').value,
            RoleId: document.getElementById('RoleId').value,
            ProfileId: document.getElementById('ProfileId').value,
            Status: 'InActive'
         };
        UserService.deactivateUser(formValues).then((user)=> {
            user.data ? toast.success('User Deactivated Successfully',{ position: 'bottom-center'}):toast.info('User does not exists',{ position: 'bottom-center'});
            clearValueForm();
        }).catch((e) => {
            console.log('Deactivate User Error', e.message);
            toast.error('Deactivate User Error ',{ position: 'bottom-center'})
        });
    }

    const changePassword = () => {
        setValueForm();
        UserService.deleteUser(formValues).then((user)=> {
            user.data ? toast.success('User Deleted Successfully',{ position: 'bottom-center'}):toast.info('User not exists',{ position: 'bottom-center'});
            clearValueForm();
        }).catch((e) => {
            console.log('Delete User Error', e.message);
            toast.error('Delete User Error ',{ position: 'bottom-center'})
        });
    }
 
    const setValueForm = (() => {
        formValues = {
            Id: document.getElementById('Id').value,
            Username: document.getElementById('Username').value,
            Password1: document.getElementById('Password1').value,
            RoleId: document.getElementById('RoleId').value,
            ProfileId: document.getElementById('ProfileId').value,
            Status: 'Active'
         };
    });
 
    const clearValueForm = (() => {
        formValues = {
            Id: '',
            Username: '',
            Password1: '',
            RoleId: '',
            ProfileId: '',
            Status: ''
         };
         //setValue(formValues);
         console.log('Cleared', formValues);
         document.getElementById('Id').value= '';
         document.getElementById('Username').value= '';
         document.getElementById('Password1').value= '';
         document.getElementById('RoleId').value= '';
         document.getElementById('ProfileId').value= '';
    });
 

    return (
    <>

      <Container>
        <Row>
        <Col  md="5">
                        <div className="heading">
                            <hr/>
                            <h5 className = "mainheading">User Registration Form</h5>
                            <hr/>
                        </div>
                    
                        <form onSubmit={handleSubmit(onSubmit)}>

                        <input hidden className="input" type="text" {...register("Id")}  id="Id" placeholder="ID"></input>

                        <input className="input" type="text" {...register("ProfileId", { required: {value: true, message: "This is required one field"}, minLength: { value: 5, message: "minimum length 5 is required" } })}  id="ProfileId" placeholder="Profile ID"></input>
                        <h2>{errors.ProfileId && <p>{errors.ProfileId.message}</p>}</h2>

                        <input className="input" type="text" {...register("Username", { required: {value: true, message: "This is required one field"},  minLength: { value: 5, message: "minimum length 5 is required" } })}  id="Username" placeholder="User Name"></input>
                        <h2>{errors.Username && <p>{errors.Username.message}</p>}</h2>
                        
                        <select                                       
                            className="select" 
                             {...register("RoleId", { required: {value: true, message: "This is required field"} })} id="RoleId">
                            <option value="admin">Admin</option>
                            <option value="manager">Manager</option>
                            <option value="user">User</option>
                    </select>
                    <h2>{errors.RoleId && <p>{errors.RoleId.message}</p>}</h2>

                        <input className="input" type="password"   {...register("Password1", { required: {value: true, message:"This is required"} })} id="Password1" placeholder="Password"></input>
                        <h2>{errors.Password1 && <p>{errors.Password1.message}</p>}</h2>
                        <hr/>

                        <div className="container">
                            <div className="row">
                                <div className="col md-3 sm-3 xs-2">
                                    <button className="submit" type="submit">Save</button>
                                </div>
                                </div>
                        </div>
                    </form>  
                    <hr/>    
             </Col>
             <Col md="2">
                 <div className="heading">
                             <hr/>
                                <h5 className = "operation">Operations</h5>
                            <hr/>
                </div>

             <div className="container">
                         <div className="row">

                                {/* <div className="col md-3 sm-3 xs-2">
                                    <button onClick={ deleteUser } className="button" type="button">Delete</button>
                                </div> */}
                                <div className="col md-3 sm-3 xs-2">
                                    <button className="button"  onClick={() => { setValueForm(); setModalOpen(true); } }> Delete </button>
                                </div>

                                <div className="col md-3 sm-3 xs-2">
                                    <button onClick={ deactivateUser } className="button" type="button">Deactivate</button>
                                </div>
                                <div className="col md-3 sm-3 xs-2">
                                    <button 
                                    onClick = { updateUser } 
                                    className="button" type="button">Update</button>
                                </div>

                                <div className="col md-3 sm-3 xs-2">
                                    <button onClick={ changePassword } className="button" type="button">Password</button>
                                </div>
                                {    
                        modalOpen && 
                        <Modal 
                            setOpenModal={ setModalOpen } 
                            setAction = { setAction }
                            formValues = { formValues }
                            formName = "User"
                            title = "Are you sure to delete ?" 
                            message = "User will be deleted !" 
                            setRecordAdded = { setRecordAdded }
                            />
                    }          
                            </div>
                    </div>
             </Col>

          <Col md="5">
          <hr/>
         <ReactPaginate 
            previousLabel = {'<'} 
            nextLabel = {'>'}
            breakLabel = {'...'}
            pageCount = { Math.ceil(pageCount) }
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}

            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}

            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}

            breakLinkClassName={'page-link'}
            activeClassName={'active'}
        />

          <Table striped bordered hover variant="dark">
          <thead>
                <tr key= { Math.random() }>
                  <th>Profile ID</th>
                  <th>Username</th>
                  <th>Edit</th>
                </tr>
          </thead>
          <tbody>

          { 
            items.map((user) => {
            return <>
                  <tr key= { Math.random() }>
                      <td> { user.profileId }</td>
                      <td> { user.username }</td>
                      <td><button className="selectButton" onClick={(()=> GetUser(user.username))}> Select</button></td>
                  </tr>
               </>
            })                  
          }
          </tbody>
          </Table>
        </Col>
        </Row>
      </Container>      
    </>
  )
}

export default User