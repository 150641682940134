import axios from 'axios';
import { carouselInstanceUri } from '../constants';

const accessToken = 'eyJraWQiOiJQZmthOWFLWHVQcENsWVNJaVM4SzA5QlJXdklkaWx6RDAzTENsMjdoaG9zIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULlAzVUtDdWNNYlFlaGZjMFlUNHJxQTVXNjRIREZxRWY1Y1Y5MHRsZnV5NnMiLCJpc3MiOiJodHRwczovL3Nzby5qb2huZGVlcmUuY29tL29hdXRoMi9hdXNiMTB5Nmg4MWlvbGhoSjF0NyIsImF1ZCI6ImRjZSIsImlhdCI6MTY3Mjc1NjIxOSwiZXhwIjoxNjcyNzU5ODE5LCJjaWQiOiIwb2FvejVlcWhkRnF2aWR0VzF0NyIsInNjcCI6WyJkY2UiXSwic3ViIjoiMG9hb3o1ZXFoZEZxdmlkdFcxdDcifQ.jKkkbD6ykzLNMPPctSMRhnT0Ah0N2lTLKddI0FNLniWImGBqjhWTNkHfx1bFYVQrKp-FK2IYvbpttxjzACSi9cO7iKTF3Qh8xCPiyjKUvzYsYqsAkMG7Y0z2oTP3BML9y8NncQGnjx16hqzHkaDUOfdm-O8oODTEO7GvuaeTyJoluZ2wcWDHs-YLYfD0DICJDelNMHXLb-PeE0k25RtngUYJeJqJH6KwSL_CKsMKmiuutedEI4V2AMWiE_4j11tICQRlJrTV7YuBMON1sBS1zCsDuqNmTakPEEg7zBmDK_PXTYI8fKr9tAjzm43d_5IDiRCcI4FFWZGbyxmdS88Mwg';

const deereSpecial =  axios.create({
    baseURL: carouselInstanceUri,
    headers: {
        Authorization: `Bearer ${accessToken}`,
    },
})
export default  deereSpecial