import { productBaseMachineUri, apiProductBaseMachineUri } from '../constants'
import axiosInstance from '../services/AxiosInstance'

const axios = require('axios');

export class ProductBaseMachineService {

    static  getAllProductBaseMachines() {
        return axiosInstance.get(`/ProductBaseMachine`)
                    .then((resp)=>resp.data);
    }

    static  getPagedProductBaseMachine(currentPage, pageSize) {
        return axiosInstance.get(`/ProductBaseMachine/GetPageProductBaseMachine?PageNumber=${currentPage}&PageSize=${pageSize}`)
                    .then((resp)=>resp.data);
    }

    static  getProductBaseMachine(productId) {
        console.log('productID = ', productId);
        console.log('URI = ',`ProductBaseMachine/GetProductBaseMachine?ProductCode=${productId}`);
        
        return axiosInstance.get(`ProductBaseMachine/GetProductBaseMachine?ProductCode=${productId}`)
                            .then((resp)=>resp.data);
    }
    static  createProductBaseMachine(payload) {
        return axiosInstance.post(`/ProductBaseMachine`, JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static  editProductBaseMachine(payload) {
        console.log('Base Machine Edit Service = ', payload);
        return axiosInstance.patch('ProductBaseMachine/Update', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }

    static  UpdateStatus(payload) {
        console.log('Payload = ', payload);
        return axiosInstance.patch('ProductBaseMachine/UpdateStatus', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
    
    static  deleteProductBaseMachine(payload) {
        return axiosInstance.post('ProductBaseMachine/Delete', JSON.stringify(payload), {
            headers: { 
                'Content-Type': 'application/json', 
                dataType: "json" 
            }
          });
    }
}

export default ProductBaseMachineService